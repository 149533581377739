import WU from '../../images/partners/wu.png'
import Startup from '../../images/partners/startup.png'

export default function Partners() {
  return (
    <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 bg-pink-50 ">
      <div className="py-12 md:py-20">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center " data-aos="fade-up">
          <h2 className="h2 text-pink-500 mb-4"> OUR PARTNERS</h2>

          <div data-aos="fade-up" className='flex h-auto mt-20 gap-14 justify-center' data-aos-delay="400">
                <img src={WU}  className='h-32' alt={"evidenta WU"} />
                <img src={Startup}  className='h-32' alt={"evidenta WU"} />

          </div>
        </div>

      </div>
    </div>




  </section>
  )
}
