import logo from './logo.svg';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken, userLogin } from './redux/actions/auth';
import './css/style.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation
} from 'react-router-dom';
import PageRender from './customRouter/PageRender';
import Signin from './pages/Signin';
import React, { useEffect, useState } from 'react';
import Signup from './pages/Signup';
import Home from './pages/home';
import LandingPage from './pages/LandingPage';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';
import Resume from './pages/Resume';
import { ToastContainer } from 'react-toastify';
import AboutUs from './pages/AboutUs';
import TestPage from './pages/test';
import Account from './pages/Account';
import Feedback from './pages/Feedback';
import Features from './pages/Features';
import ContactUs from './pages/ContactUs';
import { useThemeProvider } from './utils/ThemeContext';
import ResetToken from './pages/ResetToken';
import { userLogout } from './redux/reducers/authSlice';
import Confirm from './pages/Confirm';
import JobListing from './pages/JobListing';
import JobPost from './components/JobListing/JobPost/JobPost';
import CompanyProfile from './pages/CompanyProfile';
import MyCompanies from './pages/MyCompanies';
import MyApplications from './pages/MyApplications';
import MyApplicants from './pages/MyApplicants';
import BankResumes from './pages/BankResumes';
import ResumeDetailled from './pages/ResumeDetailled';
import SigninEmployer from './pages/SigninEmployer';
import SignupEmployer from './pages/SignupEmployer';
import EmployersDashboard from './pages/Employers/EmployersDashboard';
import EmployersInvitations from './pages/Employers/EmployersInvitations';
import EmployersJobs from './pages/Employers/EmployersJobs';
import { ConfigProvider } from 'antd';
import EmployersCandidates from './pages/Employers/EmployersUsers';
import EmployersUnconfirmed from './pages/Employers/EmployersUnconfirmed';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminApplications from './pages/Admin/AdminApplications';
import EmployersCreation from './pages/Employers/EmployersCreation';
import { LoadScript } from '@react-google-maps/api';
import EmployersJobApplications from './pages/Employers/EmployersJobApplications';
import EmployersAccount from './pages/Employers/EmployersAccount';
import EmployersCompanyAccount from './pages/Employers/EmployersCompanyAccount';
import MyFavApplications from './pages/MyFavApplications';
import JobPage from './pages/JobPage';
import EmployersBankResumes from './pages/Employers/EmployersBankResumes';
import EmployersUsers from './pages/Employers/EmployersUsers';


const ProtectedRoute = ({ user, children, forUser = false, forEmployers = false, forAdmin = false }) => {
  const location = useLocation();

  const type = localStorage.getItem('type');
  if (!user.userToken) {
    // Redirect if there's no user token
    return <Navigate to="/landing" replace />;
  }

  const userRoles = user.userInfo.role;

  if (userRoles.includes('UNCONFIRMED_COMPANY')) {
    return <Navigate to="/employers/unconfirmed" replace />;
  }

  if (forEmployers && type !== 'company') {
    if (userRoles.includes('ROLE_ADMIN')) {
      return <Navigate to="/admin/dashboard" replace />;
    } else if (userRoles.includes('ROLE_USER')) {
      return <Navigate to="/" replace />;
    }
  }

  if (forUser && type !== 'user') {
    if (userRoles.includes('ROLE_ADMIN')) {
      return <Navigate to="/admin/dashboard" replace />;
    } else if (userRoles.includes('ROLE_COMPANY_PR') || userRoles.includes('ROLE_COMPANY_HR')) {
      if(userRoles.includes('UNCONFIRMED_COMPANY')){
        return <Navigate to="/employers/unconfirmed" replace />;
      } else {
        return <Navigate to="/employers/dashboard" replace />;
      }
    }
  }

  if (forAdmin && type !== 'admin') {
    if (userRoles.includes('ROLE_COMPANY_PR') || userRoles.includes('ROLE_COMPANY_HR')) {
      if(userRoles.includes('UNCONFIRMED_COMPANY')){
        return <Navigate to="/employers/unconfirmed" replace />;
      } else {
        return <Navigate to="/employers/dashboard" replace />;
      }
    } else if (userRoles.includes('ROLE_USER')) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};




const UnprotectedRoute = ({ user, children }) => {
  const type = localStorage.getItem('type');

  if (user.userToken) {
    const userRoles = user.userInfo.role;

    if (type === 'company') {
      if (userRoles.includes('UNCONFIRMED_COMPANY')) {
        return <Navigate to="/employers/unconfirmed" replace />;
      } else {
        return <Navigate to="/employers/dashboard" replace />;
      }
    }

    if (type === 'user') {
      return <Navigate to="/home" replace />;
    }

    if (type === 'admin') {
      return <Navigate to="/admin/dashboard" replace />;
    }
  }

  return children;
};


function App() {
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch();

  const API_PLACE = 'AIzaSyAyLpS93-2UchO9GPw6KMOaBVv49lYnPjU';
  const [libraries] = useState(['places']);


  


  useEffect( () => {
     dispatch(refreshToken({user}))
  
    }, []);
  



    return (
      <React.StrictMode>
        <ToastContainer />
  
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#f11a7b',
              borderRadius: 5,
            },
          }}
        >
          <LoadScript googleMapsApiKey={API_PLACE} libraries={libraries}>
            <Router>
              <Routes>
              <Route
                index
                element={
                  user.userToken
                    ? localStorage.getItem('type') === 'user'
                      ? <Home />
                      : <EmployersDashboard />
                    : <LandingPage />
                }
              /> <Route path="landing" element={<UnprotectedRoute user={user}><LandingPage /></UnprotectedRoute>} />
                <Route path="confirm" element={<UnprotectedRoute user={user}><Confirm /></UnprotectedRoute>} />
                <Route path="companies" element={<ProtectedRoute forUser={true} user={user}><MyCompanies /></ProtectedRoute>} />
                <Route path="applications" element={<ProtectedRoute forUser={true} user={user}><MyApplications /></ProtectedRoute>} />
                <Route path="vacancies/save" element={<ProtectedRoute forUser={true} user={user}><MyFavApplications /></ProtectedRoute>} />
                <Route path="resumes" element={<ProtectedRoute forUser={true} user={user}><BankResumes /></ProtectedRoute>} />
                <Route path="company/applicants/:id" element={<ProtectedRoute forUser={true} user={user}><MyApplicants /></ProtectedRoute>} />
                <Route path="home" element={<ProtectedRoute user={user} forUser={true}><Home /></ProtectedRoute>} />
                <Route path="settings/account" element={<ProtectedRoute user={user} forUser={true}><Account /></ProtectedRoute>} />
                <Route path="settings/feedback" element={<ProtectedRoute user={user} forUser={true}><Feedback /></ProtectedRoute>} />
                <Route path="resume/:id" element={<ProtectedRoute user={user} forUser={true}><Resume /></ProtectedRoute>} />
                <Route path="resume-detailled/:id" element={<ProtectedRoute user={user} forUser={true}><ResumeDetailled /></ProtectedRoute>} />
                <Route path="job/job-listing" element={<ProtectedRoute user={user} forUser={true}><JobListing /></ProtectedRoute>} />
                <Route path="job/job-listing/:id" element={<ProtectedRoute user={user} forUser={true}><JobPage /></ProtectedRoute>} />
                <Route path="job/job-post/:id" element={<ProtectedRoute user={user} forUser={true}><JobPost /></ProtectedRoute>} />
                <Route path="company/:id" element={<ProtectedRoute user={user} forUser={true}><CompanyProfile /></ProtectedRoute>} />
                <Route path="aboutus" element={<UnprotectedRoute user={user}><AboutUs /></UnprotectedRoute>} />
                <Route path="features" element={<UnprotectedRoute user={user}><Features /></UnprotectedRoute>} />
                <Route path="contactus" element={<UnprotectedRoute user={user}><ContactUs /></UnprotectedRoute>} />
                <Route path="signin" element={<UnprotectedRoute user={user}><Signin /></UnprotectedRoute>} />
                <Route path="reset-password" element={<UnprotectedRoute user={user}><ResetPassword /></UnprotectedRoute>} />
                <Route path="reset" element={<UnprotectedRoute user={user}><ResetToken /></UnprotectedRoute>} />
                <Route path="signup" element={<UnprotectedRoute user={user}><Signup /></UnprotectedRoute>} />
                <Route path="404-not-found" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="employers/signin" element={<UnprotectedRoute user={user}><SigninEmployer /></UnprotectedRoute>} />
                <Route path="employers/signup" element={<UnprotectedRoute user={user}><SignupEmployer /></UnprotectedRoute>} />
                <Route path="employers/dashboard" element={<ProtectedRoute user={user} forEmployers={true}><EmployersDashboard /></ProtectedRoute>} />
                <Route path="employers/job/:id" element={<ProtectedRoute user={user} forEmployers={true}><EmployersJobApplications /></ProtectedRoute>} />
                <Route path="employers/invitations" element={<ProtectedRoute user={user} forEmployers={true}><EmployersInvitations /></ProtectedRoute>} />
                <Route path="employers/unconfirmed" element={<ProtectedRoute user={user} forEmployers={true}><EmployersUnconfirmed /></ProtectedRoute>} />
                <Route path="employers/creation" element={<ProtectedRoute user={user} forEmployers={true}><EmployersCreation /></ProtectedRoute>} />
                <Route path="employers/jobs" element={<ProtectedRoute user={user} forEmployers={true}><EmployersJobs /></ProtectedRoute>} />
                <Route path="employers/users" element={<ProtectedRoute user={user} forEmployers={true}><EmployersUsers /></ProtectedRoute>} />
                <Route path="employers/bank" element={<ProtectedRoute user={user} forEmployers={true}><EmployersBankResumes /></ProtectedRoute>} />
                <Route path="employers/account" element={<ProtectedRoute user={user} forEmployers={true}><EmployersAccount /></ProtectedRoute>} />
                <Route path="employers/company/account" element={<ProtectedRoute user={user} forEmployers={true}><EmployersCompanyAccount /></ProtectedRoute>} />
                <Route path="admin/dashboard" element={<ProtectedRoute user={user} forAdmin={true}><AdminDashboard /></ProtectedRoute>} />
                <Route path="admin/applications" element={<ProtectedRoute user={user} forAdmin={true}><AdminApplications /></ProtectedRoute>} />
              </Routes>
            </Router>
          </LoadScript>
        </ConfigProvider>
      </React.StrictMode>
    );
}

export default App;
