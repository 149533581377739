import QRCode from "qrcode.react";


export default function HeroHome() {

  return (
    <section>
      <div className="max-w-6xl flex w-full justify-center items-center  mx-auto px-4 sm:px-6 relative h-screen">



        {/* Hero content */}
        <div className="relative flex items-center h-full ">

          {/* Section header */}
          <div className=" relative max-w-3xl mx-auto text-center ">

            <h1 className="h1 text-black pb-14 mb-14 border-b " data-aos="fade-up">EVERYTHING YOU DO<br/> HAS A <div className='relative' style={{display:"inline-block"}}> VALUE <div className='line'></div></div></h1>



            <p className="text-base md:text-xl  text-black mb-8" data-aos="fade-up" data-aos-delay="200">Verify your achievements, simplify career journey, reward excellence</p>



          </div>



        </div>

      </div>
    </section>
  )
}
