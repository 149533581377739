import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../images/company/office-building.png'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getVacancyById } from '../../../redux/actions/jobposting';
import { getLabelByValue } from '../../../utils/Utils';
import { Status } from '../../../utils/datas';
function JobApplicationListItem(props) {
    const navigate= useNavigate()

    const {auth} = useSelector(state=>state);
    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState(`${process.env.REACT_APP_URL_BACKEND}/file-service/api/v1/file/company/${props.job.companyId}`);

    const [isError, setIsError] = useState(false);

    useEffect(() => {
      console.log(props)
      const checkImage = async () => {
        try {
          const response = await fetch(imageUrl);
          if (!response.ok) {
            setIsError(true);
          }
        } catch (error) {
          setIsError(true);
        }
      };
  
      checkImage();
    }, [imageUrl]);

    const handleClick= async() => {
      const smBreakpoint = 768;
      const screenWidth = window.innerWidth;
      if(auth.userToken){
        try{
          let res =  await dispatch(getVacancyById({id: props.job.id, token: auth.userToken}))
          console.log(res)
          if(res.type !== "jobposting/vacancy/rejected"){
            if (screenWidth < smBreakpoint) {
              props.setJob({...props.job, ...res.payload.data})
              props.setModalOpenJob(true)
           } else {
             props.setJob({...props.job, ...res.payload.data})
           }

          }else{
            toast.error("An error occured")

          }
        }catch(e){
        }
     

  
      }
    }


  return (
    <div onClick={handleClick}
    className={`border  overflow-hidden px-5 py-4 hover:cursor-pointer 
    ${props.isSelected 
      ? '!bg-pink-50 rounded-xl  !border-l-4 !border-pink-500 !border-0'
      : 'hover:bg-pink-50 hover:border-0 hover:rounded-xl hover:border-l-4 hover:border-pink-500'
    } bg-white dark:bg-slate-800 border-0 border-b border-slate-300`}
    >
      <div className="flex justify-between items-center  md:space-y-0 space-x-2">
        {/* Left side */}
        <div className="flex items-center space-x-3 md:space-x-4">
          <div className="w-16 h-16 rounded-md  shrink-0 mt-1 hover:cursor-pointer" >
            <img  className="w-16 h-16   rounded-full" src={isError?logo:imageUrl} width="36" height="36" />
          </div>
          <div>
            <Link className="inline-flex font-semibold text-slate-800 dark:text-slate-100" /* to={`/job/job-post/${props.id}`} */>
              {props.job.title} 
            </Link>
            <div className="text-sm">{props.job.companyName && props.job.companyName}</div>

          </div>
        </div>
        {/* Right side */}
        <div className="flex-col  text-right  md:pl-0">
          <span className='italic text-sm'>{getLabelByValue(Status, props.job.status)}</span>
          
      {/*     {
            props.status === 'refused' && <span className='italic text-sm text-red-500 m-0 p-0'>Refused</span>
          }
            {
            props.status === 'accepted' && <span className='italic text-sm text-green-500 m-0 p-0'>Accepted</span>
          } */}
          <div className="text-sm text-slate-500 dark:text-slate-400 italic whitespace-nowrap">{moment(props.job.createdAt).fromNow()}</div>
        </div>
      </div>
    </div>
  );
}

export default JobApplicationListItem;