import React, { useEffect, useState } from 'react';


import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import PaginationNumeric from '../components/PaginationNumeric';
import ModalBasic from '../components/Modals/ModalBasic';
import FormCompany from '../components/Company/FormCompany';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesByAdmins } from '../redux/actions/companies';
import CompanyCard from '../components/Company/CompanyCard';

function MyCompanies() {

  const {auth, companies} = useSelector(state=>state)

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(auth)
    if(auth.userInfo.user){
        dispatch(getCompaniesByAdmins({userId:auth.userInfo.user._id, token: auth.userToken}))

    }
  }, [])
  

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */} 
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Companies pages </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Add member button */}
                <button onClick={(e) => { e.stopPropagation(); setCreateModalOpen(true); }}  className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Create Company Page</span>
                </button>
              </div>

            </div>

           {companies.companies.length === 0 && <div className="border-t border-slate-200 dark:border-slate-700">
              <div className="max-w-2xl m-auto mt-16">
                <div className="text-center px-4">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-slate-200 to-slate-100 dark:from-slate-700 dark:to-slate-800 mb-4">
                    <svg className="w-5 h-6 fill-current" viewBox="0 0 20 24">
                      <path className="text-slate-500 dark:text-slate-600" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                      <path className="text-slate-300 dark:text-slate-400" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                      <path className="text-slate-400 dark:text-slate-500" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
                    </svg>
                  </div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-2">No page created</h2>
                  <div className="mb-6">
                    Don't waste any time and start creating your company page!
                  </div>
                  <button className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="ml-2" onClick={(e) => { e.stopPropagation(); setCreateModalOpen(true); }} >Create Company Page</span>
                  </button>
                </div>
              </div>
            </div>}

            {/* Cards */}
             <div className="grid grid-cols-12 gap-6">
              {
                companies.companies.map(item => {
                  return (
                    <CompanyCard
                      key={item.id}
                      id={item._id}
                      name={item.name}
                      image={item.image}
                      link={item.link}
                      location={item.location}
                      content={item.content}
                    />
                  )
                })
              }
            </div>
 
            {/* Pagination */}
            {companies.companies > 0 &&
                <div className="mt-8">
                    <PaginationNumeric />
                </div>
            }
 

          </div>
        </main>

        <ModalBasic id="basic-modal" modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} title="Create New Page">
        {/* Modal content */}
        <div className="px-5 pt-4 pb-1">
          <div className="text-sm">
          <div className="px-5 py-4">
          <FormCompany setCreateModalOpen={setCreateModalOpen}/>
        </div>
          </div>
        </div>
     
      </ModalBasic>

      </div>
      
    </div>
  );
}

export default MyCompanies;