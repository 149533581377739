import image1 from '../../images/landing/image1.png'
import image2 from '../../images/landing/image2.png'
import avatar from '../../images/landing/avatar.png'

export default function Process() {
  return (
    <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-20 ">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-aos="fade-up">
          <h2 className="h2 text-black mb-4">GET YOUR DREAM JOB</h2>

        </div>
        <div className='flex-col md:flex-row flex gap-12 relative'>
    <div className=' md:w-1/2 h-full'>
      <img className='w-full h-full object-cover' src={image1} alt="Image 1" />
    </div>

    <div className='md:w-1/2 h-full'>
      <img className='w-full h-full object-cover' src={image2} alt="Image 2" />
    </div>

    <div className="absolute flex flex-col md:w-1/4 shadow-2xl rounded-2xl p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white">
      <div className='flex justify-between items-center'>
          <img  className='w-10' src={avatar} alt="avatar"/>
          <span className='text-gray-300'> 5 min. ago</span>
      </div>

      <span className='text-black'>John Smith had invited you to <b>interview</b></span>

      <div className='flex justify-center gap-4 pt-2'>
          <div className='flex font-semibold text-white px-4  py-1 justify-center items-center bg-pink-500 rounded-2xl'>Accept</div>
          <div className='flex  font-semibold text-gray-800 bg-gray-300 px-4  py-1 justify-center items-center rounded-2xl'>Decline</div>

      </div>

</div>

</div>



    




      </div>
    </div>
  </section>
  )
}
