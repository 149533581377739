import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Radio } from 'antd';
import { Remote } from '../../../../utils/datas';
import { getLabelByValue } from '../../../../utils/Utils';
import { useDispatch } from 'react-redux';
import { updateFilters } from '../../../../redux/reducers/filtersSlice';

const RemoteFilter = ({setIsModalOpen}) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const dispatch = useDispatch();
    const handleChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;

        // Add or remove the value based on whether the checkbox was checked or unchecked
        setSelectedValues(prev => checked ? [...prev, value] : prev.filter(item => item !== value));
    };

    
    

  
  return (
    <div className='flex flex-col gap-8'>
        <span>Your ideal home-office rhythm</span>

        <div className='grid grid-cols-2 gap-2'>
            {Remote.map((data) => (
                   <Checkbox
                   key={data.value} // Assuming your data objects have a value property
                   value={data.value}
                   onChange={handleChange}
                   checked={selectedValues.includes(data.value)} // Control the checked state
               >
                   {getLabelByValue(Remote, data.value)}

               </Checkbox>
            ))}

        </div>


        <div className='flex gap-2'>
            <Button type="primary" className='flex items-center !rounded-2xl bg-[#f11a7b] gap-2' size="large" onClick={()=> {dispatch(updateFilters({remote:selectedValues}));setSelectedValues([]); setIsModalOpen(false)}}> 
                <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="m15.7 14.3l-4.2-4.2c-.2-.2-.5-.3-.8-.3c.8-1 1.3-2.4 1.3-3.8c0-3.3-2.7-6-6-6S0 2.7 0 6s2.7 6 6 6c1.4 0 2.8-.5 3.8-1.4c0 .3 0 .6.3.8l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-.9 0-1.3M6 10.5c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5s4.5 2 4.5 4.5s-2 4.5-4.5 4.5"/>
                </svg>
                Explore jobs
            </Button>

            <Button size="large" onClick={() => {setSelectedValues([]); setIsModalOpen(false)}}> 
                Cancel
            </Button>
        </div>
    </div>
  );
};

export default RemoteFilter;
