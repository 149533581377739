import React, { useEffect, useState } from 'react';



import Image01 from '../images/company-icon-05.svg';
import Image02 from '../images/company-icon-06.svg';
import Image03 from '../images/company-icon-03.svg';
import Image04 from '../images/company-icon-07.svg';
import Image05 from '../images/company-icon-08.svg';
import Image06 from '../images/company-icon-01.svg';
import Image07 from '../images/company-icon-02.svg';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import DropdownSort from '../components/DropdownSort';
import JobListItem from '../components/JobListing/JobListItem';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SearchBar from '../components/JobListing/SearchBar/SearchBar';
import { Button, Modal, Pagination, Tag } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormApplication from '../components/JobListing/JobPost/FormApplication';
import { searchVacancies } from '../redux/actions/jobposting';
import { extractLocalityAndCountry, getLabelByValue } from '../utils/Utils';
import { EducationRequirement, JobType, Remote, WorkSchelule } from '../utils/datas';
import Job from '../components/JobListing/Job';
import { useLocation, useNavigate } from 'react-router-dom';

function JobPage() {

  const location = useLocation();
  const navigate = useNavigate();
  const job = location.state?.job;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
 

  useEffect(() => {
    if(!job){
      navigate('/job/job-listing')
    }
  }, [])
  




  return (
    <div className="flex h-screen overflow-hidden bg-white">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <main className="relative flex flex-col flex-1 overflow-y-hidden  overflow-x-hidden">

        <div className='absolute top-2 left-4'>
          
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 2048 2048">
          <path fill="currentColor" d="M2048 1088H250l787 787l-90 90L6 1024L947 83l90 90l-787 787h1798v128z"/>
      </svg>
        </div>
        {/*  Site header */}
{/*         <Header  style={{ height: "30%", background: "blue" }} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
 */}

        <div className='px-4 mt-8'>
          <Job  job={job} setCreateModalOpen={setCreateModalOpen}/>

        </div>
      </main>



    </div>
  );
}

export default JobPage;