import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import NewResumePdf from '../components/Resume/pdf/NewResumePdf';
import { getJobApplicationByUserId } from '../redux/actions/jobapplication';
import PaginationNumeric from '../components/PaginationNumeric';
import DropdownSort from '../components/DropdownSort';
import ApplicantsListItem from '../components/JobApplications/ApplicantListItem';

function MyApplicants() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { jobapps, modal, auth } = useSelector(state => state)
    const [pdfSrc, setPdfSrc] = useState(null);
    let { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        console.log(auth)
        if (auth.userInfo.user) {
            dispatch(getJobApplicationByUserId({ filter: `company=${id}`, token: auth.userToken }))

        }
    }, [])

    const backendURL = process.env.REACT_APP_URL_BACKEND


    return (
        <div className="flex h-[100dvh] overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main className="grow">
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <div className="flex flex-col space-y-10  sm:space-y-0  md:space-x-0 md:space-y-10   xl:space-y-0 ">

                                <div className="mb-8">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">My applicants </h1>
                                </div>

                                <div className='w-full'>

                                    {/* Search form */}
                                    <div className="mb-5">
                                        <form className="relative ">

                                            <label htmlFor="job-search" className="sr-only">Search</label>
                                            <div className='d-flex w-full '>
                                                <input id="job-search" className="d-flex w-11/12 form-input flex-1 pl-9 bg-white dark:bg-slate-800" type="search" placeholder="Search applications…" />
                                                <button className="w-1/12 d-flex" type="submit" aria-label="Search">
                                                    <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </form>
                                    </div>

                                    {/* Jobs header */}
                                    <div className="flex justify-between items-center mb-4">
                                        <div className="text-sm text-slate-500 dark:text-slate-400 italic">Showing {jobapps.totalJobApps} Applications</div>
                                        {/* Sort */}
                                        <div className="text-sm">
                                            <span>Sort by </span>
                                            <DropdownSort align="right" />
                                        </div>
                                    </div>

                                    {/* Jobs list */}
                                    <div className='space-y-2'>
                                        {jobapps.jobapps.map((jobapp) => {
                                            return (
                                                <ApplicantsListItem
                                                    key={jobapp.job._id}
                                                    id={jobapp.job._id}
                                                    jobapp={jobapp._id}
                                                    image={jobapp.job.image}
                                                    title={jobapp.job.title}
                                                    tags={jobapp.job.tags}
                                                    date={jobapp.createdAt}
                                                    fav={jobapp.job.fav}
                                                    company={jobapp.job.company}
                                                    resume={jobapp.resume}
                                                    user={jobapp.user}

                                                    fullname={jobapp.user.fullname}
                                                    message={jobapp.message}
                                                    status={jobapp.status}
                                                    job={jobapp.job._id}
                                                />
                                            );
                                        })}
                                    </div>

                                    {/* Pagination */}
                                    <div className="mt-6">
                                        <PaginationNumeric totalJobs={jobapps.totalJobApps} page={jobapps.page} pageSize={jobapps.pageSize} />

                                    </div>

                                </div>

                            </div>
                            <NewResumePdf className='hidden  md:flex '
                                pdfSrc={pdfSrc} setPdfSrc={setPdfSrc} />

                        </div>
                    
                    </div>



                </main>


            </div>
        </div>
    );
}

export default MyApplicants;