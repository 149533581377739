import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    type: '',
    isOpen: false,
    name: "",
    title: "",
    isEdit: false,
    data: null
  },
  reducers: {

    openModal: (state, action) => {
        state.type = action.payload.type;
        state.isOpen = action.payload.isOpen;
        state.name = action.payload.name;
        state.title = action.payload.title;
        state.isEdit = action.payload.isEdit;
        state.data = action.payload.data;

      },
  }
})

// Action creators are generated for each case reducer function
export const { openModal } = modalSlice.actions

export default modalSlice.reducer