import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditMenu from '../../components/DropdownEditMenu';
import logo from '../../images/company/office-building.png'
import { useDispatch, useSelector } from 'react-redux';
import ModalBlank from '../Modals/ModalBlank';
import { deleteCompany } from '../../redux/actions/companies';
import { toast } from 'react-toastify';
function CompanyCard(props) {
    const {auth} = useSelector(state=>state)
    const navigate = useNavigate();
    const [dangerModalOpen, setDangerModalOpen] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteResume = async (id) => {

   
        const actionResult = await  dispatch(deleteCompany({ id,token: auth.userToken }));
        if (deleteCompany.fulfilled.match(actionResult)) {
            toast.success(`Successfully deleted!`);
        } else {
            toast.error('Unknown error occurred, try again!');
    
        }
        setDangerModalOpen(false);
    
      }
  return (
    <>
    <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div className="flex flex-col h-full">
        {/* Card top */}
        <div className="grow p-5">
          {/* Menu button */}
          <div className="relative">
            <EditMenu align="right" className="absolute top-0 right-0 inline-flex">
              <li>
                <Link className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3" to="#0">Edit</Link>
              </li>

              <li>
                <Link className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"   onClick={(e) => { e.stopPropagation();  setDangerModalOpen(true); } }>Remove</Link>
              </li>
            </EditMenu>
          </div>
          {/* Image + name */}
          <header>
            <div className="flex justify-center mb-2"  onClick={() => navigate(`/company/${props.id}`)} >
                <img className="rounded-full" src={props.image?props.image: logo} width="64" height="64" alt={props.name} />
            </div>
            <div className="text-center"  onClick={() => navigate(`/company/${props.id}`)} >
              <Link className="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white" to={props.link}>
                <h2 className="text-xl leading-snug justify-center font-semibold">{props.name}</h2>
              </Link>
            </div>
          </header>
          {/* Bio */}
          <div className="text-center mt-2">
            <div className="text-sm">{props.content}</div>
          </div>
        </div>

      </div>
    </div>

    <ModalBlank id="danger-modal" modalOpen={dangerModalOpen} setModalOpen={setDangerModalOpen}>
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Delete Page?</div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>Alert!  You're about to delete this page. Are you sure you're ready to say goodbye?</p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setDangerModalOpen(false); }}>Cancel</button>
              <button onClick={(e) => handleDeleteResume(props.id)} className="btn-sm bg-rose-500 hover:bg-rose-600 text-white">Yes, Delete it</button>
            </div>
          </div>
        </div>
      </ModalBlank>
    </>
  );
}

export default CompanyCard;
