import React, { useState, useRef, useEffect, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onEditorChange, handleError, name, isRequired, MAX_CHARS, CHARS_CV_DISPLAY }, ref) => {
    const [error, setError] = useState('');

  const handleChange = (content) => {
    const text = ref.current.getEditor().getText().trim();

    if (isRequired && !text) {
      setError('Description is required');
      handleError(name, 'Description is required');
    } else {
      setError('');
      handleError(name, '');
    }

    if (text.length <= MAX_CHARS) {
      onEditorChange(content, name);
    } else {
      const truncatedText = text.substring(0, MAX_CHARS);
      ref.current.getEditor().setText(truncatedText);
      onEditorChange(truncatedText, name);
      console.log("Character limit exceeded");
    }
  };



  const editorStyle = {
    backgroundColor: '#f4f4f5',
    "minHeight": '200px',
    borderRadius: '1rem', // Add this line
};

const extractText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
};

  return (
    <>
      <label className="block text-sm font-normal mb-1 mt-6">Description ({CHARS_CV_DISPLAY} characters displayed)</label>
      <ReactQuill
        ref={ref}
        style={editorStyle}  
       className='mt-2 bg-red-200'
        value={value}
        onChange={handleChange}
      />
      <div className="text-xs text-gray-500 mt-1 self-end">
        {extractText(value).trim().length}/{MAX_CHARS}
      </div>
      {error && <div className="text-xs text-red-500 mt-1">{error}</div>}
    </>
  );
});

export default Editor;
