import React, { useEffect, useState } from 'react'
import ProgressBar from './ProgressBar'
import Job1 from './Job1'
import Job2 from './Job2'
import Job3 from './Job3'
import Job4 from './Job4'
import { getValueByLabel } from '../../../../utils/Utils'
import { EducationRequirement, JobType, WorkSchelule } from '../../../../utils/datas'

const FormJob = ({setStep,step, formData, setFormData , currentJob, isEdit, setCreateModalOpen, setIsValid, setIsLoading}) => {
 
 /*    useEffect(() => {
      if(currentJob){
        console.log("curent job")
        console.log(formData)
        const dataSource = {
          ...currentJob,
          employmentType: getValueByLabel(JobType, currentJob.employmentType),
          workSchedule: getValueByLabel(WorkSchelule, currentJob.workSchedule),

          educationRequirement: getValueByLabel(EducationRequirement, currentJob.educationRequirement),
          minYearsExperience: currentJob.minYearsExperience ===  0 ? null: currentJob.minYearsExperience,
          maxYearsExperience: currentJob.maxYearsExperience ===  1000 ? null:currentJob.maxYearsExperience

        };
        console.log(dataSource); 
        setFormData(dataSource)
      }
    }, [currentJob]); 
 */
  


    
    
  return (
    <div>
            <ProgressBar step={step} setStep={setStep}/>
            {step === 1 &&   <h2 className="text-xl text-center leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Job Information</h2>}
            {step === 2 &&   <h2 className="text-xl text-center leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">1/2 Job Details</h2>}
            {step === 3 &&   <h2 className="text-xl text-center leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">2/2Job Details</h2>}
            {step === 4 &&   <h2 className="text-xl text-center leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Last step</h2>}

            {step=== 1 && <Job1 formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>}
            {step=== 2 && <Job2 formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>}
            {step=== 3 && <Job3 isEdit={isEdit} formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>}
            {step=== 4 && <Job4 setIsValid={setIsValid} setIsLoading={setIsLoading} setCreateModalOpen={setCreateModalOpen} formData={formData} setFormData={setFormData} step={step} setStep={setStep} isEdit={isEdit} />}

    </div>
  )
}

export default FormJob