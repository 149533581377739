
import React from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { GrClose, GrValidate } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { updateItemResume, updateSectionResume } from '../../../redux/actions/resumes';
import { openModal } from '../../../redux/reducers/modalSlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const Item = ({ data, title, name, resumeId }) => {
  /*    const modal = useModalStore((state) => state.modal);
     const updateModal = useModalStore((state) => state.updateModal);
     const updateResume = useResumeStore((state) => state.updateResume); */
     const {auth} = useSelector(state=>state)
  const {id} = useParams();
  const dispatch = useDispatch();
  const handleHide = async (event) => {
    event.stopPropagation()

    try {
      let newData = { ...data };  // creates a new object that is a copy of `data`
      let isHidden = !data.isHidden;
      console.log(isHidden)
      newData.isHidden = isHidden;
      console.log(newData)
      let res = await dispatch(updateItemResume({ id, name: name, resumeData: newData, token: auth.userToken }))
      console.log(res)
      if(res.type === 'resume/updateItemResume/rejected'){
        console.log(res)
        toast.error(res.payload.response.data || "An error occurred while updating the resume.")
       }else{
        toast.success("Success!")

      }

    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the resume.")

      // Handle the error case, e.g. show an error message
    }

  }
  return (
    <div className='flex py-3 px-4 hover:bg-slate-200 dark:hover:bg-slate-700 w-full ' style={{wordBreak:'break-word'}} onClick={(e) => { e.stopPropagation(); console.log(data); dispatch(openModal({ type: 'formModal', title: title, isOpen: true, name: name, isEdit: true, data: data })) }}>
      {
        name === 'educations' &&
        <div className='flex justify-between w-full items-center'>

          <div className='flex flex-col'>
            <div className='flex items-start flex-col   break-words'>

              <span className='flex text-base font-bold break-words'>{data.degree}                         {data.file && <BsFillPatchCheckFill className='text-2xl w-10 text-green-600' />}
</span>
              <span className='italic break-words'> {data.school}</span>
              
{/*               {data.certificationtype === 2 && <BsFillPatchCheckFill className='text-2xl ml-2 text-yellow-600' />}
 */}
            </div>
            <div className='flex items-baseline'>
              <div>
                <span className='text-sm'>
                  {data.startDate && data.startDate}
                </span>
                { <> - </>}
                <span className='text-sm'>
                  {data.endDate? data.endDate : ' current'}
                </span>
              </div>

              {((data.startDate || data.endDate) && (data.city || data.country)) && <div className='mx-2'> | </div>}
              <span className='text-sm  '>{data.city}</span>
              {(data.city && data.country) && <>, </>}
              <span className='text-sm  '>{data.country}</span>

            </div>

          </div>

          <div className="flex-col md:flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600"  onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }}>
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }


      {
        name === 'works' &&
        <div className='flex justify-between w-full items-center'>
          <div className='flex flex-col'>
            <div className='flex flex-col items-start'>
              <span className='flex text-lg font-bold'>{data.title} {data.file && <BsFillPatchCheckFill className='text-2xl w-10 text-green-600' />}
</span>
              <span className=' italic'>{data.employer}</span>
{/*               {data.certificationtype === 2 && <BsFillPatchCheckFill className='text-2xl ml-2 text-yellow-600' />}
 */}            </div>
            <div className='flex items-baseline'>
              <div>
                <span className='text-sm'>
                  {data.startDate && data.startDate}
                </span>
                { <> - </>}
                <span className='text-sm'>
                  {data.endDate? data.endDate : ' current'}
                </span>
              </div>

              {((data.startDate || data.endDate) && (data.city || data.country)) && <div className='mx-2'> | </div>}
              <span className='text-sm  '>{data.city}</span>
              {(data.city && data.country) && <>, </>}
              <span className='text-sm  '>{data.country}</span>

            </div>

          </div>
          <div className="flex flex-col gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }


      {
        name === 'skills' &&
        <div className='flex justify-between w-full items-center'>
          <span className='text-lg font-bold '>{data.skillName}</span>

          <div className="flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }


      
      {
        name === 'certificatesView' &&
        <div className='flex justify-between w-full items-center'>
          <span className='text-lg font-bold '>{data.title}</span>

          <div className="flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button  onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }}  className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }



{/*       {
        name === 'language' &&
        <div className='flex justify-between w-full items-center'>
          <span className='text-lg font-bold '>{data.language}</span>

          <div className="flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: data })) }} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }
 */}

  {
        name === 'projects' &&
        <div className='flex justify-between w-full items-center'>
          <div className='flex flex-col'>
            <div className='flex flex-col  '>
              <span className='flex text-base font-bold'>{data.projectTitle}                         {data.file && <BsFillPatchCheckFill className='text-2xl w-10 text-green-600' />}
</span>
              <span className='italic'> {data.projectSubtitle}</span>
              
{/*               {data.certificationtype === 2 && <BsFillPatchCheckFill className='text-2xl ml-2 text-yellow-600' />}
 */}
            </div>
            <div className='flex items-baseline'>
            <div className='flex items-baseline'>
              <div>
                <span className='text-sm'>
                  {data.startDate && data.startDate}
                </span>
                { <> - </>}
                <span className='text-sm'>
                  {data.endDate? data.endDate : ' current'}
                </span>
              </div>

         
            </div>


            </div>

          </div>

          <div className="flex flex-col gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600" onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }} >
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }


{
        name === 'hobbies' &&
        <div className='flex justify-between w-full items-center'>
          <span className='text-lg font-bold '>{data.hobbyTitle}</span>

          <div className="flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: {id: id, idItem: data.id} })) }} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }


  {
        name === 'probonoactivity' &&
        <div className='flex justify-between w-full items-center'>
          <div className='flex flex-col'>
            <div className='flex items-end '>
              <span className='text-base font-bold'>{data.title}</span>
              {(data.title && data.organization) && <>, </>}
              <span className=' italic ml-1'> {data.organization}</span>
              

            </div>
            <div className='flex items-baseline'>
              <div>
                <span className='text-sm'>
                  {data.startdate && data.startdate.month}
                  {(data.startdate.month && data.startdate.year) && <>/</>}
                  {data.startdate && data.startdate.year}
                </span>
                {((data.startdate.month || data.startdate.year) && (data.enddate.month || data.enddate.year)) && <> - </>}
                <span className='text-sm'>
                  {data.enddate && data.enddate.month}
                  {(data.enddate.month && data.enddate.year) && <>/</>}
                  {data.enddate && data.enddate.year}
                </span>
              </div>


            </div>

          </div>

          <div className="flex gap-1">
            <button onClick={(e) => handleHide(e)} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
              {
                data.isHidden ? <AiFillEyeInvisible className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' /> : <AiFillEye className='text-xl text-slate-300 hover:text-slate-400 hover:cursor-pointer' />
              }
            </button>

            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600"  onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'deleteModal', title: 'dfdsf', isOpen: true, name: name, data: data })) }}>
              <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
              </svg>
            </button>
          </div>
        </div>
      }

    </div>
  )
}

export default Item