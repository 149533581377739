import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';



import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Admin/Menus/Sidebar';
import WelcomeBannerEmployers from '../../components/Employers/WelcomeBannerEmployers';
import Header from '../../components/Employers/Menus/Header';
import DBApplicationsCard from '../../components/Admin/Dashboard/DBApplicationsCard';

function AdminDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { resume, auth } = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate();




  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative bg-white flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <WelcomeBannerEmployers />

            <div className="grid grid-cols-12 gap-6">

              <DBApplicationsCard/>

            </div>


          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;