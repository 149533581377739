import { Button } from 'antd';
import React, { useState } from 'react'
import NewResumePdf from '../../../Resume/pdf/NewResumePdf';
import { useEffect } from 'react';

const Application = ({ job,type,setType, setCreateModalOpen, pdfSrc, setPdfSrc, createModalOpen }) => {
    const [choice, setChoice] = useState(1);

    useEffect(() => {
      if(createModalOpen){
        console.log("modal")
      }
    }, [createModalOpen])
    

    return (
        <><div className='flex justify-between  flex-wrap gap-2 md:mx-4 mt-4'>
            {(job.status.status !== 'INVITED' || job.status.status !== 'REJECTED') && <div className='flex gap-2'>
                <Button className={`flex items-center ${choice == 1 && 'bg-[#f11a7b] text-white'}`} onClick={() => setChoice(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg> Resume
                </Button>

                <Button className={`flex items-center ${choice == 2 && 'bg-[#f11a7b] text-white'}`} onClick={() => setChoice(2)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                        <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
                            <path d="M17 4H3a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5v-11A.5.5 0 0 0 17 4ZM3.5 15V5h13v10h-13Z" />
                            <path d="m17.324 4.88l-7.045 6a.5.5 0 0 1-.65-.001l-6.956-6A.5.5 0 0 1 3 4h14a.5.5 0 0 1 .324.88ZM15.642 5H4.345l5.612 4.841L15.642 5Z" />
                        </g>
                    </svg>
                    Motivation letter
                </Button>
            </div>}

{(job.status.status !== 'INVITED' && job.status.status !== 'REJECTED') &&            <div className='flex gap-2'>
                <Button className='flex items-center bg-green-500 text-white' onClick={() => { setCreateModalOpen(true); setType('invite') }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                        <path fill="currentColor" fillRule="evenodd" d="M13.488 3.43a.75.75 0 0 1 .081 1.058l-6 7a.75.75 0 0 1-1.1.042l-3.5-3.5A.75.75 0 0 1 4.03 6.97l2.928 2.927l5.473-6.385a.75.75 0 0 1 1.057-.081Z" clipRule="evenodd" />
                    </svg>
                    Invite
                </Button>

                <Button className='flex items-center bg-red-500 text-white' onClick={() => { setCreateModalOpen(true); setType('reject') }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                        <path fill="currentColor" d="M7.293 8L3.146 3.854a.5.5 0 1 1 .708-.708L8 7.293l4.146-4.147a.5.5 0 0 1 .708.708L8.707 8l4.147 4.146a.5.5 0 0 1-.708.708L8 8.707l-4.146 4.147a.5.5 0 0 1-.708-.708z" />
                    </svg>
                    Refuse
                </Button>
            </div>}



        </div>
            <div className=' mb-4  bg-white h-full  md:flex  my-4  overflow-hidden  md:mx-4 border !border-slate-200 rounded-xl '>


                {choice === 1 && <NewResumePdf 
                    pdfSrc={pdfSrc} setPdfSrc={setPdfSrc} />}


                {(choice === 2 && job.message) && <div className='flex flex-col p-4 quill-message' dangerouslySetInnerHTML={{ __html: job.message }}></div>}
                {(choice === 2 && !job.message) && <div className='flex p-4'>
                    No message
                </div>}

            </div></>
    )
}

export default Application