import React from 'react';
import { useSelector } from 'react-redux';

function WelcomeBannerEmployers() {
  const date = new Date();
/*   const {auth} = useSelector(state => state)
 */  
  const hours = date.getHours();
  let timeOfDay;

  if (hours < 12) {
    timeOfDay = "Good morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "Good afternoon";
  } else {
    timeOfDay = "Good evening";
  }

  
  return (
    <div className="relative bg-pink-200 dark:bg-[#f11a7b] p-4 sm:p-6 rounded-2xl overflow-hidden mb-8">


      {/* Content */}
      <div className="relative">
       
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">{timeOfDay}!</h1>

        
        <p className="dark:text-pink-200">Your one-stop dashboard for effortless job, application, user, and resume management. All in one place!</p>
      </div>
    </div>
  );
}

export default WelcomeBannerEmployers;
