
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const backendURL = process.env.REACT_APP_URL_BACKEND
let token = localStorage.getItem('userToken')


export const getApplications = createAsyncThunk(
    'applications/get',
    async ({token}, { rejectWithValue }) => {
      try {
        console.log(token)
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Auth '+token,
          },
        }
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/admin/applications`,
          config
        )
        return data
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )