import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { companyAcceptApplication, companyRejectApplication } from '../../../redux/actions/applicationsCompanies';


function InvitationPanel({
  transactionPanelOpen,
  setTransactionPanelOpen,
  selectedItem
}) {
  
  const closeBtn = useRef(null);
  const panelContent = useRef(null);
  const {auth} = useSelector(state => state);

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!transactionPanelOpen || panelContent.current.contains(target) || closeBtn.current.contains(target)) return;
      setTransactionPanelOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!transactionPanelOpen || keyCode !== 27) return;
      setTransactionPanelOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const accept = async () => {

    if(!message){
      toast.error("Please, enter a message!")
      return;
    }
    if(auth.userInfo){
      
      const data = {id:selectedItem.id, message:message }
      const actionResult = await dispatch(companyAcceptApplication({data, token: auth.userInfo.jwtToken }))
      console.log(actionResult)
      if(actionResult.type === 'company/accept/rejected'){
        toast.error(actionResult.payload);
      }
      if(actionResult.type==='company/accept/fulfilled'){
        toast.success("The application has been accepted!");
      }
    }

  };


  const reject = async () => {
    if(!message){
      toast.error("Please, enter a message!")
      return;
    }
    if(auth.userInfo){
      
      const data = {id:selectedItem.id, message:message }
      const actionResult = await dispatch(companyRejectApplication({data, token: auth.userInfo.jwtToken }))
      console.log(actionResult)
      if(actionResult.type === 'company/reject/rejected'){
        toast.error(actionResult.payload);
      }
      if(actionResult.type==='company/reject/fulfilled'){
        toast.success("The application has been rejected!");
      }
    }

  };
  return (
    <div
      ref={panelContent}
      className={`absolute inset-0 sm:left-auto z-20 shadow-xl transition-transform duration-200 ease-in-out ${
        transactionPanelOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="sticky top-16 bg-slate-50 dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 dark:border-slate-700 w-full sm:w-[390px] h-[calc(100dvh-64px)]">
        <button ref={closeBtn} onClick={() => setTransactionPanelOpen(false)} className="absolute top-0 right-0 mt-6 mr-6 group p-2">
          <svg
            className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
          </svg>
        </button>
        <div className="py-8 px-4 lg:px-8">
          <div className="max-w-sm mx-auto lg:max-w-none">
            <div className="text-slate-800 dark:text-slate-100 font-semibold text-center mb-1">Application Status</div>
            <div className="text-sm text-center italic">{selectedItem.createdAt}</div>
            {/* Details */}
            <div className="drop-shadow-lg mt-12">
              {/* Top */}
              <div className="bg-white dark:bg-slate-700 rounded-t-xl px-5 pb-2.5 text-center">
                <div className="mb-3 text-center">
{/*                   <img className="inline-flex w-12 h-12 rounded-full -mt-6" src={Image} width="48" height="48" alt="Transaction 04" />
 */}                </div>
                <div className="text-2xl font-semibold text-pink-500 mb-1">{selectedItem.companyName}</div>
                <div className="text-xs inline-flex font-medium bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400 rounded-full text-center px-2.5 py-1">{selectedItem.status}</div>
              </div>
              {/* Divider */}
              <div className="flex justify-between items-center" aria-hidden="true">
            {/*     <svg className="w-5 h-5 fill-white dark:fill-slate-700" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                </svg> */}
                <div className="grow w-full h-5 bg-white dark:bg-slate-700 flex flex-col justify-center">
                  <div className="h-px w-full border-t border-dashed border-slate-200 dark:border-slate-600" />
                </div>
            {/*     <svg className="w-5 h-5 fill-white dark:fill-slate-700 rotate-180" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                </svg> */}
              </div>
              {/* Bottom */}
         <div className="bg-white dark:bg-slate-800 dark:bg-gradient-to-b dark:from-slate-700 dark:to-slate-700/70 rounded-b-xl p-5 pt-2.5 text-sm space-y-3">
               
               
               
                 <div className="flex justify-between space-x-1">
                  <span className="italic">COMPANY NAME:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">{selectedItem.companyName}</span>
                </div>
                <div className="flex justify-between space-x-1">
                  <span className="italic">CITY:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">{selectedItem.city}</span>
                </div>
                <div className="flex justify-between space-x-1">
                  <span className="italic">REGISTRY NUMBER:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">{selectedItem.registryNumber}</span>
                </div>
                <div className="flex justify-between space-x-1">
                  <span className="italic">USERNAME:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">{selectedItem.username}</span>
                </div> 

                <div className="flex justify-between space-x-1">
                  <span className="italic">PHONE NUMBER:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">{selectedItem.phone}</span>
                </div> 
              </div>
            </div>
 
            {/* Notes */}
            {  selectedItem.status === 'WAITING' && 
            <div className="mt-6">
              <div className="text-sm font-semibold text-slate-800 dark:text-slate-100 mb-2">Message</div>
              <form>
                <label className="sr-only" htmlFor="notes">
                  Write a message
                </label>
                <textarea onChange={(e)=>setMessage(e.target.value)} id="notes" className="form-textarea w-full focus:border-slate-300" rows="4" placeholder="Write a note…" defaultValue={''} />
              </form>
            </div> }
            {/* Download / Report */}
            {  selectedItem.status === 'WAITING' &&          <div className="flex items-center space-x-3 mt-6">
              <div  onClick={reject} className="w-1/2">
                <button className="btn w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-rose-500">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
    <path fill="currentColor" d="M7.293 8L3.146 3.854a.5.5 0 1 1 .708-.708L8 7.293l4.146-4.147a.5.5 0 0 1 .708.708L8.707 8l4.147 4.146a.5.5 0 0 1-.708.708L8 8.707l-4.146 4.147a.5.5 0 0 1-.708-.708z"/>
</svg>
                  <span className="ml-2">Refuse</span>
                </button>
              </div>
              <div className="w-1/2">
                <button onClick={accept} className="btn w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 376 384">
    <path fill="currentColor" d="M119 282L346 55l29 30l-256 256L0 222l30-30z"/>
</svg>
                  <span className="ml-2">Accept</span>
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationPanel;
