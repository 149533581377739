import React from 'react';
import ModalBasic from '../../Modals/ModalBasic';
import { MdLanguage, MdSchool, MdSportsBaseball } from 'react-icons/md';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { BiBrain } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { updateResume } from '../../../redux/actions/resumes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrLanguage, GrUserWorker } from 'react-icons/gr';
import { IoMdBuild } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";


import { AiFillSafetyCertificate } from 'react-icons/ai';
import { removeNullProperties } from '../../../utils/Utils';

function ModalSectionsChoice({modalSectionOpen, setModalSectionOpen}) {

    const {resume,auth} = useSelector(state => state);
    const dispatch = useDispatch();
    const sections =[
        {title:"Education", name:"education" ,text:"Here you can show your primary education, college degrees & exchange semesters." ,icon:<MdSchool/>},
        {title:"Professional Experience",name:"work", text:"Here you can show your professional experience, internship..." ,icon:<BsFillBriefcaseFill/>},
        {title:"Skill", name:"skill",text:"Here you can show your skills" ,icon:<BiBrain/>},
/*         {title:"Language", name:"language",text:"Here you can show your languages" ,icon:<MdLanguage/>},*/
        {title:"Certificate", name:"certificate",text:"Here you can show your certificates" ,icon:<AiFillSafetyCertificate/>} ,
        {title:"Project", name:"project",text:"Here you can show your projects" ,icon:<IoMdBuild/>},
        {title:"Hobby", name:"hobby",text:"Here you can show your hobbies" ,icon:<MdSportsBaseball/>},
/*         {title:"Pro Bono Actitivities", name:"probonoactivity",text:"Here you can show your activities " ,icon:<FaHandsHelping/>}
 */
    ]



    const handleSectionSelection = async (section) => {
        try {
            console.log(`Section name: ${section.name}`);
            console.log('Initial statessections:', resume.resumeInfo.presentBlocks);
            
            let presentBlocks = {...resume.resumeInfo.presentBlocks};
            presentBlocks[section.name] = true;
            
            let datas = {...resume.resumeInfo}
            datas.presentBlocks = presentBlocks
            let newDatas = removeNullProperties(datas)
             const actionResult =  await dispatch(updateResume({id: resume.resumeInfo.id, resumeData:newDatas, token:auth.userToken}))

            if (updateResume.fulfilled.match(actionResult)) {
                toast.success(`Section successfully added!`);
                // Close the modal (assuming you're using redux or useState to manage modal state)
            } else {
                toast.error('Unknown error occurred, try again!');

                //throw new Error(actionResult.payload || 'Unknown error occurred');
            }  
            
          //updateSectionsSelected(name, true);
/*           const res = await updateResumeById(
            data.session.user.access_token,
            data.resume._id,
            {"statessections": sectionsSelected}
          ); */
          //updateResume({"sectionsselected": sectionsSelected});
          // Handle the success case, e.g. show a success message
        } catch (error) {
          toast.error('Unknown error occurred, try again!');
        }

        setModalSectionOpen(false)
    
      };


    return (
        <ModalBasic id="basic-modal" size="max-w-3xl" modalOpen={modalSectionOpen} setModalOpen={setModalSectionOpen} title="Select Section">
            {/* Modal content */}
            <div className="px-5 py-4 ">
                <div className="grid xl:grid-cols-3 gap-4 items-stretch">

                    {
                        sections.map((section) => (
                            <div onClick={()=> handleSectionSelection(section)} className="bg-white dark:bg-slate-800 p-4 border border-slate-200 dark:border-slate-700 rounded-sm shadow-sm">
                            {/* Card header */}
                            <div className="grow flex items-center truncate mb-2">
                                <div className="w-8 h-8 shrink-0 flex items-center justify-center  bg-[#f11a7b] rounded-full mr-2">
                                    <span className='text-white text-lg'>{section.icon}</span>
                                </div>
                                <div className="truncate">
                                    <span className="text-sm font-medium text-slate-800 dark:text-slate-100">{section.title}</span>
                                </div>
                            </div>
                            {/* Card content */}
                            <div className="text-sm mb-3">{section.text}</div>
    
                        </div>
                        ))
                    }

                </div>
            </div>

        </ModalBasic>
    );
}

export default ModalSectionsChoice;
