import React from 'react';
import { useNavigate } from 'react-router-dom';


function DBInvitationsCard() {
  const navigate = useNavigate();


  return (
    <div  onClick={()=>navigate('/employers/invitations')} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 hover:cursor-pointer hover:bg-pink-100 bg-white dark:bg-slate-800 shadow-lg rounded-2xl border border-slate-200 dark:border-slate-700">
      <div className="px-5 py-5">
        <header className="flex justify-between items-start mb-4">
          {/* Icon */}


          <svg width="50" height="50" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f11e7e" d="M243.6 148.8a6 6 0 0 1-8.4-1.2A53.58 53.58 0 0 0 192 126a6 6 0 0 1 0-12a26 26 0 1 0-25.18-32.5a6 6 0 0 1-11.62-3a38 38 0 1 1 59.91 39.63a65.69 65.69 0 0 1 29.69 22.27a6 6 0 0 1-1.2 8.4M189.19 213a6 6 0 0 1-2.19 8.2a5.9 5.9 0 0 1-3 .81a6 6 0 0 1-5.2-3a59 59 0 0 0-101.62 0a6 6 0 1 1-10.38-6a70.1 70.1 0 0 1 36.2-30.46a46 46 0 1 1 50.1 0A70.1 70.1 0 0 1 189.19 213M128 178a34 34 0 1 0-34-34a34 34 0 0 0 34 34m-58-58a6 6 0 0 0-6-6a26 26 0 1 1 25.18-32.51a6 6 0 1 0 11.62-3a38 38 0 1 0-59.91 39.63A65.69 65.69 0 0 0 11.2 140.4a6 6 0 1 0 9.6 7.2A53.58 53.58 0 0 1 64 126a6 6 0 0 0 6-6" />
          </svg>
        </header>
        <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">Invitations</h2>
        <div className="text-sm font-normal text-slate-400 dark:text-slate-500  mb-2">Easily manage your invitations, ensuring a seamless process for connecting with potential candidates.</div>
        <div className='flex flex-start gap-3'>
          <div className='flex flex-col'>
            <div className="text-3xl font-bold text-green-600 dark:text-green-600 mr-2">3</div>
            <div className="text-xs font-normal text-green-600 dark:text-green-600 ">Replied</div>
          </div>

          <div>
            <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">10</div>
            <div className="text-xs font-normal text-slate-400 dark:text-slate-500 ">Unreplied</div>
          </div>

          <div>
            <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">30</div>
            <div className="text-xs font-normal text-slate-400 dark:text-slate-500 ">Total</div>
          </div>
        </div>
        


      </div>

    </div>
  );
}

export default DBInvitationsCard;
