// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { createUser, deleteUserRole } from '../actions/usersCompanies'
import { getUsersCompany } from '../actions/auth'


const initialState = {
  loading: false,
  users: [],
  error: null,
  errorMsg: null,
  success: false,

}

const usersCompaniesSlice = createSlice({
  name: 'usersCompanies',
  initialState,
  reducers: {

  },
  extraReducers: {


    [getUsersCompany.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [getUsersCompany.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.users = payload.data
    },

    [getUsersCompany.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [createUser.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.users = [ ...state.users, payload]
    },

    [createUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [deleteUserRole.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [deleteUserRole.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
 
      state.users = state.users.map(user => 
        user.id === payload.id ? payload : user
      );
    },

    [deleteUserRole.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },
    

  },
})


export default usersCompaniesSlice.reducer