import React, { useState } from 'react'
import Sidebar from '../../components/Employers/Menus/Sidebar'
import InvitationsTable from '../../components/Employers/Invitations/InvitationsTable';
import SearchForm from '../../components/Employers/Invitations/SearchForm';
import DeleteButton from '../../components/Employers/Invitations/DeleteButton';
import InvitationPanel from '../../components/Employers/Invitations/InvitationPanel';
import PaginationClassic from '../../components/Employers/Invitations/PaginationClassic';
import SearchBar from '../../components/JobApplications/SearchBar';
import Header from '../../components/Header';

const EmployersInvitations = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
  
    const transactions = [
      {
        id: '0',
        name: 'Nina Verechshagina',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '1',
        name: 'Nikolay Verechshagin',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '2',
        name: 'John Doe',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '3',
        name: 'Brad Pitt',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '4',
        name: 'Zinédine Zidane',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '5',
        name: 'Jack Bauer',
        date: '22/01/2022',
        status: 'Refused',
      },
      {
        id: '6',
        name: 'Jack Sparrow',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '7',
        name: 'Michelle Obama',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '8',
        name: 'Mark Zuckerberg',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '9',
        name: 'Homer Simpson',
        date: '22/01/2022',
        status: 'Accepted',
      },
    ];

    const [list, setList] = useState(transactions)
    const handleSelectedItems = (selectedItems) => {
      setSelectedItems([...selectedItems]);
    };

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/*  Site header */}
        <Header title={"Invitations"} style={{ height: "30%", background: "blue" }} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="relative">
          <SearchBar items={list} setListApplications={setList} />

            {/* Content */}
            <div className="px-4 sm:px-8 pb-8 w-full max-w-9xl mx-auto">
              {/* Page header */}


              <InvitationsTable   list={list} selectedItems={handleSelectedItems} setTransactionPanelOpen={setTransactionPanelOpen} />

         
            </div>

            <InvitationPanel transactionPanelOpen={transactionPanelOpen} setTransactionPanelOpen={setTransactionPanelOpen} />
          </div>
        </main>
      </div>
    </div>  )
}

export default EmployersInvitations