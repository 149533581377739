import React, { useState } from 'react'
import ContactCta from '../components/LandingPage/ContactUs/contact-cta'
import Header from '../components/LandingPage/ui/header'
import Footer from '../components/LandingPage/ui/footer'
import PageIllustration from '../components/LandingPage/page-illustration'
import axios from 'axios'
import { toast } from 'react-toastify'
const backendURL = process.env.REACT_APP_URL_BACKEND

const ContactUs= () => {


  const [formData, setFormData] = useState({
    firstname:'',
    lastname:'',
    email:'',
    subject: '',
    message:''
  });
  const [isAgreed, setIsAgreed] = useState(false);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSend = async (event) => {
     event.preventDefault(); // Prevent default form submission

     if(!isAgreed){
      toast.warning("Please, you have to be agree with our conditions!");
      return;
     }
     const config = {
      headers: {
        'Content-Type': 'application/json',

      },  
    }

    let message = `
      Name: ${formData.firstname} ${formData.lastname}
      Email: ${formData.email}
      Message: ${formData.message}
    `

    let subject = `FORM CONTACT - ${formData.subject}`
    try{
      const { data } = await axios.post(
        `${backendURL}/user-management/sendmail`,
        {email: formData.email,subject, message  },
        config
      )

      toast.success(`Email successfully sended!`);

    }catch{
      toast.error(`An error occured, try again!`);

    }


  
  }
  return (

    <main className="bg-white dark:bg-slate-900 overflow-x-hidden">

    <Header/>

          <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

        <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

  
        {/* Hero content */}
        <div className="relative pt-10 pb-10 md:pt-20 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 text-black mb-4" data-aos="fade-up">How can we help you?</h1>
            <p className="text-xl text-black mb-8" data-aos="fade-up" data-aos-delay="200">Tell us your needs, and we’ll contact you shortly.</p>

          </div>



        </div>

      </div>
    </section>
          {/* Contact form */}
          <form className="max-w-xl mx-auto"  onSubmit={handleSend}>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">First Name <span className="text-red-600">*</span></label>
                <input id="firstname" name='firstname' type="text" onChange={handleChange} className="form-input w-full text-gray-300 border-red-500 focus:border-red-500" placeholder="Enter your first name" required />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Last Name <span className="text-red-600">*</span></label>
                <input id="lastname" name="lastname" type="text"  onChange={handleChange}className="form-input w-full text-gray-300" placeholder="Enter your last name" required />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                <input id="email" name="email" type="email"  onChange={handleChange} className="form-input w-full text-gray-300" placeholder="Enter your email address" required />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">Subject <span className="text-red-600">*</span></label>
                <input id="subject" name="subject" type="text"  onChange={handleChange}className="form-input w-full text-gray-300" placeholder="How can we help you?" required />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message</label>
                <textarea id="message" name="message" rows={4}  onChange={handleChange}className="form-textarea w-full text-gray-300" placeholder="Write your message"></textarea>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="flex items-center">
                  <input   checked={isAgreed}  onChange={() => setIsAgreed(!isAgreed)} type="checkbox" className="form-checkbox" />
                  <span className="text-gray-400 ml-2">I agree to the privacy policy</span>
                </label>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <button  type="submit" className="btn text-white bg-pink-500 hover:bg-pink-700 w-full">Send</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </section>

      <Footer/>

  </main>

 
  )
}

export default ContactUs