import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Employers/Menus/Sidebar';
import Header from '../../components/Employers/Menus/Header';
import AccountPanel from '../../components/Employers/Settings/AccountPanel';
import SettingsSidebar from '../../components/Employers/Settings/SettingsSidebar';
import CompanyAccountPanel from '../../components/Employers/Settings/CompanyAccountPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getMyCompany } from '../../redux/actions/companies';
;



function EmployersCompanyAccount() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const  {auth} = useSelector(state => state)

  useEffect(() => {
    getCompany();
  }, [])
  

  const getCompany = async () => {
    if (auth.userToken) {
      let res = await dispatch(getMyCompany({token: auth.userToken }))
    }
  }

  return (
    <div className="flex bg-white h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */} 
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header title={"Company Settings"} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

          

            {/* Content */} 
            <div className="bg-white dark:bg-slate-800 shadow-md rounded-2xl mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />
                <CompanyAccountPanel />
              </div>
            </div>

          </div>
        </main>

      </div>
      
    </div>
  );
}

export default EmployersCompanyAccount;