import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { deleteItemResume, deleteSectionResume, getResume } from '../redux/actions/resumes';
import ResumeComponent from '../components/Resume/ResumeComponent';
import ResumePDF from '../components/Resume/pdf/ResumePDF';
import ModalBasicR from '../components/Modals/ModalBasicR';
import { openModal } from '../redux/reducers/modalSlice';
import ModalBlankR from '../components/Modals/ModalBlankR';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from '../components/Resume/pdf/MyDocument';
import NewResumePdf from '../components/Resume/pdf/NewResumePdf';
import ModalBasic from '../components/Modals/ModalBasic';
import DetailsEducation from '../components/Resume/Details/DetailsEducation';
import DetailsProject from '../components/Resume/Details/DetailsProject';
import DetailsProExp from '../components/Resume/Details/DetailsProExp';
import { Modal } from 'antd';


function Resume() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createModalDetailsOpen, setCreateModalDetailsOpen] = useState(false);
  const [detailsItem, setDetailsItem] = useState(null)
  const {resume, modal, auth} = useSelector(state => state)
  const [pdfSrc, setPdfSrc] = useState(null);
  let { id } = useParams(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backendURL = process.env.REACT_APP_URL_BACKEND

   // useParams will give you the dynamic segments of the URL.
   const params = useParams();

   // useLocation will return the location object that represents the current URL.
   const location = useLocation();

  useEffect(() => {
    dispatch(getResume({id, token: auth.userToken}))
  }, []);

/*   useEffect(() => {
    if(!createModalDetailsOpen){
      navigate(``, { replace: true });

    }
  }, [createModalDetailsOpen])
   */



 
    useEffect(() => {
     // This effect will run whenever the URL changes.
 
     // Accessing the dynamic part of the URL.
     const resumeId = params.id;
 
     // Extracting query parameters.
     const queryParams = new URLSearchParams(location.search);
     const modal = queryParams.get('modal');
     const type = queryParams.get('type');
     const id = queryParams.get('id');

 
     console.log(`Resume ID: ${resumeId}`);
     console.log(`Modal: ${modal}, Type: ${type}, ID: ${id}`);
 
     // Add your logic here based on the URL and parameters.

     if(modal){
        setCreateModalDetailsOpen(true)
        setDetailsItem({type: type, id: id})

     }


 
   }, [location, params]); 


   const deleteParam = () => {
       navigate(location.pathname, { replace: true });

   }
 
  const previewUrl = `${backendURL}/resumes-management/pdf/`;

  const handleDelete = async () => {
    const actionResult = await dispatch(deleteItemResume({ name:modal.name, id: modal.data.id, idItem: modal.data.idItem , token:auth.userToken}))
     console.log(actionResult)
    if (deleteItemResume.fulfilled.match(actionResult)) {
        toast.success(`${modal.title} successfully updated!`);        // Close the modal (assuming you're using redux or useState to manage modal state)
    } else {
        toast.error('Unknown error occurred, try again!');
        //throw new Error(actionResult.payload || 'Unknown error occurred');
    }
    dispatch(openModal({isOpen:false})) 
  }

  return (
    <div className="flex h-[100dvh] overflow-hidden bg-white">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

             {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
            <main className="grow">
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">


                {
                    resume.resumeInfo && 
              
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <ResumeComponent pdfSrc={pdfSrc} setPdfSrc={setPdfSrc}  resume={resume.resumeInfo}/>
                       {/*  <ResumePDF  resume={resume.resumeInfo}/> */}
 
                    <NewResumePdf      className='flex '
 pdfSrc={pdfSrc} setPdfSrc={setPdfSrc}/> 
          
                    </div>
                }

                </div>

                <ModalBlankR id={"delete"}  type="deleteModal" >
                <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Delete this item?</div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>Alert!  You're about to delete this item. Are you sure you're ready to say goodbye?</p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); dispatch(openModal({isOpen:false}))}}>Cancel</button>
              <button className="btn-sm bg-rose-500 hover:bg-rose-600 text-white" onClick={(e)=>{e.preventDefault(); handleDelete()}}>Yes, Delete it</button>
            </div>
          </div>
        </div>
              </ModalBlankR>

            </main>

            <Modal width={600} title="More details" open={createModalDetailsOpen}  onCancel={(e) => { e.stopPropagation(); setCreateModalDetailsOpen(false); deleteParam() }} centered footer={null}>

        {/* Modal content */}
        <div className="px-5 pt-4 pb-1">
          <div className="text-sm">
            <div className="space-y-2">
        
              {
                ( detailsItem?.type === "educations" && resume.resumeInfo?.educations && resume.resumeInfo.educations.find(obj=> obj.id ===detailsItem.id))&& <DetailsEducation  education={resume.resumeInfo?.educations.find(obj=> obj.id ===detailsItem.id)}/>
              } 
 
              {
                (detailsItem?.type === "works" && resume.resumeInfo?.works && resume.resumeInfo.works.find(obj=> obj.id ===detailsItem.id))&& <DetailsProExp  exp={resume.resumeInfo?.works.find(obj=> obj.id ===detailsItem.id)}/>
              }

              {
                (detailsItem?.type === "projects" && resume.resumeInfo?.projects && resume.resumeInfo.projects.find(obj=> obj.id ===detailsItem.id))&& <DetailsProject  project={resume.resumeInfo?.projects.find(obj=> obj.id ===detailsItem.id)}/>
              }




            </div>
            <div>

            </div>
          </div>
        </div>
        {/* Modal footer */}
  
      </Modal>


        </div>
    </div>
  );
}

export default Resume;