import React, { useEffect, useState } from 'react';



import Image01 from '../images/company-icon-05.svg';
import Image02 from '../images/company-icon-06.svg';
import Image03 from '../images/company-icon-03.svg';
import Image04 from '../images/company-icon-07.svg';
import Image05 from '../images/company-icon-08.svg';
import Image06 from '../images/company-icon-01.svg';
import Image07 from '../images/company-icon-02.svg';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import DropdownSort from '../components/DropdownSort';
import JobListItem from '../components/JobListing/JobListItem';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SearchBar from '../components/JobListing/SearchBar/SearchBar';
import { Button, Modal, Pagination, Tag } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormApplication from '../components/JobListing/JobPost/FormApplication';
import { getMyVacancies, searchVacancies } from '../redux/actions/jobposting';
import { extractLocalityAndCountry, getLabelByValue } from '../utils/Utils';
import { EducationRequirement, JobType, Remote, WorkSchelule } from '../utils/datas';
import Job from '../components/JobListing/Job';

function MyFavApplications() {



  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModalViewApplyOpen, setCreateModalViewApplyOpen] = useState(false);
  const [createModalJob, setCreateModalJob] = useState(false);
  const [current, setCurrent] = useState(1);

  const [list, setList] = useState([])
  const { auth, jobposting } = useSelector(state => state);
  const dispatch = useDispatch();

  const [job, setJob] = useState(null)


  useEffect(() => {
    getVacancies(); 

  }, [])

  useEffect(() => {
  
      setList(jobposting.jobs)
      if(jobposting.jobs){
        setJob(jobposting.jobs[0])

      }

  }, [jobposting])


  const onChange = async (page) => {
    setCurrent(page);
    let res = await dispatch(getMyVacancies({filter:{size:10, page:page - 1},  token: auth.userToken }))

  };

  
  


  const getVacancies = async() => {
    if(auth.userToken && !jobposting.loading){
      let res = await dispatch(getMyVacancies({filter:{size:10, page:current - 1},  token: auth.userToken }))
      console.log(res)
    }
  }
  

  return (
    <div className="flex h-screen overflow-hidden bg-white">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <main className="relative flex flex-col flex-1 overflow-y-hidden  overflow-x-hidden">

        {/*  Site header */}
        <Header title={"Saved Vacancies"} style={{ height: "30%", background: "blue" }} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className=" h-full  overflow-y-hidden " >
          <div className="flex flex-col px-4 h-full overflow-hidden sm:px-6 lg:px-0   w-full max-w-9xl mx-auto">


            {/* Page content */}
            {list.length !== 0 &&<div className="flex h-full overflow-hidden  md:px-14 ">



              {/* Content */}
              <div className='w-full  mb-4 md:w-1/2 md:px-4  overflow-y-auto'>



             {!jobposting.loading && <>   <div className="flex justify-between items-center mb-4 ">
                  <div className="text-sm text-slate-500 dark:text-slate-400 italic">Showing {jobposting.jobs.length} Jobs</div>
                 {/*  <div className="text-sm">
                    <span>Sort by </span>
                    <DropdownSort align="right" />
                  </div> */}
                </div>

                <div className=''>
                  {list.map((item) => {
                    return (
                      <JobListItem
                        key={item.id}
                    
                        isSelected={job?.id === item.id}
                        job={item}
                        setJob={setJob}
                        setCreateModalJob={setCreateModalJob}


                      />
                    );
                  })}
                </div> </>}

                
     

                <div className="mt-6 flex justify-center">
                  <Pagination current={current} onChange={onChange} total={jobposting.totalEntity} defaultPageSize={jobposting.size}defaultCurrent={0} />
              </div>

              </div>



              <div className='hidden mb-4  bg-white  md:flex overflow-y-hidden w-1/2  mx-4 border !border-slate-200 rounded-xl '>
               {job &&               <Job  job={job} setCreateModalOpen={setCreateModalOpen} />}
              </div>



            </div>}

            {list.length === 0 && <div className='flex flex-col gap-4 w-full h-full justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 20 20">
                      <path fill="#dfd9d9" d="M18.405 4.799c-.111-.44-.655-.799-1.21-.799h-6.814c-.554 0-1.33-.318-1.722-.707l-.596-.588C7.671 2.316 6.896 2 6.342 2H3.087c-.555 0-1.059.447-1.12.994L1.675 6h16.931l-.201-1.201zM19.412 7H.588a.58.58 0 0 0-.577.635l.923 9.669A.77.77 0 0 0 1.7 18h16.6a.77.77 0 0 0 .766-.696l.923-9.669A.58.58 0 0 0 19.412 7z"/>
                  </svg>

                  <span>No vacancy found</span>


                </div>}
          </div>
        </div>

      </main>





      {job && <Modal width={800} open={createModalJob} onCancel={() => setCreateModalJob(false)} centered footer={null} >
        {/* Modal content */}
        <div >
          
              <Job  job={job} setCreateModalOpen={setCreateModalOpen} />
        </div>


      </Modal>}

      
{job && <Modal width={800} title={"Application for : " + job.title} open={createModalOpen} onCancel={() => setCreateModalOpen(false)} centered footer={null} >
        {/* Modal content */}
        <div className="md:px-5 pt-4 pb-1">
          <div className="text-sm ">
            <div className="md:px-5 py-4">
              <FormApplication setList={setList} list={list} job={job} setCreateModalOpen={setCreateModalOpen} />
            </div>
          </div>
        </div>


      </Modal>}

    </div>
  );
}

export default MyFavApplications;