// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { alertAction } from '../reducers/alertSlice'
import { getParamsFilter, jsonToFormData, removeNullProperties } from '../../utils/Utils'

const backendURL = process.env.REACT_APP_URL_BACKEND






export const createVacancy = createAsyncThunk(
    'jobposting/vacancy/create',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/create`,
          { ...data},

          config
        )
        console.log(res)
        return res
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )


  export const updateVac = createAsyncThunk(
    'job/vac/update',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/${data.id}/change`,
          { ...data},

          config
        )
        console.log(res)
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )

  export const searchVacancies = createAsyncThunk(
    'jobposting/vacancy/search',
    async ({ filter, token }, { rejectWithValue }) => {

      let param =  getParamsFilter(filter)

      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/search?order=asc&${param}`,

          config
        )
        console.log(res)
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
)

export const getMyVacancies = createAsyncThunk(
  'jobposting/vacancy/favorite',
  async ({filter, token }, { rejectWithValue }) => {
    let param =  getParamsFilter(filter)

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth ' + token 
        },
        
      }
      let res= await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/favorite?${param}`,
        config
      )
      console.log(res)
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

export const getVacanciesByCompanyId = createAsyncThunk(
    'jobposting/vacancy/company',
    async ({ id, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/company/${id}`,

          config
        )
        console.log(res)
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
)


export const getVacanciesCurrent = createAsyncThunk(
  'jobposting/vacancy/current',
  async ({filter,  token }, { rejectWithValue }) => {
    let param =  getParamsFilter(filter)

    try {
      let user = JSON.parse(localStorage.getItem("user"));
      console.log(user)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth ' + user.jwtToken 
        },
        
      }
      let res= await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/current?${param}`,

        config
      )
      console.log(res)
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)
export const getVacancyById = createAsyncThunk(
    'jobposting/vacancy',
    async ({ id, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/${id}`,

          config
        )
        
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
)


export const getVacancyResumes = createAsyncThunk(
  'jobposting/vacancy/resumes',
  async ({ id, params, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth ' + token 
        },
        
      }
      console.log(token)

      let param= getParamsFilter(params)
      if(!param.includes('status')){
        param+="&status=NOT_VIEWED&status=VIEWED&status=INVITED&status=REJECTED"
      }
      let res= await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/${id}/resumes?${param}`,

        config
      )
      
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

  export const deleteVacancyById = createAsyncThunk(
    'jobposting/vacancy/delete',
    async ({ id, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/${id}`,
          config
        )
        return id
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )


export const vacancyUpdateStatus = createAsyncThunk(
    'jobposting/vacancy/update/status',
    async ({ data, type, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        console.log(type)
        let res= await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/${data.vacancyId}/${type}/${data.resumeId}`,
          { ...data},

          config
        )
     
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )

  export const vacancyFavourite = createAsyncThunk(
    'jobposting/vacancy/favourite',
    async ({ data, token }, { rejectWithValue }) => {
      console.log(data)
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/favorite/${data.vacancyId}`,
          { ...data},

          config
        )
      
        return data.vacancyId;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )

  export const vacancyUnfavourite = createAsyncThunk(
    'jobposting/vacancy/unfavourite',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/vacancy/favorite/${data.vacancyId}`,

          config
        )
        return data.vacancyId;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )




  export const createJobRequest = createAsyncThunk(
    'jobrequest/create',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/job/request/${data.resume}/${data.vacancy}`,
          { ...data},

          config
        )
        console.log(res)
        return res
      } catch (error) {
        console.log(error)
        return rejectWithValue(error)
  
  
      }
    }
  )

  export const deleteJobRequest = createAsyncThunk(
    'jobrequest/delete',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/job/request/${data.resumeId}/${data.vacancyId}`,
          { ...data},

          config
        )
        console.log(res)
        return res
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )

  export const getMyRequests = createAsyncThunk(
    'jobrequest/my',
    async ({filter, token }, { rejectWithValue }) => {
      try {
        let param =  getParamsFilter(filter)

        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/job-posting-service/api/v1/job/request/my?${param}`,
          config
        )
        console.log(res)
        return res;
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )








