import React, { useEffect,  useState } from 'react';


import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select, Upload } from 'antd';
import { CompanySizes, Industries, businessTypes } from '../../../utils/datas';
import TextArea from 'antd/es/input/TextArea';
import { createCompanyNew, updateCompanyNew } from '../../../redux/actions/companies';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../redux/actions/file';
import logo from '../../../images/company.png'

function CompanyAccountPanel() {

  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null)
  const [fileList, setFileList] = useState([]);
  const {auth, companies} = useSelector(state=>state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (companies.companyInfo) {
      // Use setFieldsValue to update form values based on companyInfo
      form.setFieldsValue(companies.companyInfo);
      console.log(companies.companyInfo)
      if (companies.companyInfo.file) {
        let urlFile = {
          uid: '-1', // Negative id to avoid conflicts with any existing files
          name: 'Uploaded Logo', // Name to show in the UI
          status: 'done', // Show this file as already uploaded
          url: `${process.env.REACT_APP_URL_BACKEND}/file-service/${companies.companyInfo.file}`, // URL to the actual file
        };
        console.log(urlFile)
        setFileList([urlFile]);
      }
    }
  }, [companies.companyInfo, form]);

  useEffect(() => {
    console.log(fileList)
  }, [fileList])
  



  const onFinish = async (values) => {
    console.log(values);
    
    let datas = {...values};


    if(fileList.length > 0){
      console.log("uplaod wilf")
      let file = fileList[0]
      delete file.uid;

      console.log(file)
      
      let res = await dispatch(uploadFile({ data: { file }, token: auth.userToken }))
      if (res.type === "file/upload/fulfilled") {
        datas.file = res.payload.data;
        console.log(formData)
      }else{
        toast.error("An error occured during the file uploading")

      }
      console.log(res)

    }


    if(companies.companyInfo){
      let res = await dispatch(updateCompanyNew({datas:datas,token: auth.userToken}))
      if(res.type === "resume/create/rejected"){
        toast.error(res.message)
      }else{
        toast.success("Updated!")
      }
    }else{
      let res = await dispatch(createCompanyNew({datas:datas,token: auth.userToken}))
      if(res.type === "resume/create/rejected"){
        toast.error(res.message)
      }else{
        toast.success("Created!")
      }
    }
  
  };

  const onFileChange = ({ file, fileList: newFileList }) => {
    if (newFileList.length === 0) {
      setFileList([]); // Set back to null if no files selected
    } else {
      setFileList([file.originFileObj ? file.originFileObj : file]);
    }
  };

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6 ">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Company Account</h2>




        <div className='flex justify-center w-full'>
          <img src={companies?.companyInfo?.file? `${process.env.REACT_APP_URL_BACKEND}/file-service/${companies.companyInfo.file}` : logo} alt={"logo company"} className='bg-white rounded-full  ' style={{width:"120px"}}/>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
          className='md:w-3/5 mx-auto'
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input size='large' className='antd-input' />
          </Form.Item>

          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: true, message: 'Please input the url!' }]}
          >
            <Input size='large' className='antd-input' />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <TextArea size='large' className='antd-input' />
          </Form.Item>

      
  
   

          <Form.Item
            label="Company size"
            name="companySize"
            rules={[{ required: true, message: 'Please select the size!' }]}
          >
            <Select  size="large" >
              {CompanySizes.map((type, index) => (
                <Select.Option  key={index} value={type.label}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Sector"
            name="sector"
            
            rules={[{ required: true, message: 'Please select the sector!' }]}
          >
            <Select  size="large" mode='multiple'>
              {Industries.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Business Type"
            name="businessType"
            rules={[{ required: true, message: 'Please select the sector!' }]}
          >
            <Select  size="large">
              {businessTypes.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
  label="Upload Logo"
  name="file"
  rules={[
    {
      validator: (_, value) => {
        console.log(value);
        if (!value || value.fileList.length === 0) {
          return Promise.resolve(); // No file selected, resolve validation
        }

        const file = value.fileList[value.fileList.length-1]; // Access the first file (multiple is set to false)
        const isImage = file.type.startsWith('image/');
        const isLessThan5MB = file.size / 1024 / 1024 < 5; // Max size 5MB

        if (!isImage) {
          return Promise.reject(new Error('Only image files are allowed.'));
        }

        if (!isLessThan5MB) {
          return Promise.reject(new Error('Image size must be less than 5MB.'));
        }

        return Promise.resolve(); // All validations pass
      },
    },
  ]}
>
  <Upload
    beforeUpload={() => false} // Disable default browser file selection
    onChange={onFileChange}
    multiple={false} // Allow only single file upload
    fileList={fileList}
  >
    <Button>Select an Image</Button>
  </Upload>
</Form.Item>


{/* 
          <Form.Item
            label="Upload Picture"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={e => {
              if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList ? e.fileList : []; // Return empty array if fileList is null
            }}
          >
            <Upload
              name="logo"
              listType="picture"
              maxCount={1}
              onChange={onFileChange}
              beforeUpload={() => false}
              fileList={fileList}
            >
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item> */}

          <div className="flex items-center justify-end">
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center !rounded-2xl bg-[#f11a7b] gap-2"
              size="large"
            >
              Update
            </Button>
          </div>
        </Form>
      </div>
       
    </div>
  );
}

export default CompanyAccountPanel;