import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters } from '../../../../redux/reducers/filtersSlice'


const SearchBar = ({setListApplications , items}) => {

    const [selected, setSelected ] = useState("all")
    const {id} = useParams();
    const  {auth, filters} = useSelector(state=>state);
    const dispatch = useDispatch();

    useEffect(() => {
        filterStatus(selected)
        
    }, [selected])

    const handleChange = (e) => {
        console.log(e.target.value)
        let value = e.target.value
        setListApplications(items.filter(app => app.company.includes(value) || app.role.includes(value) || app.details.includes(value) ))
       
    }
    
    const filterStatus = (status) =>{
        if(selected==="all"){
            console.log("all")
            dispatch(updateFilters({...filters.filters, status: ['NOT_VIEWED', 'VIEWED', 'INVITED', 'REJECTED']}))

      
        }else{
            //setListApplications(items.filter(app => app.status === status))
            dispatch(updateFilters({...filters.filters, status: status}))

        }
    }


    
    return (
        <div className="flex flex-col gap-2 justify-center items-center  mb-5 pt-3 pb-5 border-b-[2px] ">

            {/* Search form */}

            <div className='flex flex-col w-full  gap-2 '>
                <div className="flex w-full  ">

                    {/*search part */}
 {/*                    <div className='d-flex w-full '>
                        <div className='d-flex w-full relative '>
                            <input onChange={(e)=>handleChange(e)} id="job-search" className="d-flex w-full form-input !rounded-lg focus:!border-[#f11a7b]  !py-3 flex-1 !pl-9 pr-9 dark:bg-slate-800" type="search" placeholder="Search application or keyword…" />
                            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 absolute left-3 top-1/2 transform -translate-y-1/2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                        </div>
                    </div> */}

       

                </div>

                <div className='flex gap-2 self-start overflow-x-auto flex-wrap '>
                <Button onClick={() => setSelected('all')} className={`flex items-center ${selected === 'all' && 'border-[#f11a7b]'}`} size="large">
                        All
                    </Button>
                    <Button onClick={() => setSelected('NOT_VIEWED')} className={`flex items-center ${selected === 'NOT_VIEWED' && 'border-[#f11a7b]'}`} size="large">
                        Not Viewed
                    </Button>
                    <Button onClick={() => setSelected('VIEWED')} className={`flex items-center ${selected === 'VIEWED' && 'border-[#f11a7b]'}`} size="large">
                       Viewed
                    </Button>
                    <Button onClick={() => setSelected('INVITED')} className={`flex items-center border-green-500 text-green-500 hover:border-green-400 hover:text-green-400 ${selected === 'INVITED' && 'bg-green-400 !text-white'}`}  size="large" >                   
                        Invited
                    </Button>
                    <Button  onClick={() => setSelected('REJECTED')}  className={`flex items-center ${selected === 'REJECTED' && 'bg-red-500 !text-white'}`} size="large" danger>                      
                        Rejected
                    </Button>
               


                </div>
            </div>




        </div>

    )
}

export default SearchBar