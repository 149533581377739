import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo-big.png'

import AuthImage from '../../images/landing/bg.jpg';
import { useDispatch } from 'react-redux';
import { registerUser, userLogin } from '../../redux/actions/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import OnBoarding4 from '../../components/Employers/Signup/OnBoarding4';

function EmployersCreation() {

  const [step, setStep] = useState(1);

  const dispatch = useDispatch()
  const email = useRef("");
  const fullname = useRef("");
  const password = useRef("");
  const cPassword = useRef("");
  const navigate = useNavigate();

  const submitForm = async ({ email, fullname, password }) => {
    console.log(fullname)
    if (password.current.value !== cPassword.current.value) {
      toast.error('The passwords doesn\'t match!');
      return;
    }

    const actionResult = await dispatch(registerUser({ fullname: fullname.current.value, email: email.current.value, password: password.current.value }))
    if (actionResult.type === 'auth/register/rejected') {
      toast.error(actionResult.payload.msg);
    } else {
      toast.success('Registration succeeded! Please check your mails to confirm your account');
      navigate('/signin');

    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">

      <div className="relative flex">

        {/* Content */}
        <div className="w-full md:w-1/2">

          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            <div className="flex-1">

              {/* Header */}
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className='w-40' src={Logo}  alt="logo"/>
                </Link>
                <div className="text-sm">
                  Have an account? <Link className="font-medium text-pink-500 hover:text-pink-600 dark:hover:text-pink-400" to="/employers/signin">Sign In</Link>
                </div>
              </div>

              {/* Progress bar */}
{/*               <ProgressBar step={step} setStep={setStep}/>
 */}            </div>

      {/*       {step=== 1 && <OnBoarding1 step={step} setStep={setStep}/>}
            {step=== 2 && <OnBoarding2 step={step} setStep={setStep}/>}
            {step=== 3 && <OnBoarding3 step={step} setStep={setStep}/>} */}
            {step=== 1 && <OnBoarding4 step={step} setStep={setStep}/>}

          </div>

        </div>

        {/* Image */}
        <div className="hidden overflow-hidden bg-gradient-to-tl from-pink-400 to-pink-200  md:flex justify-center items-center absolute top-4 bottom-4 right-4 rounded-3xl  md:w-1/2" aria-hidden="true">
        <img className="object-fill object-center  w-full h-full" src={AuthImage}  alt="Authentication" />
        </div>
      </div>

    </main>
  );
}

export default EmployersCreation;