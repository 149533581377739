import React, { useState } from 'react'
import { extractLocalityAndCountry, getLabelByValue } from '../../utils/Utils';
import { EducationRequirement, JobType, Remote, Status, WorkSchelule } from '../../utils/datas';
import { Button, Tag } from 'antd';

const ApplicationView = ({job, setModalDeleteOpen ,}) => {
    const [choice, setChoice] = useState(1);

  return (
    <div className='w-full overflow-y-auto py-4 md:px-4 overflow-x-hidden'>


    <header className="mb-4 flex justify-between">
      <div className='flex flex-col gap-2'>
        <h1 className="text-2xl md:text-2xl text-slate-900 dark:text-slate-100 font-bold">{job.title}</h1>
        <h1 className="text-lg  text-slate-900 dark:text-slate-100 font-normal">{job.companyName}</h1>

      </div>

      <div className='ml-4 '>

        <span className='italic text-sm'>{getLabelByValue(Status, job.status)}</span>


      </div>



    </header>

    <div className="mb-6 flex flex-col gap-4">
      <div className="flex flex-wrap items-center -m-1 gap-5">

        {job.address && <span className='flex  text-[#f11a7b] font-semibold items-center'><svg width="20" height="20" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3m0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205" />
        </svg> {extractLocalityAndCountry(job.address)} </span>}

        <span className='flex  text-[#f11a7b] font-semibold items-center'><svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="M10 3.578a.75.75 0 0 1 0-1.5h3.536a.75.75 0 0 1 0 1.5zm-3.47.452a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06-1.06l2.5-2.5a.75.75 0 0 1 1.06 0" />
          <path fill="currentColor" fillRule="evenodd" d="M12 5.06a8.5 8.5 0 1 0 0 17a8.5 8.5 0 0 0 0-17m4.99 3.711a.5.5 0 0 0-.7-.701l-3.175 2.468l-2.075 1.483a1.434 1.434 0 1 0 2 2l1.482-2.076z" clipRule="evenodd" />
        </svg> {getLabelByValue(WorkSchelule, job.workSchedule)}</span>

        {job.inclusiveAccess && <span className='flex  text-[#f11a7b] gap-1 font-semibold items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
              <path d="M9 5a2 2 0 1 0 4 0a2 2 0 1 0-4 0" />
              <path d="M11 7v8h4l4 5m-8-9h5m-9 .5a5 5 0 1 0 6 7.5" />
            </g>
          </svg>

          Inclusive Access</span>}

      </div>

      <div className='flex gap-2'>
        {(job.status !== 'REJECTED' && job.status !== 'INVITED' && job.status !== 'DELETED')  && <Button onClick={()=>{console.log("heelo");setModalDeleteOpen(true)}} className='flex items-center bg-red-500 text-white hover:bg-red-400' type="danger" >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 304 384">
              <path fill="currentColor" d="M21 341V85h256v256q0 18-12.5 30.5T235 384H64q-18 0-30.5-12.5T21 341zM299 21v43H0V21h75L96 0h107l21 21h75z"/>
          </svg>
          <span className='ml-1'>Cancel Application</span>
        </Button>}






      </div>
    </div>





    <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

    {choice === 1 && <>

      <div class='grid grid-cols-2  md:grid-cols-3 gap-4'>


        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128zm-512 0q0 27 10 50t27 40t41 28t50 10V384zM128 512q27 0 50-10t40-27t28-41t10-50H128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10zm1792-768h-128v896H256v128h1664zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20M768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50z" />
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'>Minimum Salary</span>

            {job.minSalary && <span className='font-semibold '>{job.minSalary} EUR</span>}
            {!job.minSalary && <span className='font-semibold '>Not specified</span>}

          </div>
        </div>

        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128zm-512 0q0 27 10 50t27 40t41 28t50 10V384zM128 512q27 0 50-10t40-27t28-41t10-50H128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10zm1792-768h-128v896H256v128h1664zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20M768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50z" />
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'>Maximum Salary</span>

            {job.maxSalary && <span className='font-semibold '>{job.maxSalary} EUR</span>}
            {!job.maxSalary && <span className='font-semibold '>Not specified</span>}

          </div>
        </div>


        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
              <path d="M24 42L4 18.5L9.695 6h28.61L44 18.5z" />
              <path d="m32 18l-8 9l-8-9" />
            </g>
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'>Experience </span>

            <span className='font-semibold '>{job.minYearsExperience} - {job.maxYearsExperience === 1000 ? 'No max' : job.maxYearsExperience} year(s)</span>
          </div>
        </div>

        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M3.33 8L10 12l10-6l-10-6L0 6h10v2zM0 8v8l2-2.22V9.2zm10 12l-5-3l-2-1.2v-6l7 4.2l7-4.2v6z" />
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'>Education</span>

            {job.educationRequirement && <span className='font-semibold '>{getLabelByValue(EducationRequirement, job.educationRequirement)}</span>}
            {!job.educationRequirement && <span className='font-semibold '>Not specified</span>}                      </div>
        </div>

        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4" />
            <path fill="currentColor" d="M20.59 22L15 16.41V7h2v8.58l5 5.01z" />
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'>Employement Type</span>
            {job.employmentType && <span className='font-semibold '>{getLabelByValue(JobType, job.employmentType)}</span>}
            {!job.employmentType && <span className='font-semibold '>Not specified</span>}

          </div>
        </div>

        <div className='flex gap-2'>
          <svg className="mt-1" width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" fillRule="evenodd" d="m8.36 1.37l6.36 5.8l-.71.71L13 6.964v6.526l-.5.5h-3l-.5-.5v-3.5H7v3.5l-.5.5h-3l-.5-.5V6.972L2 7.88l-.71-.71l6.35-5.8zM4 6.063v6.927h2v-3.5l.5-.5h3l.5.5v3.5h2V6.057L8 2.43z" clipRule="evenodd" />
          </svg>
          <div className='flex flex-col justify-start'>
            <span className='font-semibold text-sm text-slate-900'> Remote</span>

            {job.remote && <span className='font-semibold '>{getLabelByValue(Remote, job.remote)}</span>}
            {!job.remote && <span className='font-semibold '>Not specified</span>}                         </div>
        </div>



      </div>



      <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

      { job.keySkills.length > 0 && <> <div className='flex flex-wrap gap-1'>

{
job.keySkills.map(skill => (
<Tag className='text-base text-[#f11a7b] rounded-lg border-[#f11a7b] bg-[#f11a7b1a]' >{skill}</Tag>
))
}



</div>
<hr className="my-6 border-t border-slate-200 dark:border-slate-700" /> </>}
      <h3 className='text-xl font-bold  text-slate-900 mb-2'>Job Details</h3>

      <div className='w-full text-sm' style={{wordBreak:"break-word"}}  dangerouslySetInnerHTML={{ __html: job.description }}></div>
      
      <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />
      <h3 className='text-xl font-bold text-slate-900 mb-2'>Application Details</h3>

      <div className='flex'>
        <label className="block text-sm font-medium mb-1" htmlFor="default">Resume used:</label>      
        <a href={'/resume/'+job.resumeId} className="block text-sm ml-2" target="_blank" rel="noopener noreferrer">Click here to see</a>

      </div>
       </>



    }

    {choice === 2 && <div className='w-full flex flex-col gap-4'>

      <div className='flex gap-1 text-base'>
        <span className='font-semibold'>Resume used:</span>
        <span className='hover:cursor-pointer hover:underline'>Resume Front End</span>
      </div>

      <div className='flex flex-col gap-1 text-base'>
        <span className='font-semibold'>My motivation letter:</span>
        <p className='text-sm'>Vivamus sit amet purus a sem elementum accumsan. Mauris rutrum et massa non suscipit. Suspendisse sed posuere nisi, vel condimentum erat. Integer vitae libero at lectus imperdiet auctor. Ut iaculis lectus erat, quis volutpat neque porttitor in. Vivamus in pretium eros, ac accumsan augue. Praesent at mauris id magna tempus dignissim. Morbi mattis facilisis urna ultricies feugiat. Nunc auctor, turpis id fringilla pellentesque, dui lorem sodales ante, eget tempus neque leo quis dui. Pellentesque dictum ligula nec tellus tempor, ac finibus ante iaculis. Phasellus bibendum scelerisque ex, nec ultrices velit semper eget. Maecenas mi dolor, tempus a velit at, sodales aliquam enim. Nam aliquet ex quis interdum gravida. Maecenas vitae metus erat.</p>
      </div>

    </div>
    }

    <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />


  </div>
  )
}

export default ApplicationView