// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const backendURL = process.env.REACT_APP_URL_BACKEND
let token = localStorage.getItem('userToken')

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
});

 axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if ((error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("not good")
      try{
        
        localStorage.removeItem('user')
        localStorage.removeItem('userToken')
        window.location.href = '/landing';


      } catch (refreshError) {
        return Promise.reject(refreshError);
      } 
    }
    return Promise.reject(error);
  }
); 

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({ fullname, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        
      }
      let res= await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/user/signUp`,
        { fullname, email, password },
        config
      )
      console.log(res)
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

export const registerUserCompany = createAsyncThunk(
  'auth/register/company',
  async ({ fullname, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        
      }
      let res= await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/company/signUp`,
        { fullname, email, password },
        config
      )
      console.log(res)
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)


export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password, type }, { rejectWithValue }) => {

    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/signIn`,
        { email, password, type },
        config
      )

      
      const configJWT = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth '+ data.jwtToken
        },
      }

      if(type === 'user'){
        localStorage.setItem('type', 'user')
      }else{
        localStorage.setItem('type', 'company')

      }


      let  dataUser;
      console.log(data.role[0])
      if(data.role[0].includes("USER")){
        dataUser = await axios.get(
          `${backendURL}/resume-service/api/v1/user/current`,        configJWT
        ) 

    
      }

      else{

        // store user's token in local storage
        localStorage.setItem('userToken', data.jwtToken)
        
        localStorage.setItem('user', JSON.stringify(data))


     

          
        localStorage.setItem("firstLogin", true)
        return data

      }


      if(dataUser){
        //console.log(dataUser)

        let newData = {
          user: {
            fullname: dataUser.data.fullname,
            resumes: dataUser.data.resumes}
          ,
          jwtToken: data.jwtToken,
          refreshToken: data.refreshToken,
          role: data.role,
          isAuthenticated: data.isAuthenticated
        }
        // store user's token in local storage
        localStorage.setItem('userToken', data.jwtToken)
        
        localStorage.setItem('user', JSON.stringify(newData))

        localStorage.setItem('userInfo', JSON.stringify({
          fullname: dataUser.data.fullname,
          resumes: dataUser.data.resumes}))
  
        localStorage.setItem("firstLogin", true)
  
        return newData
      }else{
        return rejectWithValue(-1)

      }

   

    } catch (error) {
      console.log(error)

      return rejectWithValue(error.response.data)

    }
  }
)


export const getUsersCompany= createAsyncThunk(
  'company/my',
  async ({ token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth ' + token 
        },
        
      }
      let res= await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/company/users`,

        config
      )
      console.log(res)
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)


export const userChangePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ oldPassword, newPassword, auth }, { rejectWithValue }) => {
    try {

      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Auth "+  auth,

        },  
      }
      const { data } = await axios.put(
        `${backendURL}/authentification/api/v1/change/password`,
        { oldPassword, newPassword },
        config
      )

 
      return data
    } catch (error) {
      console.log(error)
      // return custom error message from API if any
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response.data.msg)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)




export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async ({user}, { rejectWithValue }) => {

       //let user = JSON.parse(localStorage.getItem("user"))
      console.log("refresh")
      console.log(user)

        try {

            const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' :'Auth ' + user.userInfo.refreshToken
              },
          }
            const { data } = await axiosInstance.post(
              `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/refreshToken`,
              {},
              config
            )
            localStorage.removeItem('userToken')

            localStorage.setItem('userToken', data.jwtToken)
            localStorage.removeItem('user')
             let newUser = {...user}
             newUser.jwtToken = data.jwtToken
             newUser.refreshToken = data.refreshToken

            localStorage.setItem('user', JSON.stringify(data))
              
      
            return data
          } catch (error) {
            console.log(error)
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
              return rejectWithValue(error.response.data.message)
            } else {
              return rejectWithValue(error.message)
            }
          }
 
    }
  )
  


