// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { alertAction } from '../reducers/alertSlice'

const backendURL = process.env.REACT_APP_URL_BACKEND



export const createJobApplication = createAsyncThunk(
  'jobapplication/create',
  async ({ formData , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data } = await axios.post(
        `${backendURL}/company-management/jobapplication`,
        {...formData},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getJobApplicationByUserId = createAsyncThunk(
  'jobapplication/get',
  async ({filter,token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axios.get(
        `${backendURL}/company-management/jobapplication?${filter}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)



export const updateJobApplication = createAsyncThunk(
  'jobapplication/update',
  async ({ id, jobapplicationData, token }, { rejectWithValue }) => {
    console.log(id)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data } = await axios.put(
        `${backendURL}/company-management/jobapplication/${id}`,
        {...jobapplicationData},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


