import React, { useState } from 'react';
import DatasPerso from './DatasPerso';
import ModalBasic from '../Modals/ModalBasic';
import ModalSectionsChoice from './Sections/ModalSectionsChoice';
import Section from './Sections/Section';
import { MdLanguage, MdSchool, MdSportsBaseball } from 'react-icons/md';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { BiAddToQueue, BiBrain } from 'react-icons/bi';
import ModalBasicR from '../Modals/ModalBasicR';
import FormSection from './Sections/FormSection';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GrLanguage, GrUserWorker } from 'react-icons/gr';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import DropdownShare from './DropdownShare';
import { IoMdBuild } from 'react-icons/io';
import { FaHandsHelping } from 'react-icons/fa';
import DatasPersoNew from './DatasPersoNew';

function ResumeComponent({ resume , pdfSrc, setPdfSrc  }) {
  const [modalSectionOpen, setModalSectionOpen] = useState(false)
  const { modal } = useSelector(state => state)


  window.html2canvas = html2canvas;



  


  const handleDownload = () => {
    if (pdfSrc) {
      // Create a link element
      const link = document.createElement('a');
      // Set the download attribute with a filename
      link.download = 'resume.pdf';
      // Set the href attribute to the PDF URL
      link.href = pdfSrc;
      // Append the link to the body
      document.body.appendChild(link);
      // Trigger a click on the link
      link.click();
      // Remove the link from the body
      document.body.removeChild(link);
    }
  };
  



  return (
    <div className='flex flex-col gap-4 md:gap-6'>



      <div className=" px-3 py-2 md:px-5 md:py-4 bg-white dark:bg-slate-800  rounded-2xl border border-slate-200 dark:border-slate-700">
        <div className='flex justify-between items-center'>
          <h1 className='text-xl font-semibold text-slate-800 dark:text-slate-100'>{resume.title}</h1>


          <div className='flex gap-2'>
{/*             <DropdownShare id={resume._id}/>
 */}            <div className=''>
            <button onClick={handleDownload} className="btn  bg-[#f11a7b] hover:bg-pink-500 text-white hidden">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1="12" x2="12" y1="15" y2="3" /></svg>
              <span className="ml-2 hidden sm:block">Download</span>
            </button>
            </div>
        

          </div>

        </div>
      </div>


      <DatasPersoNew resume={resume} />

      { 
       <Section datauser={resume} title="Education" name="educations" icon={<MdSchool />} />
      }
      { 
       <Section datauser={resume} title="Professional Experience" name="works" icon={<BsFillBriefcaseFill />} />
      }
      { 
        <Section datauser={resume} title="Skill" name="skills" icon={<BiBrain />} />
      }

{/*       {
        <Section datauser={resume} title="Language" name="language" icon={<MdLanguage />} />
      } */}

      {
        <Section datauser={resume} title="Certificate" name="certificatesView" icon={<AiFillSafetyCertificate />} />
      } 

      {
         <Section datauser={resume} title="Project" name="projects" icon={<IoMdBuild/>} />
      }

      {
        <Section datauser={resume} title="Hobby" name="hobbies" icon={<MdSportsBaseball />} />
      }

{/* {
        <Section datauser={resume} title="Pro Bono Activity" name="probonoactivity" icon={<FaHandsHelping />} />
      } */}



{/*       <div className='flex justify-center'>
        <button onClick={(e) => { e.stopPropagation(); setModalSectionOpen(true) }} className="btn-lg bg-[#f11a7b] hover:bg-pink-500 text-white">
          <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="ml-2">Add Section</span>
        </button>
      </div>
 */}


      <ModalSectionsChoice modalSectionOpen={modalSectionOpen} setModalSectionOpen={setModalSectionOpen} />


      <ModalBasicR id={modal.name} type="formModal" title="Create New ">
        <div className="px-10 py-6">
          <FormSection />
        </div>

      </ModalBasicR>
    </div>
  );
}

export default ResumeComponent;
