import { Button, Modal, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import RemoteFilter from './Filters/RemoteFilter';
import SectorFilter from './Filters/ContractDurationFilter';
import AllFilter from './Filters/AllFilter';
import WorkScheduleFilter from './Filters/WorkScheduleFilter';
import ContractDurationFilter from './Filters/ContractDurationFilter';
import { updateFilters } from '../../../redux/reducers/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { searchVacancies } from '../../../redux/actions/jobposting';
import { getLabelByValue } from '../../../utils/Utils';
import { EducationRequirement, JobType, Remote, WorkSchelule } from '../../../utils/datas';
import { openModal } from '../../../redux/reducers/modalSlice';

const titles =[
    "Remote",
    "Contract",
    "Contract Duration",
    "All filters"
]

const SearchBar = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [choice, setChoice] = useState(0);
    const [formData, setFormData] = useState({
        workSchedule: [],
        employmentType: [],
        formattedAddress: '',

        minWorkExperienceYear: '',
        maxWorkExperienceYear:'',
        minSalary: '',
        maxSalary:'',
        remote: [],
        educationRequirement: []
    });

    const [searchInput, setSearchInput] = useState('');
    const [address, setAddress] = useState('');
    const {auth, filters} = useSelector(state => state);

    const dispatch = useDispatch();


    useEffect(() => {
      if(!isModalOpen){
        dispatch(openModal({ isOpen: false }))
    }
    }, [isModalOpen])
    

    const showModal = (choice) => {
      setChoice(choice)
      setIsModalOpen(true);
      dispatch(openModal({ isOpen: true }))


    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const search = () => {
        getVacancies();

    }

    const getVacancies = async() => {
        if(auth.userToken){
          let res = await dispatch(searchVacancies({filter: {formattedAddress:address, keyWords:searchInput}, token: auth.userToken}))
          console.log(res)
        }
      }

    return (
        <div className="flex flex-col gap-2 justify-center items-center  mb-5 pt-3 pb-5 border-b-[2px] ">

            {/* Search form */}

            <div className='flex flex-col w-full md:w-3/4 gap-2 '>
                <div className="flex w-full ">

                    {/*search part */}
                    <div className='d-flex w-5/6 md:w-6/12 '>
                        <div className='d-flex w-full relative'>
                            <input onChange={(e)=>setSearchInput(e.target.value)} id="job-search" className="d-flex w-full form-input !rounded-l-2xl focus:!border-[#f11a7b] !rounded-r-none  !py-3 flex-1 !pl-9 pr-9 dark:bg-slate-800" type="search" placeholder="Search job title or keyword…" />
                            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 absolute left-3 top-1/2 transform -translate-y-1/2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                        </div>
                    </div>

                    <div className='hidden md:flex md:w-5/12 '>
                        <div className='d-flex w-full relative'>
                            <input   onChange={(e)=>setAddress(e.target.value)} id="job-search" className="d-flex w-full form-input !rounded-r-none   focus:!border-[#f11a7b]  !rounded-l-none  !py-3 flex-1 !pl-9 pr-9 dark:bg-slate-800" type="search" placeholder="Localisation" />

                            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 absolute left-3 top-1/2 transform -translate-y-1/2" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3m0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205" />
                            </svg>
                        </div>
                    </div>

                    <div className='d-flex w-1/6 md:w-1/12  '>
                    <Button  onClick={search}className='flex items-center bg-[#f11a7b] text-white h-full !rounded-l-none !rounded-r-2xl' >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-width="2">
                                <circle cx="11" cy="11" r="7"/>
                                <path stroke-linecap="round" d="m20 20l-3-3"/>
                            </g>
                        </svg>
                                                
                        </Button>
                    </div>

                </div>

                <div className='flex gap-2 self-start'>

                    <Button onClick={()=>showModal(0)} className='hidden !rounded-2xl md:flex items-center' size="large">
                        <svg width="18" height="18" className='mr-1 ' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" fillRule="evenodd" d="m8.36 1.37l6.36 5.8l-.71.71L13 6.964v6.526l-.5.5h-3l-.5-.5v-3.5H7v3.5l-.5.5h-3l-.5-.5V6.972L2 7.88l-.71-.71l6.35-5.8zM4 6.063v6.927h2v-3.5l.5-.5h3l.5.5v3.5h2V6.057L8 2.43z" clipRule="evenodd" />
                        </svg>
                        Remote
                    </Button>


                    <Button onClick={()=>showModal(1)} className='hidden  !rounded-2xl md:flex  items-center gap-1' size="large">            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6 21q-.846 0-1.423-.577Q4 19.846 4 19v-1.154q0-.666.475-1.14q.474-.475 1.14-.475H7V4.615q0-.666.475-1.14Q7.949 3 8.615 3h9.77q.666 0 1.14.475q.475.474.475 1.14V19q0 .846-.577 1.423Q18.846 21 18 21H6Zm12-1q.425 0 .713-.288T19 19V4.615q0-.269-.173-.442T18.385 4h-9.77q-.269 0-.442.173T8 4.615v11.616h7.385q.666 0 1.14.474q.475.475.475 1.141V19q0 .425.288.713T18 20ZM9.885 8.385q-.213 0-.357-.144t-.143-.357q0-.213.143-.356q.144-.143.357-.143h7.23q.213 0 .357.144q.143.144.143.356q0 .213-.143.356q-.144.144-.357.144h-7.23Zm0 2.769q-.213 0-.357-.144t-.143-.357q0-.213.143-.356q.144-.143.357-.143h7.23q.213 0 .357.144q.143.144.143.357q0 .212-.143.356q-.144.143-.357.143h-7.23Z"/>
</svg>
                        Contract
                    </Button>
                    <Button onClick={()=>showModal(2)} className='hidden !rounded-2xl md:flex  items-center gap-1' size="large">          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18Zm11-9c0 6.075-4.925 11-11 11S1 18.075 1 12S5.925 1 12 1s11 4.925 11 11Zm-8 4.414l-4-4V5.5h2v6.086L16.414 15L15 16.414Z"/>
</svg>
                        Contract Duration
                    </Button>
{ !(filters.filters?.workSchedule?.length === 0 && filters.filters?.remote?.length === 0  && filters.filters?.educationRequirement?.length === 0 && filters.filters.formattedAddress=== '' && filters.filters.minWorkExperienceYear === '' && filters.filters.maxWorkExperienceYear=== '' && filters.filters.minSalary=== '' && filters.filters.maxSalary=== ''  )          &&         <Button onClick={()=>dispatch(updateFilters({...formData}))} className='hidden !rounded-2xl md:flex  items-center gap-1' size="large">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 40 40">
                            <path fill="currentColor" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747L21.499 19.994z"/>
                        </svg>
                        Reset filter
                    </Button>}
                    <Button  onClick={()=>showModal(3)} className='flex !rounded-2xl items-center bg-[#f11a7b]' type='primary' size="large">                       <svg width="18" height="18" className='mr-1 ' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M9 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2M6.17 5a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-7.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 0 1 0-2zM15 11a1 1 0 1 0 0 2a1 1 0 0 0 0-2m-2.83 0a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 1 1 0-2zM9 17a1 1 0 1 0 0 2a1 1 0 0 0 0-2m-2.83 0a3.001 3.001 0 0 1 5.66 0H19a1 1 0 1 1 0 2h-7.17a3.001 3.001 0 0 1-5.66 0H5a1 1 0 1 1 0-2z" />
                    </svg>
                        All filters
                    </Button>


                </div>
{filters.filters &&
                <div className='flex gap-2 self-start mt-2'>
                         {
                        filters.filters?.formattedAddress  && <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>{filters.filters?.formattedAddress}</Tag>
                    }

                    {
                        filters.filters?.minWorkExperienceYear  && <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>Min exp: {filters.filters?.minWorkExperienceYear}</Tag>
                    }
                    
                    {
                        filters.filters?.maxWorkExperienceYear  && <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>Max exp: {filters.filters?.maxWorkExperienceYear}</Tag>
                    }

                    {
                        filters.filters?.minSalary  && <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>Min salary: {filters.filters?.minSalary}</Tag>
                    }

                    {
                        filters.filters?.maxSalary  && <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>Max salary: {filters.filters?.maxSalary}</Tag>
                    }

           
{
                        filters.filters.remote?.length > 0 &&  filters.filters.remote.map((work) =>(
                            <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>{getLabelByValue(Remote, work)}</Tag>
                        )) 
                    }


                    {
                        filters.filters.workSchedule?.length > 0 &&  filters.filters.workSchedule.map((work) =>(
                            <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>{getLabelByValue(WorkSchelule, work)}</Tag>
                        )) 
                    }
                    {
                        filters.filters.educationRequirement?.length > 0 &&  filters.filters.educationRequirement.map((work) =>(
                            <Tag className='text-base text-[#f11a7b] rounded-2xl border-[#f11a7b] bg-[#f11a7b1a]'>{getLabelByValue(EducationRequirement, work)}</Tag>
                        )) 
                    }


                </div>}

            </div>

            <Modal centered  title={titles[choice]} open={isModalOpen}     bodyStyle={choice === 3 ? { height: "80vh", maxHeight:"80vh", overflowY:"hidden" } : {}} 
onOk={handleOk} onCancel={handleCancel} footer={null}>
                {choice === 0 && <RemoteFilter setIsModalOpen={setIsModalOpen}/>}
                {choice === 1 && <WorkScheduleFilter setIsModalOpen={setIsModalOpen}/>}
                {choice === 2 && <ContractDurationFilter setIsModalOpen={setIsModalOpen}/>}
                {choice === 3 && <AllFilter isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>}

            </Modal>


        </div>

    )
}

export default SearchBar