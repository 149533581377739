import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';



import { useDispatch, useSelector } from 'react-redux';
import NotFoundImage from '../../images/404-illustration.svg';
import NotFoundImageDark from '../../images/404-illustration-dark.svg';
import { userLogout } from '../../redux/reducers/authSlice';

import Logo from '../../images/logo-big.png'

import AuthImage from '../../images/landing/bg.jpg';
import OnBoarding1 from '../../components/Employers/Creation/OnBoarding1';

function EmployersUnconfirmed() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { resume, auth } = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState(0);
  const [step, setStep] = useState(1);

  
  const logout = () => {
    dispatch(userLogout())
  }


  return (
    <>
    {status===0 && <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/*  Site header */}

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img className="dark:hidden" src={NotFoundImage} width="176" height="176" alt="404 illustration" />
                  <img className="hidden dark:block" src={NotFoundImageDark} width="176" height="176" alt="404 illustration dark" />
                </div>
                <div className="mb-6">Hmm... your company page hasn't been validated yet, please wait! <br/> Or create a page for your company</div>
                <div className='flex justify-center gap-2'>
                  <Link onClick={logout} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                    Log out
                  </Link>

                  <Link onClick={() => setStatus(1)} className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">
                    Create Company Page
                  </Link>
                </div>
            
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>}

    {
      status===1 && 
      <main className="bg-white dark:bg-slate-900">

      <div className="relative flex">

        {/* Content */}
        <div className="w-full md:w-1/2">

          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            <div className="flex-1">

              {/* Header */}
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" onClick={()=>setStatus(0)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 42 42">
                    <path fill="currentColor" fillRule="evenodd" d="M27.066 1L7 21.068l19.568 19.569l4.934-4.933l-14.637-14.636L32 5.933z"/>
                </svg>
                </Link>
           
              </div>

              {/* Progress bar */}
{/*               <ProgressBar step={step} setStep={setStep}/>
 */}             </div>

            {step=== 1 && <OnBoarding1 setStatus={setStatus}/>}
{/*             {step=== 2 && <OnBoarding2 step={step} setStep={setStep}/>}
            {step=== 3 && <OnBoarding3 step={step} setStep={setStep}/>} 
            {step=== 4 && <OnBoarding4 step={step} setStep={setStep}/>} */}

          </div>

        </div>

        {/* Image */}
        <div className="hidden overflow-hidden bg-gradient-to-tl from-pink-400 to-pink-200  md:flex justify-center items-center absolute top-4 bottom-4 right-4 rounded-3xl  md:w-1/2" aria-hidden="true">
        <img className="object-cover object-left  w-full h-full" src={AuthImage}  alt="Authentication" />
        </div>
      </div>

    </main>
    }

    </>
  );
}

export default EmployersUnconfirmed;