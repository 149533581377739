import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';



import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Employers/Menus/Sidebar';
import WelcomeBannerEmployers from '../../components/Employers/WelcomeBannerEmployers';
import Header from '../../components/Employers/Menus/Header';
import DBJobsCard from '../../components/Employers/Dashboard/DBJobsCard';
import DBResumesBankCard from '../../components/Employers/Dashboard/DBResumesBankCard';
import DBInvitationsCard from '../../components/Employers/Dashboard/DBInvitationsCard';
import DBCompanySettingsCard from '../../components/Employers/Dashboard/DBCompanySettingsCard';
import DBAccountSettingsCard from '../../components/Employers/Dashboard/DBAccountSettingsCard';
import { getMyCompany } from '../../redux/actions/companies';
import DBUsersCard from '../../components/Employers/Dashboard/DBUsersCard';

function EmployersDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { resume, auth } = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCompany();
  }, [])
  

  const getCompany = async () => {
    if (auth.userToken) {
      let res = await dispatch(getMyCompany({token: auth.userToken }))
    }
  }



  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative bg-white flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <WelcomeBannerEmployers />

            <div className="grid grid-cols-12 gap-6">

              <DBJobsCard/>
              <DBResumesBankCard/>
               {(auth.userInfo && auth.userInfo.role[0].includes('FULL'))  &&<DBUsersCard/>}
            {/*   <DBInvitationsCard />Account Setting
              <DBResumesBankCard/> */} 
              {(auth.userInfo && auth.userInfo.role[0].includes('FULL'))  &&<DBCompanySettingsCard/>}
              <DBAccountSettingsCard/>
            </div>


          </div>
        </main>
      </div>
    </div>
  );
}

export default EmployersDashboard;