
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { jsonToFormData } from '../../utils/Utils'




export const uploadFile = createAsyncThunk(
  'file/upload',
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization' : 'Auth ' + token 
        },
      }
      let newDatas  = jsonToFormData(data)

      let res= await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/file-service/api/v1/file`,
        newDatas,

        config
      )
      console.log(res)
      return res
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

export const deleteFile = createAsyncThunk(
  'file/delete',
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization' : 'Auth ' + token 
        },
      }
      let newDatas  = jsonToFormData(data)

      let res= await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/file-service/api/v1/file`,
        newDatas,

        config
      )
      console.log(res)
      return res
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

