import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import HeroHome from '../components/LandingPage/hero-home';
import Process from '../components/LandingPage/process';
import Features from '../components/LandingPage/features';
import Header from '../components/LandingPage/ui/header';
import Tabs from '../components/LandingPage/tabs';
import Target from '../components/LandingPage/target';
import Footer from '../components/LandingPage/ui/footer';
import AOS from "aos";
import "aos/dist/aos.css";
import PageIllustration from '../components/LandingPage/page-illustration';
import Goal from '../components/LandingPage/goal';
import Mission from '../components/LandingPage/mission';
import Closing from '../components/LandingPage/closing';
import { useThemeProvider } from '../utils/ThemeContext';
import Partners from '../components/LandingPage/partners';

function LandingPage() {

  const { currentTheme, changeCurrentTheme } = useThemeProvider();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
    changeCurrentTheme('light');

  })
  return (
    <main className="bg-white dark:bg-slate-900 overflow-x-hidden">
      <Header/>
      <HeroHome/>
      <Mission/>
      
      <Goal/>
      <Process/>
{/*        <Features/>
 */}{/*        <Tabs/> 
 */}      
      <Target/>
      <Partners/>

      <Footer/>
    </main>
  );
}

export default LandingPage;