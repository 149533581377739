// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { alertAction } from '../reducers/alertSlice'
import { jsonToFormData, removeNullProperties } from '../../utils/Utils'

const backendURL = process.env.REACT_APP_URL_BACKEND




export const getCompaniesApplications = createAsyncThunk(
  'applications/get',
  async ({token}, { rejectWithValue }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth '+ token,
        },
      }
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/admin/applications`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)



export const companyApplication = createAsyncThunk(
    'company/apply',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/company/data`,
          { ...data},

          config
        )
        console.log(res)
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )


export const companyAcceptApplication = createAsyncThunk(
    'company/accept',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/admin/applications/confirmed/${data.id}`,
           {message:data.message},

          config
        )
        console.log(res)
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )


  export const companyRejectApplication = createAsyncThunk(
    'company/reject',
    async ({ data, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token 
          },
          
        }
        let res= await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/admin/applications/rejected/${data.id}`,
          {message:data.message},

          config
        )
        console.log(res)
      } catch (error) {
        return rejectWithValue(error.response.data)
  
  
      }
    }
  )











