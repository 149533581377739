import { Button, Checkbox, Form, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { EducationRequirement, JobType, Remote, WorkSchelule, companySizes, contractDurations, contractType, educationsDatas, experiencesDatas, remoteDatas, sectors } from '../../../../utils/datas'
import { getLabelByValue } from '../../../../utils/Utils'
import { updateFilters } from '../../../../redux/reducers/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'

const AllFilter = ({setIsModalOpen, isModalOpen}) => {


    const [form] = Form.useForm();

    const  {filters, modal} = useSelector(state=>state)
    const [formData, setFormData] = useState({
        workSchedule: filters.filters.workSchedule || [],
        employmentType: filters.filters.employmentType || [],
        formattedAddress: filters.filters.formattedAddress || '',
        minWorkExperienceYear: filters.filters.minWorkExperienceYear || '',
        maxWorkExperienceYear: filters.filters.maxWorkExperienceYear || '',
        minSalary: filters.filters.minSalary || '',
        maxSalary: filters.filters.maxSalary || '',
        remote: filters.filters.remote || [],
        educationRequirement: filters.filters.educationRequirement || [],
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (modal.isOpen) {
          form.resetFields();
          console.log("form reset")
        }
      }, [modal]);

  





    // Function to handle checkbox changes
    const handleCheckboxChange = (category, value, isChecked) => {
        if (isChecked) {
            // Add the value to the array in formData
            setFormData({
                ...formData,
                [category]: [...formData[category], value]
            });
        } else {
            // Remove the value from the array in formData
            setFormData({
                ...formData,
                [category]: formData[category].filter(item => item !== value)
            });
        }
    };

    const handleInputChange = (category, value) => {
        console.log(value)
        setFormData({
            ...formData,
            [category]: value
        });
    };


    return (
        <div className='flex flex-col overflow-hidden p-0 justify-between h-full'>


            <div className='flex flex-col overflow-y-scroll '>
            <Form form={form} layout="vertical">

            <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg width="20" height="20" className='mr-1 ' viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1664 512h256v1536H256V512h256V384h128v128h896V384h128zm128 128h-128v128h128zm-256 0H640v128h896zm-1024 0H384v128h128zM384 1920h1408V896H384zM256 384V256H128v1408H0V128h256V0h128v128h896V0h128v128h256v128h-256v128h-128V256H384v128zm384 1024v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-768 256v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-256-512v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128z" />
                    </svg>LOCALISATION</h3>
                    <span>Where are you looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="formattedAddress"
                               
                            >
                                <Input   onChange={(e) => handleInputChange('formattedAddress', e.target.value)}
     size="large" className='antd-input w-full' placeholder="Localisation" />
                            </Form.Item>
                           
                        </Form.Item>

                    </div>
                </div>





                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 2048 2048">
    <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128h256zm-512 0q0 27 10 50t27 40t41 28t50 10V384h-128zM128 512q27 0 50-10t40-27t28-41t10-50H128v128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20v384zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128v-128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10v128zm1792-768h-128v896H256v128h1664V512zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19zm896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19zm-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20zM768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50v128z"/>
</svg>SALARY</h3>
                    <span>The amount of salary that you are looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="minSalary"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <InputNumber  onChange={(e) => handleInputChange('minSalary', e)}
    min={0} size="large" className='antd-input w-full' placeholder="Mininum year of experience" />
                            </Form.Item>
                            <Form.Item
                                name="maxSalary"

                                style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
                            >
                                <InputNumber   onChange={(e) => handleInputChange('maxSalary', e)} min={0} size="large" className='antd-input w-full' placeholder="Maximum year of experience" />
                            </Form.Item>
                        </Form.Item>

                    </div>
                </div>


                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg width="20" height="20" className='mr-1 ' viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1664 512h256v1536H256V512h256V384h128v128h896V384h128zm128 128h-128v128h128zm-256 0H640v128h896zm-1024 0H384v128h128zM384 1920h1408V896H384zM256 384V256H128v1408H0V128h256V0h128v128h896V0h128v128h256v128h-256v128h-128V256H384v128zm384 1024v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-768 256v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-256-512v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128z" />
                    </svg>EXPERIENCE</h3>
                    <span>The amount of experience that you are looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="minYearsExperience"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <InputNumber  onChange={(e) => handleInputChange('minWorkExperienceYear', e)}
    min={0} size="large" className='antd-input w-full' placeholder="Mininum year of experience" />
                            </Form.Item>
                            <Form.Item
                                name="maxYearsExperience"

                                style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
                            >
                                <InputNumber   onChange={(e) => handleInputChange('maxWorkExperienceYear', e)} min={0} size="large" className='antd-input w-full' placeholder="Maximum year of experience" />
                            </Form.Item>
                        </Form.Item>

                    </div>
                </div>



                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-2'> <svg width="24" height="24" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1024 736q0 13-9.5 22.5T992 768t-22.5-9.5T960 736V315L607 492q-40 20-95 20t-95-20L39 303Q0 283 0 255.5T39 209L417 20q40-20 95-20t95 20l378 189q34 17 38 42q1 1 1 4zM639 556l193-97v141q0 43-93.5 73.5T512 704t-226.5-30.5T192 600V459l193 97q40 20 127 20t127-20" />
                    </svg>EDUCATION LEVEL</h3>
                    <span>Your level of education</span>
                    <div className='flex flex-col gap-2 mt-4'>
                        {EducationRequirement.map((data) => (
                            <Checkbox
                            
                            key={data.value}
                            onChange={(e) => handleCheckboxChange('educationRequirement', data.value, e.target.checked)}
                            >{data.label}</Checkbox>
                        ))}

                    </div>
                </div>







                </Form>

            </div>

            <div className='flex gap-2 mt-8 items-center justify-end' style={{ height: "80px" }}>
            <Button size="large" className='!rounded-2xl'>
                    Cancel
                </Button>
                <Button onClick={() => {dispatch(updateFilters(formData)); setIsModalOpen(false)}} type="primary" className='flex items-center bg-[#f11a7b] !rounded-2xl gap-2' size="large">
                    <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="m15.7 14.3l-4.2-4.2c-.2-.2-.5-.3-.8-.3c.8-1 1.3-2.4 1.3-3.8c0-3.3-2.7-6-6-6S0 2.7 0 6s2.7 6 6 6c1.4 0 2.8-.5 3.8-1.4c0 .3 0 .6.3.8l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-.9 0-1.3M6 10.5c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5s4.5 2 4.5 4.5s-2 4.5-4.5 4.5" />
                    </svg>
                    Explore jobs
                </Button>

             
            </div>

        </div>
    )
}

export default AllFilter