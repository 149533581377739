import React, { useState } from 'react';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import cv from '../../images/cv/cv.png'
import EditMenu from '../DropdownEditMenu'
import ModalBasic from '../Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import { createResume, deleteResume, updateResume } from '../../redux/actions/resumes';
import ModalBlank from '../Modals/ModalBlank';
import { jsonToFormData } from '../../utils/Utils';
import Input from '../Form/Input';
import { Modal, Popover } from 'antd';



function ResumesCard({ resumes }) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [publicModalOpen, setPublicModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const [currentResume, setCurrentResume] = useState(null);
  const [titleResume, setTitleResume] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector(state => state)

  let init = {
    jobTitle: '',
    fullname: '',
    isHidden: false
  }
  const [formData, setFormData] = useState(init);
  const [formErrors, setFormErrors] = useState({});

  const [openPopoverId, setOpenPopoverId] = useState(null);

  const handleOpenChange = (resumeId, isOpen) => {
    if (isOpen) {
      setOpenPopoverId(resumeId);
    } else {
      setOpenPopoverId(null);
    }
  };




  const handleInputError = (inputName, errorMessage) => {
    setFormErrors(prevErrors => {
      if (errorMessage === '') {
        // If the error message is empty, remove the key from the errors object
        const { [inputName]: removed, ...newErrors } = prevErrors;
        return newErrors;
      } else {
        // Otherwise, update or add the error message for the inputName
        return { ...prevErrors, [inputName]: errorMessage };
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleCreateResume = async (e) => {
    if (Object.values(formErrors).filter(error => error).length > 0) {
      toast.error('Please, fill all the required field!');
      return;
    }
    e.stopPropagation();
    /*     if (titleResume.trim() === "") {
          // Optionally show a message to the user that the field can't be empty.
          alert("The title cannot be empty.");
          return;
        }
     */


    console.log(formData)

    const actionResult = await dispatch(createResume({ datas: formData, token: auth.userToken }));

    if (createResume.fulfilled.match(actionResult)) {
      toast.success(`Resume successfully Created!`);
    } else {
      toast.error('Unknown error occurred, try again!');

      // throw new Error(actionResult.payload || 'Unknown error occurred');
    }
    setCreateModalOpen(false);
    setTitleResume("");
  }


  const handleUpdateTitleResume = async (e) => {
    if (Object.values(formErrors).filter(error => error).length > 0) {
      toast.error('Please, fill all the required field!');
      return;
    }
    e.stopPropagation();
    /*     if (titleResume.trim() === "") {
          // Optionally show a message to the user that the field can't be empty.
          alert("The title cannot be empty.");
          return;
        }
     */


    let newTitle = formData.jobTitle

    let newData = {...currentResume};
    newData.jobTitle = newTitle;
    let res = await dispatch(updateResume({ id: currentResume.id, resumeData: newData, token: auth.userToken }));
    if (res.type === 'resume/update/rejected') {
      console.log(res)
      toast.error(res.payload.response.data || "An error occurred while updating the resume.")
    } else {
      toast.success("Title successfully changed!")

    }

    setRenameModalOpen(false);
    setFormData(init)
    setTitleResume("");
  }
  const handleDeleteResume = async (id) => {


    const actionResult = await dispatch(deleteResume({ id, token: auth.userToken }));
    if (deleteResume.fulfilled.match(actionResult)) {
      toast.success(`Successfully deleted!`);
    } else {
      toast.error('Unknown error occurred, try again!');

    }
    setDangerModalOpen(false);

  }

  const handleUpdateResume = async (resume) => {

    let updatedResume = { ...resume };
    if (resume.isHidden) {
      updatedResume.isHidden = false;

    } else {
      updatedResume.isHidden = true;

    }
    console.log(updateResume)

    const actionResult = await dispatch(updateResume({ id: resume.id, resumeData: updatedResume, token: auth.userToken }));
    if (updateResume.fulfilled.match(actionResult)) {
      toast.success(`Successfully updated!`);
    } else {
      toast.error('Unknown error occurred, try again!');

    }
    setPublicModalOpen(false);

  }
  return (

    <div className='flex flex-col gap-5 bg-slate-100 rounded-2xl px-8 py-5'>


      <h2 className='font-semibold text-xl '>Your resumes</h2>

      <div class="grid grid-cols-1 md:grid-cols-5 gap-4 mb-5">
        {/*         I want to display an image in each div .  I have 7 have images that same just different colors, how can I render them randomly
 */}        <div onClick={(e) => { e.stopPropagation(); setCreateModalOpen(true); }} class="flex hover:cursor-pointer hover:border-3 flex-col justify-center items-center h-[300px]  border-dashed border-2 border-[#f11a7b] rounded-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 1024 1024">
            <path fill="#f11a7b" d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0zm0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01zM736 480H544V288c0-17.664-14.336-32-32-32s-32 14.336-32 32v192H288c-17.664 0-32 14.336-32 32s14.336 32 32 32h192v192c0 17.664 14.336 32 32 32s32-14.336 32-32V544h192c17.664 0 32-14.336 32-32s-14.336-32-32-32z" />
          </svg>

          <span className='text-base font-semibold text-[#f11a7b]'>Add resume</span>

        </div>

        {
          resumes.map((resume) => (
            <div onClick={() => navigate(`/resume/${resume.id}`)} class="relative hover:scale-105 transition  flex flex-col justify-center items-center shadow-css bg-white h-[300px]   rounded-2xl hover:cursor-pointer">
              <div className='absolute top-2 left-2'>{!resume.isHidden ?
                <svg width="22" height="22" className=" text-slate-300 fill-current " viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6C5.188 6 1 12 1 12s4.188 6 11 6s11-6 11-6s-4.188-6-11-6zm0 10c-3.943 0-6.926-2.484-8.379-4c1.04-1.085 2.862-2.657 5.254-3.469A3.96 3.96 0 0 0 8 11a4 4 0 0 0 8 0a3.96 3.96 0 0 0-.875-2.469c2.393.812 4.216 2.385 5.254 3.469c-1.455 1.518-4.437 4-8.379 4z" />
                </svg> : <svg width="22" height="22" className=" text-slate-300 fill-current " viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 12s4.188-6 11-6c.947 0 1.839.121 2.678.322L8.36 12.64A3.96 3.96 0 0 1 8 11c0-.937.335-1.787.875-2.469c-2.392.812-4.214 2.385-5.254 3.469a14.868 14.868 0 0 0 2.98 2.398l-1.453 1.453C2.497 14.13 1 12 1 12zm22 0s-4.188 6-11 6c-.946 0-1.836-.124-2.676-.323L5 22l-1.5-1.5l17-17L22 5l-3.147 3.147C21.501 9.869 23 12 23 12zm-2.615.006a14.83 14.83 0 0 0-2.987-2.403L16 11a4 4 0 0 1-4 4l-.947.947c.31.031.624.053.947.053c3.978 0 6.943-2.478 8.385-3.994z" />
                </svg>
              } </div>

              <img src={cv} className='w-20 h-20' />
              <span className='mt-5 text-lg font-semibold text-center'>{resume.jobTitle}</span>

              <span className='italic text-base text-center'>{resume.fullname}</span>

              <span className='absolute  italic text-xs bottom-2 right-2'>{moment(resume.createdAt).format('MM/DD/YYYY')}</span>


              <div className='absolute top-2 right-2 hover:cursor-pointer hover:bg-slate-100 rounded-full'>
                <Popover
                  content={<div className='w-28 flex flex-col'>
                    <div className=' flex gap-1 items-center hover:cursor-pointer py-2  font-semibold hover:bg-slate-100 ' onClick={(e) => { e.stopPropagation(); setOpenPopoverId(null); setCurrentResume(resume); setRenameModalOpen(true); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512">
    <path fill="currentColor" d="m292.6 407.78l-120-320a22 22 0 0 0-41.2 0l-120 320a22 22 0 0 0 41.2 15.44l36.16-96.42a2 2 0 0 1 1.87-1.3h122.74a2 2 0 0 1 1.87 1.3l36.16 96.42a22 22 0 0 0 41.2-15.44Zm-185.84-129l43.37-115.65a2 2 0 0 1 3.74 0l43.37 115.67a2 2 0 0 1-1.87 2.7h-86.74a2 2 0 0 1-1.87-2.7ZM400.77 169.5c-41.72-.3-79.08 23.87-95 61.4a22 22 0 0 0 40.5 17.2c8.88-20.89 29.77-34.44 53.32-34.6c32.32-.22 58.41 26.5 58.41 58.85a1.5 1.5 0 0 1-1.45 1.5c-21.92.61-47.92 2.07-71.12 4.8c-54.75 6.44-87.43 36.29-87.43 79.85c0 23.19 8.76 44 24.67 58.68C337.6 430.93 358 438.5 380 438.5c31 0 57.69-8 77.94-23.22h.06a22 22 0 1 0 44 .19v-143c0-56.18-45-102.56-101.23-102.97ZM380 394.5c-17.53 0-38-9.43-38-36c0-10.67 3.83-18.14 12.43-24.23c8.37-5.93 21.2-10.16 36.14-11.92c21.12-2.49 44.82-3.86 65.14-4.47a2 2 0 0 1 2 2.1C455 370.1 429.46 394.5 380 394.5Z"/>
</svg>
                      Rename
                      </div>
                    <div className='flex gap-1 items-center hover:cursor-pointer py-2 hover:bg-slate-100 font-semibold ' onClick={(e) => { e.stopPropagation(); setOpenPopoverId(null); setCurrentResume(resume); setPublicModalOpen(true); }} >   {
                      resume.isHidden ?
                        <svg width="22" height="22" className=" fill-current " viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 6C5.188 6 1 12 1 12s4.188 6 11 6s11-6 11-6s-4.188-6-11-6zm0 10c-3.943 0-6.926-2.484-8.379-4c1.04-1.085 2.862-2.657 5.254-3.469A3.96 3.96 0 0 0 8 11a4 4 0 0 0 8 0a3.96 3.96 0 0 0-.875-2.469c2.393.812 4.216 2.385 5.254 3.469c-1.455 1.518-4.437 4-8.379 4z" />
                        </svg> : <svg width="22" height="22" className=" fill-current " viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 12s4.188-6 11-6c.947 0 1.839.121 2.678.322L8.36 12.64A3.96 3.96 0 0 1 8 11c0-.937.335-1.787.875-2.469c-2.392.812-4.214 2.385-5.254 3.469a14.868 14.868 0 0 0 2.98 2.398l-1.453 1.453C2.497 14.13 1 12 1 12zm22 0s-4.188 6-11 6c-.946 0-1.836-.124-2.676-.323L5 22l-1.5-1.5l17-17L22 5l-3.147 3.147C21.501 9.869 23 12 23 12zm-2.615.006a14.83 14.83 0 0 0-2.987-2.403L16 11a4 4 0 0 1-4 4l-.947.947c.31.031.624.053.947.053c3.978 0 6.943-2.478 8.385-3.994z" />
                        </svg>
                    }  {
                        !resume.isHidden ? "Hide" : "Show"}


                    </div>
                    <div className=' flex gap-1 items-center hover:cursor-pointer py-2 text-red-500 font-semibold hover:bg-slate-100 ' onClick={(e) => { e.stopPropagation(); setOpenPopoverId(null); setCurrentResume(resume); setDangerModalOpen(true); }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                        <path fill="currentColor" d="m9.4 15.808l2.6-2.6l2.6 2.6l.708-.708l-2.6-2.6l2.6-2.6l-.708-.708l-2.6 2.6l-2.6-2.6l-.708.708l2.6 2.6l-2.6 2.6l.708.708ZM7.615 20q-.69 0-1.152-.462Q6 19.075 6 18.385V6H5V5h4v-.77h6V5h4v1h-1v12.385q0 .69-.462 1.152q-.463.463-1.153.463h-8.77ZM17 6H7v12.385q0 .23.192.423q.193.192.423.192h8.77q.23 0 .423-.192q.192-.193.192-.423V6ZM7 6v13V6Z" />
                      </svg>
                      Delete</div>

                  </div>}
                  trigger="click"
                  open={openPopoverId === resume.id}
                  onOpenChange={(isOpen) => handleOpenChange(resume.id, isOpen)}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3" d="M6 12h0m6 0h0m6 0h0" />
                    </svg>
                  </div>
                </Popover>
              </div>

            </div>

          ))
        }


      </div>

      <Modal centered onCancel={() => setCreateModalOpen(false)} open={createModalOpen} footer={null} title="Create New Resume">
        {/* Modal content */}
        <div className="px-5 pt-4 pb-1">
          <div className="text-sm">
            <div className="space-y-2">
              <p>Create a new resume for a separate role you want to apply for - tailor your CV to job requirements</p>
            </div>
            <div>

              <Input  {...{ name: "jobTitle", text: "Job Title", maxLength: 120, isRequired: true, placeholder: "Job Title", value: formData.jobTitle, handleChange: handleChange, handleError: handleInputError }} />
              <Input  {...{ name: "fullname", text: "Full Name", maxLength: 120, isRequired: true, placeholder: "Full Name", value: formData.fullname, handleChange: handleChange, handleError: handleInputError }} />
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4">
          <div className="flex flex-wrap justify-end space-x-2">
            <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setCreateModalOpen(false); }}>Cancel</button>
            <button onClick={(e) => handleCreateResume(e)} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white" >Create</button>
          </div>
        </div>
      </Modal>

      <Modal centered onCancel={() => setDangerModalOpen(false)} open={dangerModalOpen} footer={null} id="danger-modal" >
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Delete Resume?</div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>Alert!  You're about to delete this resume. All the applications provided with this resume will be deleted. Are you sure you're ready to say goodbye?</p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setDangerModalOpen(false); }}>Cancel</button>
              <button onClick={(e) => handleDeleteResume(currentResume.id)} className="btn-sm bg-rose-500 hover:bg-rose-600 text-white">Yes, Delete it</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal id="public-modal" centered onCancel={() => setPublicModalOpen(false)} open={publicModalOpen} footer={null}>
        {currentResume &&
          <div className="p-5 flex space-x-4">
            {/* Icon */}
            <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-green-100 dark:bg-green-500/30">
              <svg className="w-4 h-4 shrink-0 fill-current text-green-500" viewBox="0 0 16 16">
                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
              </svg>
            </div>
            {/* Content */}
            <div>
              {/* Modal header */}
              <div className="mb-2">
                <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">{currentResume.isHidden ? "Make this resume public?" : "Make this resume not public?"}</div>
              </div>
              {/* Modal content */}
              <div className="text-sm mb-10">
                <div className="space-y-2">
                  <p>{currentResume.isHidden ? "Alert!  You're about to make this resume public. It's means all the company will be able to see with masterpiece?" : "Alert!  You're about to make this resume not public. It's means no one will be able to see this masterpiece? Be careful, all the applications provided with this resume will be deleted."}</p>
                </div>
              </div>
              {/* Modal footer */}
              <div className="flex flex-wrap justify-end space-x-2">
                <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setPublicModalOpen(false); }}>Cancel</button>
                <button onClick={(e) => handleUpdateResume(currentResume)} className="btn-sm bg-green-500 hover:bg-green-600 text-white">{!currentResume.isHidden ? "Yes, remove it" : "Yes, publish it"}</button>
              </div>
            </div>
          </div>}
      </Modal>

      <Modal centered onCancel={() => setRenameModalOpen(false)} open={renameModalOpen} footer={null} title="Rename Resume">
        {/* Modal content */}
        <div className="px-5 pt-4 pb-1">
          <div className="text-sm">
          {/*   <div className="space-y-2">
              <p>Create a new resume for a separate role you want to apply for - tailor your CV to job requirements</p>
            </div> */}
            <div>

              <Input  {...{ name: "jobTitle", text: "Title", maxLength: 120, isRequired: true, placeholder: "New Title", value: formData.jobTitle, handleChange: handleChange, handleError: handleInputError }} />
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4">
          <div className="flex flex-wrap justify-end space-x-2">
            <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setCreateModalOpen(false); }}>Cancel</button>
            <button onClick={(e) => handleUpdateTitleResume(e)} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white" >Update</button>
          </div>
        </div>
      </Modal>

      
    </div>
  );
}

export default ResumesCard;
