import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../redux/reducers/counterSlice'
import authReducer from '../redux/reducers/authSlice'
import resumeReducer from '../redux/reducers/resumeSlice'
import modalReducer from '../redux/reducers/modalSlice'
import alertReducer from '../redux/reducers/alertSlice'
import companyReducer from '../redux/reducers/companiesSlice'
import jobReducer from '../redux/reducers/jobsSlice'
import jobappReducer from '../redux/reducers/jobapplicationSlice'
import applicationsCompaniesSlice from './reducers/applicationsCompaniesSlice'
import jobpostingReducer from './reducers/jobPostingSlice'
import filtersReducer from '../redux/reducers/filtersSlice'
import usersCompaniesReducer from './reducers/usersCompaniesSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    alert: alertReducer,
    applicationsCompanies: applicationsCompaniesSlice,
    resume: resumeReducer,
    modal: modalReducer,
    companies: companyReducer,
    jobs: jobReducer,
    jobapps: jobappReducer,
    jobposting: jobpostingReducer,
    filters: filtersReducer,
    usersCompanies: usersCompaniesReducer
  }
})