import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import WelcomeBanner from '../components/Home/WelcomeBanner';
import ResumesCard from '../components/Home/ResumesCard';
import { useDispatch, useSelector } from 'react-redux';
import { getResumes } from '../redux/actions/resumes';
import { userLogout } from '../redux/reducers/authSlice';

function Home() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {resume, auth} = useSelector(state => state)
  const dispatch = useDispatch();

  
  useEffect(() => {

    if(auth.userToken && !auth.loading){
      dispatch(getResumes({token: auth.userToken}))
    }

  }, [auth]);

  return (
    <div className="flex h-[100dvh] overflow-hidden bg-white dark:bg-slate-900">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header title={"Dashboard"} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow flex flex-col h-full ">
          <div className="px-4 sm:px-6 h-full  lg:px-8 py-8 w-full max-w-9xl mx-auto">
            
            <WelcomeBanner/>
            <ResumesCard resumes={resume.resumes}/>

            
          </div>
        </main>
    </div>
    </div>
  );
}

export default Home;