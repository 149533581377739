import React, { useState } from 'react'
import { Button, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createVacancy, updateVac } from '../../../../redux/actions/jobposting'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify'
const Job4 = ({setCreateModalOpen, setIsLoading, isEdit,setStep, step, formData, setFormData}) => {

  const [form] = Form.useForm();
  const {auth} = useSelector(state => state)
  const dispatch = useDispatch();
  const [description, setDescription] = useState('')

  
  const handleQuillChange = (content) => {
    setDescription(content); // Update local state
    form.setFieldsValue({ message: content }); // Update form value manually
};

  const onFinish = async (values) => {
  

    const updatedFormData = {...formData, ...values};

    if(auth.userToken){
      
      if(!isEdit){
        const actionResult = await dispatch(createVacancy({data: updatedFormData, token: auth.userToken }))
        console.log(actionResult)
  
        if(actionResult.type === 'jobposting/vacancy/create/rejected'){
          toast.error(actionResult.payload);
        }
        if(actionResult.type==='jobposting/vacancy/create/fulfilled'){
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            toast.success(`Successfully Created!`);
      
            setCreateModalOpen(false)
          }, 1000)
        }
      }else{

       try {
        setIsLoading(true);

          // Dispatch the async thunk
          await dispatch(updateVac({ data:updatedFormData, token: auth.userToken })).unwrap();
          // If the promise resolves successfully, show success toast
          toast.success("Job Edited!");
        } catch (error) {
          // If the promise is rejected, handle the error
          console.error(error); // Useful for debugging
          toast.error(error.message || "An error occurred!");
        } finally {
          setIsLoading(false);

          setCreateModalOpen(false)

        }  

      }


      

    }
  


  }
  const editorStyle = {
    backgroundColor: '#f4f4f5',
    "minHeight": '300px',
    borderRadius: '0.75rem', // Add this line
};

  return (
    <div className="px-4 py-8">
    <div className="max-w-md mx-auto">
         <style>
                {`
           .ql-toolbar.ql-snow {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            background:white;
          }
            .ql-container.ql-snow {
              border:none;
            }
          `}
          </style>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={formData}
      >

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please enter a description!' }]}
          >
            <ReactQuill   value={description} // Control ReactQuill with local state
                        onChange={handleQuillChange} 
                         style={editorStyle} className='mt-2 '  theme="snow" />
          </Form.Item>

                  


          <div className="flex items-center justify-end gap-2">
          <Button
              type="default"
              htmlType="submit"
              className="flex items-center gap-2"
              size="large"
              onClick={() => setStep(step-1)}
            >
              Previous Step
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#f11a7b] gap-2"
              size="large"
            >
              {isEdit?"Update":"Create"}
            </Button>
          </div>
      </Form>
    </div>
  </div>)
}

export default Job4