import QRCode from 'qrcode.react'

export default function Target() {
  return (
    <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 bg-gray-100">
      <div className="py-12 md:py-20">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center " data-aos="fade-up">
          <h2 className="h2 text-black mb-4"> Let employers see the real you!</h2>

          <div data-aos="fade-up" data-aos-delay="400">
                <a className="btn text-white text-2xl bg-pink-500 hover:bg-pink-700 w-full mb-4 sm:w-auto sm:mb-0" href="/signup">Sign up</a>
              </div>
        </div>

      </div>
    </div>


    <div className='flex flex-col justify-center items-center my-20'>
    <h2 className="h2  text-[#f11a7b] mb-6"> SCAN ME!</h2>

    <QRCode
      value="https://evidenta.io/"
      size={300}
      bgColor={"#ffffff"}
      fgColor={"#000000"}
      level={"L"}
      className="flex justify-center"
    />
    </div>

  </section>
  )
}
