import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/FOND.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import { toast } from 'react-toastify';
import axios from 'axios';
import Logo from '../images/logo-big.png'

function ResetPassword() {
  const backendURL = process.env.REACT_APP_URL_BACKEND
  const email = useRef("");

  const handleSend = async (event) => {
    event.preventDefault(); // Prevent default form submission


    const config = {
     headers: {
       'Content-Type': 'application/json',

     },  
   }

   


   try{
     const { data } = await axios.get(
       `${process.env.REACT_APP_URL_BACKEND}/authentification/api/v1/forgot/password/${email.current.value}`,
       config
     )

     toast.success(`Email successfully sended!`);

   }catch(e){
    console.log(e)
     toast.error(e.response.data);

   }
   


 
 }
  return (
    <main className="bg-white dark:bg-slate-900">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img style={{width:"200px"}} src={Logo} alt={"logo"} />
                </Link>
              </div>
            </div>
            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Reset your Password </h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-rose-500">*</span></label>
                    <input ref={email} id="email" className="form-input w-full" type="email" />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button onClick={handleSend} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white whitespace-nowrap">Send Reset Link</button>
                </div>
              </form>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
        </div>

      </div>

    </main>
  );
}

export default ResetPassword;