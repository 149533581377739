
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function MobileMenu() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const trigger = useRef(null)
  const mobileNav = useRef(null)



  return (
    <div className="md:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen && 'active'}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg
          className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="4" width="24" height="2" rx="1" />
          <rect y="11" width="24" height="2" rx="1" />
          <rect y="18" width="24" height="2" rx="1" />
        </svg>
      </button>

      {/*Mobile navigation */}
      <nav
        id="mobile-nav"
        ref={mobileNav}
        className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
        style={mobileNavOpen ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0.8 }}
      >
        <ul className="bg-white px-4 py-2">
          <li>
            <Link to="/" className="flex text-black hover:text-gray-200 py-2" onClick={() => setMobileNavOpen(false)}>
              Home
            </Link>
          </li>
 
   {/*        <li>
            <Link to="/aboutus" className="flex text-black hover:text-gray-200 py-2" onClick={() => setMobileNavOpen(false)}>
              About us
            </Link>
          </li> */}
          <li>
            <Link to="/contactus" className="flex text-black hover:text-gray-200 py-2" onClick={() => setMobileNavOpen(false)}>
              Contact us
            </Link>
          </li>
   
          <li>
            <Link to="/signin" className="flex font-medium w-full text-pink-500 hover:text-gray-200 py-2 justify-center" onClick={() => setMobileNavOpen(false)}>
              Sign in
            </Link>
          </li>
          <li>
            <Link
              to="/employers/signin"
              className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-pink-500 hover:bg-pink-700 transition duration-150 ease-in-out" onClick={() => setMobileNavOpen(false)}
            >
             Employers
            </Link>

         
          </li>
        </ul>
      </nav>
    </div>
  )
}
