import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';


import Sidebar from '../../components/Employers/Menus/Sidebar';
import Header from '../../components/Employers/Menus/Header';
import { getVacancyById, getVacancyResumes } from '../../redux/actions/jobposting';
import { useParams } from 'react-router';
import JobApplicationItem from '../../components/Employers/Jobs/Applications/JobApplicationItem';
import NewResumePdf from '../../components/Resume/pdf/NewResumePdf';
import { Button, Modal, Pagination } from 'antd';
import FormApplicationAnswer from '../../components/Employers/Jobs/Applications/FormApplicationAnswer';
import PaginationNumeric from '../../components/PaginationNumeric';
import SearchBar from '../../components/Employers/Jobs/Applications/SearchBar';
import Application from '../../components/Employers/Jobs/Applications/Application';
import { updateFilters } from '../../redux/reducers/filtersSlice';
function EmployersJobApplications() {



  const [job, setJob] = useState(null)

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openHiringProcess, setOpenHiringProcess] = useState(false);

  const [choice, setChoice] = useState(1);
  const [initialList, setInitialList] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModalJob, setCreateModalJob] = useState(false);

  const [type, setType] = useState('');
  const [page, setPage] = useState(0);
  const [vacancy, setVacancy] = useState(null);


  const [listApplications, setListApplications] = useState([]);
  const [pdfSrc, setPdfSrc] = useState(null);
  const [current, setCurrent] = useState(1);

  const { auth, jobposting, filters } = useSelector(state => state);

  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
       dispatch(updateFilters({page: current - 1, size: 10, status: ['NOT_VIEWED', 'VIEWED', 'INVITED', 'REJECTED']}))
        getVacancy(id)
  }, [])

  useEffect(() => {
    if(filters.filters){
      console.log("filters")
      getRequests(filters.filters);

    }
    }, [filters])


  useEffect(() => {
    setListApplications(jobposting.applications)
    setInitialList(jobposting.applications)
  }, [jobposting])




  const getRequests = async (params) => {
    console.log('get request')
    if (auth.userToken) {
      let res = await dispatch(getVacancyResumes({id: id, params, token: auth.userToken }))
    }
  }

  const getVacancy = async (id) => {
    if (auth.userToken) {
      let res = await dispatch(getVacancyById({id: id, token: auth.userToken }))
      setVacancy(res.payload.data)
      console.log(res)
    }
  }

  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);

    dispatch(updateFilters({...filters.filters, page: page - 1}))


  };




  return (
    <div className="flex h-[100dvh] overflow-hidden bg-white">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />




      <main className="relative flex flex-col flex-1 overflow-y-hidden  overflow-x-hidden">

        {/*  Site header */}
        <Header title={vacancy && vacancy.title?vacancy.title:null} style={{ height: "30%", background: "blue" }} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className=" h-full  overflow-y-hidden " >
          <div className="flex flex-col px-4 h-full overflow-hidden sm:px-6 lg:px-0   w-full max-w-9xl mx-auto">
      

            {/* Page content */}
            {listApplications.length !== 0 &&<div className="flex h-full overflow-hidden  md:px-14 ">



              {/* Content */}
              <div className='w-full  mb-4 md:w-1/2 md:px-4  overflow-y-auto overflow-x-hidden'>

      {/* Page header */}
      <SearchBar items={initialList} setListApplications={setListApplications} />

                {!jobposting.loading&& <><div className="flex justify-between items-center mb-4 ">
                  <div className="text-sm text-slate-500 dark:text-slate-400 italic">Showing {listApplications.length} {listApplications.length >1?'applications':'application'}</div>
                  </div>

               {listApplications.length > 0 && <div>
                  {listApplications.map((jobapp) => {
                    return (
                      <JobApplicationItem
                        key={jobapp.id}
                        {...jobapp}

                        isSelected={job?job.id === jobapp.id:false}
                        setJob={setJob}
                        setCreateModalJob={setCreateModalJob}


                      />
                    );
                  })}
                </div>} 


     
                
                <div className="mt-6 flex justify-center">
                  <Pagination current={current} onChange={onChange} total={jobposting.totalEntity} defaultPageSize={jobposting.size} defaultCurrent={0} />
              </div>
                
                
                </>}


      

              </div>



              <div className=' flex-col hidden md:flex  md:w-1/2  ' >
               {job &&  <Application type={type} setType={setType}   job={job} setCreateModalOpen={setCreateModalOpen} pdfSrc={pdfSrc} setPdfSrc={setPdfSrc}/>}

                
      
                {
                  !job && <div className='flex flex-col gap-4 w-full h-full justify-center items-center'> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 36 36">
    <path fill="#dfd9d9" d="M30.4 17.6c-1.8-1.9-4.2-3.2-6.7-3.7c-1.1-.3-2.2-.5-3.3-.6c2.8-3.3 2.3-8.3-1-11.1s-8.3-2.3-11.1 1s-2.3 8.3 1 11.1c.6.5 1.2.9 1.8 1.1v2.2l-1.6-1.5c-1.4-1.4-3.7-1.4-5.2 0c-1.4 1.4-1.5 3.6-.1 5l4.6 5.4c.2 1.4.7 2.7 1.4 3.9c.5.9 1.2 1.8 1.9 2.5v1.9c0 .6.4 1 1 1h13.6c.5 0 1-.5 1-1v-2.6c1.9-2.3 2.9-5.2 2.9-8.1v-5.8c.1-.4 0-.6-.2-.7zm-22-9.4c0-3.3 2.7-5.9 6-5.8c3.3 0 5.9 2.7 5.8 6c0 1.8-.8 3.4-2.2 4.5v-5a3.4 3.4 0 0 0-3.4-3.2c-1.8-.1-3.4 1.4-3.4 3.2v5.2c-1.7-1-2.7-2.9-2.8-4.9zM28.7 24c.1 2.6-.8 5.1-2.5 7.1c-.2.2-.4.4-.4.7v2.1H14.2v-1.4c0-.3-.2-.6-.4-.8c-.7-.6-1.3-1.3-1.8-2.2c-.6-1-1-2.2-1.2-3.4c0-.2-.1-.4-.2-.6l-4.8-5.7c-.3-.3-.5-.7-.5-1.2c0-.4.2-.9.5-1.2c.7-.6 1.7-.6 2.4 0l2.9 2.9v3l1.9-1V7.9c.1-.7.7-1.3 1.5-1.2c.7 0 1.4.5 1.4 1.2v11.5l2 .4v-4.6c.1-.1.2-.1.3-.2c.7 0 1.4.1 2.1.2v5.1l1.6.3v-5.2l1.2.3c.5.1 1 .3 1.5.5v5l1.6.3v-4.6c.9.4 1.7 1 2.4 1.7l.1 5.4z" class="clr-i-outline clr-i-outline-path-1"/>
    <path fill="none" d="M0 0h36v36H0z"/>
</svg>
                  <span >Select an application</span>
                  </div>
                }
              </div>



            </div>}

            {listApplications.length === 0 && <div className='flex flex-col gap-4 w-full h-full justify-center items-center'>
            <svg id="fi_12716622" enable-background="new 0 0 500 500" height={200} width={200} viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"><g><path d="m399.755 329.894v54.213l-155.048 88.96v-179.669l60.979 90.479z" fill="#f7a63b"></path><path d="m244.707 293.398v179.669l-155.093-88.96v-53.431l97.382 55.824z" fill="#ffb859"></path><path d="m462.62 293.214-155.047 88.96-61.025-90.432 152.654-87.58 2.347-1.334.092-.046 1.519 2.255z" fill="#ffc16e"></path><path d="m307.573 382.174-1.887 1.703-60.979-90.479 1.841-1.656z" fill="#ffcc8a"></path><path d="m474.126 136.419-72.485 66.363-.092.046-2.347 1.334-154.495-88.637 74.371-68.02z" fill="#ffc16e"></path><path d="m475 139.318-71.84 65.719-1.519-2.255 72.485-66.363z" fill="#f7a63b"></path><path d="m89.292 203.978-1.702-.966-.967 1.565-61.623-86.889 1.749-1.841z" fill="#ffcc8a"></path><path d="m244.707 115.525-154.816 88.822-.599-.369-62.543-88.131 155.093-88.914z" fill="#ffb859"></path><path d="m242.683 291.972-57.712 93.102-155.047-88.96 56.699-91.537.967-1.565 1.702.966.599.369z" fill="#ffc16e"></path><path d="m244.707 115.525-154.816 88.822 152.792 87.625 2.025 1.427 1.841-1.657 152.654-87.579zm0 170.786-142.713-81.826 142.713-81.827 142.667 81.827z" fill="#ffcc8a"></path><path d="m387.375 204.485-142.668 81.826v-163.653z" fill="#ffb859"></path><path d="m244.707 122.658v163.653l-142.713-81.826z" fill="#f7a63b"></path><path d="m244.707 293.398-57.711 93.102-2.025-1.426 57.712-93.102z" fill="#f7a63b"></path><path d="m307.573 382.174-1.887 1.703 154.819-88.847 2.115-1.816z" fill="#eb9e38"></path></g></svg>


<span className='font-bold text-xl'>No application found</span>



                </div>}
                

          </div>
        </div>


      </main>


      <Modal width={800} title={"Application"} open={createModalOpen} onCancel={() => setCreateModalOpen(false)} centered footer={null} >
        {/* Modal content */}
        <div className="md:px-5 pt-4 pb-1">
          <div className="text-sm ">
            <div className="md:px-5 py-4">
              <FormApplicationAnswer status={type}  open={createModalOpen}  job={job} setCreateModalOpen={setCreateModalOpen} />
            </div>
          </div>
        </div>



      </Modal>



      {job && <Modal width={800} open={createModalJob} onCancel={() => setCreateModalJob(false)} centered footer={null} >
        {/* Modal content */}
          
        <Application  type={type} setType={setType} job={job} createModalOpen={createModalOpen} setCreateModalOpen={setCreateModalOpen} pdfSrc={pdfSrc} setPdfSrc={setPdfSrc}/>      
 

      </Modal>}


    </div>

  );
}

export default EmployersJobApplications;