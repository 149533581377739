import VideoThumb from '../../images/landing/hero-image-01.jpg'

import { useEffect } from 'react';

import  goal1 from "../../images/landing/goal1.png"
import  goal2 from "../../images/landing/goal2.png"
import  goal3 from "../../images/landing/goal3.png"
export default function Mission() {

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative bg-pink-50 ">

  

        {/* Hero content */}
        <div className="relative pt-4 pb-10 md:pt-10 md:pb-16">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center  ">
            <h2 className="h2 text-pink-500 mb-4" data-aos="fade-up">WHAT DO WE OFFER</h2>

            <div className='flex flex-col w-100 '>
              <div className='flex w-100 border-b py-4 border-gray-200 ' data-aos="fade-up" data-aos-delay="200">
                <img src={goal1} alt={"logo"} className='w-20 mr-4 md:mr-0  md:w-28' />
                <div className='flex-col md:flex-row flex flex-1   md:items-center  '>
                   <h2 className='hidden md:block text-left text-black text-xl font-bold pl-6 w-1/3'>CV <br/> BUILDER</h2>
                   <h2 className=' md:hidden text-left text-black text-xl font-bold w-100'>CV BUILDER</h2>

                   <span className='text-black text-base md:text-lg text-left'>Create a professional resume with our easy-to-use template</span>
                </div>
              </div>

              <div className='flex w-100 border-b py-4 border-gray-200 ' data-aos="fade-up" data-aos-delay="200">
                <img src={goal2} alt={"logo"} className='w-20 mr-4 md:mr-0   md:w-28' />
                <div className='flex-col md:flex-row flex flex-1   md:items-center   '>
                   <h2 className='hidden md:block text-left text-black text-xl font-bold pl-6 w-1/3'>CV <br/> VERIFICATION</h2>
                   <h2 className=' md:hidden text-left text-black text-xl font-bold w-100'>CV VERIFICATION</h2>

                   <span className='text-black text-lg text-left' >Get your qualifications verified for potential employers</span>
                </div>
              </div>

              <div className='flex w-100 py-4  border-gray-400  ' data-aos="fade-up" data-aos-delay="200">
                <img src={goal3} alt={"logo"} className='w-20  mr-4 md:mr-0  md:w-28' />
                <div className='flex-col md:flex-row flex flex-1   md:items-center  '>
                   <h2 className='hidden md:block text-left text-black text-xl font-bold pl-6 w-1/3'>JOB <br/> PORTAL</h2>
                   <h2 className=' md:hidden text-left text-black text-xl font-bold w-100'>JOB PORTAL</h2>

                   <span className='text-black text-lg text-left' >Submit and manage job applications easily</span>
                </div>
              </div>

            </div>

          </div>



        </div>

      </div>
    </section>
  )
}
