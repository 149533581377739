// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { createJobApplication, getJobApplicationByUserId, updateJobApplication } from '../actions/jobapplication'


const initialState = {
  loading: false,
  jobappInfo: null,
  jobapps: [],
  error: null,
  errorMsg: null,
  success: false,
  totalJobApps: 0,
  page: 0,
  pageSize: 0
}

const jobSlice = createSlice({
  name: 'jobapp',
  initialState,
  reducers: {

  },
  extraReducers: {



    [createJobApplication.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [createJobApplication.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobapps = [payload, ...state.jobapps]
    },

    [createJobApplication.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    
    [updateJobApplication.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [updateJobApplication.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobappInfo = payload
      state.jobapps = state.jobapps.map(jobapp => 
        jobapp._id === payload._id ? payload : jobapp
      );
    },

    [updateJobApplication.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [getJobApplicationByUserId.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getJobApplicationByUserId.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobapps = payload.jobApplications
      state.totalJobApps= payload.totalJobApplications
      state.page = payload.page
      state.pageSize = payload.pageSize
    },
    [getJobApplicationByUserId.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
      state.jobapps = []
    },


   

  },
})


export default jobSlice.reducer