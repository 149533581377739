
import TeamImage01 from '../../images/about/man.png'
import nina from '../../images/landing/nina.png'
import mis from '../../images/landing/team.jpg'
import kola from '../../images/landing/kola.png'
import flo from '../../images/landing/flo.png'


export default function Team() {
  return (
    <section>
      <div className="max-w-6xl mx-auto mt-10 px-4 sm:px-6">
        <div className="py-12 md:py-20 ">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 text-black mb-4">OUR TEAM</h2>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" >

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" >
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={nina} width={160} height={160} alt="Team member 01" />
                <h4 className="text-lg  font-bold text-black uppercase border-b-8 border-pink-500 mb-2 pb-2">Nina Verechshagina</h4>
                <div className="text-black mb-1">Our CEO</div>
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" >
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={kola} width={160} height={160} alt="Team member 02" />
                <h4 className="text-lg  text-black uppercase  font-bold border-b-8 border-pink-500 mb-2 pb-2">Nikolay Verechshagin</h4>
                <div className="text-black mb-1">Backend Developer</div>
              </div>
            </div>

            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={mis} width={160} height={160} alt="Team member 03" />
                <h4 className="text-lg text-black uppercase  font-bold border-b-8 border-pink-500 mb-2 pb-2">Mikhail Kochetov</h4>
                <div className="text-black mb-1 text-center">Passionate & Data-Savvy Entrepreneur, currently Technology Consultant at PwC</div>
              </div>
            </div>

            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={flo} width={160} height={160} alt="Team member 03" />
                <h4 className="text-lg text-black uppercase  font-bold border-b-8 border-pink-500 mb-2 pb-2">Florent Ricciardi</h4>
                <div className="text-black mb-1">Lead Developer</div>
              </div>
            </div>






          </div>

        </div>
      </div>
    </section>
  )
}
