import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Form, Input, Modal, Popover, Select } from 'antd';
import { toast } from 'react-toastify';
import Sidebar from '../../components/Employers/Menus/Sidebar';
import Header from '../../components/Employers/Menus/Header';
import ModalBlank from '../../components/Modals/ModalBlank';
import {  createUser, deleteUserRole } from '../../redux/actions/usersCompanies';
import { getUsersCompany } from '../../redux/actions/auth';

function EmployersUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [users, setUsers] = useState([]);

  const { usersCompanies, auth } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth && auth.userInfo.jwtToken) {
      getUsers();
    }
  }, [auth]);

  const handleRoleChange = (value) => {
    setSelectedRole(value);
  };

  const getUsers = async () => {
    let res = await dispatch(getUsersCompany({ token: auth.userInfo.jwtToken }));
    if (res.payload.data) {
      setUsers(res.payload.data);
    }
  };

  const onFinish = async (values) => {
    let user = users.find((user)=> user.email === values.email);
    console.log(user)
    const actionResult = await dispatch(createUser({ formData: {...values, user: user?user:null}, token: auth.userInfo.jwtToken }));
    if (actionResult.type === 'user/create/rejected') {
      toast.error(actionResult.payload.response.data ? actionResult.payload.response.data : actionResult.payload.message);
    }
    if (actionResult.type === 'user/create/fulfilled') {
      setTimeout(() => {
        toast.success(`Successfully Created!`);
        setCreateModalOpen(false);

      }, 1000);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOpenChangePop = (userId, isOpen) => {
    if (isOpen) {
      setOpenPopoverId(userId);
    } else {
      setOpenPopoverId(null);
    }
  };

  const handleDeleteUser = async () => {
    if(!selectedRole)return;
    const actionResult = await dispatch(deleteUserRole({ formData: { email: currentUser.email, value: selectedRole }, token: auth.userInfo.jwtToken }));
    if (deleteUserRole.fulfilled.match(actionResult)) {
      toast.success(`Successfully deleted!`);
    } else {
      toast.error('Unknown error occurred, try again!');
    }
    setDangerModalOpen(false);
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative bg-white flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow overflow-hidden">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full overflow-y-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Users management</h1>
              </div>
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button onClick={() => setCreateModalOpen(true)} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add role</span>
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {usersCompanies.users && usersCompanies.users.length > 0 &&
               usersCompanies.users.map((user) => (
                 user.role.authority.some(role => role.includes('COMPANY'))  && <div className="flex relative flex-col justify-center items-center shadow-css rounded-xl h-[220px] p-4" key={user.id}>
                    <div className="absolute top-2 right-2 hover:cursor-pointer hover:bg-slate-100 rounded-full">
                      <Popover
                        content={
                          <div className="w-28 flex flex-col">
                            <div className="flex gap-1 items-center hover:cursor-pointer py-2 text-red-500 font-semibold hover:bg-slate-100" onClick={(e) => { e.stopPropagation(); setOpenPopoverId(null); setCurrentUser(user); setDangerModalOpen(true); }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                <path fill="currentColor" d="m9.4 15.808l2.6-2.6l2.6 2.6l.708-.708l-2.6-2.6l2.6-2.6l-.708-.708l-2.6 2.6l-2.6-2.6l-.708.708l2.6 2.6l-2.6 2.6l.708.708ZM7.615 20q-.69 0-1.152-.462Q6 19.075 6 18.385V6H5V5h4v-.77h6V5h4v1h-1v12.385q0 .69-.462 1.152q-.463.463-1.153.463h-8.77ZM17 6H7v12.385q0 .23.192.423q.193.192.423.192h8.77q.23 0 .423-.192q.192-.193.192-.423V6ZM7 6v13V6Z" />
                              </svg>
                              Remove Role
                            </div>
                          </div>
                        }
                        trigger="click"
                        open={openPopoverId === user.id}
                        onOpenChange={(isOpen) => handleOpenChangePop(user.id, isOpen)}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="3" d="M6 12h0m6 0h0m6 0h0" />
                          </svg>
                        </div>
                      </Popover>
                    </div>
                    <Avatar
                      size={{
                        xs: 64,
                        sm: 64,
                        md: 64,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      style={{
                        backgroundColor: '#fde3cf',
                        color: '#f56a00',
                      }}
                    >
                      {user.email[0]}
                    </Avatar>
                    <span className="text-center italic mt-4" style={{ overflowWrap: 'anywhere' }}>{user.email}</span>
                    {
                      user.role.authority.filter((role) =>role !== 'USER').map((role) => (
                        <>
                        {role === 'COMPANY_HR'  && <span className="text-center font-semibold" style={{ overflowWrap: 'anywhere' }}>Human Resources</span>}

                        {role === 'COMPANY_PR'  && <span className="text-center font-semibold" style={{ overflowWrap: 'anywhere' }}>Project Manager</span>}
                        </>
                      ))
                    }
                  </div>
                ))}



            </div>

            {usersCompanies.users && usersCompanies.users.length === 0 && <div className='flex flex-col gap-4 w-full h-full justify-center items-center'>
            <svg id="fi_12716622" enable-background="new 0 0 500 500" height={200} width={200} viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"><g><path d="m399.755 329.894v54.213l-155.048 88.96v-179.669l60.979 90.479z" fill="#f7a63b"></path><path d="m244.707 293.398v179.669l-155.093-88.96v-53.431l97.382 55.824z" fill="#ffb859"></path><path d="m462.62 293.214-155.047 88.96-61.025-90.432 152.654-87.58 2.347-1.334.092-.046 1.519 2.255z" fill="#ffc16e"></path><path d="m307.573 382.174-1.887 1.703-60.979-90.479 1.841-1.656z" fill="#ffcc8a"></path><path d="m474.126 136.419-72.485 66.363-.092.046-2.347 1.334-154.495-88.637 74.371-68.02z" fill="#ffc16e"></path><path d="m475 139.318-71.84 65.719-1.519-2.255 72.485-66.363z" fill="#f7a63b"></path><path d="m89.292 203.978-1.702-.966-.967 1.565-61.623-86.889 1.749-1.841z" fill="#ffcc8a"></path><path d="m244.707 115.525-154.816 88.822-.599-.369-62.543-88.131 155.093-88.914z" fill="#ffb859"></path><path d="m242.683 291.972-57.712 93.102-155.047-88.96 56.699-91.537.967-1.565 1.702.966.599.369z" fill="#ffc16e"></path><path d="m244.707 115.525-154.816 88.822 152.792 87.625 2.025 1.427 1.841-1.657 152.654-87.579zm0 170.786-142.713-81.826 142.713-81.827 142.667 81.827z" fill="#ffcc8a"></path><path d="m387.375 204.485-142.668 81.826v-163.653z" fill="#ffb859"></path><path d="m244.707 122.658v163.653l-142.713-81.826z" fill="#f7a63b"></path><path d="m244.707 293.398-57.711 93.102-2.025-1.426 57.712-93.102z" fill="#f7a63b"></path><path d="m307.573 382.174-1.887 1.703 154.819-88.847 2.115-1.816z" fill="#eb9e38"></path></g></svg>


<span className='font-bold text-xl'>No user found</span>



                </div>}
          </div>
        </main>
      </div>

      <Modal width={500} title="Add role" open={createModalOpen} onCancel={() => setCreateModalOpen(false)} centered footer={null}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="pt-10"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input an email!' }]}
          >
            <Input className="antd-input w-full" />
          </Form.Item>

          <Form.Item
            label="Role"
            name="value"
            rules={[{ required: true, message: 'Please select the role!' }]}
          >
            <Select size="large" className="antd-select" placeholder="Select a role">
              <Select.Option key={0} value="COMPANY_HR">
                Human Resources
              </Select.Option>
              <Select.Option key={1} value="COMPANY_PR">
                Project Manager
              </Select.Option>

              

            </Select>
          </Form.Item>

          <Form.Item className="flex justify-end">
            <Button type="primary" className="flex items-center !rounded-2xl bg-[#f11a7b] gap-2" htmlType="submit">
              Add role
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal width={500} open={dangerModalOpen} onCancel={() => setDangerModalOpen(false)} centered footer={null}>

        <div className=" flex space-x-4">
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          <div>
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Remove Role?</div>
            </div>
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>Alert! You're about to remove this role to the user. . Are you sure you want to continue?</p>
              </div>
              {currentUser && currentUser.role && (
                <Select size="large" className="w-60 mt-4" placeholder="Select a role to delete" onChange={handleRoleChange}>
                  {currentUser.role.authority.filter((role) => role !== "USER").map((type) => (
                    <Select.Option key={type} value={type}>
                      {
                        type === 'COMPANY_HR' && "Human Resources"
                      }
                       {
                        type === 'COMPANY_PR' && "Project Manager"
                      }
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setDangerModalOpen(false); }}>Cancel</button>
              <button onClick={(e) => handleDeleteUser(currentUser.id)} className="btn-sm bg-rose-500 hover:bg-rose-600 text-white">Yes, Remove it</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EmployersUsers;
