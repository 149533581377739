import React from 'react'
import { extractLocalityAndCountry } from '../../../utils/Utils';



const DetailsProExp= ({ exp }) => {

  
const formatDate = (dateString) => {
  const parts = dateString.split('.');
  if (parts.length === 3) {
      const date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date.toLocaleDateString('en-EN', { month: 'short', year: 'numeric' }).replace('.', '');
  }
  return dateString;
};


  return (
    <div className='flex mb-3 flex-col' >

      <div className='flex flex-1 items-baseline justify-between'>
        <span className='font-bold uppercase'>{exp.title}</span>
      </div>
      <div className='flex flex-col flex-1 items-baseline justify-between'>
        <span className='font-bold'>{exp.employer}</span>
        <span className='text-xs text-right	'>{extractLocalityAndCountry(exp.address)} </span>

        <span className='text-xs text-right	'>{formatDate(exp.startDate)} – {exp.endDate?formatDate(exp.endDate):'Present'}</span>

      </div>
      <div
          className='text-xs  mt-4 text-content'  style={{wordBreak:'break-word'}}
          dangerouslySetInnerHTML={{ __html: exp.description }}
        />


      

      


    </div>
  )
}

export default DetailsProExp