import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'


const backendURL = process.env.REACT_APP_URL_BACKEND



export const createUser = createAsyncThunk(
  'user/create',
  async ({ formData , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' + token,

        },
      }
      const { data } = await axios.post(
        `${backendURL}/authentification/api/v1/user/role`,
        {...formData},
        config
      )
      if (formData.user) {
        let user = { ...formData.user };
        let authority = [...user.role.authority, formData.value];
        user.role = { ...user.role, authority }; // Ensure role is updated properly
        formData.user = user; // Update formData with the modified user object
      
        console.log(user);
        return user;
      }
      
      return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
     
    }
  }
)


export const deleteUserRole = createAsyncThunk(
  'user/delete/role',
  async ({ formData , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' + token,

        },
        data: formData
      }

      const { data } = await axios.delete(
        `${backendURL}/authentification/api/v1/user/role`,
        config
      )

      return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
     
    }
  }
)






