// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin } from '../actions/auth'



const initialState = {
  msg: '',
  code: null
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertAction: (state, action) => {
      state.msg = action.payload.msg;
      state.code = action.payload.code
    },
  },
  extraReducers: {
        // login user
       
  },
})

export const { alertAction } = alertSlice.actions;

export default alertSlice.reducer