// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { alertAction } from '../reducers/alertSlice'
import { convertDateFormat, getParamsFilter, jsonToFormData, removeNullProperties } from '../../utils/Utils'



const backendURL = process.env.REACT_APP_URL_BACKEND

let token = localStorage.getItem('userToken')

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
});

/* axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    console.log(error)
    if ((error.response.status === 400 )&& !originalRequest._retry) {
      originalRequest._retry = true;
      try{


        return Promise.reject(error)
        //return axiosInstance(originalRequest); // Retry the original request
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } 
    }
    return Promise.reject(error);
  }
); */



export const createResume = createAsyncThunk(
  'resume/create',
  async ({ datas , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' +token,

        },
      }

      let dataFormat = jsonToFormData(datas);
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_URL_BACKEND}/resume-service/api/v1/resume`,
        dataFormat,
        config
      )


      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateResumeSort = createAsyncThunk(
  'resume/update',
  async ({ id,name, ids, token }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' + token,

        },
      }


      let newName = name.replace(/s$/, '');
      if (newName === "certificatesView"){
        newName = "certificate"
      }

      if (newName === "hobbie"){
        newName = "hobby"
      }
      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_URL_BACKEND}/resume-service/api/v1/${newName}/sort/resume/${id}`,
        {list: ids},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(error)

    }
  }
)
export const updateResume = createAsyncThunk(
  'resume/update',
  async ({ id, resumeData, token }, { rejectWithValue }) => {
    console.log(id)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' + token,

        },
      }


      let formData = jsonToFormData(removeNullProperties(resumeData))

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${JSON.stringify(value)}`);
      }
      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_URL_BACKEND}/resume-service/api/v1/resume/${id}`,
        formData,
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(error)

    }
  }
)
export const getResume = createAsyncThunk(
  'resume/get',
  async ({id, token}, { rejectWithValue }) => {
    try {
      console.log(token)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' + token,
        },
      }
      const { data } = await axiosInstance.get(
        `${backendURL}/resume-service/api/v1/resume/${id}`,
        config
      )
      console.log(data)
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getResumeWithoutOwning = createAsyncThunk(
  'resume-no-owning/get',
  async ({id, token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axiosInstance.get(
        `${backendURL}/resumes-management/resumeWithoutOwning/${id}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const searchResumes = createAsyncThunk(
  'resumes/search',
  async ({filter, token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':'Auth '+ token,
        },
      }
      let param =  getParamsFilter(filter)

      const { data } = await axios.get(
        `${backendURL}/resume-service/api/v1/resume/search?${param}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)




export const getResumes = createAsyncThunk(
  'resumes/get',
  async ({token}, { rejectWithValue }) => {
   // let user = JSON.parse(localStorage.getItem("user"))

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth '+token,
        },
      }
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_URL_BACKEND}/resume-service/api/v1/user/current`,
        config
      )
      return data.resumes
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getResumesFilter = createAsyncThunk(
  'resumes-filter/get',
  async ({filter, token}, { rejectWithValue }) => {
    try {
      console.log(token)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axiosInstance.get(
        `${backendURL}/resumes-management/resumes?${filter}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)




export const deleteResume = createAsyncThunk(
  'resume/delete',
  async ({id, token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' + token,
        },
      }
      const { data } = await axiosInstance.delete(
        `${process.env.REACT_APP_URL_BACKEND}/resume-service/api/v1/resume/${id}`,
        config
      )
      return {id: id}
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const addItemResume = createAsyncThunk(
  'resume/addItemResume',
  async ({ id, name, resumeData , token},thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    console.log(name)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' +token,

        },
      }

      const configFormData = {
        headers: {
          'Authorization': 'Auth ' +token,
          'Content-Type': 'multipart/form-data'

        },
      }

      const configFormDataTokenId = {
        headers: {
          'Authorization': 'Auth 854057d4-39ab-483e-95fd-7399d5d8f92e',
          'Content-Type': 'multipart/form-data'

        },
      }
/* 
       if(resumeData.file){
        console.log(resumeData.file)
        console.log(token)
        let newData = jsonToFormData({file:resumeData.file })

        const { data, status } = await axiosInstance.post(
          `${backendURL}/file-service/api/v1/file`,
          newData,
          configFormDataTokenId
        )
        console.log(data)
      }
  */

      if(name === 'educations'){
        let dataform = {
          school: resumeData.school,
          degree: resumeData.degree,
          description: resumeData.description,
          actualId: 'aaaa',
          startDate: resumeData.startDate,
          endDate: resumeData.endDate,
          isHidden: false,
          address: resumeData.address,
          file: resumeData.file?resumeData.file:'',


        }
        
        let newData = jsonToFormData(dataform)
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/education/resume/${id}`,
          newData,
          configFormData
        )
        console.log(status)
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'works'){
        let dataform = {
          title: resumeData.title,
          employer: resumeData.employer,
          description: resumeData.description,
          address: resumeData.address,
          actualId: 'aaaa',
          startDate: resumeData.startDate,
          endDate: resumeData.endDate,
          isHidden: false,
          file: resumeData.file?resumeData.file:'',


        }
        
        let newData = jsonToFormData(dataform)
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/work/resume/${id}`,
          newData,
          configFormData
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name==='projects'){
        let dataform = {
          projectTitle: resumeData.projectTitle,
          projectSubtitle: resumeData.projectSubtitle,
          description: resumeData.description,
          startDate: convertDateFormat(resumeData.startDate),
          endDate: resumeData.endDate?convertDateFormat(resumeData.endDate): '',
          isHidden: false,
          

        }
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/project/resume/${id}`,
          dataform,
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name==='certificatesView'){

        let dataform = {
          title: resumeData.title,
          certificateDescription : resumeData.certificateDescription ,
          isHidden: false,

        }
        console.log("certifi")
        console.log(resumeData)
        let newData = jsonToFormData(dataform)
        newData.append('file', resumeData.file)
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/certificate/resume/${id}`,
          (newData),
          configFormData
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'skills'){
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/skill/resume/${id}`,
          {skillName:resumeData.skillName, skillInfo: resumeData.skillInfo, skillType: resumeData.skillType, isHidden: false},
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'hobbies'){
        const { data, status } = await axiosInstance.post(
          `${backendURL}/resume-service/api/v1/hobby/resume/${id}`,
          {hobbyTitle:resumeData.hobbyTitle, hobbyDescription: resumeData.hobbyDescription, isHidden: false},
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }


      
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)



export const updateItemResume = createAsyncThunk(
  'resume/updateItemResume',
  async ({ id, name, resumeData , token},thunkAPI) => {
    const dispatch = thunkAPI.dispatch;

    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Auth ' +token,

        },
      }

      const configFormData = {
        headers: {
          'Authorization': 'Auth ' +token,
          'Content-Type': 'multipart/form-data'

        },
      }

      console.log(name)
      if(name === 'educations'){
        let dataform = {
          school: resumeData.school,
          degree: resumeData.degree,
          description: resumeData.description,
          address: resumeData.address,
          actualId: 'aaaa',
          startDate: resumeData.startDate,
          endDate: resumeData.endDate?resumeData.endDate:'',
          isHidden: resumeData.isHidden?resumeData.isHidden:false,
          file: resumeData.file?resumeData.file:'',

          id: resumeData.id
  
        }
        
        let newData = jsonToFormData(dataform)
        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/education/resume/${id}`,
          newData,
          configFormData
        )
        dispatch(alertAction({code:status}))
        return data
      }

      if(name === 'works'){
        let dataform = {
          title: resumeData.title,
          employer: resumeData.employer,
          description: resumeData.description,
          address: resumeData.address,
          actualId: 'aaaa',
          startDate: resumeData.startDate,
          endDate: resumeData.endDate?resumeData.endDate: '',
          isHidden: resumeData.isHidden?resumeData.isHidden: false,
          id:resumeData.id,
          file: resumeData.file?resumeData.file:'',

        }
        
        let newData = jsonToFormData(dataform)
        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/work/resume/${id}`,
          newData,
          configFormData
        )
        dispatch(alertAction({code:status}))
        console.log(status)
        return data
      }
      if(name === 'certificatesView'){
        let dataform = {
          title: resumeData.title,
          certificateDescription : resumeData.certificateDescription ,
          isHidden: resumeData.isHidden?resumeData.isHidden: false,
          id:resumeData.id,

        }
        let newData= jsonToFormData(dataform)

/*         if(resumeData.file){
          const fileData = resumeData.file; // This is your fetched file data (e.g., byte array)
          const mimeType = "application/pdf"; // MIME type of your file

          const blob = new Blob([fileData], { type: mimeType });
          const fileName = "downloadedFile.pdf"; // Name your file appropriately
          const file = new File([blob], fileName, { type: mimeType });
          newData.append('file', file )

        } */

        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/certificate/resume/${id}`,
          newData,
          configFormData
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'projects'){
        let dataform = {
          projectTitle: resumeData.projectTitle,
          projectSubtitle: resumeData.projectSubtitle,
          description: resumeData.description,
          startDate: convertDateFormat(resumeData.startDate),
          endDate: resumeData.endDate?convertDateFormat(resumeData.endDate): '',
          isHidden: resumeData.isHidden?resumeData.isHidden: false,
          id:resumeData.id,
          //file: resumeData.file?resumeData.file:'',

        }
        
        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/project/resume/${id}`,
          dataform,
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'skills'){
        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/skill/resume/${id}`,
          {id:resumeData.id,skillName:resumeData.skillName, skillInfo: resumeData.skillInfo,  skillType: resumeData.skillType,           isHidden: resumeData.isHidden?resumeData.isHidden: false
          },
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'hobbies'){
        const { data, status } = await axiosInstance.put(
          `${backendURL}/resume-service/api/v1/hobby/resume/${id}`,
          {id:resumeData.id, hobbyTitle:resumeData.hobbyTitle, hobbyDescription: resumeData.hobbyDescription,           isHidden: resumeData.isHidden?resumeData.isHidden: false
          },
          config
        )
        dispatch(alertAction({code:status}))
        return data
      }


      
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)


export const deleteItemResume = createAsyncThunk(
  'resume/deleteItemResume',
  async ({ name, id, idItem , token},thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    console.log(name)
    console.log(idItem)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' +token,

        },
      }


      if(name === 'educations'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/education/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }

      if(name === 'projects'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/project/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'works'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/work/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'skills'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/skill/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'certificatesView'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/certificate/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }
      if(name === 'hobbies'){
        const { data, status } = await axiosInstance.delete(
          `${backendURL}/resume-service/api/v1/hobby/${idItem}/resume/${id}`,config
        )
        dispatch(alertAction({code:status}))
        return data
      }


      
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)


export const addSectionResume = createAsyncThunk(
  'resume/addSection',
  async ({ id,name, resumeData , token},thunkAPI) => {
    console.log(id)
    const dispatch = thunkAPI.dispatch;

    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data, status } = await axiosInstance.put(
        `${backendURL}/resumes-management/addsection/${id}`,
        {section: name,data:resumeData},
        config
      )

        dispatch(alertAction({code:status}))
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

export const updateSectionResume = createAsyncThunk(
  'resume/updateSection',
  async ({ id,name, resumeData,token }, { rejectWithValue }) => {
    console.log(id)
    console.log(resumeData)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data, response } = await axiosInstance.put(
        `${backendURL}/resumes-management/updatesection/${id}`,
        { "section": name,
        "sectionId":resumeData._id,
        "data" : resumeData},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const deleteSectionResume = createAsyncThunk(
  'resume/deleteSection',
  async ({ id,name, resumeData, token}, { rejectWithValue }) => {
    console.log(id)
    console.log(resumeData)
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data, response } = await axiosInstance.put(
        `${backendURL}/resumes-management/deletesection/${id}`,
        { "section": name,
        "sectionId":resumeData._id,
        "data" : resumeData},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
