import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Admin/Menus/Sidebar'

import Header from '../../components/Admin/Menus/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesApplications } from '../../redux/actions/applicationsCompanies';
import { getApplications } from '../../redux/actions/admin';
import InvitationsTable from '../../components/Admin/Applications/InvitationsTable';
import InvitationPanel from '../../components/Admin/Applications/InvitationPanel';
import SearchBar from '../../components/Admin/Applications/SearchBar';

const AdminApplications = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
    const transactions = [
      {
        id: '0',
        name: 'Nina Verechshagina',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '1',
        name: 'Nikolay Verechshagin',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '2',
        name: 'John Doe',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '3',
        name: 'Brad Pitt',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '4',
        name: 'Zinédine Zidane',
        date: '22/01/2022',
        status: 'Pending',
      },
      {
        id: '5',
        name: 'Jack Bauer',
        date: '22/01/2022',
        status: 'Refused',
      },
      {
        id: '6',
        name: 'Jack Sparrow',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '7',
        name: 'Michelle Obama',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '8',
        name: 'Mark Zuckerberg',
        date: '22/01/2022',
        status: 'Accepted',
      },
      {
        id: '9',
        name: 'Homer Simpson',
        date: '22/01/2022',
        status: 'Accepted',
      },
    ];
    const [list, setList] = useState(transactions);
    const [listInitial, setListInitial] = useState([]);

    const {auth, applicationsCompanies} = useSelector(state => state)

    const dispatch = useDispatch();

    const handleSelectedItems = (selectedItems) => {
      setSelectedItems([...selectedItems]);
    };

    useEffect(() => {

      if(auth.userInfo){
        dispatch(getApplications({token: auth.userInfo.jwtToken}))

      }
  
    }, []);

    useEffect(() => {
      const dataSource = applicationsCompanies.applications.map(item => ({
        id: item.id, // It's important to have a unique key for each row
        companyName: item.companyInfo.companyName,
        city: item.companyInfo.city.formattedAddress,
        username: item.companyInfo.username,
        registryNumber: item.companyInfo.registryNumber,
        phone: item.companyInfo.phone,

        createdAt: item.createdAt,
        status: item.applicationStatus.status,

      }));
      setList(dataSource)
      setListInitial(dataSource)

    }, [applicationsCompanies])
    
    

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/*  Site header */}
        <Header title={"Invitations"} style={{ height: "30%", background: "blue" }} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="relative">

{     list &&     <SearchBar items={listInitial} setListApplications={setList} />
}
            {/* Content */}
            <div className="px-4 sm:px-8 pb-8 w-full max-w-9xl mx-auto">
              {/* Page header */}


{          <InvitationsTable   list={list} selectedItems={handleSelectedItems} setSelectedItem={setSelectedItem} setTransactionPanelOpen={setTransactionPanelOpen} />
}
         
            </div>

{   selectedItem &&         <InvitationPanel transactionPanelOpen={transactionPanelOpen} selectedItem={selectedItem}  setTransactionPanelOpen={setTransactionPanelOpen}  s />
}          </div>
        </main>
      </div>
    </div>  )
}

export default AdminApplications