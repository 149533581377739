import React from 'react';

function PaginationNumeric({ totalJobs, page, pageSize }) {
  const totalPages = Math.ceil(totalJobs / pageSize);

  // Calculate the range of visible page numbers
  const pageRange = 3; // Adjust this value to control how many pages are visible
  const startPage = Math.max(1, page - pageRange);
  const endPage = Math.min(totalPages, page + pageRange);

  const pages = [];

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-center">
      <nav className="flex" role="navigation" aria-label="Navigation">
        <div className="mr-2">
          {/* Previous page button */}
          <span className={`inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600 ${page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#f11a7b] dark:hover:bg-[#f11a7b]'}`}>
            <span className="sr-only">Previous</span>
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </span>
        </div>
        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {pages.map((pageNum) => (
            <li key={pageNum}>
              {pageNum === page ? (
                <span className="inline-flex items-center justify-center rounded leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-pink-500">
                  {pageNum}
                </span>
              ) : (
                <a
                  className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 hover:bg-[#f11a7b] dark:hover:bg-[#f11a7b] border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:text-white"
                  href={`#page-${pageNum}`}
                >
                  {pageNum}
                </a>
              )}
            </li>
          ))}
        </ul>
        <div className="ml-2">
          {/* Next page button */}
          <a
            href={`#page-${page + 1}`}
            className={`inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-slate-800 ${page === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#f11a7b] dark:hover:bg-[#f11a7b]'} border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300`}
          >
            <span className="sr-only">Next</span>
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default PaginationNumeric;
