import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getLabelByValue } from '../../../../utils/Utils';
import { Status } from '../../../../utils/datas';
import { getResume } from '../../../../redux/actions/resumes';
import { vacancyUpdateStatus } from '../../../../redux/actions/jobposting';
function JobApplicationItem(props) {
    const navigate= useNavigate()

    const {auth} = useSelector(state=>state);
    const {id} = useParams();
    
    const dispatch = useDispatch();

    const handleClick= async() => {
 
        const smBreakpoint = 768;
        const screenWidth = window.innerWidth;
        if(auth.userToken){

          if(props.status.status === 'NOT_VIEWED'){
          
            try{
              let data = {
                vacancyId: id,
                resumeId: props.resumeId
              }
              await dispatch(vacancyUpdateStatus({ data,type:'watched' , token: auth.userToken })).unwrap();

            }catch(e){

            }

          }
          let res =  await dispatch(getResume({id: props.resumeId, token: auth.userToken}))
          console.log(res)
          props.setJob({...props})
  
    
          } 
    
        if (screenWidth < smBreakpoint) {
          // Action for screens smaller than 'sm'
         
          props.setCreateModalJob(true)
          // Place your action for small screens here
        }
    }

    


  return (
    <div onClick={handleClick}
    className={`border px-5 py-4 hover:cursor-pointer  rounded-xl overflow-hidden
    ${props.isSelected 
      ? '!bg-pink-50 !border-l-4 !border-pink-500 !border-0'
      : 'hover:bg-pink-50 hover:border-0 hover:border-l-4 hover:border-pink-500'
    } bg-white dark:bg-slate-800 border-0 border-b border-slate-300`}
    >
      <div className="flex justify-between items-center  md:space-y-0 space-x-2">
        {/* Left side */}
        <div className="flex items-center space-x-3 md:space-x-4">
          <div>
            <Link className="inline-flex font-semibold text-slate-800 dark:text-slate-100" /* to={`/job/job-post/${props.id}`} */>
              Resume
            </Link>
          </div>
        </div>
        {/* Right side */}
        <div className="flex flex-col gap-2 text-right  items-center justify-center md:pl-0">
          <span className=' text-sm'>{getLabelByValue(Status, props.status.status)}</span>
          <span className='italic text-sm' >{moment(props.createdAt).fromNow()}</span>
        </div>
      </div>
    </div>
  );
}

export default JobApplicationItem;