import React, { useState, useEffect } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { Disclosure } from '@headlessui/react';
import { BsBoxes } from 'react-icons/bs';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Item from './Item';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../redux/reducers/modalSlice';
import { updateResume, updateResumeSort } from '../../../redux/actions/resumes';
import { updateResumeLocal } from '../../../redux/reducers/resumeSlice';

const Section = (props) => {
    const { title, name, icon } = props;
    const { resume, auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (resume && resume.resumeInfo && resume.resumeInfo[name]) {
            setItems(resume.resumeInfo[name].map((item, index) => ({
                ...item,
                id: item.id ? item.id.toString() : `item-${index}`
            })));
        }
    }, [resume, name]);

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        setItems(reorderedItems);

        let newResume = {...resume.resumeInfo}
        newResume[name] = reorderedItems

        const reorderedItemIds = reorderedItems.map(item => item.id);


        console.log(reorderedItemIds)

        await dispatch(updateResumeLocal({...newResume}));



       await dispatch(updateResumeSort({ id: newResume.id, name, ids: reorderedItemIds, token: auth.userToken }));


     

        //Change resume.resumeInfo[name] with new reorderedItems

    };

    return (
        <div className='relative flex flex-col items-start px-5 py-5 md:px-5 md:pl-10 md:py-4 bg-white dark:bg-slate-800 rounded-2xl border border-slate-200 dark:border-slate-700 hover:cursor-pointer'>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg text-left text-lg font-semibold text-slate-800 dark:text-slate-100 focus:outline-none focus-visible:ring ">
                            <h2 className='flex items-center font-semibold'>
                                <span className='mr-4 text-3xl'>{icon ? icon : <BsBoxes />}</span> {title}
                            </h2>
                            <AiOutlineDown
                                className={`${
                                    open ? 'rotate-180 transform' : ''
                                } text-2xl text-slate-800 dark:text-slate-100`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 w-full ">
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {items.map((data, index) => (
                                                <Draggable key={data.id} draggableId={data.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Item title={title} data={data} name={name} resumeId={resume.resumeInfo.id} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className='flex justify-center mt-4'>
                                <button
                                    onClick={(e) => { e.stopPropagation(); dispatch(openModal({ type: 'formModal', title: title, isOpen: true, name: name })) }}
                                    className="btn bg-cyan-500 hover:bg-cyan-600 text-white"
                                >
                                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                    </svg>
                                    <span className="ml-2">Add New</span>
                                </button>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
}

export default Section;
