import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import SearchAddress from '../../Form/SearchAddress';
import { companyApplication } from '../../../redux/actions/applicationsCompanies';
import { toast } from 'react-toastify';

const OnBoarding1 = ({ setStatus }) => {
  const dispatch = useDispatch();
  const  {auth} = useSelector(state=>state);
  const [localisationText, setLocalisationText] = useState("");
  const [localisationObject, setLocalisationObject] = useState(null);

  // Function to handle form submission
  const onFinish = async (values) => {
    console.log('Success:', values);
    // You can dispatch your action here
    // dispatch(userLogin(values));

    if(auth.userInfo){
      let data =  {...values, city: localisationObject}
      const actionResult = await dispatch(companyApplication({data, token: auth.userInfo.jwtToken }))
      console.log(actionResult)
      if(actionResult.type === 'company/apply/rejected'){
        toast.error(actionResult.payload);
        setStatus(0)
      }
      if(actionResult.type==='company/apply/fulfilled'){
        toast.success("Your application has been submited. You will receive an answer soon");
        setStatus(0)
      }
    } 

  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  
  const handleAddressChange = (newAddress) => {

    setLocalisationText(newAddress.formatted_address) 
    const { formatted_address,address_components, geometry } = newAddress;
    const newObject = { formatted_address,address_components, geometry };
    setLocalisationObject(newObject)


}

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Company application</h1>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: 'Please input your company name!' }]}
          >
            <Input  className='form-input w-full' />
          </Form.Item>

          <Form.Item
            label="User Name"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input className='form-input w-full' />
          </Form.Item>



          <Form.Item
                label="City"
                name="localisation"
                required={true}
                rules={[
                    {
                        validator: (_, value) =>
                            localisationObject ? Promise.resolve() : Promise.reject(new Error('Enter a city!'))
                    }
                ]}

            >
                <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


          </Form.Item>

          <Form.Item
            label="Registry Number"
            name="registryNumber"
            rules={[{ required: true, message: 'Please input your registry number!' }]}
          >
            <Input className='form-input w-full' />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input  className='form-input w-full'/>
          </Form.Item>

          <Form.Item className='flex justify-end'>
            <Button type="primary" className="flex items-center bg-[#f11a7b] gap-2" htmlType="submit">
              Apply
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default OnBoarding1;
