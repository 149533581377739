import React, { useState, useEffect } from 'react';
import { Document, PDFViewer, Page, Text, pdf } from '@react-pdf/renderer';
import Quixote from '../components/pdfTest';
import { useDispatch, useSelector } from 'react-redux';
import { getResume } from '../redux/actions/resumes';

const TestPage = () => {
  const [pdfSrc, setPdfSrc] = useState(null);
  const {resume, auth} = useSelector(state=>state)
  const cv = resume.resumeInfo
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResume({id:'64d8c1cc6769045227b04f7f', token: auth.userToken}))
  }, []);

  useEffect(() => {
    const generatePdfDocument = async () => {
      try {
        console.log(resume)
        const doc = <Quixote resume={cv}/>;
        const asPdf = pdf([]); // empty array as initial pages
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const url = URL.createObjectURL(blob);
        setPdfSrc(url);
      } catch (error) {
        console.error('Error generating PDF', error);
      }
    };

    generatePdfDocument();


 

    return () => {
      if (pdfSrc) {
        // Clean up object URL
        URL.revokeObjectURL(pdfSrc);
      }
    };
  }, [resume]);

  return (
    <div style={{ width:'100%', height:'100vh'}}>
     <div style={{ width:'100%', height:'100vh', backgroundColor: '#yourPreferredColor'}}>
    {pdfSrc ? (
      <iframe 
        src={`${pdfSrc}#toolbar=0&navpanes=0&scrollbar=0&zoom=scale`}
        style={{ 
          width: '50%', 
          height: '100%', 
          border: "none",
          background:'red'
        }}
        frameBorder="0"
      />
    ) : (
      'Generating PDF...'
    )}
  </div>

  </div>
  );
}

export default TestPage;
