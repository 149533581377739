import React, { useRef, useState } from 'react';

import Image from '../../../images/user-avatar-32.png';
import { userChangePassword } from '../../../redux/actions/auth';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

function AccountPanel() {

  const [sync, setSync] = useState(false);
  const oldPassword = useRef("");
  const newPassword = useRef("");
  const {auth} = useSelector(state=>state)

  const dispatch = useDispatch();

  const submitForm = async ({}) => {
    try {
        const actionResult = await dispatch(userChangePassword({oldPassword: oldPassword.current, newPassword: newPassword.current,auth: auth.userInfo.jwtToken}));

        if (userChangePassword.fulfilled.match(actionResult)) {
            toast.success(`Successfully changed password!`);
        } else {
            toast.error('Wrong password or an error occurred, try again!');
        }
    } catch (error) {
        toast.error('An unexpected error occurred.');
        console.error(error);
    }
}


  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">My Account</h2>
        {/* Picture */}
        <section>
          <div className="flex items-center">
            <div className="mr-4">
              <img className="w-20 h-20 rounded-full" src={Image} width="80" height="80" alt="User upload" />
            </div>
{/*             <button className="btn-sm bg-[#f11a7b] hover:bg-pink-500 text-white">Change</button>
 */}          </div>
        </section>

        {/* Email */}

        {/* Password */}
        <section>
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Password</h2>
          <div className="text-sm">You can set a new password.</div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="password">Old Password</label>
              <input id="password" className="form-input w-full" type="text"   onChange={(e) => (oldPassword.current = e.target.value)}/>
            </div>
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="new-password">New Password</label>
              <input id="new-password" className="form-input w-full" type="text"   onChange={(e) => (newPassword.current = e.target.value)} />
            </div>
      
          </div>
        </section>

      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
          <div className="flex self-end">
            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">Cancel</button>
            <button className="btn bg-[#f11a7b] hover:bg-pink-500 text-white ml-3"  onClick={(e)=>{submitForm({oldPassword, newPassword}); e.preventDefault()}}>Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AccountPanel;