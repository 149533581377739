import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai';



const Input = (props) => {
  const { text, name, placeholder, value, isRecommended, isRequired, isOptionnal, isRemovable, actionRemovable, handleChange, maxLength , handleError } = props;
  const [error, setError] = useState('');

  const handleDelete = () => {
    if (actionRemovable) {
      actionRemovable(name, false);
    }
  };


  useEffect(() => {
    if (isRequired && !value) {
      if(handleError){
        handleError(name, `${text} is required`);
      }
      

    }else{
      if(handleError){
        handleError(name, '');

      }
    }
  }, [value])
  
  



  const handleInputChange = (e) => {
    const newValue = e.target.value;
    handleChange(e); // Call the passed handleChange

    // Validate using newValue directly instead of value from state
    if (isRequired && !newValue) {
      handleError(name, `${text} is required`);

      setError(`${text} is required`);
    } else {
      setError('');
      handleError(name, ``);

    }
};

  return (
    <div className='flex flex-col  w-full mt-4'>
      <div className='flex items-baseline'>

        <label className="block text-sm font-medium mb-1" htmlFor="default">{text}</label>
        {isOptionnal && <span className='ml-2 font-light text-xs text-gray-400'>optional</span>}
        {isRecommended && <span className='ml-2 font-light text-xs text-gray-400'>recommended</span>}
        {isRequired && <span className="text-rose-500">*</span>}
      </div>

      <div className='flex'>
        <input type="text" name={name}           onChange={handleInputChange} 
 value={value || ''} required={isRequired} placeholder={placeholder} maxLength={maxLength}
          className="form-input w-full" />
        {isRemovable &&


          <button onClick={handleDelete} className="btn dark:bg-slate-800 ml-1 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
            <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
              <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
            </svg>
          </button>}

      </div>
      
      {((maxLength) && value)  && <div className="text-xs text-gray-500 mt-1 self-end">
          {`${value.length}/${maxLength}`} 
        </div>}

        {error && <div className="text-xs text-red-500 mt-1">{error}</div>}

    </div>
  )
}

export default Input