// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { addItemResume, addSectionResume, createResume, deleteItemResume, deleteResume, deleteSectionResume, getResume, getResumeWithoutOwning, getResumes, getResumesFilter, searchResumes, updateItemResume, updateResume, updateSectionResume } from '../actions/resumes'


const initialState = {
  loading: false,
  resumeInfo: null,
  resumes: [],
  error: null,
  errorMsg: null,
  success: false,
  page: 1,
  size: 0,
  totalEntity: 0,
  totalPage: 0,
}

const resumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    updateResumeLocal: (state , {payload}) => {
      state.resumeInfo= payload

    },
    cleanResume: (state) => {
      state.loading= false
      state.resumeInfo= null
      state.resumes= []
      state.error= null
      state.errorMsg= null
      state.success= false
    },
    cleanResumeInfos: (state) => {
      state.resumeInfo= null

    },
  },
  extraReducers: {
    // login user
    [getResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload
    },
    [getResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getResumeWithoutOwning.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getResumeWithoutOwning.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload
    },
    [getResumeWithoutOwning.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },


    [searchResumes.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [searchResumes.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumes = payload.resumes
      state.page = payload.page
      state.size = payload.size
      state.totalEntity =  payload.totalEntity
      state.totalPage =  payload.totalPage

   
    },

    [searchResumes.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
      state.resumes = []
      state.page = 0
      state.pageSize = 0
      state.totalResumes = 0
    },
    [getResumes.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getResumes.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumes = payload
   
    },

    [getResumesFilter.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getResumesFilter.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getResumesFilter.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumes = payload.resumes
      state.page = payload.page
      state.pageSize = payload.pageSize
      state.totalResumes =  payload.totalResumes
    },


    [getResumes.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
      state.resumes = []
      state.page = 0
      state.pageSize = 0
      state.totalResumes = 0
    },

    [createResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [createResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumes = [payload, ...state.resumes]
    },

    [createResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [updateResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [updateResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload
      state.resumes = state.resumes.map(resume => 
        resume.id === payload.id ? payload : resume
      );

    },

    [updateResume.rejected]: (state, { payload }) => {
      state.loading = false
    },


    [addItemResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [addItemResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [addItemResume.rejected]: (state, { payload }) => {
      state.loading = false
    },


    [updateItemResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [updateItemResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [updateItemResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [deleteItemResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [deleteItemResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [deleteItemResume.rejected]: (state, { payload }) => {
      state.loading = false
    },


    [addSectionResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [addSectionResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [addSectionResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [updateSectionResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [updateSectionResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [updateSectionResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [deleteSectionResume.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [deleteSectionResume.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.resumeInfo = payload

    },

    [deleteSectionResume.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [deleteResume.pending]: (state) => {
      state.loading = true;
      state.errorMsg = null;
    },
    
    [deleteResume.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.resumes = state.resumes.filter(resume => resume.id !== payload.id);
    },
    
    [deleteResume.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMsg = payload;
      state.error = true;
    },

  },
})

export const { cleanResume, cleanResumeInfos, updateResumeLocal } = resumeSlice.actions;

export default resumeSlice.reducer