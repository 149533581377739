// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { getCompaniesApplications } from '../actions/applicationsCompanies'


const initialState = {
  loading: false,
  applications: [],
  error: null,
  errorMsg: null,
  success: false,
}

const applicationsCompaniesSlice = createSlice({
  name: 'applicationsCompanies',
  initialState,
  reducers: {
    cleanApplications: (state) => {
      state.loading= false
      state.applications= []
      state.error= null
      state.errorMsg= null
      state.success= false
    },

  },
  extraReducers: {



    [getCompaniesApplications.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getCompaniesApplications.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.applications = payload
   
    },

    [getCompaniesApplications.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },


    
 

  



  },
})

export const { cleanApplications } = applicationsCompaniesSlice.actions;

export default applicationsCompaniesSlice.reducer