import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
const MyDocument = () => {
    const { resume } = useSelector(state => state)
    const cv = resume.resumeInfo

    
    return (
    
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>{cv.fullname}</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
)};

export default MyDocument