import React, { useState, useEffect } from 'react';
import NotFoundImage from '../images/404-illustration.svg';
import NotFoundImageDark from '../images/404-illustration-dark.svg';
import CompanyBg from '../images/company-bg.jpg';
import CompanyImage from '../images/company-icon-01.svg';
import Sidebar from '../components/Sidebar';
import JobListItem from '../components/JobListing/JobListItem';
import Header from '../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyById } from '../redux/actions/companies';
import logo from '../images/company/office-building.png'
import { getJobs } from '../redux/actions/jobs';
import PaginationNumeric from '../components/PaginationNumeric';

function CompanyProfile() {
  const { companies, auth, jobs } = useSelector(state => state)
  const dispatch = useDispatch();

  const { id } = useParams();
  const items = [
    // Group 1
    [
      {
        id: 0,
        image: CompanyImage,
        company: 'Company 01',
        role: 'Senior Software Engineer Backend',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 7',
        type: 'New',
        fav: false,
      },
      {
        id: 1,
        image: CompanyImage,
        company: 'Company 02',
        role: 'React.js Software Developer',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 6',
        type: 'New',
        fav: true,
      },
      {
        id: 2,
        image: CompanyImage,
        company: 'Company 03',
        role: 'Senior Full Stack Rails Developer',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 6',
        type: 'New',
        fav: false,
      },
    ],
    // Group 2
    [
      {
        id: 0,
        image: CompanyImage,
        company: 'Company 01',
        role: 'Senior Web App Designer',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 3',
        type: '',
        fav: false,
      },
      {
        id: 1,
        image: CompanyImage,
        company: 'Company 02',
        role: 'Senior UI/UX Designer',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 3',
        type: '',
        fav: false,
      },
    ],
    // Group 3
    [
      {
        id: 0,
        image: CompanyImage,
        company: 'Company 01',
        role: 'Financial Planning & Analysis Manager',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 2',
        type: '',
        fav: false,
      },
      {
        id: 1,
        image: CompanyImage,
        company: 'Company 02',
        role: 'Senior Consultant Growth Strategy',
        link: '/job/job-post',
        details: 'Full-time / Remote / London, UK',
        date: 'Jan 2',
        type: '',
        fav: false,
      },
    ],
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [company, setCompany] = useState({
    name:"Google",
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in tempus orci. Cras pellentesque eget metus vel vehicula. Aliquam dapibus, purus a facilisis tincidunt, quam erat volutpat est, sed dapibus turpis ante at felis. Nunc ut elit turpis. Cras vel malesuada quam. Aliquam rhoncus vel turpis sed consequat. In hac habitasse platea dictumst. Ut ac blandit tellus. Proin vehicula gravida efficitur.",
    location: "Marseille, France"
  });
  const navigate = useNavigate();
  useEffect(() => {

/*     if (companies.companies.length > 0) {
      const company = companies.companies.find((item) => item._id === id);

      if (company) {
        setCompany(company);
      }
    } else {
      dispatch(getCompanyById({ id, token: auth.userToken }))
        .catch((error) => {
          // Handle the error here, e.g., show an error message to the user.
          console.error('Error fetching company data:', error);
        });
    } */
  }, [])

  useEffect(() => {
    if (auth.userInfo.user) {
      dispatch(getJobs({ filter: `companyId=${id}`, token: auth.userToken }))

    }
  }, [])

  useEffect(() => {
    if (companies.companyInfo) {
      setCompany(companies.companyInfo)

    }
  }, [companies.companyInfo])


  return (
    <div className="flex h-[100dvh] overflow-hidden bg-white">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {company && <main className="grow">
          {/* Profile background */}
          <div className="h-56 bg-slate-200 dark:bg-slate-900">
            <img className="object-cover h-full w-full" src={CompanyBg} width="2560" height="440" alt="Company background" />
          </div>
          {/* Header */}
          <header className="text-center bg-slate-50 dark:bg-slate-800/20 pb-6 border-b border-slate-200 dark:border-slate-700">
            <div className="px-4 sm:px-6 lg:px-8 w-full">
              <div className="max-w-3xl mx-auto">
                {/* Avatar */}
                <div className="-mt-12 mb-2">
                  <div className="inline-flex -ml-1 -mt-1 sm:mb-0">
                    <img className="rounded-full border-4 border-white dark:border-slate-900" src={company.image ? company.image : logo} width="104" height="104" alt="Avatar" />
                  </div>
                </div>

                {/* Company name and info */}
                <div className="mb-4">
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-2">{company.name}</h2>
                  <p>{company.description}</p>
                </div>

                {/* Meta */}
                <div className="inline-flex flex-wrap justify-center sm:justify-start space-x-4">

                  {company.location && <div className="flex items-center">
                    <svg className="w-4 h-4 fill-current shrink-0 text-slate-400 dark:text-slate-500" viewBox="0 0 16 16">
                      <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
                    </svg>
                    <span className="text-sm font-medium whitespace-nowrap text-slate-500 dark:text-slate-400 ml-2">{company.location}</span>
                  </div>}
                  {company.website &&
                    <div className="flex items-center">
                      <svg className="w-4 h-4 fill-current shrink-0 text-slate-400 dark:text-slate-500" viewBox="0 0 16 16">
                        <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
                      </svg>
                      <a className="text-sm font-medium whitespace-nowrap text-pink-500 hover:text-pink-500 dark:hover:text-pink-400 ml-2" href="#0">
                        {company.website}
                      </a>
                    </div>}
                </div>
              </div>
            </div>
          </header>

          {/* Page content */}
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <div className="max-w-3xl mx-auto">
              <div className='flex justify-between items-center mb-5'>
                <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold ">Open Positions at {company.name}</h3>

 {/*                {company.admins.some(admin => admin._id === auth.userInfo.user._id)

                  &&

                  <div className='flex gap-1'>
                    <button onClick={(e) => { e.stopPropagation(); navigate(`/company/applicants/${id}`) }} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                      <svg width="16" height="16" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#b1b3f8" d="M240 216V32H92a12 12 0 0 0-12 12v424a12 12 0 0 0 12 12h328a12 12 0 0 0 12-12V224H248a8 8 0 0 1-8-8Z" />
                        <path fill="#b1b3f8" d="M272 41.69V188a4 4 0 0 0 4 4h146.31a2 2 0 0 0 1.42-3.41L275.41 40.27a2 2 0 0 0-3.41 1.42Z" />
                      </svg>
                      <span className="hidden xs:block ml-2">Applicants</span>
                    </button>
                    <button onClick={(e) => { e.stopPropagation(); navigate(`/resumes`) }} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                    <svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#b1b3f8" d="m25.7 9.3l-7-7c-.2-.2-.4-.3-.7-.3H8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V10c0-.3-.1-.5-.3-.7zM18 4.4l5.6 5.6H18V4.4zM24 28H8V4h8v6c0 1.1.9 2 2 2h6v16z"/>
                        <path fill="#b1b3f8" d="M10 22h12v2H10zm0-6h12v2H10z"/>
                    </svg>
                      <span className="hidden xs:block ml-2">CV Banks</span>
                    </button>
                  </div>
                }
 */}
              </div>

              {/* Job list */}
              <div className="space-y-6">
                {/* Group 1 */}
                <div>
                  {/* Job list */}
                  <div className="space-y-2">
                    {items.map((job) => {
                      return (
                        <JobListItem
                          key={job._id}
                          id={job._id}
                          image={job.image}
                          title={job.role}
                          tags={job.details}
                          date={job.date}
                          fav={job.fav}
                          company={job.company}
                        />
                      );
                    })}
                  </div>
                </div>
                {/* Pagination */}
                <div className="mt-6">
                  <PaginationNumeric totalJobs={jobs.totalJobs} page={jobs.page} pageSize={jobs.pageSize} />

                </div>


              </div>
            </div>
          </div>
        </main>}

        {
          !company && <main className="grow">
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="max-w-2xl m-auto mt-16">
                <div className="text-center px-4">
                  <div className="inline-flex mb-8">
                    <img className="dark:hidden" src={NotFoundImage} width="176" height="176" alt="404 illustration" />
                    <img className="hidden dark:block" src={NotFoundImageDark} width="176" height="176" alt="404 illustration dark" />
                  </div>
                  <div className="mb-6">Hmm...this page doesn’t exist. Try searching for something else!</div>
                  <Link to="/companies" className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </main>
        }

      </div>
    </div>
  );
}

export default CompanyProfile;