import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig('./src/css/tailwind.config.js')
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);


export const jsonToFormData = (json) => {
  const formData = new FormData();
  const appendedKeys = new Set();

  Object.keys(json).forEach(key => {
    if (appendedKeys.has(key)) {
      return; // Skip this key if it has already been appended
    }

    if (Array.isArray(json[key])) {
      // If the value is an array, append the array as a JSON string
      formData.append(key, JSON.stringify(json[key]));
    } else if (typeof json[key] === 'object' && json[key] !== null) {
      // If the value is a nested object (but not a File), stringify it
      if (json[key] instanceof File) {
        // If the value is a File object, append it directly
        formData.append(key, json[key]);
      } else {
        // Stringify nested objects
        formData.append(key, JSON.stringify(json[key]));
      }
    } else {
      // Append other data as form data
      formData.append(key, json[key]);
    }

    appendedKeys.add(key); // Mark this key as appended
  });

  return formData;
};

export const removeNullProperties = (obj) =>{
  Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
          delete obj[key];
      }
  });
  return obj;
}


export const getLabelByValue = (data,value)  => {
  const item = data.find(item => item.value === value);
  return item ? item.label : null;
}

export const getValueByLabel = (data,label)  => {
  const item = data.find(item => item.label === label);
  return item ? item.value : null;
}

export const extractLocalityAndCountry = (addressComponents) => {
  if(!addressComponents) return ""
  let locality = '';
  let country = '';

  addressComponents.address_components.forEach(component => {
    if (component.types.includes('locality')) {
      locality = component.long_name;
    }
    if (component.types.includes('country')) {
      country = component.long_name;
    }
  });

  if(!locality && country){
    return country
  }
  if(locality && !country){
    return locality
  }

  return `${locality}, ${country}`; 
}

export const convertDateFormat =(dateString) => {
  // Split the original date string on the hyphen
  const parts = dateString.split('-');

  // Check if the split parts array has exactly three parts (year, month, day)
  if (parts.length === 3) {
      const [year, month, day] = parts;
      // Return the date in the format "DD.MM.YYYY"
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
  }

  // Return original date string or throw an error if the format is not as expected
  return dateString; // or you could throw an error or return null depending on your use case
}

export const getParamsFilter =  (params) => {
  if(!params) return "";
  let queryString = "";

  Object.keys(params).forEach(key => {
    const value = params[key];
    // Check if the value is an array and not empty
    if (Array.isArray(value) && value.length >0) {
      console.log(key)

      value.forEach(item => {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(item)}&`;
      });
    }
    // Check if the value is not an array, not null, and not an empty string
    else if ( !Array.isArray(value) && value !== "" && value !== null && value !== undefined) {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
  });

  console.log(queryString)
  // Remove the last '&' from the queryString
  return queryString.slice(0, -1);
  
}

