import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Svg, G, Path, Image, Link } from '@react-pdf/renderer';

import parse, { domToReact } from 'html-react-parser';
import { extractLocalityAndCountry } from '../../../utils/Utils';

Font.register({
    family: 'montserrat',
    fonts: [
        { src: '/fonts/Montserrat-Regular.ttf' },
        { src: '/fonts/Montserrat-Italic.ttf', fontStyle: 'italic' },
        { src: '/fonts/Montserrat-BoldItalic.ttf', fontWeight: 800, fontStyle: 'italic' },

        { src: '/fonts/Montserrat-SemiBold.ttf', fontWeight: 700 },
        { src: '/fonts/Montserrat-Bold.ttf', fontWeight: 800 },
        { src: '/fonts/Montserrat-Black.ttf', fontWeight: 900 },
    ]
});




let regularSize = 12;


const options = {
    replace: (domNode) => {
        if (domNode.type === 'tag') {
            switch (domNode.name) {
                case 'ul':

                case 'ol':
                    return (
                        <View style={{ marginBottom: 3 }}>
                            {domNode.children.map((child, i) => {
                                if (child.type === 'tag' && child.name === 'li') {
                                    return (
                                        <View key={i} style={{ flexDirection: 'row', marginBottom: 4,  }}>
                                            <Text style={{ width: 10, textAlign: 'left' }}>
                                                {domNode.name === 'ol' ? `${i + 1}. ` : '•'}
                                            </Text>
                                            <Text style={{ marginLeft: 0 }}>{domToReact(child.children, options)}</Text>
                                        </View>
                                    );
                                }
                            })}
                        </View>
                    );
                case 'li':
                    return (
                        <View style={{ flexDirection: 'row', marginBottom: 3, color:"blue !important" }}>
                            <Text>{domToReact(domNode.children, options)}</Text>
                        </View>
                    );
                case 'p':
                    return (
                        <Text key={`p-${Math.random()}`} style={{ marginBottom: 4 }}>
                            {domToReact(domNode.children, options)}
                        </Text>
                    );
                case 'span':
                    return (
                        <Text key={`p-${Math.random()}`} style={{ marginBottom: 4 }}>
                            {domToReact(domNode.children, options)}
                        </Text>
                    );

                case 'strong':
                    return (
                        <Text style={{ fontWeight: 'bold' }}>
                            {domToReact(domNode.children, options)}
                        </Text>
                    );
                case 'em':
                    return (
                        <Text style={{ fontStyle: 'italic' }}>
                            {domToReact(domNode.children, options)}
                        </Text>
                    );
                case 'u':
                    return (
                        <Text style={{ textDecoration: 'underline' }}>
                            {domToReact(domNode.children, options)}
                        </Text>
                    );
                // Add more cases for other HTML elements if needed
            }
        }
    }
};


const styles = StyleSheet.create({
    page: {
        display:'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        fontFamily: 'montserrat'

    },
    name: {
        fontSize: 22,
        fontFamily: 'montserrat',
        fontWeight: 700,
        color: 'black',
        textTransform: 'uppercase',
        margin: 0,
        padding: 0,

    },
    jobTitle: {
        fontFamily: 'montserrat',
        fontWeight: 700,

        fontSize: 22,
        padding: 0,
        color: '#E30074'

    },
    topheader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 15

    },
    header: {
        display:'flex',

        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        fontSize: 12,
        gap: 10,
    },

    headerline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
    },

    headertext: {
        marginLeft: '2px',
        marginBottom: 0,
        padding: 0
    },

    headerText: {
        marginBottom: 0,
        padding: 0

    },

    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeaderView: {
        display:'flex',

        marginTop: 10,
        flexDirection: 'row', borderBottomWidth: 1,
        borderBottomColor: '#E30074',
        borderBottomStyle: 'solid', alignItems: 'flex-start', marginBottom: 10, padding: 0
    },
    sectionHeaderText: {
        fontSize: 14,
        fontFamily: 'montserrat',
        fontWeight: 700,

        borderBottomWidth: 4,
        borderBottomColor: '#E30074',
        borderBottomStyle: 'solid',
        paddingBottom: 2,
        marginTop: 0,
        marginBottom: 0,
        
        width:95,
        color: '#E30074',
        alignItems: 'flex-start',

    },

    sectionHeaderItem: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 14,
        marginBottom: 1,
        width: "100%"
    },


    sectionTitleItem: {
        fontSize: 13,
        fontFamily: 'montserrat',
        fontWeight: 700,
        textTransform: 'uppercase',
        paddingRight: "5px",
        display:"flex",
        wordWrap: "break-word", 
        overflowWrap: "break-word" ,
        width:"70%",
        alignItems:'center'


    },

    projectTitleItem: {
        fontSize: 13,
        fontFamily: 'montserrat',
        fontWeight: 700,
        textTransform: 'uppercase',
        paddingRight: "5px",
        display:"flex",
        wordWrap: "break-word", 
        overflowWrap: "break-word" ,
        width:"100%",


    },

    sectionTime: {
        display: "flex",
        flexDirection: 'row',
        color: '#969696',
        fontSize: 12,
        textAlign:'right',
        maxWidth:"30%",
        wordWrap: "break-word", 
        overflowWrap: "break-word" ,

    },

    sectionCertif:{
       
        display: 'flex',
        justifyContent: 'flex-end',/* Align content to the right */
        width: "100%",
    },

    certifLink:{
        display:'flex',

        alignSelf:'flex-end'
    
    },

    sectionDescription:{
        marginTop : 10,
        padding: 0,
        width: "100%",
        wordBreak :'break-word',
    
    },


    sectionSubTitleItem: {
        fontSize: 13,
        fontFamily: 'montserrat',
        fontWeight: 700,
        display:"flex",
        wordWrap: "break-word", /* Older browsers */
        overflowWrap: "break-word",
        width:"75%",

    

    },
    sectionItem: {
        marginBottom: 5,

        fontSize: 10,
    },


    skillsContainer: {
        display:'flex',

        flexDirection: 'col', // set direction to row for two columns

    },

    
    hobbiesContainer: {
        display:'flex',

        flexDirection: 'row', // set direction to row for two columns
        flexWrap: 'wrap', // allow items to wrap to next line
    },

    languagesContainer: {
        display:'flex',

        flexDirection: 'row', // set direction to row for two columns
        flexWrap: 'wrap', // allow items to wrap to next line
        gap:"10px"
    },

    skillItem: {
        fontSize: 12,
        fontWeight: 500
    },



});


const getTextLength = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent.length;
};



const PDF = ({ resume, isDetailled = false }) => {
    // Initialize a state array to manage the expanded/collapsed state of each description
     const [expandedEducation, setExpandedEducation] = useState(resume.educations.map(() => false));
   const [expandedExp, setExpandedExp] = useState(resume.works.map(() => false));
    const [expandedProject, setExpandedProject] = useState(resume.projects.map(() => false));
/*     const [expandedProBono, setExpandedProBono] = useState(resume.project.map(() => false));
 */
    const url = `${process.env.REACT_APP_URL}/resume/${resume.id}`



    const formatDate = (dateString) => {
        const parts = dateString.split('.');
        if (parts.length === 3) {
            const date = new Date(parts[2], parts[1] - 1, parts[0]);
            return date.toLocaleDateString('en-EN', { month: 'short', year: 'numeric' }).replace('.', '');
        }
        return dateString;
    };
    

    
    return (
        <Document>
            <Page style={styles.page}>

               <View style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                    <View style={styles.topheader}>
                        <Text style={styles.name}>{resume.fullname}</Text>
                        <Text style={styles.jobTitle}>{resume.jobtitle}</Text>
                    </View>

                    <View style={styles.header}>

                        {resume.email && <View style={styles.headerline}>
                            <Svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" fillRule="evenodd" d="m7.172 11.334l2.83 1.935l2.728-1.882l6.115 6.033c-.161.052-.333.08-.512.08H1.667c-.22 0-.43-.043-.623-.12l6.128-6.046ZM20 6.376v9.457c0 .247-.054.481-.15.692l-5.994-5.914L20 6.376ZM0 6.429l6.042 4.132l-5.936 5.858A1.663 1.663 0 0 1 0 15.833V6.43ZM18.333 2.5c.92 0 1.667.746 1.667 1.667v.586L9.998 11.648L0 4.81v-.643C0 3.247.746 2.5 1.667 2.5h16.666Z" />
                            </Svg>
                            <Text style={styles.headertext}>
                                {resume.email}
                            </Text>
                        </View>}
                        {resume.phoneNumber && <View style={styles.headerline}>
                            <Svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M11.229 11.229c-1.583 1.582-3.417 3.096-4.142 2.371c-1.037-1.037-1.677-1.941-3.965-.102c-2.287 1.838-.53 3.064.475 4.068c1.16 1.16 5.484.062 9.758-4.211c4.273-4.274 5.368-8.598 4.207-9.758c-1.005-1.006-2.225-2.762-4.063-.475c-1.839 2.287-.936 2.927.103 3.965c.722.725-.791 2.559-2.373 4.142z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.phoneNumber}</Text>

                        </View>}

                        {resume.nationality && <View style={styles.headerline}>
                            <Svg width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M6 21V4h9.75q.35 0 .65.163t.5.437L20 9l-3.1 4.4q-.2.275-.5.438t-.65.162H8v7H6Z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.nationality}</Text>
                        </View>}
                        {resume.gender && <View style={styles.headerline}>
                            <Svg width="12" height="12" viewBox="0 0 1024 1025" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M1009 512L864 961q-14 26-40 44.5t-58 19.5q-28-1-56-20.5T672 961L527 512q-23-58-9.5-93t49.5-35h402q36 0 49.5 35t-9.5 93zm-246 513h6h-6zM576 192q0-80 56.5-136t136-56T904 56t56 136t-56 136t-135.5 56t-136-56T576 192zm-119 833H54q-35 0-48.5-35t8.5-93l176-525q-56-21-91.5-70T63 192q0-80 56.5-136t136-56t136 56T448 192q0 62-36.5 111.5T318 373l179 524q23 58 9 93t-49 35z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.gender}</Text>

                        </View>}
                        {resume.dateBirth && <View style={styles.headerline}>
                            <Svg width="12" height="12" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M448 384c-28.02 0-31.26-32-74.5-32c-43.43 0-46.825 32-74.75 32c-27.695 0-31.454-32-74.75-32c-42.842 0-47.218 32-74.5 32c-28.148 0-31.202-32-74.75-32c-43.547 0-46.653 32-74.75 32v-80c0-26.5 21.5-48 48-48h16V112h64v144h64V112h64v144h64V112h64v144h16c26.5 0 48 21.5 48 48v80zm0 128H0v-96c43.356 0 46.767-32 74.75-32c27.951 0 31.253 32 74.75 32c42.843 0 47.217-32 74.5-32c28.148 0 31.201 32 74.75 32c43.357 0 46.767-32 74.75-32c27.488 0 31.252 32 74.5 32v96zM96 96c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.dateBirth}</Text>

                        </View>}
                        {resume.localisation && <View style={styles.headerline}>
                            <Svg width="15" height="15" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3zm0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.localisation}</Text>

                        </View>}

                        {resume.drivinglicense && <View style={styles.headerline}>
                            <Svg width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <Path fill="#E30074" d="M8.028 4a2.75 2.75 0 0 0-2.654 2.026L4.422 9.52A2.75 2.75 0 0 0 2 12.25v2.25c0 .865.4 1.636 1.023 2.14a3.25 3.25 0 0 0 6.32.61h4.814a3.252 3.252 0 0 0 6.258-.258A2.75 2.75 0 0 0 22 14.5v-1.688a2.75 2.75 0 0 0-2.083-2.668l-2.183-.546l-2.508-4.246A2.75 2.75 0 0 0 12.858 4h-4.83ZM6.822 6.421A1.25 1.25 0 0 1 8.028 5.5H9.5v4H5.982l.84-3.079ZM11 9.5v-4h1.858c.443 0 .852.234 1.077.614l2 3.386H11Zm-4.75 5a1.75 1.75 0 1 1 0 3.5a1.75 1.75 0 0 1 0-3.5Zm9.25 1.75a1.75 1.75 0 1 1 3.5 0a1.75 1.75 0 0 1-3.5 0Z" />
                            </Svg>
                            <Text style={styles.headertext}>{resume.drivinglicense}</Text>

                        </View>}

                    </View>

                    <View style={styles.header}>



                    </View>

                </View>

                 {
                    (resume.educations.length > 0) &&

                    <View style={styles.section}>

                        <View style={styles.sectionHeaderView}>
                            <Text style={styles.sectionHeaderText}>EDUCATION</Text>
                            <Text></Text>
                        </View>

                        {
                            resume.educations.map((education, index) => (
                                <> {!education.isHidden &&
                                    <View style={styles.sectionItem}>


                               
                                        <View style={{display:'flex', flexDirection:'row', width:'100%'}}>
                                
                                            <View style={{ width:"100%"}}>
                                                <View style={styles.sectionHeaderItem}>
                                                    <Text style={styles.sectionTitleItem}>{education.degree}</Text>
                                                    <Text style={styles.sectionTime}>{extractLocalityAndCountry(education.address)}</Text>
                                                </View>
                                                <View style={styles.sectionHeaderItem}>

                                                    <Text style={styles.sectionSubTitleItem} >{education.school}</Text>
                                                    <Text style={styles.sectionTime}>{formatDate(education.startDate)} – {education.endDate?formatDate(education.endDate):'Present'}</Text>
                                                </View>

                                            </View>
                                            {education.file &&(
                                            <View style={{marginLeft:'5px', display:'flex',width:'auto'}} >
                                                    <View style={styles.sectionCertif}>
                                                        <Link style={styles.certifLink} src={`${process.env.REACT_APP_URL_BACKEND}/file-service/${education.file}`}>
                                                        <Image
                                                            style={{ width: 20, height: 20 }} // Adjust the size as needed
                                                            source={require('../../../images/pdf/check.png')} // Make sure the path is correct
                                                        />
                                                        Certified
                                                        </Link>
                                                    
                                                    </View>
                                            </View>)}
                                        </View>
                                        

                                        {
                                            (education.description && !isDetailled) &&
                                            <View style={styles.sectionDescription}> 
                                                    {parse(expandedEducation[index] ? education.description : `${education.description.substring(0, 500)}${education.description.length > 500 ? '...' : ''}`, options)}

                                                    {
                                                        getTextLength(education.description) > 500 &&
                                                        <Link src={url+`?modal=true&type=educations&id=${education.id}`}>Show more</Link>
                                                    }


                                            </View>
                                        } 

                                        {
                                            (education.description && isDetailled) &&
                                            <View style={styles.sectionDescription}>
                                                    {parse(education.description , options)}


                                            </View>
                                        }
                                    </View>}
                                </>
                            ))
                        }


                    </View>

                }
 
                {
                    ( resume.works.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>EXPERIENCE</Text>
                            <Text></Text>
                        </View>
                        {
                            resume.works.map((exp, index) => (
                                <>{!exp.isHidden &&
                                    <View style={styles.sectionItem}>

                                        <View style={{display:'flex', flexDirection:'row', width:'100%'}}>
                        
                                            <View style={{ width:"100%"}}>
                                                <View style={styles.sectionHeaderItem}>
                                                    <Text style={styles.sectionTitleItem}>{exp.title}</Text>
                                                    <Text style={styles.sectionTime}>{extractLocalityAndCountry(exp.address)}</Text>
                                                </View>
                                                <View style={styles.sectionHeaderItem}>

                                                    <Text style={styles.sectionSubTitleItem} >{exp.employer}</Text>
                                                    <Text style={styles.sectionTime}>{formatDate(exp.startDate)} – {exp.endDate?formatDate(exp.endDate):'Present'}</Text>
                                                </View>

                                            </View>

                                            {exp.file && (
                                            <View style={{marginLeft:'5px', display:'flex',width:'auto'}} >
                                                    <View style={styles.sectionCertif}>
                                                        <Link style={styles.certifLink} src={`${process.env.REACT_APP_URL_BACKEND}/file-service/${exp.file}`}>
                                                        <Image
                                                            style={{ width: 20, height: 20 }} // Adjust the size as needed
                                                            source={require('../../../images/pdf/check.png')} // Make sure the path is correct
                                                        />
                                                        Certified
                                                        </Link>
                                                    
                                                    </View>
                                            </View>)}
                                        </View>
                                        
                                        {
                                            (exp.description && !isDetailled) &&
                                            <View style={styles.sectionDescription}>
                                                    {parse(expandedExp[index] ? exp.description : `${exp.description.substring(0, 500)}${exp.description.length > 500 ? '...' : ''}`, options)}

                                                    {
                                                        getTextLength(exp.description) > 500 &&
                                                        <Link src={url+`?modal=true&type=works&id=${exp.id}`}>Show more</Link>
                                                    }


                                            </View>
                                        }

                                        
                                            {(exp.description && isDetailled) &&
                                            <View style={styles.sectionDescription}>
                                                    {parse( exp.description , options)}


                                            </View>
                                        }
                                    </View>}
                                </>
                            ))
                        }


                    </>

                }

              {
                    ( resume.projects.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>PROJECT</Text>
                            <Text></Text>
                        </View>
                        {
                            resume.projects.map((project, index) => (
                                <>{!project.isHidden &&
                                    <View style={styles.sectionItem}>

  

                                        <View style={{display:'flex', flexDirection:'row', width:'100%'}}>
                              
                                            <View style={{ width:"100%"}}>
                                                <View style={styles.sectionHeaderItem}>
                                                    <Text style={styles.sectionTitleItem}>{project.projectTitle}</Text>
                                                    <Text style={styles.sectionTime}>{extractLocalityAndCountry(project.address)}</Text>
                                                </View>
                                                <View style={styles.sectionHeaderItem}>

                                                    <Text style={styles.sectionSubTitleItem} >{project.projectSubtitle}</Text>
                                                    <Text style={styles.sectionTime}>{formatDate(project.startDate)} – {project.endDate?formatDate(project.endDate):'Present'}</Text>
                                                </View>

                                            </View>

                                            {project.file && (
                                            <View style={{marginLeft:'5px', display:'flex',width:'auto'}} >
                                                    <View style={styles.sectionCertif}>
                                                        <Link style={styles.certifLink} src={`${process.env.REACT_APP_URL_BACKEND}/file-service/${project.file}`}>
                                                        <Image
                                                            style={{ width: 20, height: 20 }} // Adjust the size as needed
                                                            source={require('../../../images/pdf/check.png')} // Make sure the path is correct
                                                        />
                                                        Certified
                                                        </Link>
                                                    
                                                    </View>
                                            </View>)}
                                        </View>


                                        {
                                            (project.description && !isDetailled) &&
                                            <View style={styles.sectionDescription}> 
                                                    {parse(expandedProject[index] ? project.description : `${project.description.substring(0, 500)}${project.description.length > 500 ? '...' : ''}`, options)}

                                                    {
                                                        getTextLength(project.description) > 500 &&
                                                        <Link src={url+`?modal=true&type=projects&id=${project.id}`}>Show more</Link>
                                                    }


                                            </View>
                                        }

                                        
                                            {(project.description && isDetailled) &&
                                            <View style={styles.sectionDescription}>
                                                    {parse( project.description , options)}


                                            </View>
                                        }
                                    </View>}
                                </>
                            ))
                        }


                    </>

                }
  
                {
                    (resume.skills.filter(skill => skill.skillType !=='LANGUAGE').length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>SKILL</Text>
                            <Text></Text>
                        </View>
                        <View style={styles.skillsContainer}>

                            {
                                resume.skills.filter(skill => skill.skillType !=='LANGUAGE').map((skill) => (
                                    <>{!skill.isHidden &&
                                        <View style={styles.skillItem}>

                                            <View >
                                                <Text>• {skill.skillName} {skill.skillInfo && <>({skill.skillInfo})</>}</Text>
                                            </View>
                                       

                                        </View>}
                                    </>
                                ))
                            }

                        </View>


                    </>

                }

{
                    (resume.skills.filter(skill => skill.skillType ==='LANGUAGE').length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>LANGUAGE</Text>
                            <Text></Text>
                        </View>
                        <View style={styles.skillsContainer}>

                            {
                                resume.skills.filter(skill => skill.skillType ==='LANGUAGE').map((skill) => (
                                    <>{!skill.isHidden &&
                                        <View style={styles.skillItem}>

                                            <View >
                                                <Text>• {skill.skillName} {skill.skillInfo && <>({skill.skillInfo})</>}</Text>
                                            </View>

                                        </View>}
                                    </>
                                ))
                            }

                        </View>


                    </>

                }





{
                    ( resume.certificatesView.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>CERTIFICATE</Text>
                            <Text></Text>
                        </View>
                        <View style={styles.skillsContainer}>

                            {
                                resume.certificatesView.map((certificate) => (
                                    <>{!certificate.isHidden &&
                                        <View style={{ ...styles.skillItem }}>

                                            <View style={styles.sectionHeaderItem}>
                                                <Text style={styles.sectionSubTitleItem}>{certificate.title}</Text>
                                            </View>
                                            <Text style={styles.sectionItem}>{certificate.certificateDescription}</Text>

                                        </View>}
                                    </>
                                ))
                            }

                        </View>


                    </>

                }


{
                    ( resume.hobbies.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>HOBBY</Text>
                            <Text></Text>
                        </View>
                        <View style={styles.hobbiesContainer}>

                            {
                                resume.hobbies.map((hobby,index) => (
                                    <>{!hobby.isHidden &&
                                        <View style={{ ...styles.skillItem }}>
                                           
                                            <View style={styles.sectionHeaderItem}>
                                                <Text >{hobby.hobbyTitle}{index !== resume.hobbies.length - 1 ? ' – ' : ''}
 </Text>
                                            </View>

                                        </View>}
                                    </>
                                ))
                            }

                        </View>


                    </>


                }  
                
                {/* 
                {
                    ( resume.language.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>LANGUAGE</Text>
                            <Text></Text>
                        </View>
                        <View style={styles.skillsContainer}>

                            {
                                resume.language.map((language) => (
                                    <>{!language.ishidden &&
                                        <View style={{ ...styles.skillItem }}>

                                            <View >
                                                <Text >{language.language}  {language.languagelevel && <>({language.languagelevel})</>}</Text>
                                            </View>
                                            <Text >{language.languageinfos}</Text>

                                        </View>}
                                    </>
                                ))
                            }

                        </View>


                    </>

                }

                */}

     {/*            {
                    (resume.statessections.probonoactivity && resume.probonoactivity.length > 0) &&

                    <>
                        <View style={styles.sectionHeaderView}>

                            <Text style={styles.sectionHeaderText}>PRO BONO ACTIVITY</Text>
                            <Text></Text>
                        </View>
                        {
                            resume.probonoactivity.map((probonoactivity, index) => (
                                <>{!probonoactivity.ishidden &&
                                    <View style={styles.sectionItem}>

                                        <View style={styles.sectionHeaderItem}>
                                            <Text style={styles.sectionTitleItem}>{probonoactivity.title}</Text>
                                            <Text style={styles.sectionTime}>{probonoactivity.city}{(probonoactivity.city && probonoactivity.country) && ','} {probonoactivity.country}</Text>

                                        </View>
                                        <View style={styles.sectionHeaderItem}>
                                            <Text style={styles.sectionTitleItem} >{probonoactivity.organization}</Text>
                                            <Text style={styles.sectionTime}>{`${ getMonthName(probonoactivity.startdate.month)} ${probonoactivity.startdate.year}`}{(probonoactivity.startdate.year && !probonoactivity.enddate.noshow) && '–'}{` ${ getMonthName(probonoactivity.enddate.month)} ${probonoactivity.enddate.year}`}{probonoactivity.enddate.current && 'Present'}</Text>
                                        </View>
                                        {
                                            (probonoactivity.description && !isDetailled) &&
                                            <View style={styles.sectionDescription}> 
                                                    {parse(expandedProBono[index] ? probonoactivity.description : `${probonoactivity.description.substring(0, 500)}${probonoactivity.description.length > 500 ? '...' : ''}`, options)}

                                                    {
                                                        getTextLength(probonoactivity.description) > 500 &&
                                                        <Link src={url+`?modal=true&type=probonoactivity&id=${probonoactivity._id}`}>Show more</Link>
                                                    }


                                            </View>
                                        }

                                        
                                            {(probonoactivity.description && isDetailled) &&
                                            <View style={styles.sectionDescription}>
                                                    {parse( probonoactivity.description , options)}


                                            </View>
                                        }
                                    </View>}
                                </>
                            ))
                        }


                    </>

                }  */}

            </Page>
        </Document>)
};

export default PDF;
