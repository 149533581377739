import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { EducationRequirement, JobType, Remote, WorkSchelule, contractType } from '../../../../utils/datas';

const Job2 = ({ formData, setFormData, step, setStep }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // Set form initial values
    form.setFieldsValue(formData);
  }, [formData, form]);

  const onFinish = (values) => {
    console.log(values);
  
    // Update formData state with the new values
    setFormData(currentFormData => {
      // Combine current form data with new values
      const updatedFormData = {...currentFormData, ...values};
      console.log(updatedFormData); // This will log the updated form data correctly
      return updatedFormData;
    });
  
    setStep(step + 1);
  };
  


  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
        >

          <Form.Item
            label="Remote"
            name="remote"
            rules={[{ required: true, message: 'Please select the remote policy!' }]}
          >
            <Select  size="large">
              {Remote.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Work Schedule"
            name="workSchedule"
            rules={[{ required: true, message: 'Please select the work schedule!' }]}
          >
            <Select  size="large">
              {WorkSchelule.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          
          <Form.Item
            label="Education Requirement"
            name="educationRequirement"
            rules={[{ required: true, message: 'Please select the education!' }]}
          >
            <Select  size="large">
              {EducationRequirement.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>




  


          <div className="flex items-center justify-end gap-2">
          <Button
              type="default"
              htmlType="submit"
              className="flex items-center gap-2"
              size="large"
              onClick={() => setStep(step-1)}
            >
              Previous Step
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#f11a7b] gap-2"
              size="large"
            >
              Next Step
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Job2;
