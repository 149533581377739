import React, { useState } from 'react';


import Sidebar from '../components/Sidebar';
import SettingsSidebar from '../components/Settings/SettingsSidebar';
import FeedbackPanel from '../components/Settings/FeedbackPanel';
import Header from '../components/Header';

function Feedback() {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header  title={"Account Settings"} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

     
            {/* Content */}
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />
                <FeedbackPanel />
              </div>
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default Feedback;