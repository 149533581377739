import React, { useState, useEffect } from 'react';
import { Button, Table } from 'antd';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';

function InvitationsTable({
  selectedItems,
  setSelectedItem,
  list,
  setTransactionPanelOpen
}) {


  const columns = [
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyInfo.companyName',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
 
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },


    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 160,
      render: (text, record) => <div style={{display:"flex", gap: "5px"}}>
      <Button  className='flex justify-center items-center ' onClick={(e) => { e.stopPropagation(); setTransactionPanelOpen(true); setSelectedItem(record)}}    size="large" icon={<BsEye />}  />
      <Button  className='flex justify-center items-center 'onClick={(e)=>{}}  type="primary" danger size="large" icon={<MdDelete/>}  />

   </div>,
    },
  ];


  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);



  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white   dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">All Applications <span className="text-slate-400 dark:text-slate-500 font-medium"> {list.length}</span></h2>
      </header>
      <div className='px-4 rounded-md overflow-hidden'>

     
        
        <Table

        columns={columns}
        dataSource={list}
        scroll={{
          x: 1300,
        }}
  />
      </div>
    </div>


  );
}

export default InvitationsTable;
