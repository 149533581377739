import { Button, Checkbox, Form, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { EducationRequirement, JobType, Remote, WorkSchelule, companySizes, contractDurations, contractType, educationsDatas, experiencesDatas, remoteDatas, sectors } from '../../../../utils/datas'
import { getLabelByValue } from '../../../../utils/Utils'
import { updateFilters } from '../../../../redux/reducers/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'

const AllFilter = ({setIsModalOpen, isModalOpen}) => {


    const [form] = Form.useForm();

    const  {filters, modal} = useSelector(state=>state)
    const [formData, setFormData] = useState({
        workSchedule: filters.filters.workSchedule || [],
        employmentType: filters.filters.employmentType || [],
        formattedAddress: filters.filters.formattedAddress || '',
        minWorkExperienceYear: filters.filters.minWorkExperienceYear || '',
        maxWorkExperienceYear: filters.filters.maxWorkExperienceYear || '',
        minSalary: filters.filters.minSalary || '',
        maxSalary: filters.filters.maxSalary || '',
        remote: filters.filters.remote || [],
        educationRequirement: filters.filters.educationRequirement || [],
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (modal.isOpen) {
          form.resetFields();
          console.log("form reset")
        }
      }, [modal]);

  





    // Function to handle checkbox changes
    const handleCheckboxChange = (category, value, isChecked) => {
        if (isChecked) {
            // Add the value to the array in formData
            setFormData({
                ...formData,
                [category]: [...formData[category], value]
            });
        } else {
            // Remove the value from the array in formData
            setFormData({
                ...formData,
                [category]: formData[category].filter(item => item !== value)
            });
        }
    };

    const handleInputChange = (category, value) => {
        console.log(value)
        setFormData({
            ...formData,
            [category]: value
        });
    };


    return (
        <div className='flex flex-col overflow-hidden p-0 justify-between h-full'>


            <div className='flex flex-col overflow-y-scroll '>
            <Form form={form} layout="vertical">

            <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg width="20" height="20" className='mr-1 ' viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1664 512h256v1536H256V512h256V384h128v128h896V384h128zm128 128h-128v128h128zm-256 0H640v128h896zm-1024 0H384v128h128zM384 1920h1408V896H384zM256 384V256H128v1408H0V128h256V0h128v128h896V0h128v128h256v128h-256v128h-128V256H384v128zm384 1024v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-768 256v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-256-512v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128z" />
                    </svg>LOCALISATION</h3>
                    <span>Where are you looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="formattedAddress"
                               
                            >
                                <Input   onChange={(e) => handleInputChange('formattedAddress', e.target.value)}
     size="large" className='antd-input w-full' placeholder="Localisation" />
                            </Form.Item>
                           
                        </Form.Item>

                    </div>
                </div>
                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'> <svg width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M832 384H576V128H192v768h640zm-26.496-64L640 154.496V320zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32m160 448h384v64H320zm0-192h160v64H320zm0 384h384v64H320z" />
                    </svg>CONTRACT</h3>
                    <span>The type of contract or working hours that suit you</span>
                    <div className='grid grid-cols-2 gap-2 max-h-80 mt-4'>
                        {WorkSchelule.map((data) => (
                            <Checkbox
                                key={data.value}
                                onChange={(e) => handleCheckboxChange('workSchedule', data.value, e.target.checked)}>
                                {getLabelByValue(WorkSchelule, data.value)}
                            </Checkbox>
                        ))}
                    </div>
                </div>

                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'> <svg width="23" height="23" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="m19.03 7.39l1.42-1.42c-.45-.51-.9-.97-1.41-1.41L17.62 6c-1.55-1.26-3.5-2-5.62-2a9 9 0 0 0 0 18c5 0 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61M13 14h-2V7h2zm2-13H9v2h6z" />
                    </svg>CONTRACT DURATION</h3>
                    <span>The ideal duration for you</span>
                    <div className='grid grid-cols-2 gap-2 max-h-80 mt-4'>
                        {JobType.map((data) => (
                            <Checkbox
                                key={data.value}
                                onChange={(e) => handleCheckboxChange('employmentType', data.value, e.target.checked)}>
                                {getLabelByValue(JobType, data.value)}
                            </Checkbox>
                        ))}
                    </div>
                </div>

                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'> <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m3 12l2-2m0 0l7-7l7 7M5 10v10a1 1 0 0 0 1 1h3m10-11l2 2m-2-2v10a1 1 0 0 1-1 1h-3m-6 0a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1m-6 0h6" />
                    </svg>REMOTE</h3>
                    <span>Your ideal home-office rhythm</span>
                    <div className='flex flex-col gap-2 mt-4'>
                        {Remote.map((data) => (
                            <Checkbox
                                key={data.value}
                                onChange={(e) => handleCheckboxChange('remote', data.value, e.target.checked)}
                            >{getLabelByValue(Remote, data.value)}</Checkbox>
                        ))}

                    </div>
                </div>

                {/* 
                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg width="20" height="20" className='mr-1 ' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M30 8H2v18h28ZM20 8s0-4-4-4s-4 4-4 4M8 26V8m16 18V8" />
                    </svg>SECTOR</h3>
                    <span>The sector of activity that you like or are good at</span>
                    <div className='grid grid-cols-2 gap-2 max-h-80 overflow-y-scroll mt-4'>
                        {sectors.map((data) => (
                            <Checkbox>{data.label}</Checkbox>
                        ))}

                    </div>
                </div>
 */}

                {/*                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'> <svg width="23" height="23" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M12 16.14h-.87a8.67 8.67 0 0 0-6.43 2.52l-.24.28v8.28h4.08v-4.7l.55-.62l.25-.29a11 11 0 0 1 4.71-2.86A6.59 6.59 0 0 1 12 16.14" class="clr-i-solid clr-i-solid-path-1" />
                        <path fill="currentColor" d="M31.34 18.63a8.67 8.67 0 0 0-6.43-2.52a10.47 10.47 0 0 0-1.09.06a6.59 6.59 0 0 1-2 2.45a10.91 10.91 0 0 1 5 3l.25.28l.54.62v4.71h3.94v-8.32Z" class="clr-i-solid clr-i-solid-path-2" />
                        <path fill="currentColor" d="M11.1 14.19h.31a6.45 6.45 0 0 1 3.11-6.29a4.09 4.09 0 1 0-3.42 6.33Z" class="clr-i-solid clr-i-solid-path-3" />
                        <path fill="currentColor" d="M24.43 13.44a6.54 6.54 0 0 1 0 .69a4.09 4.09 0 0 0 .58.05h.19A4.09 4.09 0 1 0 21.47 8a6.53 6.53 0 0 1 2.96 5.44" class="clr-i-solid clr-i-solid-path-4" />
                        <circle cx="17.87" cy="13.45" r="4.47" fill="currentColor" class="clr-i-solid clr-i-solid-path-5" />
                        <path fill="currentColor" d="M18.11 20.3A9.69 9.69 0 0 0 11 23l-.25.28v6.33a1.57 1.57 0 0 0 1.6 1.54h11.49a1.57 1.57 0 0 0 1.6-1.54V23.3l-.24-.3a9.58 9.58 0 0 0-7.09-2.7" class="clr-i-solid clr-i-solid-path-6" />
                        <path fill="none" d="M0 0h36v36H0z" />
                    </svg>COMPANY SIZE</h3>
                    <span>The size of company you feel comfortable in</span>
                    <div className='flex flex-wrap gap-2 justify-center mt-4'>
                        {companySizes.map((data) => (
                            <Button>{data.label}</Button>
                        ))}
                    </div>
                </div>
 */}



                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 2048 2048">
    <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128h256zm-512 0q0 27 10 50t27 40t41 28t50 10V384h-128zM128 512q27 0 50-10t40-27t28-41t10-50H128v128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20v384zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128v-128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10v128zm1792-768h-128v896H256v128h1664V512zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19zm896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19zm-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20zM768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50v128z"/>
</svg>SALARY</h3>
                    <span>The amount of salary that you are looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="minSalary"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <InputNumber  onChange={(e) => handleInputChange('minSalary', e)}
    min={0} size="large" className='antd-input w-full' placeholder="Mininum year of experience" />
                            </Form.Item>
                            <Form.Item
                                name="maxSalary"

                                style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
                            >
                                <InputNumber   onChange={(e) => handleInputChange('maxSalary', e)} min={0} size="large" className='antd-input w-full' placeholder="Maximum year of experience" />
                            </Form.Item>
                        </Form.Item>

                    </div>
                </div>


                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-1'>  <svg width="20" height="20" className='mr-1 ' viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1664 512h256v1536H256V512h256V384h128v128h896V384h128zm128 128h-128v128h128zm-256 0H640v128h896zm-1024 0H384v128h128zM384 1920h1408V896H384zM256 384V256H128v1408H0V128h256V0h128v128h896V0h128v128h256v128h-256v128h-128V256H384v128zm384 1024v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-768 256v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128zm-256-512v-128h128v128zm256 0v-128h128v128zm256 0v-128h128v128z" />
                    </svg>EXPERIENCE</h3>
                    <span>The amount of experience that you are looking for</span>

                    <div className='gap-2 mt-4'>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="minYearsExperience"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <InputNumber  onChange={(e) => handleInputChange('minWorkExperienceYear', e)}
    min={0} size="large" className='antd-input w-full' placeholder="Mininum year of experience" />
                            </Form.Item>
                            <Form.Item
                                name="maxYearsExperience"

                                style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
                            >
                                <InputNumber   onChange={(e) => handleInputChange('maxWorkExperienceYear', e)} min={0} size="large" className='antd-input w-full' placeholder="Maximum year of experience" />
                            </Form.Item>
                        </Form.Item>

                    </div>
                </div>



                <div className='flex flex-col border-b-2 border-slate-200 py-8 px-2'>
                    <h3 className='flex items-center font-semibold text-lg gap-2'> <svg width="24" height="24" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M1024 736q0 13-9.5 22.5T992 768t-22.5-9.5T960 736V315L607 492q-40 20-95 20t-95-20L39 303Q0 283 0 255.5T39 209L417 20q40-20 95-20t95 20l378 189q34 17 38 42q1 1 1 4zM639 556l193-97v141q0 43-93.5 73.5T512 704t-226.5-30.5T192 600V459l193 97q40 20 127 20t127-20" />
                    </svg>EDUCATION LEVEL</h3>
                    <span>Your level of education</span>
                    <div className='flex flex-col gap-2 mt-4'>
                        {EducationRequirement.map((data) => (
                            <Checkbox
                            
                            key={data.value}
                            onChange={(e) => handleCheckboxChange('educationRequirement', data.value, e.target.checked)}
                            >{data.label}</Checkbox>
                        ))}

                    </div>
                </div>







                </Form>

            </div>

            <div className='flex gap-2 mt-8 items-center' style={{ height: "80px" }}>
                <Button onClick={() => {dispatch(updateFilters(formData)); setIsModalOpen(false)}} type="primary" className='flex  !rounded-2xl items-center bg-[#f11a7b] gap-2' size="large">
                    <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="m15.7 14.3l-4.2-4.2c-.2-.2-.5-.3-.8-.3c.8-1 1.3-2.4 1.3-3.8c0-3.3-2.7-6-6-6S0 2.7 0 6s2.7 6 6 6c1.4 0 2.8-.5 3.8-1.4c0 .3 0 .6.3.8l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-.9 0-1.3M6 10.5c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5s4.5 2 4.5 4.5s-2 4.5-4.5 4.5" />
                    </svg>
                    Explore jobs
                </Button>

                <Button size="large">
                    Cancel
                </Button>
            </div>

        </div>
    )
}

export default AllFilter