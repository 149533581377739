// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { createVacancy, deleteJobRequest, deleteVacancyById, getMyRequests, getMyVacancies, getVacanciesByCompanyId, getVacanciesCurrent, getVacancyResumes, searchVacancies, updateVac, vacancyFavourite, vacancyUnfavourite, vacancyUpdateStatus } from '../actions/jobposting'


const initialState = {
  loading: false,
  jobs: [],
  applications: [],
  error: null,
  errorMsg: null,
  success: false,
}

const jobPostingSlice = createSlice({
  name: 'jobPosting',
  initialState,
  reducers: {
    cleanJobs: (state) => {
      state.loading= false
      state.jobs= []
      state.applications = []
      state.error= null
      state.errorMsg= null
      state.success= false
    },

  },
  extraReducers: {


    [createVacancy.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [createVacancy.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = [...state.jobs, payload.data]
    },

    [createVacancy.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [updateVac.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [updateVac.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.jobs = state.jobs.map(job => 
        job.id === payload.data.id ? payload.data : job
      );

    },

    [updateVac.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },

    [getVacanciesCurrent.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getVacanciesCurrent.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = payload.data.entities
      state.size = payload.data.size
      state.page = payload.data.page
      state.totalEntity = payload.data.totalEntity
      state.totalPage = payload.data.totalPage

   
    },
    [getVacanciesCurrent.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getMyVacancies.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [getMyVacancies.fulfilled]: (state, { payload }) => {
      state.jobs = payload.data.entities
      state.size = payload.data.size
      state.page = payload.data.page
      state.totalEntity = payload.data.totalEntity
      state.totalPage = payload.data.totalPage
      state.loading = false
      state.success = true
    },
    [getMyVacancies.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },


    [getVacanciesByCompanyId.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getVacanciesByCompanyId.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = payload.data
   
    },

    [getVacanciesByCompanyId.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getVacancyResumes.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [getVacancyResumes.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.applications = payload.data.entities
      state.size = payload.data.size
      state.page = payload.data.page
      state.totalEntity = payload.data.totalEntity
      state.totalPage = payload.data.totalPage
    },

    [getVacancyResumes.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [searchVacancies.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [searchVacancies.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = payload.data.entities
      state.size = payload.data.size
      state.page = payload.data.page
      state.totalEntity = payload.data.totalEntity
      state.totalPage = payload.data.totalPage

   
    },

    [searchVacancies.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },


    [deleteVacancyById.pending]: (state) => {
      state.loading = true;
      state.errorMsg = null;
    },
    
    [deleteVacancyById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.jobs = state.jobs.filter(job => job.id !== payload);
    },
    
    [deleteVacancyById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMsg = payload;
      state.error = true;
    },


    [getMyRequests.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getMyRequests.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.applications = payload.data.entities
      state.size = payload.data.size
      state.page = payload.data.page
      state.totalEntity = payload.data.totalEntity
      state.totalPage = payload.data.totalPage    },

    [getMyRequests.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [deleteJobRequest.pending]: (state) => {
      state.loading = true;
      state.errorMsg = null;
    },
    
    [deleteJobRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.jobs = []
      state.applications = state.applications.filter(job => job.id !== payload);
    },
    
    [deleteJobRequest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMsg = payload;
      state.error = true;
    },

    [vacancyUpdateStatus.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [vacancyUpdateStatus.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = []
      state.applications = state.applications.map(application => 
        application.id === payload.id ? payload : application
      );

    },

    [vacancyUpdateStatus.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },


    [vacancyFavourite.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [vacancyFavourite.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = state.jobs.map(job => 
        job.id === payload ? {...job, isSaved:true} : job
      );

    },

    [vacancyFavourite.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },


    [vacancyUnfavourite.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [vacancyUnfavourite.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = state.jobs.map(job => 
        job.id === payload ? {...job, isSaved:false} : job
      );

    },

    [vacancyUnfavourite.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
    },
    
 

  



  },
})

export const { cleanJobs } = jobPostingSlice.actions;

export default jobPostingSlice.reducer