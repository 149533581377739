import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSectionResume, getResume, getResumesFilter } from '../redux/actions/resumes';
import NewResumePdf from '../components/Resume/pdf/NewResumePdf';
import { getJobApplicationByUserId } from '../redux/actions/jobapplication';
import PaginationNumeric from '../components/PaginationNumeric';
import JobApplicationListItem from '../components/JobApplications/JobApplicationListItem';
import DropdownSort from '../components/DropdownSort';
import ApplicantsListItem from '../components/JobApplications/ApplicantListItem';
import ResumeListItem from '../components/Resume/ResumeListItem';
import { cleanResume, cleanResumeInfos } from '../redux/reducers/resumeSlice';

function BankResumes() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { resume, modal, auth } = useSelector(state => state)
    const [pdfSrc, setPdfSrc] = useState(null);
    let { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected,setSelected] = useState("");
    const [inputSearch, setInputSearch] = useState("");

    useEffect(() => {
        console.log(auth)
        if (auth.userInfo.user) {
            dispatch(cleanResumeInfos())
            dispatch(getResumesFilter({ filter: ``, token: auth.userToken }))

        }
    }, [])

    const backendURL = process.env.REACT_APP_URL_BACKEND


    return (
        <div className="flex h-[100dvh] overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main className="grow">
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <div className="flex flex-col space-y-10  sm:space-y-0  md:space-x-0 md:space-y-10   xl:space-y-0 ">

                                <div className="mb-8">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Search For The Perfect Resume  </h1>
                                </div>

                                <div className='w-full'>

                                    {/* Search form */}
                                    <div className="mb-5">
                                        <div className="relative ">

                                            <label htmlFor="job-search" className="sr-only">Search</label>
                                            <div className='d-flex w-full '>
                                                <input onChange={e => setInputSearch(e.target.value)} id="job-search" className="d-flex w-11/12 form-input flex-1 pl-9 bg-white dark:bg-slate-800" type="search" placeholder="Search resumes" />
                                                <button onClick={(e) => { e.stopPropagation();              dispatch(cleanResumeInfos())
;  dispatch(getResumesFilter({ filter: `all=${inputSearch}`, token: auth.userToken }))
 }} className="w-1/12 d-flex">
                                                    <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Jobs header */}
                                    <div className="flex justify-between items-center mb-4">
                                        <div className="text-sm text-slate-500 dark:text-slate-400 italic">Showing {resume.totalResumes} Resumes</div>
                                        {/* Sort */}
                                        <div className="text-sm">
                                            <span>Sort by </span>
                                            <DropdownSort  align="right" />
                                        </div>
                                    </div>


                                    {/* Jobs list */}
                                    <div className='space-y-2'>
                                        {resume.resumes.map((resume) => {
                                            return (
                                                <ResumeListItem
                                                    id={resume._id}
                                                    fullname={resume.fullname}
                                                    localisation={resume.localisation}
                                                    jobtitle={resume.jobtitle}
                                                    setSelected={setSelected}
                                                    isSelected={selected === resume._id}
                                                    key={resume._id}
                                                />
                                            );
                                        })} 
                                    </div>

                                    {/* Pagination */}
                                    <div className="mt-6">
                                        <PaginationNumeric totalJobs={resume.totalResumes} page={resume.page} pageSize={resume.pageSize} />

                                    </div>

                                </div>

                            </div>
                            {
                               resume.resumeInfo&&         <NewResumePdf className='hidden  md:flex '
                                        pdfSrc={pdfSrc} setPdfSrc={setPdfSrc} />
                            }

{
                               !resume.resumeInfo&&    <div className='flex w-full justify-center  items-center align-middle'>
                                        <div className='flex flex-col items-center'>
                                        <svg width="128" height="128" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="#6366f1">
                                                            <path d="M13.743 20.35c-.12.738.381 1.445 1.064 1.883c.714.457 1.732.707 2.93.53a3.794 3.794 0 0 0 2.654-1.666c.504-.763.711-1.692.48-2.381a.5.5 0 0 0-.818-.203c-1.796 1.704-3.824 2.123-5.643 1.448a.5.5 0 0 0-.667.39Zm20.076 0c.119.738-.382 1.445-1.065 1.883c-.714.457-1.731.707-2.93.53a3.794 3.794 0 0 1-2.653-1.666c-.504-.763-.712-1.692-.48-2.381a.5.5 0 0 1 .818-.203c1.796 1.704 3.824 2.123 5.642 1.448a.5.5 0 0 1 .668.39ZM33.258 31c-1.72-3.562-5.22-6-9.258-6c-4.038 0-7.538 2.438-9.258 6c-.469.97.316 2 1.394 2h15.728c1.078 0 1.863-1.03 1.394-2Z"/>
                                                            <path fillRule="evenodd" d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6S6 14.059 6 24s8.059 18 18 18Zm0-2c8.837 0 16-7.163 16-16S32.837 8 24 8S8 15.163 8 24s7.163 16 16 16Z" clipRule="evenodd"/>
                                                        </g>
                                                    </svg>
                                        Please select a resume
                                        </div>
                          
                                </div>
                            }
                          

                        </div>
                    </div>



                </main>


            </div>
        </div>
    );
}

export default BankResumes;