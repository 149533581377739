import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo-big.png'

import AuthImage from '../images/company/entrepreneurs-isometric.svg';
import AuthDecoration from '../images/auth-decoration.png';
import { useDispatch } from 'react-redux';
import { registerUser, userLogin } from '../redux/actions/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Signup() {


  const dispatch = useDispatch()
  const email = useRef("");
  const fullname = useRef("");
  const password = useRef("");
  const cPassword = useRef("");
  const navigate = useNavigate();

  const submitForm = async ({ email, fullname, password }) => {
    console.log(fullname)
    if (password.current.value !== cPassword.current.value) {
      toast.error('The passwords doesn\'t match!');
      return;
    }

    const actionResult = await dispatch(registerUser({ fullname: fullname.current.value, email: email.current.value, password: password.current.value }))
    console.log(actionResult)
    if (actionResult.type === 'auth/register/rejected') {
      toast.error(actionResult.payload);
    } else {
      toast.success('Registration succeeded! Please check your mails to confirm your account');
      navigate('/signin');

    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className='w-40' src={Logo} alt="logo" />

                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Create your Account </h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-rose-500">*</span></label>
                    <input ref={email} id="email" className="form-input w-full" type="email" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="name">Full Name <span className="text-rose-500">*</span></label>
                    <input ref={fullname} id="fullname" className="form-input w-full" type="text" />
                  </div>
{/* 
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="name">Employment Status <span className="text-rose-500">*</span></label>
                    <select style={{width:'100%'}} name='employmentStatus' placeholder='Select the status' className="form-select">
                      <option key='OPEN_TO_OFFERS' value='OPEN_TO_OFFERS'>Open to offers</option>
                      <option key='ACTIVELY_SEEKING' value='ACTIVELY_SEEKING'>Actively seeking</option>
                      <option key='NOT_LOOKING' value='NOT_LOOKING'>Not looking</option>
                    </select>                  
                  </div>
 */}


                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input ref={password} id="password" className="form-input w-full" type="password" autoComplete="on" />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="confirm-password">Confirm Password</label>
                    <input ref={cPassword} id="cPassword" className="form-input w-full" type="password" autoComplete="on" />
                  </div>
                </div>
                <div className="flex items-center justify-end mt-6">

                  <button className="btn bg-[#f11a7b] hover:bg-[#f11a7b] text-white ml-3 whitespace-nowrap" onClick={(e) => { submitForm({ email, fullname, password }); e.preventDefault() }}>Sign Up</button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-700">
                <div className="text-sm">
                  Have an account? <Link className="font-medium text-[#f11a7b] hover:text-pink-500 dark:hover:text-pink-400" to="/signin">Sign In</Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden bg-gradient-to-tl  from-pink-500 to-pink-300  md:flex justify-center items-center absolute top-4 bottom-4 right-4 rounded-3xl md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-2/3 " src={AuthImage}  alt="Authentication" />
        </div>


      </div>

    </main>
  );
}

export default Signup;