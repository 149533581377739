import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/landing/bg.jpg';
import { userLogin } from '../redux/actions/auth';
import { useDispatch } from 'react-redux';
import Logo from '../images/logo-big.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Signin() {

  const dispatch= useDispatch()
  const email = useRef("");
  const password = useRef("");

  const submitForm = async ({ email,password}) => {
    const actionResult = await dispatch(userLogin({email:email.current, password: password.current, type:'user'}))
    console.log(actionResult)
    if(actionResult.type === 'auth/login/rejected'){
      toast.error(actionResult.payload);

    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className='w-40' src={Logo}  alt="logo"/>
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Welcome back!</h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address</label>
                    <input id="email" className="form-input w-full" type="email" onChange={(e) => (email.current = e.target.value)} required/>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input id="password" className="form-input w-full" type="password" autoComplete="on" onChange={(e) => (password.current = e.target.value)} required />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
                  </div>
                  <button className="btn bg-[#f11a7b] hover:bg-pink-500 text-white ml-3"  onClick={(e)=>{submitForm({email, password}); e.preventDefault()}}>Sign In</button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-700">
                <div className="text-sm">
                  Don’t you have an account? <Link className="font-medium text-pink-500 hover:text-pink-500 dark:hover:text-pink-400" to="/signup">Sign Up</Link>
                </div>

              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden bg-gradient-to-tl overflow-hidden from-pink-500 to-pink-300  md:flex justify-center items-center absolute top-4 bottom-4 right-4 rounded-3xl  md:w-1/2" aria-hidden="true">
          <img className="object-fill object-center  w-full h-full" src={AuthImage}  alt="Authentication" />
        </div>

      </div>

    </main>
  );
}

export default Signin;