import Dropdown from '../utils/dropdown'
import MobileMenu from './mobile-menu'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../../images/logo-big.png'
import { useEffect, useState } from 'react';
export default function Header() {

  const [selected, setSelected] = useState('Home'); // Default selected link
  const location = useLocation(); // Get the current location


  useEffect(() => {
    // Set the selected state based on the current path
    const path = location.pathname;
    if (path === "/") {
      setSelected('Home');
    } else if (path === "/aboutus") {
      setSelected('Aboutus');
    } else if (path === "/contactus") {
      setSelected('Contactus');
    } else if (path === "/signin") {
      setSelected('Signin');
    } else if (path === "/signup") {
      setSelected('Signup');
    }
  }, [location]);
  // Function to update the selected link
  const handleSelect = (linkName) => {
    setSelected(linkName);
  };

  // Function to apply conditional styling
  const getLinkClassName = (linkName) => {
    return selected === linkName
      ? "text-pink-500 font-bold  border-b-4 border-pink-500 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
      : "text-black border-b-4 border-gray-200  hover:border-pink-500 hover:text-pink-500 px-4 py-2 flex items-center transition duration-150 ease-in-out";
  };
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className=" flex items-center gap-4 mr-4">
            {/* Logo */}
            <img  src={Logo} alt="logo"  className='w-40'/>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop menu links */}
            <ul className="flex gap-4 grow justify-end flex-wrap items-center">
              <li>
                <Link to="/" className={getLinkClassName('Home')} onClick={() => handleSelect('Home')} >
                  Home
                </Link>
              </li>

        
         {/*      <li>
                <Link to="/aboutus" className={getLinkClassName('Aboutus')} onClick={() => handleSelect('Aboutus')} >
                  About us
                </Link>
              </li> */}

              <li>
                <Link to="/contactus" className={getLinkClassName('Contactus')} onClick={() => handleSelect('Contactus')} >
                  Contact us 
                </Link>
              </li>

            </ul>

            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/signin"
                  className="font-medium text-pink-500 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Sign in
                </Link>
              </li>
               <li>
                 <Link to="/employers/signin" className="btn-sm text-white bg-pink-500 hover:bg-[#f11a7b] ml-3">
                  Employers
                </Link> 
              </li>  
            </ul>
          </nav>

          <MobileMenu />

        </div>
      </div>
    </header>
  )
}
