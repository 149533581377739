import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { JobType, contractType } from '../../../../utils/datas';
import SearchAddress from '../../../Form/SearchAddress';

const Job1 = ({ formData, setFormData, step, setStep }) => {
  const [form] = Form.useForm();
  const [localisationText, setLocalisationText] = useState("");
  const [localisationObject, setLocalisationObject] = useState(null);

  
  useEffect(() => {
    if(formData && formData.address){
        setLocalisationText(formData.address.formatted_address)
        setLocalisationObject(formData.address)
    }

  }, [formData])

  useEffect(() => {
    // Set form initial values
    console.log(formData)
    form.setFieldsValue(formData);
  }, [formData, form]);

  const onFinish = (values) => {
    console.log(values);
  
    // Update formData state with the new values
    setFormData(currentFormData => {
      // Combine current form data with new values
      const updatedFormData = {...currentFormData, ...values};
      console.log(updatedFormData); // This will log the updated form data correctly
      return updatedFormData;
    });
  
    setStep(step + 1);
  };
  

  const handleAddressChange = (newAddress) => {

    setLocalisationText(newAddress.formatted_address) 
    const { formatted_address,address_components, geometry } = newAddress;
    const newObject = { formatted_address,address_components, geometry };
    setLocalisationObject(newObject)

    setFormData({...formData, address: newObject})

}

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
        >
          <Form.Item
            label="Job title"
            name="title"
            rules={[{ required: true, message: 'Please input the job title!' }]}
          >
            <Input size='large' className='antd-input' />
          </Form.Item>


      
  
          <Form.Item
                label="Address"
                name="address"
                required={true}
                rules={[
                    {
                        validator: (_, value) =>
                            localisationText ? Promise.resolve() : Promise.reject(new Error('Enter a location!'))
                    }
                ]}

            >
                <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


          </Form.Item>

          <Form.Item
            label="Job type"
            name="employmentType"
            rules={[{ required: true, message: 'Please select the job type!' }]}
          >
            <Select  size="large">
              {JobType.map((type, index) => (
                <Select.Option  key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="flex items-center justify-end">
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#f11a7b] gap-2"
              size="large"
            >
              Next Step
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Job1;
