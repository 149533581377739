import React, { useRef, useEffect } from 'react';
import Transition from '../../utils/Transition';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/reducers/modalSlice';

function ModalBlankR({
  children,
  id,
  type,
  size = 'max-w-lg'
}) {

  const modalContent = useRef(null);
  const {modal} = useSelector(state => state)
  const dispatch = useDispatch();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modal.isOpen || modalContent.current.contains(target)) return
      //dispatch(openModal({type:'', isOpen:false}))
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modal.isOpen || keyCode !== 27) return;
      dispatch(openModal({type:'', isOpen:false}))

    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });




  return (
    <>
      
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modal.isOpen && modal.type === type}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modal.isOpen && modal.type === type}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
 <div ref={modalContent} className={`bg-white dark:bg-slate-800 rounded shadow-lg overflow-auto ${size} w-full max-h-full`}>
          {children}
        </div>      
      </Transition>
    </>
  );
}

export default ModalBlankR;
