import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/company/office-building.png'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getResume, getResumeWithoutOwning } from '../../redux/actions/resumes';
import { updateJobApplication } from '../../redux/actions/jobapplication';
function ApplicantsListItem(props) {
    const  {auth} = useSelector(state => state)
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    const answerApplicant = (answer) => {
      let data = {
        message: props.message,
        company: props.company[0]._id ,
        user: props.user._id, 
        resume:props.resume, // 'User' should match your user model name
        job: props.job, // 'User' should match your user model name
        status: answer,
      }
      dispatch(updateJobApplication({id: props.jobapp, jobapplicationData: data , token: auth.userToken  }))
    }
  return (
    <div
      className={`shadow-lg rounded-sm border hover:cursor-pointer px-5 py-4 ${
         'bg-white dark:bg-slate-800 '
      }  ${props.status === 'refused' && 'border-1 border-red-400'}    
      ${props.status === 'pending' && 'border-1 dark:border-slate-800 border-slate-200 '}

      ${props.status === 'accepted' && 'border-1 border-green-400'}

      `}

      onClick={()=> {setOpen(!open) ;  dispatch(getResumeWithoutOwning({id: props.resume, token: auth.userToken}))}
    }
    >
      <div className="md:flex justify-between items-center space-y-4 md:space-y-0 space-x-2">
        {/* Left side */}
        <div className="flex items-start space-x-3 md:space-x-4">
          <div className="w-9 h-9 shrink-0 mt-1 hover:cursor-pointer" onClick={()=> navigate(`/company/${props.company._id}`)}>
            <img  className="w-9 h-9  rounded-full" src={props.image?props.image:logo} width="36" height="36" />
          </div>
          <div>
          <div className="font-semibold text-slate-800 dark:text-slate-100">{props.fullname}</div>

            <Link className="text-sm" to={`/job/job-post/${props.id}`}>
              {props.title}
            </Link>

          </div>
        </div>
        {/* Right side */}
        <div className="flex items-center space-x-4 pl-10 md:pl-0">
          <div className="text-sm text-slate-500 dark:text-slate-400 italic whitespace-nowrap">{moment(props.date).fromNow()}</div>
       
 
        </div>
      </div>

      {
        open && 
          <div className='mt-4'>
            Message:
          <div  dangerouslySetInnerHTML={{ __html: props.message }}>
      
            
          </div>
  
          {props.status === 'pending' &&         
            <div className='flex justify-end gap-2 mt-4'>
              <button  onClick={(e) => { e.stopPropagation(); answerApplicant("refused")}} className="btn bg-red-400 hover:bg-red-500 text-white">
                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#d36161" d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"/>
                </svg>
                <span className="hidden xs:block ml-2">Refuse</span>
              </button>   

              <button  onClick={(e) => { e.stopPropagation(); answerApplicant("accepted") }} className="btn bg-green-400 hover:bg-green-500 text-white">
                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#2e9c56" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41L9 16.17z"/>
                </svg>
                <span className="hidden xs:block ml-2">Accept</span>
              </button> 
            </div>
            }
          {
            props.status === 'accepted'&&
            <div className='flex justify-end gap-2 mt-4'>
              <button  className="btn bg-green-400  text-white">
                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#2e9c56" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41L9 16.17z"/>
                </svg>
                <span className="hidden xs:block ml-2">Accepted</span>
              </button> 
          </div>

          }
                   
         {
            props.status === 'refused'&&
            <div className='flex justify-end gap-2 mt-4'>
              <button className="btn bg-red-400 text-white">
                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#d36161" d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"/>
                </svg>
                <span className="hidden xs:block ml-2">Refused</span>
              </button>   

          </div>

          }
          </div>
          }


    </div>
  );
}

export default ApplicantsListItem;