import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio, Button, message, Input } from 'antd';
import ReactQuill from 'react-quill'; // Ensure you have ReactQuill for rich text editing
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { vacancyUpdateStatus } from '../../../../redux/actions/jobposting';
import { useParams } from 'react-router-dom';

const FormApplicationAnswer = ({ job, status, setCreateModalOpen, open, }) => {
    const { resume, auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {id} = useParams();


  useEffect(() => {
    form.setFieldsValue({ message: "" }); 

  }, [open])
  
    


    const handleQuillChange = (content) => {
        form.setFieldsValue({ message: content }); // Update form value manually
    };

    const onFormFinish = async  (values) => {
        // Process form values here, such as submitting them to a backend service
        console.log('Form values:', values);
        
        let data = {
            vacancyId: id,
            email: values.email,
            message: values.message,
            resumeId: job.resumeId
        }



        try {
    
              let res =  await dispatch(vacancyUpdateStatus({ data,type:status , token: auth.userToken })).unwrap();
              // If the promise resolves successfully, show success toast
              // Update and set list logic remains the same
        /*       const updatedJob = { ...job, jobRequestView: res };
              const updatedItems = list.map(item =>
                  item.id === job.id ? updatedJob : item
              );
              setList(updatedItems); */
              toast.success("Answer sent!");
              window.location.reload()
            } catch (error) {
              // If the promise is rejected, handle the error
              console.error(error); // Useful for debugging
              toast.error(error.message || "An error occurred!");
            } finally {
    
              setCreateModalOpen(false)
    
            }  
    
        
    };
    

    const editorStyle = {
        backgroundColor: '#f4f4f5',
        "minHeight": '300px',
        borderRadius: '0.75rem', // Add this line
    };

    return (
        <>
                    <style>
                {`
           .ql-toolbar.ql-snow {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            background:white;
          }
            .ql-container.ql-snow {
              border:none;
            }
          `}
            </style>
            <Form form={form} layout="vertical" onFinish={onFormFinish}>
                <Form.Item
                    name="email"
                    label="Email to contact"                   
                     rules={[{ required: true, message: 'Please enter an email!' }]}
                >
                    <Input className='form-input w-full'/>
                </Form.Item>

                <Form.Item
                    name="message"
                    label="Message for the applicant"                   
                     rules={[{ required: true, message: 'Please enter a message!' }]}
                >
                    <ReactQuill   value={message} // Control ReactQuill with local state
                        onChange={handleQuillChange} 
                         style={editorStyle} className='mt-2 '  theme="snow" />
                </Form.Item>

                <div className="flex justify-end space-x-2">
                    <Button onClick={() => setCreateModalOpen(false)}>Close</Button>
                    {
                        status === 'invite' && <Button type="primary"  className="flex items-center bg-green-500 color-white gap-2" htmlType="submit">Invite</Button>
                    }
                    {
                        status === 'reject' && <Button type="primary"  className="flex items-center bg-red-500 color-white gap-2" htmlType="submit">Reject</Button>

                    }
                </div>
            </Form>
        </>
    );
};

export default FormApplicationAnswer;
