import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio, Button, message } from 'antd';
import ReactQuill from 'react-quill'; // Ensure you have ReactQuill for rich text editing
import 'react-quill/dist/quill.snow.css';
import { getResumes } from '../../../redux/actions/resumes';
import { createJobApplication } from '../../../redux/actions/jobapplication';
import { createJobRequest } from '../../../redux/actions/jobposting';
import { toast } from 'react-toastify';

const FormApplication = ({ job,setCreateModalJob, setCreateModalOpen, setList, list }) => {
    const { resume, auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [message, setMessage] = useState(''); // State to track message content


    useEffect(() => {
        if (auth.userToken) {
            dispatch(getResumes({ token: auth.userToken }));
        }
    }, [auth.userToken, dispatch]);

    const handleQuillChange = (content) => {
        setMessage(content); // Update local state
        form.setFieldsValue({ message: content }); // Update form value manually
    };

    const onFormFinish = async  (values) => {
        // Process form values here, such as submitting them to a backend service
        console.log('Form values:', values);

        let data = {
            resume: values.resume,
            vacancy: job.id,
            message: values.message
        }

        try {
            //setIsLoading(true);
    
              // Dispatch the async thunk
             let res =  await dispatch(createJobRequest({ data, token: auth.userToken })).unwrap();
             console.log(res)
              // If the promise resolves successfully, show success toast
              //job.jobRequestView = res;
              // Update and set list logic remains the same
              const updatedJob = { ...job, jobRequestView: res };
              const updatedItems = list.map(item =>
                  item.id === job.id ? updatedJob : item
              );
              setList(updatedItems);
              toast.success("Application sent!");
              setCreateModalJob(false)
            } catch (error) {
              // If the promise is rejected, handle the error
              console.error(error); // Useful for debugging
              toast.error(error.response.data || "An error occurred!");
            } finally {
    
              setCreateModalOpen(false)
    
            }  
    
        
    };
    

    const editorStyle = {
        backgroundColor: '#f4f4f5',
        "minHeight": '300px',
        borderRadius: '0.75rem', // Add this line
    };

    return (
        <>
                    <style>
                {`
           .ql-toolbar.ql-snow {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            background:white;
          }
            .ql-container.ql-snow {
              border:none;
            }
          `}
            </style>
            <Form form={form} layout="vertical" onFinish={onFormFinish}>
                <Form.Item
                    name="resume"
                    label="Resume(s)"
                    rules={[{ required: true, message: 'Please select a resume!' }]}
                >
                    <Radio.Group>
                        {resume.resumes.length > 0 ? (
                            resume.resumes.map((resume) => (
                                <Radio value={resume.id} key={resume.id} style={{wordBreak: "break-all"}}>{resume.jobTitle}</Radio>
                            ))
                        ) : (
                            <div>You don't have any resumes</div>
                        )}
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="message"
                    label="Message for the company"                   
                    rules={[{ required: true, message: 'Please enter a message!' }]}



                    
                >
                    {/* This assumes ReactQuill can be controlled by Form.Item. If not, you may need to manage its state separately and then update the form's state on submit */}
                    <ReactQuill   value={message} // Control ReactQuill with local state
                        onChange={handleQuillChange} 
                         style={editorStyle} className='mt-2 '  theme="snow" />
                </Form.Item>

                <div className="flex justify-end space-x-2">
                    <Button onClick={() => setCreateModalOpen(false)}>Close</Button>
                    <Button type="primary"  className="flex items-center bg-[#f11a7b] gap-2" htmlType="submit">Apply</Button>
                </div>
            </Form>
        </>
    );
};

export default FormApplication;
