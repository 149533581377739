import React, { useState } from 'react';

function JobSidebar() {



  return (
    <div className="space-y-8">
    
      {/* White box */}
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
        <div className="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
          {/* Group 1 */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Remote</div>
            <ul className="space-y-2">
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" defaultChecked />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Unknown</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Occasionally</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Frequently</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Fully Remote</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">On-Site Only</span>
                </label>
              </li>
            </ul>
          </div>
    
          {/* Group 3 */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Experience</div>
            <ul className="space-y-2">
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" defaultChecked />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Entry Level</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Intermediate Level</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Experienced</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Senior Level</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Executive Level</span>
                </label>
              </li>
             
            </ul>
          </div>

          <button  className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">
               
                <span className="hidden xs:block ml-2">All Filters</span>
              </button>

        </div>
      </div>
    </div>
  );
}

export default JobSidebar;