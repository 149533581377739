import React from 'react';
import { useNavigate } from 'react-router-dom';


function DBJobsCard() {
  const navigate = useNavigate();



  return (
    <div onClick={()=>navigate('/employers/jobs')}  className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 hover:cursor-pointer hover:bg-pink-100 bg-white dark:bg-slate-800 shadow-lg rounded-2xl border border-slate-200 dark:border-slate-700">
      <div className="px-5 py-5">
        <header className="flex justify-between items-start mb-4">
          {/* Icon */}
  
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g fill="none">
                  <path stroke="#f11e7e" strokeLinecap="round" strokeWidth="1.5" d="M9.17 4a3.001 3.001 0 0 1 5.66 0"/>
                  <path fill="#f11e7e" d="M12 22.25a.75.75 0 0 0 0 1.5zm-7.682-.422l.498-.56zm15.364 0l-.498-.56zM7.955 23.684a.75.75 0 1 0 .09-1.498zM2.25 13c0 1.874 0 3.83.169 5.466c.085.82.215 1.589.422 2.244c.203.646.503 1.256.979 1.679l.996-1.121c-.183-.163-.377-.478-.545-1.008c-.164-.52-.28-1.178-.36-1.948c-.16-1.543-.161-3.415-.161-5.312zM12 23.75c2.102 0 3.746.001 5.027-.152c1.293-.154 2.33-.477 3.153-1.209l-.996-1.121c-.495.44-1.178.703-2.335.84c-1.17.14-2.709.142-4.849.142zm-3.955-1.564c-1.714-.103-2.613-.371-3.229-.918l-.996 1.121c1.016.903 2.363 1.188 4.135 1.295zm12.205-8.799c0 1.832-.013 3.616-.178 5.08c-.082.731-.2 1.353-.36 1.845c-.164.5-.352.799-.528.956l.996 1.121c.46-.409.755-.993.957-1.61c.206-.628.338-1.362.426-2.144c.176-1.562.186-3.432.187-5.247z"/>
                  <path stroke="#f11e7e" strokeLinecap="round" strokeWidth="1.5" d="m14.66 15l3.004-.783M9.34 15l-6.008-1.567c-.595-.155-.893-.233-1.082-.419a.891.891 0 0 1-.1-.116C2 12.688 2 12.417 2 11.876c0-2.13 0-3.195.673-3.914c.13-.138.275-.264.434-.377C3.934 7 5.159 7 7.609 7h8.782c2.45 0 3.675 0 4.502.585c.16.113.305.239.434.377c.673.72.673 1.784.673 3.914c0 .54 0 .811-.15 1.022a.885.885 0 0 1-.1.116c-.15.148-.369.227-.75.331"/>
                  <path stroke="#f11e7e" strokeLinecap="round" strokeWidth="1.5" d="M14 13.5h-4a.5.5 0 0 0-.5.5v2.162a.5.5 0 0 0 .314.464l.7.28a4 4 0 0 0 2.972 0l.7-.28a.5.5 0 0 0 .314-.465V14a.5.5 0 0 0-.5-.5Z"/>
              </g>
          </svg>
        </header>
        <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">Jobs</h2>
        <div className="text-sm font-normal text-slate-400 dark:text-slate-500  mb-2">View, edit and manage your job posts</div>
      {/*   <div className="flex items-start">
          <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">10</div>
          <div className="text-sm font-semibold text-white px-1.5 bg-emerald-500 rounded-full">+20%</div>
        </div>
        <div className="text-xs font-normal text-slate-400 dark:text-slate-500  mb-1">Job Clicks</div>
 */}
      </div>

    </div>
  );
}

export default DBJobsCard;
