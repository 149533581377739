// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { createJob, getJobById, getJobs } from '../actions/jobs'


const initialState = {
  loading: false,
  jobInfo: null,
  jobs: [],
  error: null,
  errorMsg: null,
  success: false,
  totalJobs: 0,
  page: 0,
  pageSize: 0
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {

  },
  extraReducers: {



    [createJob.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [createJob.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = [payload, ...state.jobs]
    },

    [createJob.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [createJob.pending]: (state) => {
      state.loading = true;
      state.errorMsg = null;
    },
    

    [getJobs.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getJobs.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobs = payload.jobs
      state.page = payload.page
      state.pageSize = payload.pageSize
      state.totalJobs = payload.totalJobs
    },
    [getJobs.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
      state.jobs = []
    },


    [getJobById.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getJobById.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.jobInfo = payload.jobs[0]?payload.jobs[0]:null
    },
    [getJobById.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },
 

  },
})


export default jobSlice.reducer