import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../../components/lotties/loader.json'

import { addItemResume, updateItemResume } from '../../../redux/actions/resumes';

import { openModal } from '../../../redux/reducers/modalSlice';
import { useParams } from 'react-router-dom';
import MonthYearPicker from '../../Form/MonthYearPicker';
import Editor from '../../Form/Editor';
import SearchAddress from '../../Form/SearchAddress';
import SearchAddressOldStyle from '../../Form/SearchAddressOldStyle';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { uploadFile } from '../../../redux/actions/file';
import dayjs from 'dayjs';
import Lottie from 'react-lottie-player';

const FormSection = () => {

    const quillRef = useRef(null);
    const [form] = Form.useForm();

    const MAX_CHARS = 1000; // Define your maximum character limit for the editor

    const CHARS_CV_DISPLAY = 500;  //Define the number of character displayed on the cv
    const { modal, resume, auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [startDateValue, setStartDateValue] = useState('');
    const [endDateValue, setEndDateValue] = useState('');
    const [file, setFile] = useState(null);
    const [fileTmp, setFileTmp] = useState('');

    const [error, setError] = useState('');
    const [endDateIsCurrent, setEndDateIsCurrent] = useState(true);

    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);



    let { id } = useParams();

    const sectionsinfos = [
        { name: 'educations', isuploadable: true, isdatable: true, islocalisable: true, islevelable: false, description: true, iscertifiable: true },
        { name: 'works', isuploadable: true, isdatable: true, islocalisable: true, islevelable: false, description: true, iscertifiable: true },
        { name: 'skills', isuploadable: false, isdatable: false, islocalisable: false, islevelable: false, description: false, iscertifiable: true },
        { name: 'language', isdatable: false, islocalisable: false, islevelable: false, description: false, iscertifiable: true },
        { name: 'certificatesView', isuploadable: false, isdatable: false, islocalisable: false, islevelable: true, description: false, iscertifiable: false },

        { name: 'projects', isuploadable: false, isdatable: true, islocalisable: false, islevelable: false, description: true },
        { name: 'hobbies', isdatable: false, islocalisable: false, islevelable: false, description: false },
        { name: 'probonoactivity', isdatable: true, islocalisable: true, islevelable: false, description: true },

    ]

    const sectioninfo = sectionsinfos.find(section => section.name === modal.name)

    const initializeEdit = {

        id: modal.isEdit ? modal.data.id : '',
        title: modal.isEdit ? modal.data.title : '',
        employer: modal.isEdit ? modal.data.employer : '',
        degree: modal.isEdit ? modal.data.degree : '',
        school: modal.isEdit ? modal.data.school : '',
        city: modal.isEdit ? modal.data.city : '',
        country: modal.isEdit ? modal.data.country : '',
        state: modal.isEdit ? modal.data.state : '',

        skillName: modal.isEdit ? modal.data.skillName : '',
        skillInfo: modal.isEdit ? modal.data.skillInfo : '',
        skillType: modal.isEdit ? modal.data.skillType : '',
        language: modal.isEdit ? modal.data.language : '',
        languageinfos: modal.isEdit ? modal.data.languageinfos : '',
        languagelevel: modal.isEdit ? modal.data.languagelevel : '',

        certificateDescription: modal.isEdit ? modal.data.certificateDescription : '',
        file: modal.isEdit ? modal.data.file : null,


        projectTitle: modal.isEdit ? modal.data.projectTitle : '',
        projectSubtitle: modal.isEdit ? modal.data.projectSubtitle : '',

        hobbyTitle: modal.isEdit ? modal.data.hobbyTitle : '',
        hobbyDescription: modal.isEdit ? modal.data.hobbyDescription : '',


        description: modal.isEdit ? modal.data.description : '',
        certificationtype: modal.isEdit ? modal.data.certificationtype : '',
        startDate: modal.isEdit ? modal.data.startDate : '',
        endDate: modal.isEdit ? modal.data.endDate : '',


    }

    const initialize = {

        jobtitle: "",
        employer: "",
        degree: "",
        school: "",
        city: "",
        country: "",
        address: null,
        skillName: "",
        skillInfo: "",
        skillType: "",
        description: "",
        certificationtype: "",
        startDate: "",
        endDate: "",
        projectTitle: "",
        projectSubtitle: '',
        title: '',
        certificateDescription: ''

    }

    const [formData, setFormData] = useState(modal.isEdit ? initializeEdit : initialize);
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        const { startDate, endDate, ...restOfData } = formData; // Destructure to exclude 'dateBirth'
        form.setFieldsValue({ ...restOfData });
       // form.setFieldsValue({...formData });
      }, [formData, form]);

      useEffect(() => {
        if (modal.isEdit) {
            const startDate = modal.data.startDate ? dayjs(modal.data.startDate, 'DD.MM.YYYY') : null;
            const endDate = modal.data.endDate ? dayjs(modal.data.endDate, 'DD.MM.YYYY') : null;
            form.setFieldsValue({ startDate, endDate });
            setStartDateValue(startDate);
            setEndDateValue(endDate);
        }else{
            const startDate =  null;
            const endDate =null;
            form.setFieldsValue({ startDate, endDate });
            setStartDateValue(startDate);
            setEndDateValue(endDate);
        }
    }, [modal.isEdit, modal.data, form]);

    



    useEffect(() => {
        setFormData(modal.isEdit ? initializeEdit : initialize)
        setFormErrors({})
        setLocalisationObject(null)
        setLocalisationText('')

        console.log("open")
        console.log(modal)
   
        if (modal.isEdit) {
            console.log(modal.data)

            if(modal.data.startDate){
                setStartDateValue(modal.data.startDate)
            }

            if(modal.data.endDate){
                setEndDateValue(modal.data.endDate)
            }
          
          
            if (modal.data.address) {
                setLocalisationObject(modal.data.address)
                setLocalisationText(modal.data.address.formatted_address)
            }

            console.log(modal.data.file)
            if (modal.data.file) {
                setFileTmp(modal.data.file)
            }

            if(modal.data.endDate){
                setEndDateIsCurrent(false)
            }else{
                setEndDateIsCurrent(true)

            }

        }
        else{
            setStartDateValue(null)
            setEndDateValue(null)
            setEndDateIsCurrent(false)

        }
        return () => {
            setStartDateValue(null)
            setEndDateValue(null)
            setEndDateIsCurrent(false)

            form.resetFields(); // Reset form fields when modal is closed
            //dispatch(cleanResume());
        }
    }, [modal.isOpen])

    const handleDelete = () => {
        setFile(null);
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEndDateChange = (event) => {
        setEndDateIsCurrent(event.target.checked);
        if (endDateIsCurrent) {
            setEndDateValue('')
        }
    };


    const handleChangeEditor = (value, key) => {
        const editor = quillRef.current.getEditor();
        const text = editor.getText().trim();

        if (text.length <= MAX_CHARS) {
            setFormData(prevState => ({
                ...prevState,
                [key]: value
            }));
        } else {
            // Truncate the text to the maximum length
            const truncatedText = text.substring(0, MAX_CHARS);
            // Update the editor's content
            editor.setText(truncatedText);
            // Update the formData with the truncated text
            setFormData(prevState => ({
                ...prevState,
                [key]: truncatedText
            }));

            console.log("Character limit exceeded");
        }
    };

    const handleEditorError = (inputName, errorMessage) => {
        setFormErrors({ ...formErrors, [inputName]: errorMessage });
    };

    const handleFileChange = (e) => {

        const selectedFile = e.target.files[0];

        const allowedMimeTypes = ['application/pdf'];

        if (selectedFile && allowedMimeTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setError('');
        } else {
            setFile(null);
            if (selectedFile) {
                setError('Please select a valid PDF file.');

            }
        }
    };


    const handleSaveNew = async (name, data) => {
        if (endDateIsCurrent) {
            setFormData({ ...data, ["enddate"]: null });
        }
        const actionResult = await dispatch(addItemResume({ id, name, resumeData: data, token: auth.userToken }));
        if (addItemResume.fulfilled.match(actionResult)) {
            toast.success(`${modal.title} successfully created!`);
            dispatch(openModal({ isOpen: false }))
            // Close the modal (assuming you're using redux or useState to manage modal state)
        } else {
            console.log(actionResult)
            toast.error('Unknown error occurred, try again!');

        }

    }

    const handleUpdateNew = async (name, data) => {
        if (endDateIsCurrent) {
            setFormData({ ...data, ["enddate"]: null });
        }
        const actionResult = await dispatch(updateItemResume({ id, name, resumeData: data, token: auth.userToken }));
        if (updateItemResume.fulfilled.match(actionResult)) {
            toast.success(`${modal.title} successfully updated!`);
            dispatch(openModal({ isOpen: false }))
            // Close the modal (assuming you're using redux or useState to manage modal state)
        } else {
            console.log(actionResult)

            toast.error('Unknown error occurred, try again!');

        }

    }
    const handleValid = async () => {

        if (resume.loading) return;
        formData.address = localisationObject

        console.log(formData)



        if (endDateIsCurrent) {
            setEndDateValue('')
            formData.endDate = '';

        }


        if (file) {
            /*    if(fileTmp){
                   let res = await dispatch(uploadFile({data:{file},  token: auth.userToken}))
   
               } */
               console.log(file)
            let res = await dispatch(uploadFile({ data: { file }, token: auth.userToken }))
            if (res.type === "file/upload/fulfilled") {
                formData.file = res.payload.data;
                console.log(formData)
            }
        }

        if (modal.isEdit) {
            handleUpdateNew(modal.name, formData)
        } else {
            //console.log(modal.name)
            handleSaveNew(modal.name, formData)
        }
    };

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)


    }

    const handleDeleteFile = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            file: '' // Set the file property to an empty string
        }));
    }


    const handleChangeInput = e => {
        const { name, value } = e.target
        console.log(name)
        setFormData({ ...formData, [name]: value })

    }

    const handleChangeSelected = (value, option) => {
        console.log("Selected Value: ", value);
        console.log("Option selected: ", option); // option provides more details if needed
        setFormData({ ...formData, ['skillType']: value })

    };

    const onChangeStartDate = (date, dateString) => {
        if (date) {
          console.log(dateString);  // Outputs the formatted date string
      
          console.log(date.year()); // Accessing the year from a dayjs object, if directly using dayjs
          setStartDateValue(dateString)
          setFormData({ ...formData, ['startDate']: dateString })

        } 
      };
    
      const onChangeEndDate = (date, dateString) => {
        if (date) {
            setEndDateValue(dateString)
            setFormData({ ...formData, ['endDate']: dateString })

        } 
      };
      
      


    return (
        <div>
            <style>
                {`
           .ql-toolbar.ql-snow {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            background:white;
          }
            .ql-container.ql-snow {
              border:none;
            }
          `}
            </style>



            {
                formData &&
                <Form
                    form={form}
                    className='w-full mt-2'
                    layout="vertical"
                    onFinish={handleValid}

                >


                    {
                        modal.name === "works" &&
                        <>
                            <Form.Item
                                label="Job Title"
                                name="title"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the job title!' }]}
                            >
                                <Input size='large' name="title" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="Employer"
                                name="employer"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the  employer!' }]}
                            >
                                <Input size='large' name="employer" className='antd-input' max={60} />
                            </Form.Item>
                        </>
                    }

                    {
                        modal.name === "educations" &&
                        <>
                            <Form.Item
                                label="Degree"
                                name="degree"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the  degree!' }]}
                            >
                                <Input size='large' name="degree" placeholder="[DEGREE] IN [FIELD]" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="School"
                                name="school"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the  school!' }]}
                            >
                                <Input size='large' name="school" placeholder="School Name" className='antd-input' max={60} />
                            </Form.Item>
                        </>
                    }


                    {
                        modal.name === "skills" &&
                        <>
                            <Form.Item
                                label="Skill"
                                name="skillName"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the  skill!' }]}
                            >
                                <Input size='large' name="skillName" placeholder="Enter Skill" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="Information"
                                name="skillInfo"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the information!' }]}
                            >
                                <Input size='large' name="skillInfo" placeholder="Enter more information" className='antd-input' max={60} />
                            </Form.Item>

                            {



                                <Form.Item
                                    label="Skill Type"
                                    name="skillType"
                                    className='w-full'

                                >
                                    <Select size="large" name="skillType" onChange={handleChangeSelected}
                                    >
                                        <Select.Option value="" disabled > Select the type </Select.Option >
                                        <Select.Option key='SOFT' value='SOFT'>Soft</Select.Option >
                                        <Select.Option key='HARD' value='HARD'>Hard</Select.Option >
                                        <Select.Option key='LANGUAGE' value='LANGUAGE'>Language</Select.Option >
                                    </Select>
                                </Form.Item>
                            }
                        </>
                    }

                    {
                        modal.name === "language" &&
                        <>
                            <Form.Item
                                label="Language"
                                name="language"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the language!' }]}
                            >
                                <Input size='large' name="language" placeholder="Enter Language" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="Information"
                                name="languageinfos"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the information!' }]}
                            >
                                <Input size='large' name="languageinfos" placeholder="Enter more information" className='antd-input' max={60} />
                            </Form.Item>

                            {
                                sectioninfo?.islevelable &&

                                <div className='flex flex-col  w-full mt-4'>
                                    <h5 className="block text-sm font-medium mb-1 mt-4" >Language Level</h5>
                                    <select name='languagelevel' value={formData.languagelevel} onChange={handleChange} placeholder='Select your level' className="form-select">
                                        <option value="" disabled > Select your level </option>
                                        <option key='novice' value='A1'>A1 (Beginner)</option>
                                        <option key='beginner' value='A2'>A2 (Elementary)</option>
                                        <option key='skillful' value='B1'>B1 (Intermediate)</option>
                                        <option key='experienced' value='B2'>B2 (Upper Intermediate)</option>
                                        <option key='expert' value='C1'>C1 (Advanced)</option>
                                        <option key='mastery' value='C2'>C2 (Proficiency)</option>

                                    </select>
                                </div>
                            }
                        </>
                    }

                    {
                        modal.name === "certificatesView" &&
                        <>
                            <Form.Item
                                label="Title"
                                name="title"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the title!' }]}
                            >
                                <Input size='large' name="title" placeholder="Enter title" className='antd-input' max={60} />
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="certificateDescription"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the description!' }]}
                            >
                                <Input size='large' name="certificateDescription" placeholder="Enter more information" className='antd-input' max={60} />
                            </Form.Item>

                        </>
                    }

                    {
                        modal.name === "projects" &&
                        <>
                            <Form.Item
                                label="Project Title"
                                name="projectTitle"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the title!' }]}
                            >
                                <Input size='large' name="projectTitle" placeholder="Enter title" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="Sub Title"
                                name="projectSubtitle"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the subtitle!' }]}
                            >
                                <Input size='large' name="projectSubtitle" placeholder="Enter Sub Title" className='antd-input' max={60} />
                            </Form.Item>

                        </>
                    }


                    {
                        modal.name === "hobbies" &&
                        <>
                            <Form.Item
                                label="Hobby"
                                name="hobbyTitle"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the hobby!' }]}
                            >
                                <Input size='large' name="hobbyTitle" placeholder="Enter hobby" className='antd-input' max={60} />
                            </Form.Item>

                        </>
                    }

                    {
                        modal.name === "probonoactivity" &&
                        <>
                            <Form.Item
                                label="Title"
                                name="title"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the title!' }]}
                            >
                                <Input size='large' name="title" placeholder="Enter Title" className='antd-input' max={60} />
                            </Form.Item>
                            <Form.Item
                                label="Organization"
                                name="organization"
                                onChange={(e) => handleChangeInput(e)}

                                rules={[{ required: true, message: 'Please input the organization!' }]}
                            >
                                <Input size='large' name="organization" placeholder="Enter organization" className='antd-input' max={60} />
                            </Form.Item>

                        </>
                    }






                    {sectioninfo?.islocalisable && <div className='  w-full'>



                        <Form.Item
                            label="Localisation"
                            name="localisation"
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        localisationObject ? Promise.resolve() : Promise.reject(new Error('Enter a city!'))
                                }
                            ]}

                        >
                            <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


                        </Form.Item>
                    </div>}


                    {sectioninfo?.isdatable &&<div className='flex flex-col w-full'> <div className='grid grid-cols-2 gap-2 w-full '>
                     {/*    <div className='flex flex-col  justify-start'>
                            <h5 className="block text-sm font-medium mb-1 mt-4" >Start Date*</h5>

                            <MonthYearPicker handleError={handleInputError} name='startDate' isRequired defaultValue={formData.startDate} onDateChange={setStartDateValue} />

                        </div> */}


                               {<Form.Item
                  label="Start Date"
                  name="startDate"
                  className='w-full mb-1'
                  rules={[
                    { required: true, message: 'Please input the start date!' },
                    // Adding the custom validation rule to check if the date is in the past
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || dayjs(value).isBefore(dayjs(), 'day')) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The start date must be in the past!'));
                      }
                    })
                  ]}
                  initialValue={startDateValue?dayjs(startDateValue, 'DD.MM.YYYY'):''}
                >
                   <DatePicker size='large'                   
 className='antd-input w-full' format={'YYYY-MM-DD'} onChange={onChangeStartDate} needConfirm /> 

                </Form.Item>
}



                 {!endDateIsCurrent && <Form.Item
                  label="End Date"
                  name="endDate"
                  className='w-full mb-1'
                  rules={[{ required: endDateIsCurrent?false:true, message: 'Please input the end date!' }]}

                  initialValue={endDateValue?dayjs(endDateValue, 'DD.MM.YYYY'):''}
                >
                   <DatePicker size='large'                   
 className='antd-input w-full' format={'YYYY-MM-DD'} onChange={onChangeEndDate} needConfirm /> 

                </Form.Item>} 

                {
                    endDateIsCurrent && <span className='h-full flex items-center justify-center italic'>Current</span>
                }


        {/*                 <div className='flex flex-col  justify-start'>
                            <div className='flex justify-between mb-1 mt-4'>
                                <h5 className="block text-sm font-medium " >End Date*</h5>
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" checked={endDateIsCurrent}
                                        onChange={handleEndDateChange} />
                                    <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Current</span>
                                </label>
                            </div>
                            {!endDateIsCurrent && <MonthYearPicker handleError={handleInputError} name='endDate' defaultValue={formData.endDate} onDateChange={setEndDateValue} />
                            }
                            {
                                endDateIsCurrent && <span className='h-full flex items-center justify-center italic'>Current</span>
                            }




                        </div> */}
                    </div>
                    <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" checked={endDateIsCurrent}
                                        onChange={handleEndDateChange} />
                                    <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Current</span>
                                </label>
                    </div>}





                    {
                        sectioninfo?.description &&
                        <Editor
                            value={formData.description}
                            onEditorChange={handleChangeEditor}
                            handleError={handleEditorError}
                            name="description"
                            ref={quillRef}
                            isRequired={false}
                            MAX_CHARS={MAX_CHARS}
                            CHARS_CV_DISPLAY={CHARS_CV_DISPLAY}

                        />
                    }

                    {
                        (sectioninfo?.isuploadable && !formData.file) &&

                        <div className='flex flex-col'>
                            <h5 className="block text-sm font-normal mb-1 mt-4" >File</h5>


                            <div className=''>
                                <div class="max-w-xl">
                                    <label
                                        class="flex flex-col items-center gap-2 justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-2xl appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                        <span class="flex items-center space-x-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                            </svg>
                                            {file ? (
                                                <span className="flex items-center space-x-2">
                                                    <span className="font-medium text-gray-600">{file.name}</span>
                                                    <button onClick={(e) => { e.stopPropagation(); handleDelete() }} className="text-red-600 hover:text-red-800">Delete</button>
                                                </span>
                                            ) : (
                                                <span className="font-medium text-gray-600">
                                                    Drop files to Attach, or
                                                    <span className="text-pink-600 underline">browse</span>
                                                </span>
                                            )}

                                        </span>

                                        <input onChange={handleFileChange} type="file" name="file_upload" class="hidden" />
                                    </label>
                                    {error && <span className='text-red-800'>Wrong format! Please upload a pdf. {error}</span>}

                                </div>

                            </div>
                        </div>
                    }

                    {
                        (sectioninfo?.isuploadable && formData.file) &&

                        <div className='flex flex-col mb-10'>
                            <h5 className="block text-sm font-medium mb-1 mt-4" >File</h5>

                            <div className='flex justify-between'>
                                <a href={`${process.env.REACT_APP_URL_BACKEND}/file-service/${formData.file}`} target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
                                    file {formData.file.split('/').pop()}

                                </a>
                                <button onClick={(e) => { e.stopPropagation(); handleDeleteFile() }} className="text-red-600 hover:text-red-800">Delete File</button>
                            </div>



                        </div>
                    }






<div className="sticky bottom-0 pt-8 py-4 bg-white dark:bg-slate-800 border-t border-slate-200 dark:border-slate-700">
                <div className="flex flex-wrap justify-end ">
                <Button
              type="primary"
              htmlType="submit"
              className="flex items-center !rounded-2xl bg-[#f11a7b] "
              size="large"
            >
             Save
            </Button>
                </div>
            </div>



                </Form>
            }


{
          resume.loading && <div className='loading-screen'>
            <Lottie
              loop
              animationData={loader}
              play
              style={{ width: 150, height: 150 }}
            />
          </div>
        }

     
        </div>
    )
}

export default FormSection