import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { registerUserCompany } from '../../../redux/actions/auth';

const OnBoarding4 = ({ step, setStep }) => {

  const dispatch = useDispatch()
  const email = useRef("");
  const fullname = useRef("");
  const password = useRef("");
  const cPassword = useRef("");
  const navigate = useNavigate();



  const submitForm = async ({ email, fullname, password }) => {
    console.log(fullname)
    if (password.current.value !== cPassword.current.value) {
      toast.error('The passwords doesn\'t match!');
      return;
    }

    const actionResult = await dispatch(registerUserCompany({ fullname: fullname.current.value, email: email.current.value, password: password.current.value }))
    console.log(actionResult)
    if (actionResult.type === 'auth/register/company/rejected') {
      toast.error(actionResult.payload);
    } else {
      toast.success('Registration succeeded! Please check your mails to confirm your account');
      navigate('/signin');

    }
  }

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">

        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">User contact</h1>
        {/* htmlForm */}
        <form>
          <div className="space-y-4 mb-8">




            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-rose-500">*</span></label>
              <input ref={email} id="email" className="form-input w-full" type="email" />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">Full Name <span className="text-rose-500">*</span></label>
              <input ref={fullname} id="fullname" className="form-input w-full" type="text" />
            </div>


            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
              <input ref={password}  id="password" className="form-input w-full" type="password" autoComplete="on" />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="confirm-password">Confirm Password</label>
              <input  ref={cPassword}  id="cPassword" className="form-input w-full" type="password" autoComplete="on" />
            </div>



          </div>
          <div className="flex items-center justify-end">
            <button className="btn bg-[#f11a7b] hover:bg-[#f11a7b] text-white ml-3 whitespace-nowrap" onClick={(e) => { submitForm({ email, fullname, password }); e.preventDefault() }}>Sign Up</button>
          </div>
        </form>

      </div>
    </div>)
}

export default OnBoarding4