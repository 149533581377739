import { Dialog, Tab } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiSave } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import Input from '../Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { createCompany } from '../../redux/actions/companies';
import { openModal } from '../../redux/reducers/modalSlice';

const FormCompany = ({setCreateModalOpen}) => {
    /*   const modal = useModalStore((state) => state.modal);
      const updateModal = useModalStore((state) => state.updateModal);
      const deleteResumeSection = useResumeStore((state) => state.deleteResumeSection);
      const increase = useBearStore((state) => state.increase);
      const updateResumeSection = useResumeStore((state) => state.updateResumeSection);
   */
    const { resume, modal, alert,auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');




    useEffect(() => {
        setFormData(modal.isEdit ? initializeEdit : initialize)

        return () => {

            //dispatch(cleanResume());
        }
    }, [modal.isOpen])


    const initializeEdit = {
        _id: modal.isEdit ? modal.data._id : '',
        name: modal.isEdit ? modal.data.name : '',
        description: modal.isEdit ? modal.data.description : '',
        location: modal.isEdit ? modal.data.location : '',
        website: modal.isEdit ? modal.data.website : '',
    }

    const initialize = {
        name: "",
        description: "",
        location: "",
        website: "",
    }
    const [formData, setFormData] = useState(modal.isEdit ? initializeEdit : initialize);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeEditor = (value, key) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }


    const handleUpdate = async (name) => {

        try {
            let newData = formData;
            console.log(newData)

       /*      const actionResult = await dispatch(updateSectionResume({ id: resume.resumeInfo._id, name: name, resumeData: formData , token:auth.userToken}))
            if (updateSectionResume.fulfilled.match(actionResult)) {
                toast.success(`${modal.title} successfully updated!`);
                dispatch(openModal({ isOpen: false }))
                // Close the modal (assuming you're using redux or useState to manage modal state)
            } else {
                toast.error('Unknown error occurred, try again!');

                throw new Error(actionResult.payload || 'Unknown error occurred');
            } */

         

        } catch (error) {
            console.error(error);
         
            alert('An error occurred while updating the page.');
        }
    };

    const handleSave = async (name) => {
        try {
            let newData = formData;
            console.log(newData)
            delete formData._id;
            formData.userId= auth.userInfo.user._id

            const actionResult = await dispatch(createCompany({ formData, token: auth.userToken }));
            if (createCompany.fulfilled.match(actionResult)) {
                toast.success(`${modal.name} successfully created!`);
                setCreateModalOpen(false)
                // Close the modal (assuming you're using redux or useState to manage modal state)
            } else {
                toast.error('Unknown error occurred, try again!');

               // throw new Error(actionResult.payload || 'Unknown error occurred');
            } 

        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the page.');
        }
    };

    const handleValid = async () => {
        if (modal.isEdit) {
            handleUpdate(modal.name)
        } else {
            handleSave(modal.name)
        }
    };

    const handleDelete = () => {
        setFile(null);
    };


    const editorStyle = {
        backgroundColor: '#f4f4f5',
        "minHeight": '200px',
        borderRadius: '0.75rem', // Add this line
    };

    return (
        <>
            <style>
                {`
           .ql-toolbar.ql-snow {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            background:white;
          }
            .ql-container.ql-snow {
              border:none;
            }
          `}
            </style>

            {
                formData &&

                <div className="mt-2 ">


                    <Input  {...{ name: "name", text: "Name", placeholder: "Name", value: formData.name, handleChange: handleChange }} />
                    <Input  {...{ isOptionnal: true, name: "website", text: "Website", placeholder: "Website", value: formData.website, handleChange: handleChange }} />
                    <Input  {...{isOptionnal: true,  name: "location", text: "Location", placeholder: "Location", value: formData.location, handleChange: handleChange }} />
                    <Input  {...{isOptionnal: true,  name: "description", text: "Description", placeholder: "Description", value: formData.description, handleChange: handleChange }} />

                   
                   
                    


                </div>

            }
            


            <div className="sticky bottom-0 px-5 py-4 bg-white dark:bg-slate-800 border-t border-slate-200 dark:border-slate-700">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation();  setCreateModalOpen(false)
}}>Close</button>
                    <button onClick={handleValid}
                        className="btn bg-[#f11a7b] hover:bg-pink-500 text-white">Save</button>
                </div>
            </div>
        </>
    )
}

export default FormCompany