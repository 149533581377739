import React from 'react'
import { Link } from 'react-router-dom'

const ProgressBar = ({step, setStep}) => {

    const isSelected  = "flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-pink-500 text-white"
    const isNotSelected = "flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
    return (
        <div className="px-4 pt-12 pb-8">
            <div className="max-w-md mx-auto w-full">
                <div className="relative">
                    <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700" aria-hidden="true"></div>
                    <ul className="relative flex justify-between w-full">
                        <li>
                            <Link className={(step == 1)?isSelected:isNotSelected} >1</Link>
                        </li>
                        <li>
                            <Link className={(step == 2)?isSelected:isNotSelected} >2</Link>
                        </li>
                        <li>
                            <Link className={(step == 3)?isSelected:isNotSelected}>3</Link>
                        </li>
                        <li>
                            <Link className={(step == 4)?isSelected:isNotSelected} >4</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar