import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/company/office-building.png'
import moment from 'moment';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { vacancyFavourite, vacancyUnfavourite } from '../../redux/actions/jobposting';
function JobListItem(props) {
    const dispatch= useDispatch()
    const {auth} = useSelector(state=>state);
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(`${process.env.REACT_APP_URL_BACKEND}/file-service/api/v1/file/company/${props.job.companyId}`);

    const [isError, setIsError] = useState(false);

    useEffect(() => {
      const checkImage = async () => {
        try {
          const response = await fetch(imageUrl);
          if (!response.ok) {
            setIsError(true);
          }
        } catch (error) {
          setIsError(true);
        }
      };
  
      checkImage();
    }, [imageUrl]);

    
    const seeJob = () => {
      const smBreakpoint = 768;
      const screenWidth = window.innerWidth;
  
      if (screenWidth < smBreakpoint) {
        // Action for screens smaller than 'sm'
        console.log('Action for smaller screens');
        //navigate('/job/job-listing/' + props.job.id, { state: { job: props.job } });
         props.setJob({...props.job})
        props.setCreateModalJob(true)
        // Place your action for small screens here
      } else {
        // Action for screens 'sm' and above
        props.setJob({...props.job})
        console.log('Action for larger screens');
        // Place your action for large screens here
      }
    }

  return (
    <div onClick={()=>seeJob()}
    className={`border px-5 py-4 hover:cursor-pointer  overflow-hidden
    ${props.isSelected 
      ? '!bg-pink-50 !border-l-4  rounded-2xl  !border-pink-500 !border-0'
      : 'hover:bg-pink-50 hover:rounded-2xl hover:border-0 hover:border-l-4 hover:border-pink-500'
    } bg-white dark:bg-slate-800 border-0 border-b border-slate-300`}
    >
      <div className="flex justify-between items-center  md:space-y-0 space-x-2">
        {/* Left side */}
        <div className="flex items-center space-x-3 md:space-x-4">
          <div className="w-16 h-16  rounded-md overflow-hidden shrink-0 mt-1 hover:cursor-pointer" >
            <img  className="w-16 h-16" src={isError?logo: imageUrl}  />
          </div>
          
          <div>
            <Link className="inline-flex  font-bold text-slate-900 dark:text-slate-100" /* to={`/job/job-post/${props.id}`} */>
              {props.job.title}
            </Link>
            <div className="text-sm uppercase">{props.job && props.job.companyName}</div>
{/*             <div className="text-sm font-semibold">{props.minSalary && props.minSalary} EUR</div>
 */}
{/*             <div className="text-sm text-slate-500 dark:text-slate-400 italic whitespace-nowrap">{moment(props.date).fromNow()}</div>
 */}
{/*             <div className="text-sm">{props.tags } react / node</div>
 */}          </div>
        </div>
        {/* Right side */}
{/* {  props.isSaved &&      <div className="flex items-center space-x-4 pl-10 md:pl-0">
           <button className={'text-amber-500'}>
            <span className="sr-only">Bookmark</span>
            <svg className="w-3 h-4 fill-current" width="12" height="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 0C.9 0 0 .9 0 2v14l6-3 6 3V2c0-1.1-.9-2-2-2H2Z" />
            </svg>
          </button>
        </div>} */}

        <div className='flex flex-col gap-2'>

       

        {
          props.job.jobRequestView && 
          <svg width="25" height="25" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f11a7b" d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
          </svg>
        }
                {
          !props.job.isSaved &&

      <svg  onClick={(e)=>{e.stopPropagation();dispatch(vacancyFavourite({data:{vacancyId: props.job.id},token: auth.userToken}))}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
      <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3Zm1 17.27l-4.5-2.6a1 1 0 0 0-1 0L7 19.27V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1Z"/>
  </svg>
        }

{
          props.job.isSaved &&
         
/*       <svg onClick={(e)=>{e.stopPropagation(); dispatch(vacancyUnfavourite({data:{vacancyId: props.job.id},token: auth.userToken}))}}  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 17 16">
      <path fill="currentColor" fill-rule="evenodd" d="M12.958 1.03a4.015 4.015 0 0 0-3.911 3.148A4.054 4.054 0 0 0 5.102 1.03c-2.237 0-4.05 1.824-4.05 4.072c0 6.496 8.005 9.838 8.005 9.838s7.912-3.258 7.912-9.879c0-2.228-1.795-4.031-4.011-4.031z"/>
  </svg> */

<svg onClick={(e)=>{e.stopPropagation(); dispatch(vacancyUnfavourite({data:{vacancyId: props.job.id},token: auth.userToken}))}}  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 22a1 1 0 0 1-.5-.134L12 18.694l-5.5 3.172A1 1 0 0 1 5 21V5a3.003 3.003 0 0 1 3-3h8a3.003 3.003 0 0 1 3 3v16a1 1 0 0 1-1 1Z"/>
</svg>
        }

</div>
      </div>
    </div>
  );
}

export default JobListItem;