import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/company/office-building.png'
import moment from 'moment';
import { getResume, getResumeWithoutOwning } from '../../redux/actions/resumes';
import { useDispatch, useSelector } from 'react-redux';
function ResumeListItem(props) {
    const navigate= useNavigate()
    const {auth} = useSelector(state => state);
    const dispatch = useDispatch();
  return (
    <div    onClick={()=> {props.setSelected(props.id); dispatch(getResumeWithoutOwning({id: props.id, token: auth.userToken}))}}

      className={`shadow-lg !rounded-xl overflow-hidden border px-5 py-4
      ${props.isSelected && ' border-pink-400'}   
      ${!props.isSelected && ' border-slate-200 dark:border-slate-700'}   

      ${
         'bg-white dark:bg-slate-800 '
      }`}
    >
      <div className="md:flex justify-between items-center space-y-4 md:space-y-0 space-x-2">
        {/* Left side */}
        <div className="flex items-start space-x-3 md:space-x-4">
          <div className="w-9 h-9 shrink-0 mt-1 hover:cursor-pointer">
            <img  className="w-9 h-9  rounded-full" src={props.image?props.image:logo} width="36" height="36" />
          </div>
          <div>
            <Link className="inline-flex font-semibold text-slate-800 dark:text-slate-100" >
              {props.fullname?props.fullname:"Name not filled"}
            </Link>
            <div className="text-sm">{props.jobtitle?props.jobtitle:'Job not filled'}</div>
            <div className="text-sm">{props.localisation?props.localisation:'Localisation not filled'}</div>
          </div>
        </div>
        {/* Right side */}
        <div className="flex items-center space-x-4 pl-10 md:pl-0">
       
          <button className={`${props.fav ? 'text-amber-500' : 'text-slate-300 dark:text-slate-600 hover:text-slate-400 dark:hover:text-slate-500'}`}>
            <span className="sr-only">Bookmark</span>
            <svg className="w-3 h-4 fill-current" width="12" height="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 0C.9 0 0 .9 0 2v14l6-3 6 3V2c0-1.1-.9-2-2-2H2Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResumeListItem;