import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

function SettingsSidebar() {

  const location = useLocation();
  const { pathname } = location;
  const {auth} = useSelector(state=>state)

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">Settings</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/employers/account" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/employers/account') && 'bg-pink-50 dark:bg-[#f11a7b]/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/employers/account') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/employers/account') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>My Account</span>
            </NavLink>
          </li>
        </ul>
{(auth.userInfo && auth.userInfo.role[0].includes('FULL'))   &&      <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/employers/company/account" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/employers/company/account') && 'bg-pink-50 dark:bg-[#f11a7b]/30'}`}>
         
              <svg xmlns="http://www.w3.org/2000/svg"  className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/employers/company/account') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
    <path fill="currentColor" d="M14 15V4h-3V1H2v14H0v1h7v-3h2v3h7v-1h-2zm-8-4H4V9h2v2zm0-3H4V6h2v2zm0-3H4V3h2v2zm3 6H7V9h2v2zm0-3H7V6h2v2zm0-3H7V3h2v2zm4 6h-2V9h2v2zm0-3h-2V6h2v2z"/>
</svg>
              <span className={`text-sm font-medium ${pathname.includes('/employers/company/account') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Company Account</span>
            </NavLink>
          </li>
        </ul>}
      </div>
      
      {/* Group 2 */}
{/*       <div>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">Experience</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/feedback" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/feedback') && 'bg-pink-50 dark:bg-[#f11a7b]/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/feedback') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/feedback') ? 'text-pink-500 dark:text-pink-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Give Feedback</span>
            </NavLink>
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default SettingsSidebar;