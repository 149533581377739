import React from 'react'
import cv from '../../../images/landing/features/cv1.png'
import resume from '../../../images/landing/features/resume.png'
import resume2 from '../../../images/landing/features/resume2.png'


const ManageCV = () => {
  return (
    <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-20 border-t border-gray-800">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Pursue Goals with Purpose</div>
          <h1 className="h2 mb-4">Your Resume, Illuminated by Blockchain</h1>
          <p className="text-xl text-gray-400">Elevate your career narrative with unmatched trust. In an era of authenticity, let every achievement shine brighter, backed by the unbreakable seal of blockchain.</p>
        </div>

        {/* Items */}
        <div className="grid gap-20">

          {/* 1st item */}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
              <img className="max-w-full mx-auto md:max-w-none h-auto" src={cv} width={540} height={405} alt="Features 01" />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <div className="font-architects-daughter text-xl text-pink-500 mb-2">Multiple Resumes, One Dashboard</div>
                <h3 className="h3 mb-3">Faster Creation. Efficient Management.</h3>
                <p className="text-xl text-gray-400 mb-4">Craft diverse CVs tailored to every opportunity, all while managing them seamlessly from a single, intuitive platform. Dive into a world where showcasing your versatility is as easy as a few clicks.</p>
                <ul className="text-lg text-gray-400 -mb-2">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Manage multiple CV in the same time</span>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          {/* 2nd item */}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl" data-aos="fade-up">
              <img className="max-w-full mx-auto md:max-w-none h-auto" src={resume2} width={540} height={405} alt="Features 02" />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
              <div className="md:pl-4 lg:pl-12 xl:pl-16">
                <div className="font-architects-daughter text-xl text-pink-500 mb-2">Resume Building, Redefined</div>
                <h3 className="h3 mb-3"> All-inclusive Inputs. Blockchain-backed Trust.</h3>
                <p className="text-xl text-gray-400 mb-4">Design your resume with depth, adding work experiences, education, skills, projects, and beyond. With the added assurance of certifying your experiences, ensure every chapter of your professional journey stands out with undeniable credibility.</p>
                <ul className="text-lg text-gray-400 -mb-2">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Many inputs available (education, work experience, skills...)</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Different level of certification (none, external, and by Evidenta)</span>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          {/* 3rd item */}
          <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
            {/* Image */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
              <img className="max-w-full mx-auto md:max-w-none h-auto" src={resume} width={540} height={405} alt="Features 03" />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <div className="font-architects-daughter text-xl text-pink-500 mb-2">Share with Ease, Shine with Authenticity</div>
                <h3 className="h3 mb-3">Seamless Sharing. Instant Verification.</h3>
                <p className="text-xl text-gray-400 mb-4">Whether you're downloading your CV as a PDF or sharing a direct link, your professional story is always at your fingertips. And for those receiving your link? A single click unveils your journey, complete with the power of one-click experience verification. Your credentials, effortlessly showcased and undeniably verified.</p>
                <ul className="text-lg text-gray-400 -mb-2">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Download the resume in pdf</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Share the resume with an URL</span>
                  </li>

                </ul>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
  )
}

export default ManageCV