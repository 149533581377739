import { createSlice } from '@reduxjs/toolkit'

export const filtersSclice = createSlice({
  name: 'filters',
  initialState: {
    filters: {
      page: 0,
      size: 10,
    }
  },
  reducers: {

    updateFilters: (state, action) => {
        state.filters = {...state.filters, ...action.payload};


      },
  }
})

// Action creators are generated for each case reducer function
export const { updateFilters } = filtersSclice.actions

export default filtersSclice.reducer