import React from 'react';
import logo from '../../../images/company.png'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DBCompanySettingsCard() {

  const {companies} = useSelector(state=>state)
  const navigate = useNavigate();

  return (
    <div  onClick={()=>navigate('/employers/company/account')} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 hover:cursor-pointer hover:bg-pink-100 bg-white dark:bg-slate-800 shadow-lg rounded-2xl border border-slate-200 dark:border-slate-700">
      <div className="px-5 py-5">
        <header className="flex justify-between items-start mb-4">
          {/* Icon */}


        <img src={companies?.companyInfo && companies?.companyInfo?.file !== 'null'? `${process.env.REACT_APP_URL_BACKEND}/file-service/${companies.companyInfo.file}` : logo} alt={"logo company"} className='bg-white rounded-full ' style={{width:"60px"}}/>
        </header>
        <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">Company settings</h2>
        <div className="text-sm font-normal text-slate-400 dark:text-slate-500  mb-2">Simplify company management by accessing our Company Settings, where you can effortlessly handle user accounts, update company information, and more</div>
    
      </div>

    </div>
  );
}

export default DBCompanySettingsCard;
