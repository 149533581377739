// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { alertAction } from '../reducers/alertSlice'
import { jsonToFormData } from '../../utils/Utils'

const backendURL = process.env.REACT_APP_URL_BACKEND
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
});

/* axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    console.log(error)
    if ((error.response.status === 403 || error.response.status === 401 )&& !originalRequest._retry) {
      originalRequest._retry = true;
      try{


        return Promise.reject(error)
        //return axiosInstance(originalRequest); // Retry the original request
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } 
    }
    return Promise.reject(error);
  }
);
 */


export const createCompany = createAsyncThunk(
  'company/create',
  async ({ formData , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,

        },
      }
      const { data } = await axios.post(
        `${backendURL}/company-management/`,
        {...formData},
        config
      )

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getMyCompany= createAsyncThunk(
  'company/my',
  async ({ token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Auth ' + token 
        },
        
      }
      let res= await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/company-service/api/v1/company/my`,

        config
      )
      console.log(res)
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data)


    }
  }
)

export const getCompaniesByAdmins = createAsyncThunk(
  'companiesByAdmin/get',
  async ({userId,token}, { rejectWithValue }) => {
    try {
      console.log(token)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axios.get(
        `${backendURL}/company-management/companiesByAdminId/${userId}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCompanyById = createAsyncThunk(
  'company/get',
  async ({id,token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axios.get(
        `${backendURL}/company-management/company/${id}`,
        config
      )
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const deleteCompany = createAsyncThunk(
  'company/delete',
  async ({id, token}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      }
      const { data } = await axios.delete(
        `${backendURL}/company-management/${id}`,
        config
      )
      return {_id: id}
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const createCompanyNew = createAsyncThunk(
  'resume/create',
  async ({ datas , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' +token,

        },
      }

      console.log("datas")
      console.log(datas)

      let dataFormat = jsonToFormData(datas);
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_URL_BACKEND}/company-service/api/v1/company`,
        dataFormat,
        config
      )


      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const updateCompanyNew = createAsyncThunk(
  'resume/create',
  async ({ datas , token}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Authorization': 'Auth ' +token,

        },
      }
      console.log("datas")
      console.log(datas)
      let dataFormat = jsonToFormData(datas);
      console.log(dataFormat)

      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_URL_BACKEND}/company-service/api/v1/company`,
        dataFormat,
        config
      )


      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

