// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { refreshToken, registerUser, registerUserCompany, userChangePassword, userLogin } from '../actions/auth'

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken: localStorage.getItem('userToken'),
  userInfo: ("[object Object]"==localStorage.getItem('user'))?null:JSON.parse(localStorage.getItem('user')),
  type: '',

  error: null,
  success: false,
  res: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogout: (state) => {
      // Clear the user data from the local storage and reset the state
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
      localStorage.removeItem('firstLogin'); 
      localStorage.removeItem('userInfo'); 
      localStorage.removeItem('type'); 

      state.loading = false;
      state.userToken = null;
      state.userInfo = null;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
          },
          [userLogin.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userInfo = payload
            state.userToken = payload.jwtToken
            state.type = payload.type
          },
          [userLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
          },

          [refreshToken.pending]: (state) => {
            state.loading = true
            state.error = null
          },
          [refreshToken.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userInfo = payload
            state.userToken = payload.jwtToken
            state.type = payload.role
            state.role = payload.role

          },
          [refreshToken.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
          },

    // register user
    [registerUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    // register user
    [registerUserCompany.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerUserCompany.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
    },
    [registerUserCompany.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    [userChangePassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userChangePassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
    },
    [userChangePassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload.msg
    },
  },
})

export const { userLogout } = authSlice.actions;

export default authSlice.reducer