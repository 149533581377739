import React, { useEffect, useState } from 'react'
import Quixote from '../../pdfTest';
import { useSelector } from 'react-redux';
import { Document, Font, PDFViewer, Page, StyleSheet, Text, pdf } from '@react-pdf/renderer';
import PDF from './PDF';


Font.register({
  family: 'montserrat',
  fonts: [
      { src: '/fonts/Montserrat-Regular.ttf' },
      { src: '/fonts/Montserrat-Italic.ttf', fontStyle: 'italic' },
      { src: '/fonts/Montserrat-BoldItalic.ttf', fontWeight: 800, fontStyle: 'italic' },

      { src: '/fonts/Montserrat-SemiBold.ttf', fontWeight: 700 },
      { src: '/fonts/Montserrat-Bold.ttf', fontWeight: 800 },
      { src: '/fonts/Montserrat-Black.ttf', fontWeight: 900 },
  ]
});


const NewResumePdf = ({ pdfSrc, setPdfSrc, isDetailled = false , resumeData }) => {
    const {resume, auth} = useSelector(state=>state)
    const cv = resumeData?resumeData:resume.resumeInfo
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        const generatePdfDocument = async () => {
          try {
            const doc = <PDF resume={cv} isDetailled={isDetailled}/>;
            const asPdf = pdf([]); // empty array as initial pages
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            setPdfData(blob)
            const url = URL.createObjectURL(blob);
            console.log(url)
            setPdfSrc(url);
          } catch (error) {
            console.error('Error generating PDF', error);
          }
        };
    
        generatePdfDocument();
    
    
     
    
        return () => {
          if (pdfSrc) {
            // Clean up object URL
            URL.revokeObjectURL(pdfSrc);
          }
        };
      }, [resume]);

    
  return (
    <>
    {pdfSrc ? (
      <iframe 
        src={`${pdfSrc}#toolbar=0&navpanes=0&scrollbar=0&zoom=scale`}
        style={{ 
          width: '100%', 
          height: '100%', 
          minHeight: '100vh',
          border: "none",
          overflow:'hidden',
          borderRadius: '18px'
        }}
        
      /> 

      

    ) : (
      'Generating PDF...'
    )}
  </>

  )
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default NewResumePdf