import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


const SearchBar = ({setCreateModalOpen}) => {

    const [selected, setSelected ] = useState("all")
    const {auth} = useSelector(state=>state)
 
    useEffect(() => {
        filterStatus(selected)
        
    }, [selected])

    const handleChange = (e) => {
   
    }
    
    const filterStatus = (status) =>{
   
    }
    return (
        <div className="flex flex-col gap-2 justify-center items-center px-4  mb-5 pt-3 pb-5 border-b-[2px] ">

            {/* Search form */}

            <div className='flex flex-col w-full md:mr-40 gap-2 '>
{/*                 <div className="flex w-full ">

                    <div className='d-flex w-full '>
                        <div className='d-flex w-full relative'>
                            <input onChange={(e)=>handleChange(e)} id="job-search" className="d-flex w-full form-input !rounded-lg focus:!border-[#f11a7b]  !py-3 flex-1 !pl-9 pr-9 dark:bg-slate-800" type="search" placeholder="Search job title or keyword…" />
                            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 absolute left-3 top-1/2 transform -translate-y-1/2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                        </div>
                    </div>

       

                </div> */}

                <div className='flex justify-end '>
{/*                 <div className='flex gap-2 self-start'>
                <Button onClick={() => setSelected('all')} className='flex items-center' size="large">
                        All
                    </Button>
              
                    <Button onClick={() => setSelected('accepted')} className='flex items-center border-green-500 text-green-500 hover:border-green-400 hover:text-green-400' size="large" >                   
                        On Going
                    </Button>
                    <Button  onClick={() => setSelected('refused')}  className='flex items-center' size="large" danger>                      
                        Finished
                    </Button>
               


                </div> */}

               {(auth.userInfo && !auth.userInfo.role[0].includes('PR')) && <button className="btn bg-pink-500 hover:bg-[#f11a7b] text-white"   onClick={(e) => { e.stopPropagation(); setCreateModalOpen(true); }} >
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add Job</span>
                </button>}

                </div>
            </div>




        </div>

    )
}

export default SearchBar