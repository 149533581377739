import React from 'react';
import { useSelector } from 'react-redux';

function WelcomeBanner() {
  const date = new Date();
  const {auth} = useSelector(state => state)
  const hours = date.getHours();
  let timeOfDay;
  let user = JSON.parse(localStorage.getItem("userInfo"))

  if (hours < 12) {
    timeOfDay = "Good morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "Good afternoon";
  } else {
    timeOfDay = "Good evening";
  }

  
  return (
    <div className="relative bg-pink-200 dark:bg-[#f11a7b] p-4 sm:p-6 rounded-2xl overflow-hidden mb-8">
      {/* Background illustration */}
   

      {/* Content */}
      <div className="relative">
        { auth.userInfo &&
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">{timeOfDay}, {user && user.fullname?user.fullname: ''}. </h1>

        }
        <p className="dark:text-pink-200">Your resumes, your control. Manage them all seamlessly on this page.</p>
      </div>
    </div>
  );
}

export default WelcomeBanner;
