import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, InputNumber, Checkbox } from 'antd';
import { EducationRequirement, JobType, Remote, WorkSchelule, contractType } from '../../../../utils/datas';

const Job3 = ({ formData, setFormData, step, setStep, isEdit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // Set form initial values

    form.setFieldsValue(formData);
  }, [formData, form]);

  useEffect(() => {
    if(isEdit){
      console.log("is edit")
      const dataSource = {
        ...formData,


        minYearsExperience: formData.minYearsExperience ===  0 ? null: formData.minYearsExperience,
        maxYearsExperience: formData.maxYearsExperience ===  1000 ? null:formData.maxYearsExperience

      };
      setFormData(dataSource)
    }
  }, [])
  

  const onFinish = (values) => {
    console.log(values);
  
 
    // Update formData state with the new values
    setFormData(currentFormData => {
      if(!values.inclusiveAccess){
        values.inclusiveAccess = false;
      }
      console.log(values)
      // Combine current form data with new values
      const updatedFormData = {...currentFormData, ...values};
      console.log(updatedFormData); // This will log the updated form data correctly
      return updatedFormData;
    });
  
    setStep(step + 1);
  };
  

  const validateYearsExperience = (_, value) => {
    const minYearsExperience = form.getFieldValue('minYearsExperience');
    const maxYearsExperience = form.getFieldValue('maxYearsExperience');

    // If both fields are filled and max is not greater than min, return a promise rejection
    if (minYearsExperience !==  (undefined || null) && maxYearsExperience !==  (undefined || null)) {
      if (maxYearsExperience <= minYearsExperience) {
        return Promise.reject(new Error('Maximum year must be greater than Minimum year'));
      }
    }

    return Promise.resolve();
  };

  const validateSalary = (_, value) => {
    const minSalary = form.getFieldValue('minSalary');
    const maxSalary = form.getFieldValue('maxSalary');

    // If both fields are filled and max is not greater than min, return a promise rejection
    if (minSalary !== (undefined || null) && maxSalary !==  (undefined || null)) {
      if (maxSalary <= minSalary) {
        return Promise.reject(new Error('Maximum salary must be greater than Minimum salary'));
      }
    }

    return Promise.resolve();
  };


  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
        >

          <Form.Item label="Year(s) of experience" style={{ marginBottom: 0 }}>
            <Form.Item
              name="minYearsExperience"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <InputNumber min={0} size="large" className='antd-input w-full' placeholder="Mininum year of experience" />
            </Form.Item>
            <Form.Item
              name="maxYearsExperience"
              rules={[
                { validator: validateYearsExperience }
              ]}
              style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
            >
              <InputNumber  min={0} size="large" className='antd-input w-full' placeholder="Maximum year of experience" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Salary" style={{ marginBottom: 0 }}>
            <Form.Item
              name="minSalary"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <InputNumber min={0} size="large" className='antd-input w-full' placeholder="Mininum salary" />
            </Form.Item>
            <Form.Item
              name="maxSalary"
              rules={[
                { validator: validateSalary }
              ]}
              style={{ display: 'inline-block', width: 'calc(50% )', margin: '0', marginLeft: "8px" }}
            >
              <InputNumber min={0} size="large" className='antd-input w-full' placeholder="Maximum salary" />
            </Form.Item>
          </Form.Item>

                    
          <Form.Item
            label="Required Skill(s)"
            name="keySkills"
            rules={[{ required: true, message: 'Please enter at least 1 skill!' }]}

          >
              <Select
              size='large'
                mode="tags"
                tokenSeparators={[',']}
              />
          </Form.Item>

          <Form.Item
            name="inclusiveAccess"
            valuePropName="checked" // This makes sure the Checkbox reflects the form state
          >
            <Checkbox>Inclusive Access</Checkbox>
          </Form.Item>


          <div className="flex items-center justify-end gap-2">
          <Button
              type="default"
              htmlType="submit"
              className="flex items-center gap-2"
              size="large"
              onClick={() => setStep(step-1)}
            >
              Previous Step
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#f11a7b] gap-2"
              size="large"
            >
              Next Step
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Job3;
