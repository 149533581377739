export const job_types = [
    "Administrative",
    "Accounting/Finance",
    "Human Resources",
    "Marketing",
    "Sales",
    "Information Technology (IT)",
    "Customer Service",
    "Healthcare",
    "Education",
    "Engineering",
    "Manufacturing/Production",
    "Retail",
    "Hospitality",
    "Legal",
    "Research and Development (R&D)",
    "Design",
    "Operations",
    "Project Management",
    "Logistics/Supply Chain",
    "Public Relations (PR)",
    "Media and Communications",
    "Social Services",
    "Non-Profit/Volunteer",
    "Real Estate",
    "Construction",
    "Environmental",
    "Science",
    "Arts and Entertainment",
    "Consulting",
    "Transportation"
]

export const salary_ranges = [
    "20K€ - 50K€",
    "50K€ - 100K€",
    "> 100K€"
]


export const businessTypes = [
    {"label": "Private Company", "value": "private_company"},
    {"label": "Public Company", "value": "public_company"},
    {"label": "Sole Proprietorship", "value": "sole_proprietorship"},
    {"label": "Partnership", "value": "partnership"},
    {"label": "Limited Liability Company (LLC)", "value": "llc"},
    {"label": "Corporation", "value": "corporation"},
    {"label": "Non-Profit Organization", "value": "non_profit"},
    {"label": "Cooperative (Co-op)", "value": "cooperative"},
    {"label": "Franchise", "value": "franchise"},
    {"label": "Social Enterprise", "value": "social_enterprise"},
    {"label": "Government-Owned Corporation", "value": "government_owned_corporation"}
  ]

export const companySizes = [
    {"label": "1-50", "value": "1-50"},
    {"label": "51-200", "value": "51-200"},
    {"label": "200-500", "value": "200-500"},
    {"label": "500-1000", "value": "500-1000"},
    {"label": "5000-10000", "value": "5000-10000"},
    {"label": "10000+", "value": "10_000+"}

  ];


export const sectors = [

        {"label": "Accounting", "value": "accounting"},
        {"label": "Aerospace & Aviation", "value": "aerospace_aviation"},
        {"label": "Agriculture", "value": "agriculture"},
        {"label": "Alternative Dispute Resolution", "value": "alternative_dispute_resolution"},
        {"label": "Alternative Medicine", "value": "alternative_medicine"},
        {"label": "Animation", "value": "animation"},
        {"label": "Apparel & Fashion", "value": "apparel_fashion"},
        {"label": "Apparel Retail", "value": "apparel_retail"},
        {"label": "Architecture", "value": "architecture"},
        {"label": "Arts & Crafts", "value": "arts_crafts"},
        {"label": "Arts and Crafts", "value": "arts_and_crafts"},
        {"label": "Automotive", "value": "automotive"},
        {"label": "Automotive Retail", "value": "automotive_retail"},
        {"label": "Banking", "value": "banking"},
        {"label": "Biotechnology", "value": "biotechnology"},
        {"label": "Broadcast Media", "value": "broadcast_media"},
        {"label": "Building Materials", "value": "building_materials"},
        {"label": "Business Supplies & Equipment", "value": "business_supplies_equipment"},
        {"label": "Capital Markets", "value": "capital_markets"},
        {"label": "Chemical Manufacturing", "value": "chemical_manufacturing"},
        {"label": "Chemicals", "value": "chemicals"},
        {"label": "Civil Engineering", "value": "civil_engineering"},
        {"label": "Commercial Real Estate", "value": "commercial_real_estate"},
        {"label": "Commercial Real Estate Services", "value": "commercial_real_estate_services"},
        {"label": "Computer & Network Security", "value": "computer_network_security"},
        {"label": "Computer Games", "value": "computer_games"},
        {"label": "Computer Hardware", "value": "computer_hardware"},
        {"label": "Computer Networking", "value": "computer_networking"},
        {"label": "Computer Software", "value": "computer_software"},
        {"label": "Construction", "value": "construction"},
        {"label": "Construction Services", "value": "construction_services"},
        {"label": "Consumer Electronics", "value": "consumer_electronics"},
        {"label": "Consumer Goods", "value": "consumer_goods"},
        {"label": "Consumer Services", "value": "consumer_services"},
        {"label": "Cosmetics", "value": "cosmetics"},
        {"label": "Dairy", "value": "dairy"},
        {"label": "Defense & Space", "value": "defense_space"},
        {"label": "Design", "value": "design"},
        {"label": "E-learning", "value": "e_learning"},
        {"label": "Education Management", "value": "education_management"},
        {"label": "Electrical & Electronic Manufacturing", "value": "electrical_electronic_manufacturing"},
        {"label": "Entertainment", "value": "entertainment"},
        {"label": "Environmental Services", "value": "environmental_services"},
        {"label": "Events", "value": "events"},
        {"label": "Events Services", "value": "events_services"},
        {"label": "Executive Office", "value": "executive_office"},
        {"label": "Facilities Services", "value": "facilities_services"},
        {"label": "Farming", "value": "farming"},
        {"label": "Financial Services", "value": "financial_services"},
        {"label": "Fine Art", "value": "fine_art"},
        {"label": "Fishery", "value": "fishery"},
        {"label": "Food & Beverage Production", "value": "food_beverage_production"},
        {"label": "Food Production", "value": "food_production"},
        {"label": "Fundraising", "value": "fundraising"},
        {"label": "Furniture", "value": "furniture"},
        {"label": "Gambling & Casinos", "value": "gambling_casinos"},
        {"label": "Glass, Ceramics, & Concrete Manufacturing", "value": "glass_ceramics_concrete_manufacturing"},
        {"label": "Government Administration", "value": "government_administration"},
        {"label": "Graphic Design", "value": "graphic_design"},
        {"label": "Health, Wellness & Fitness", "value": "health_wellness_fitness"},
        {"label": "Higher Education", "value": "higher_education"},
        {"label": "Hospital & Health Care", "value": "hospital_health_care"},
        {"label": "Hospitality", "value": "hospitality"},
        {"label": "Human Resources", "value": "human_resources"},
        {"label": "Import & Export", "value": "import_export"},
        {"label": "Individual & Family Services", "value": "individual_family_services"},
        {"label": "Industrial Automation", "value": "industrial_automation"},
        {"label": "Information Services", "value": "information_services"},
        {"label": "Information Technology & Services", "value": "information_technology_services"},
        {"label": "Insurance", "value": "insurance"},
        {"label": "International Affairs", "value": "international_affairs"},
        {"label": "International Trade & Development", "value": "international_trade_development"},
        {"label": "Internet", "value": "internet"},
        {"label": "Investment Banking", "value": "investment_banking"},
        {"label": "Investment Management", "value": "investment_management"},
        {"label": "Judiciary", "value": "judiciary"},
        {"label": "Law Enforcement", "value": "law_enforcement"},
        {"label": "Law Practice", "value": "law_practice"},
        {"label": "Legal Services", "value": "legal_services"},
        {"label": "Legislative Offices", "value": "legislative_offices"},
        {"label": "Libraries", "value": "libraries"},
        {"label": "Logistics & Supply Chain", "value": "logistics_supply_chain"},
        {"label": "Luxury Goods & Jewelry", "value": "luxury_goods_jewelry"},
        {"label": "Machinery", "value": "machinery"},
        {"label": "Management Consulting", "value": "management_consulting"},
        {"label": "Manufacturing", "value": "manufacturing"},
        {"label": "Maritime", "value": "maritime"},
        {"label": "Market Research", "value": "market_research"},
        {"label": "Mechanical or Industrial Engineering", "value": "mechanical_industrial_engineering"},
        {"label": "Media Production", "value": "media_production"},
        {"label": "Medical Device", "value": "medical_device"},
        {"label": "Medical Practice", "value": "medical_practice"},
        {"label": "Mental Health Care", "value": "mental_health_care"},
        {"label": "Military", "value": "military"},
        {"label": "Mining & Metals", "value": "mining_metals"},
        {"label": "Mobile Games", "value": "mobile_games"},
        {"label": "Motion Pictures & Film", "value": "motion_pictures_film"},
        {"label": "Museums", "value": "museums"},
        {"label": "Music", "value": "music"},
        {"label": "Nanotechnology", "value": "nanotechnology"},
        {"label": "Newspapers", "value": "newspapers"},
        {"label": "Non-profit Organization Management", "value": "non_profit_organization_management"},
        {"label": "Oil & Energy", "value": "oil_energy"},
        {"label": "Online Media", "value": "online_media"},
        {"label": "Outsourcing", "value": "outsourcing"},
        {"label": "Packaging", "value": "packaging"},
        {"label": "Paper & Forest Products", "value": "paper_forest_products"},
        {"label": "Performing Arts", "value": "performing_arts"},
        {"label": "Pharmaceuticals", "value": "pharmaceuticals"},
        {"label": "Philanthropy", "value": "philanthropy"},
        {"label": "Photography", "value": "photography"},
        {"label": "Plastics Manufacturing", "value": "plastics_manufacturing"},
        {"label": "Political Organizations", "value": "political_organizations"},
        {"label": "Primary/Secondary Education", "value": "primary_secondary_education"},
        {"label": "Printing", "value": "printing"},
        {"label": "Professional Training & Coaching", "value": "professional_training_coaching"},
        {"label": "Program Development", "value": "program_development"},
        {"label": "Public Policy", "value": "public_policy"},
        {"label": "Public Relations & Communications", "value": "public_relations_communications"},
        {"label": "Public Safety", "value": "public_safety"},
        {"label": "Publishing", "value": "publishing"},
        {"label": "Railroad Manufacturing", "value": "railroad_manufacturing"},
        {"label": "Ranching", "value": "ranching"},
        {"label": "Real Estate", "value": "real_estate"},
        {"label": "Recreational Facilities & Services", "value": "recreational_facilities_services"},
        {"label": "Religious Institutions", "value": "religious_institutions"},
        {"label": "Renewables & Environment", "value": "renewables_environment"},
        {"label": "Research", "value": "research"},
        {"label": "Restaurants", "value": "restaurants"},
        {"label": "Retail", "value": "retail"},
        {"label": "Security Services", "value": "security_services"},
        {"label": "Semiconductors", "value": "semiconductors"},
        {"label": "Shipbuilding", "value": "shipbuilding"},
        {"label": "Sporting Goods Retail", "value": "sporting_goods_retail"},
        {"label": "Sports", "value": "sports"},
        {"label": "Staffing & Recruiting", "value": "staffing_recruiting"},
        {"label": "Supermarkets", "value": "supermarkets"},
        {"label": "Telecommunications", "value": "telecommunications"},
        {"label": "Textiles Manufacturing", "value": "textiles_manufacturing"},
        {"label": "Think Tanks", "value": "think_tanks"},
        {"label": "Tobacco", "value": "tobacco"},
        {"label": "Translation & Localization", "value": "translation_localization"},
        {"label": "Transportation/Trucking", "value": "transportation_trucking"},
        {"label": "Utilities", "value": "utilities"},
        {"label": "Venture Capital & Private Equity", "value": "venture_capital_private_equity"},
        {"label": "Veterinary", "value": "veterinary"},
        {"label": "Warehousing", "value": "warehousing"},
        {"label": "Wholesale", "value": "wholesale"},
        {"label": "Wine & Spirits", "value": "wine_spirits"},
        {"label": "Wireless", "value": "wireless"},
        {"label": "Writing & Editing", "value": "writing_editing"}
      
  ]
  

  export const contractType = [
    { "label": "Full-Time", "value": "full_time" },
    { "label": "Part-Time", "value": "part_time" },
    { "label": "Contract", "value": "contract" },
    { "label": "Temporary", "value": "temporary" },
    { "label": "Internship", "value": "internship" },
    { "label": "Freelance", "value": "freelance" },
    { "label": "Remote", "value": "remote" },
    { "label": "Volunteer", "value": "volunteer" }
  ]
  
  export const remoteDatas = [
    { "label": "Unknown", "value": "unknown" },
    { "label": "Occasionally", "value": "occasionally" },
    { "label": "Frequently", "value": "frequently" },
    { "label": "Fully Remote", "value": "fully_remote" },
    { "label": "On-Site Only", "value": "onsite_only" }
  ]
  

  export const contractDurations = [
    { "label": "1-3 months", "value": "1-3_months" },
    { "label": "3-6 months", "value": "3-6_months" },
    { "label": "6-12 months", "value": "6-12_months" },
    { "label": "More than 12 months", "value": "more_than_12_months" },
    { "label": "Open-ended", "value": "open_ended" }
  ]
  

  export const experiencesDatas = [
    { "label": "Not Specified", "value": "not_specified" },
    { "label": "Entry Level", "value": "entry_level" },
    { "label": "Intermediate Level", "value": "intermediate_level" },
    { "label": "Experienced", "value": "experienced" },
    { "label": "Senior Level", "value": "senior_level" },
    { "label": "Executive Level", "value": "executive_level" }
  ]

  export const educationsDatas =[
    { "label": "Not Specified", "value": "not_specified" },
    { "label": "High School or Equivalent", "value": "high_school_or_equivalent" },
    { "label": "Associate's Degree", "value": "associates_degree" },
    { "label": "Bachelor's Degree", "value": "bachelors_degree" },
    { "label": "Master's Degree", "value": "masters_degree" },
    { "label": "Doctorate or Professional Degree", "value": "doctorate_or_professional_degree" }
  ]
  

  export const JobType = [
    { "label": "Permanent", "value": "PERMANENT" },
    { "label": "Internship", "value": "INTERNSHIP" },
    { "label": "Contract", "value": "CONTRACT" },
    { "label": "Temporary", "value": "TEMPORARY" },
    { "label": "Temporary to hire", "value": "TEMP_TO_HIRE" },
    { "label": "Seasonal", "value": "SEASONAL" },
  ]

  export const Remote = [
    { "label": "Full Remote", "value": "FULL_REMOTE" },
    { "label": "Hybrid", "value": "HYBRID" },
    { "label": "No Remote", "value": "NON_REMOTE" },
  ]

  export const Status = [
    { "label": "Viewed", "value": "VIEWED" },
    { "label": "Not Viewed", "value": "NOT_VIEWED" },
    { "label": "Rejected", "value": "REJECTED" },
    { "label": "Invited", "value": "INVITED" },
    { "label": "Deleted", "value": "DELETED" },

  ]

  export const EducationRequirement = [
    { "label": "Not required or specified", "value": "NOT_REQUIRED_OR_NOT_SPECIFIED" },
    { "label": "Bachelor Degree", "value": "BACHELOR_DEGREE" },
    { "label": "Master Degree", "value": "MASTER_DEGREE" },
    { "label": "Doctoral Degree", "value": "DOCTORAL_DEGREE" },
    { "label": "Associate Degree", "value": "ASSOCIATE_DEGREE" },
  ]

  export const WorkSchelule = [
    { "label": "Full Time", "value": "FULL_TIME" },
    { "label": "Part Time", "value": "PART_TIME" },
    { "label": "Shift Schedule", "value": "SHIFT_SCHEDULE" },
    { "label": "Flexible Schedule", "value": "FLEXIBLE_SCHEDULE" },
    { "label": "Split Shifts", "value": "SPLIT_SHIFTS" },
    { "label": "Rotating Shift", "value": "ROTATING_SHIFT" },
    { "label": "Overtime", "value": "OVERTIME" },
    { "label": "Compressed Schedule", "value": "COMPRESSED_SCHEDULE" },
    { "label": "Freelance Schedule", "value": "FREELANCE_SCHEDULE" },

  ]




  export const CompanySizes = [
    { "label": "1-50", "value": "ONE_TO_FIFTY" },
    { "label": "51-200", "value": "FIFTY_ONE_TO_TWO_HUNDRED" },
    { "label": "201-500", "value": "TWO_HUNDRED_ONE_TO_FIVE_HUNDRED" },
    { "label": "501-1000", "value": "FIVE_HUNDRED_ONE_TO_THOUSAND" },
    { "label": "1001-5000", "value": "THOUSAND_ONE_TO_FIVE_THOUSAND" },
    { "label": "5001-10000", "value": "FIVE_THOUSAND_TO_TEN_THOUSAND" },
    { "label": "10_000+", "value": "THOUSAND_PLUS" },
  ]

export const Industries = [
  {"label": "Accounting", "value": "accounting"},
  {"label": "Aerospace & Aviation", "value": "aerospace_aviation"},
  {"label": "Agriculture", "value": "agriculture"},
  {"label": "Alternative Dispute Resolution", "value": "alternative_dispute_resolution"},
  {"label": "Alternative Medicine", "value": "alternative_medicine"},
  {"label": "Animation", "value": "animation"},
  {"label": "Apparel & Fashion", "value": "apparel_fashion"},
  {"label": "Apparel Retail", "value": "apparel_retail"},
  {"label": "Architecture", "value": "architecture"},
  {"label": "Arts & Crafts", "value": "arts_crafts"},
  {"label": "Arts and Crafts", "value": "arts_and_crafts"},
  {"label": "Automotive", "value": "automotive"},
  {"label": "Automotive Retail", "value": "automotive_retail"},
  {"label": "Banking", "value": "banking"},
  {"label": "Biotechnology", "value": "biotechnology"},
  {"label": "Broadcast Media", "value": "broadcast_media"},
  {"label": "Building Materials", "value": "building_materials"},
  {"label": "Business Supplies & Equipment", "value": "business_supplies_equipment"},
  {"label": "Capital Markets", "value": "capital_markets"},
  {"label": "Chemical Manufacturing", "value": "chemical_manufacturing"},
  {"label": "Chemicals", "value": "chemicals"},
  {"label": "Civil Engineering", "value": "civil_engineering"},
  {"label": "Commercial Real Estate", "value": "commercial_real_estate"},
  {"label": "Commercial Real Estate Services", "value": "commercial_real_estate_services"},
  {"label": "Computer & Network Security", "value": "computer_network_security"},
  {"label": "Computer Games", "value": "computer_games"},
  {"label": "Computer Hardware", "value": "computer_hardware"},
  {"label": "Computer Networking", "value": "computer_networking"},
  {"label": "Computer Software", "value": "computer_software"},
  {"label": "Construction", "value": "construction"},
  {"label": "Construction Services", "value": "construction_services"},
  {"label": "Consumer Electronics", "value": "consumer_electronics"},
  {"label": "Consumer Goods", "value": "consumer_goods"},
  {"label": "Consumer Services", "value": "consumer_services"},
  {"label": "Cosmetics", "value": "cosmetics"},
  {"label": "Dairy", "value": "dairy"},
  {"label": "Defense & Space", "value": "defense_space"},
  {"label": "Design", "value": "design"},
  {"label": "E-learning", "value": "e_learning"},
  {"label": "Education Management", "value": "education_management"},
  {"label": "Electrical & Electronic Manufacturing", "value": "electrical_electronic_manufacturing"},
  {"label": "Entertainment", "value": "entertainment"},
  {"label": "Environmental Services", "value": "environmental_services"},
  {"label": "Events", "value": "events"},
  {"label": "Events Services", "value": "events_services"},
  {"label": "Executive Office", "value": "executive_office"},
  {"label": "Facilities Services", "value": "facilities_services"},
  {"label": "Farming", "value": "farming"},
  {"label": "Financial Services", "value": "financial_services"},
  {"label": "Fine Art", "value": "fine_art"},
  {"label": "Fishery", "value": "fishery"},
  {"label": "Food & Beverage Production", "value": "food_beverage_production"},
  {"label": "Food Production", "value": "food_production"},
  {"label": "Fundraising", "value": "fundraising"},
  {"label": "Furniture", "value": "furniture"},
  {"label": "Gambling & Casinos", "value": "gambling_casinos"},
  {"label": "Glass, Ceramics, & Concrete Manufacturing", "value": "glass_ceramics_concrete_manufacturing"},
  {"label": "Government Administration", "value": "government_administration"},
  {"label": "Graphic Design", "value": "graphic_design"},
  {"label": "Health, Wellness & Fitness", "value": "health_wellness_fitness"},
  {"label": "Higher Education", "value": "higher_education"},
  {"label": "Hospital & Health Care", "value": "hospital_health_care"},
  {"label": "Hospitality", "value": "hospitality"},
  {"label": "Human Resources", "value": "human_resources"},
  {"label": "Import & Export", "value": "import_export"},
  {"label": "Individual & Family Services", "value": "individual_family_services"},
  {"label": "Industrial Automation", "value": "industrial_automation"},
  {"label": "Information Services", "value": "information_services"},
  {"label": "Information Technology & Services", "value": "information_technology_services"},
  {"label": "Insurance", "value": "insurance"},
  {"label": "International Affairs", "value": "international_affairs"},
  {"label": "International Trade & Development", "value": "international_trade_development"},
  {"label": "Internet", "value": "internet"},
  {"label": "Investment Banking", "value": "investment_banking"},
  {"label": "Investment Management", "value": "investment_management"},
  {"label": "Judiciary", "value": "judiciary"},
  {"label": "Law Enforcement", "value": "law_enforcement"},
  {"label": "Law Practice", "value": "law_practice"},
  {"label": "Legal Services", "value": "legal_services"},
  {"label": "Legislative Offices", "value": "legislative_offices"},
  {"label": "Libraries", "value": "libraries"},
  {"label": "Logistics & Supply Chain", "value": "logistics_supply_chain"},
  {"label": "Luxury Goods & Jewelry", "value": "luxury_goods_jewelry"},
  {"label": "Machinery", "value": "machinery"},
  {"label": "Management Consulting", "value": "management_consulting"},
  {"label": "Manufacturing", "value": "manufacturing"},
  {"label": "Maritime", "value": "maritime"},
  {"label": "Market Research", "value": "market_research"},
  {"label": "Mechanical or Industrial Engineering", "value": "mechanical_industrial_engineering"},
  {"label": "Media Production", "value": "media_production"},
  {"label": "Medical Device", "value": "medical_device"},
  {"label": "Medical Practice", "value": "medical_practice"},
  {"label": "Mental Health Care", "value": "mental_health_care"},
  {"label": "Military", "value": "military"},
  {"label": "Mining & Metals", "value": "mining_metals"},
  {"label": "Mobile Games", "value": "mobile_games"},
  {"label": "Motion Pictures & Film", "value": "motion_pictures_film"},
  {"label": "Museums", "value": "museums"},
  {"label": "Music", "value": "music"},
  {"label": "Nanotechnology", "value": "nanotechnology"},
  {"label": "Newspapers", "value": "newspapers"},
  {"label": "Non-profit Organization Management", "value": "non_profit_organization_management"},
  {"label": "Oil & Energy", "value": "oil_energy"},
  {"label": "Online Media", "value": "online_media"},
  {"label": "Outsourcing", "value": "outsourcing"},
  {"label": "Packaging", "value": "packaging"},
  {"label": "Paper & Forest Products", "value": "paper_forest_products"},
  {"label": "Performing Arts", "value": "performing_arts"},
  {"label": "Pharmaceuticals", "value": "pharmaceuticals"},
  {"label": "Philanthropy", "value": "philanthropy"},
  {"label": "Photography", "value": "photography"},
  {"label": "Plastics Manufacturing", "value": "plastics_manufacturing"},
  {"label": "Political Organizations", "value": "political_organizations"},
  {"label": "Primary/Secondary Education", "value": "primary_secondary_education"},
  {"label": "Printing", "value": "printing"},
  {"label": "Professional Training & Coaching", "value": "professional_training_coaching"},
  {"label": "Program Development", "value": "program_development"},
  {"label": "Public Policy", "value": "public_policy"},
  {"label": "Public Relations & Communications", "value": "public_relations_communications"},
  {"label": "Public Safety", "value": "public_safety"},
  {"label": "Publishing", "value": "publishing"},
  {"label": "Railroad Manufacturing", "value": "railroad_manufacturing"},
  {"label": "Ranching", "value": "ranching"},
  {"label": "Real Estate", "value": "real_estate"},
  {"label": "Recreational Facilities & Services", "value": "recreational_facilities_services"},
  {"label": "Religious Institutions", "value": "religious_institutions"},
  {"label": "Renewables & Environment", "value": "renewables_environment"},
  {"label": "Research", "value": "research"},
  {"label": "Restaurants", "value": "restaurants"},
  {"label": "Retail", "value": "retail"},
  {"label": "Security Services", "value": "security_services"},
  {"label": "Semiconductors", "value": "semiconductors"},
  {"label": "Shipbuilding", "value": "shipbuilding"},
  {"label": "Sporting Goods Retail", "value": "sporting_goods_retail"},
  {"label": "Sports", "value": "sports"},
  {"label": "Staffing & Recruiting", "value": "staffing_recruiting"},
  {"label": "Supermarkets", "value": "supermarkets"},
  {"label": "Telecommunications", "value": "telecommunications"},
  {"label": "Textiles Manufacturing", "value": "textiles_manufacturing"},
  {"label": "Think Tanks", "value": "think_tanks"},
  {"label": "Tobacco", "value": "tobacco"},
  {"label": "Translation & Localization", "value": "translation_localization"},
  {"label": "Transportation/Trucking", "value": "transportation_trucking"},
  {"label": "Utilities", "value": "utilities"},
  {"label": "Venture Capital & Private Equity", "value": "venture_capital_private_equity"},
  {"label": "Veterinary", "value": "veterinary"},
  {"label": "Warehousing", "value": "warehousing"},
  {"label": "Wholesale", "value": "wholesale"},
  {"label": "Wine & Spirits", "value": "wine_spirits"},
  {"label": "Wireless", "value": "wireless"},
  {"label": "Writing & Editing", "value": "writing_editing"}


]
export const BusinessStructures = [
  { "label": "Private Company", "value": "private_company" },
  { "label": "Public Company", "value": "public_company" },
  { "label": "Sole Proprietorship", "value": "sole_proprietorship" },
  { "label": "Partnership", "value": "partnership" },
  { "label": "Limited Liability Company (LLC)", "value": "limited_liability_company" },
  { "label": "Corporation", "value": "corporation" },
  { "label": "Non-Profit Organization", "value": "non_profit_organization" }, // Corrected typo for consistency
  { "label": "Cooperative", "value": "cooperative" },
  { "label": "Franchise", "value": "franchise" },
  { "label": "Social Enterprise", "value": "social_enterprise" },
  { "label": "Government Owned Corporation", "value": "government_owned_corporation" },
];