import React, { useEffect, useState } from 'react'
import { EducationRequirement, JobType, Remote, Status, WorkSchelule } from '../../../utils/datas';
import { extractLocalityAndCountry, getLabelByValue, getValueByLabel } from '../../../utils/Utils';
import { Button, Modal, Tag } from 'antd';
import ModalBlankR from '../../Modals/ModalBlankR';
import FormJob from './Form/FormJob';
import ModalBlank from '../../Modals/ModalBlank';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteVacancyById } from '../../../redux/actions/jobposting';
import { useNavigate } from 'react-router-dom';

const JobView = ({job, setIsLoading}) => {

    const [createModalOpen,setCreateModalOpen] = useState(false);
    const [modalDeleteOpen,setModalDeleteOpen] = useState(false);  
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(job)

    const {auth} = useSelector(state=>state)
    const dispatch = useDispatch();
    const navigate = useNavigate();

  
    
    
    const edit = () => {
   
          setCreateModalOpen(true)
    }

    const deleteJobPosting = async () => {
        try {
          // Dispatch the async thunk
          await dispatch(deleteVacancyById({ id: job.id, token: auth.userToken })).unwrap();
          // If the promise resolves successfully, show success toast
          toast.success("Job deleted!");
        } catch (error) {
          // If the promise is rejected, handle the error
          console.error(error); // Useful for debugging
          toast.error(error.message || "An error occurred!");
        } finally {
          setModalDeleteOpen(false); // Adjust modal behavior as necessary
        }
      };
      
  return (
<div className='w-full overflow-y-auto py-4 md:px-4 overflow-x-hidden '>


<header className="mb-4 flex justify-between">
  <div className='flex flex-col gap-2'>
    <h1 className="text-2xl md:text-2xl text-slate-900 dark:text-slate-100 font-bold">{job.title}</h1>
    <h1 className="text-lg  text-slate-900 dark:text-slate-100 font-normal">{job.companyName}</h1>

  </div>

  <div className='ml-4 '>

    <span className='italic text-sm'>{getLabelByValue(Status, job.status)}</span>


  </div>



</header>

<div className="mb-6 flex flex-col gap-4">
  <div className="flex flex-wrap items-center -m-1 gap-5">

    {job.address && <span className='flex  text-[#f11a7b] font-semibold items-center'><svg width="20" height="20" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3m0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205" />
    </svg> {extractLocalityAndCountry(job.address)} </span>}

    <span className='flex  text-[#f11a7b] font-semibold items-center'><svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M10 3.578a.75.75 0 0 1 0-1.5h3.536a.75.75 0 0 1 0 1.5zm-3.47.452a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06-1.06l2.5-2.5a.75.75 0 0 1 1.06 0" />
      <path fill="currentColor" fillRule="evenodd" d="M12 5.06a8.5 8.5 0 1 0 0 17a8.5 8.5 0 0 0 0-17m4.99 3.711a.5.5 0 0 0-.7-.701l-3.175 2.468l-2.075 1.483a1.434 1.434 0 1 0 2 2l1.482-2.076z" clipRule="evenodd" />
    </svg> {getLabelByValue(WorkSchelule, job.workSchedule)}</span>

    {job.inclusiveAccess && <span className='flex  text-[#f11a7b] gap-1 font-semibold items-center'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M9 5a2 2 0 1 0 4 0a2 2 0 1 0-4 0" />
          <path d="M11 7v8h4l4 5m-8-9h5m-9 .5a5 5 0 1 0 6 7.5" />
        </g>
      </svg>

      Inclusive Access</span>}

  </div>

  <div className='flex gap-2'>
         <Button className='flex items-center bg-white text-[#f11a7b] border-[#f11a7b]'   onClick={(e)=>{navigate('/employers/job/'+job.id)}}   >
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none">
        <path fill="currentColor" d="M6.5 12.5a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Zm0 2.5a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Z"/>
        <path fill="currentColor" fill-rule="evenodd" d="M11.185 1H4.5A1.5 1.5 0 0 0 3 2.5v15A1.5 1.5 0 0 0 4.5 19h11a1.5 1.5 0 0 0 1.5-1.5V7.202a1.5 1.5 0 0 0-.395-1.014l-4.314-4.702A1.5 1.5 0 0 0 11.185 1ZM4 2.5a.5.5 0 0 1 .5-.5h6.685a.5.5 0 0 1 .369.162l4.314 4.702a.5.5 0 0 1 .132.338V17.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-15Z" clip-rule="evenodd"/>
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M11.5 2.1v4.7h4.7"/>
        <path fill="currentColor" d="M8.134 6.133a1.067 1.067 0 1 0 0-2.133a1.067 1.067 0 0 0 0 2.133Z"/>
        <path fill="currentColor" fill-rule="evenodd" d="M10.266 8.444c0-1.134-.955-1.955-2.133-1.955S6 7.309 6 8.444v.534a.356.356 0 0 0 .356.355h3.555a.356.356 0 0 0 .355-.355v-.534Z" clip-rule="evenodd"/>
    </g>
</svg>
        Applications    <div className='bg-red-600 flex justify-center items-center rounded-full p-0 text-white absolute top-[-10px] right-[-5px] w-5 h-5 font-bold'>{job.unreadRequest?job.unreadRequest: 0}</div>
        </Button> 
{ (auth.userInfo && !auth.userInfo.role[0].includes('PR')) &&       <Button className='flex items-center bg-white text-[#f11a7b] border-[#f11a7b] ' type='primary' onClick={() => edit()} >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
    <path fill="currentColor" d="M27.87 7.863L23.024 4.82l-7.89 12.566l4.843 3.04L27.87 7.863zM14.395 21.25l-.107 2.855l2.527-1.337l2.35-1.24l-4.673-2.936l-.097 2.658zM29.163 3.24L26.63 1.647a1.364 1.364 0 0 0-1.88.43l-1 1.588l4.843 3.042l1-1.586c.4-.64.21-1.483-.43-1.883zm-3.965 23.82c0 .275-.225.5-.5.5h-19a.5.5 0 0 1-.5-.5v-19a.5.5 0 0 1 .5-.5h13.244l1.884-3H5.698c-1.93 0-3.5 1.57-3.5 3.5v19c0 1.93 1.57 3.5 3.5 3.5h19c1.93 0 3.5-1.57 3.5-3.5V11.097l-3 4.776v11.19z"/>
</svg>
          Edit
        </Button> }

{ (auth.userInfo && !auth.userInfo.role[0].includes('PR')) &&       <Button  className='flex items-center text-white' danger onClick={() => setModalDeleteOpen(true)} >
    
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3 6.524c0-.395.327-.714.73-.714h4.788c.006-.842.098-1.995.932-2.793A3.68 3.68 0 0 1 12 2a3.68 3.68 0 0 1 2.55 1.017c.834.798.926 1.951.932 2.793h4.788c.403 0 .73.32.73.714a.722.722 0 0 1-.73.714H3.73A.722.722 0 0 1 3 6.524ZM11.607 22h.787c2.707 0 4.06 0 4.94-.863c.881-.863.971-2.28 1.151-5.111l.26-4.08c.098-1.537.146-2.306-.295-2.792c-.442-.487-1.188-.487-2.679-.487H8.23c-1.491 0-2.237 0-2.679.487c-.441.486-.392 1.255-.295 2.791l.26 4.08c.18 2.833.27 4.249 1.15 5.112C7.545 22 8.9 22 11.607 22Z"/>
</svg>
          Delete
        </Button>}
      </div>

</div>





<hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

<>

<div class='grid grid-cols-2  md:grid-cols-3 gap-4'>


    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128zm-512 0q0 27 10 50t27 40t41 28t50 10V384zM128 512q27 0 50-10t40-27t28-41t10-50H128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10zm1792-768h-128v896H256v128h1664zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20M768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50z" />
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'>Minimum Salary</span>

        {job.minSalary && <span className='font-semibold '>{job.minSalary} EUR</span>}
        {!job.minSalary && <span className='font-semibold '>Not specified</span>}

      </div>
    </div>

    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M2048 384v1280H128v-256H0V256h1792v128zm-512 0q0 27 10 50t27 40t41 28t50 10V384zM128 512q27 0 50-10t40-27t28-41t10-50H128zm0 512q53 0 99 20t82 55t55 81t20 100h1024q0-53 20-99t55-82t81-55t100-20V640q-53 0-99-20t-82-55t-55-81t-20-100H384q0 53-20 99t-55 82t-81 55t-100 20zm1536 128q-27 0-50 10t-40 27t-28 41t-10 50h128zM128 1280h128q0-27-10-50t-27-40t-41-28t-50-10zm1792-768h-128v896H256v128h1664zM448 896q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m896 0q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19m-448 256q-53 0-99-20t-82-55t-55-81t-20-100V768q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100v128q0 53-20 99t-55 82t-81 55t-100 20M768 896q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50V768q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50z" />
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'>Maximum Salary</span>

        {job.maxSalary && <span className='font-semibold '>{job.maxSalary} EUR</span>}
        {!job.maxSalary && <span className='font-semibold '>Not specified</span>}

      </div>
    </div>


    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
          <path d="M24 42L4 18.5L9.695 6h28.61L44 18.5z" />
          <path d="m32 18l-8 9l-8-9" />
        </g>
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'>Experience </span>

        <span className='font-semibold '>{job.minYearsExperience} - {job.maxYearsExperience === 1000 ? 'No max' : job.maxYearsExperience} year(s)</span>
      </div>
    </div>

    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M3.33 8L10 12l10-6l-10-6L0 6h10v2zM0 8v8l2-2.22V9.2zm10 12l-5-3l-2-1.2v-6l7 4.2l7-4.2v6z" />
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'>Education</span>

        {job.educationRequirement && <span className='font-semibold '>{getLabelByValue(EducationRequirement, job.educationRequirement)}</span>}
        {!job.educationRequirement && <span className='font-semibold '>Not specified</span>}                      </div>
    </div>

    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4" />
        <path fill="currentColor" d="M20.59 22L15 16.41V7h2v8.58l5 5.01z" />
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'>Employement Type</span>
        {job.employmentType && <span className='font-semibold '>{getLabelByValue(JobType, job.employmentType)}</span>}
        {!job.employmentType && <span className='font-semibold '>Not specified</span>}

      </div>
    </div>

    <div className='flex gap-2'>
      <svg className="mt-1" width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillRule="evenodd" d="m8.36 1.37l6.36 5.8l-.71.71L13 6.964v6.526l-.5.5h-3l-.5-.5v-3.5H7v3.5l-.5.5h-3l-.5-.5V6.972L2 7.88l-.71-.71l6.35-5.8zM4 6.063v6.927h2v-3.5l.5-.5h3l.5.5v3.5h2V6.057L8 2.43z" clipRule="evenodd" />
      </svg>
      <div className='flex flex-col justify-start'>
        <span className='font-semibold text-sm text-slate-900'> Remote</span>

        {job.remote && <span className='font-semibold '>{getLabelByValue(Remote, job.remote)}</span>}
        {!job.remote && <span className='font-semibold '>Not specified</span>}                         </div>
    </div>



  </div>



  <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

  { job.keySkills.length > 0 && <> <div className='flex flex-wrap gap-1'>

{
job.keySkills.map(skill => (
<Tag className='text-base text-[#f11a7b] rounded-lg border-[#f11a7b] bg-[#f11a7b1a]' >{skill}</Tag>
))
}



</div>
<hr className="my-6 border-t border-slate-200 dark:border-slate-700" /> </>}
  <h3 className='text-xl font-bold text-slate-900 mb-2'>Job Details</h3>

  <div className='w-full text-sm' style={{wordBreak:"break-word"}}  dangerouslySetInnerHTML={{ __html: job.description }}></div>

   </>




<hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

     <Modal width={800} title="Edit job" open={createModalOpen}  onCancel={()=> setCreateModalOpen(false)} centered footer={null}>
        <FormJob step={step} setStep={setStep} formData={formData} setFormData={setFormData}  setCreateModalOpen={setCreateModalOpen} setIsLoading={setIsLoading} isEdit={true}/>
      </Modal>

      
      <Modal width={500} open={modalDeleteOpen}  onCancel={()=> setModalDeleteOpen(false)} centered footer={null}>

        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Delete Job Post?</div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>Alert!  You're about to delete this job post. Are you sure you're ready to say goodbye?</p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" onClick={(e) => { e.stopPropagation(); setModalDeleteOpen(false); }}>Cancel</button>
              <button onClick={(e) => deleteJobPosting()} className="btn-sm bg-rose-500 hover:bg-rose-600 text-white">Yes, Delete it</button>
            </div>
          </div>
        </div>
      </Modal>
</div>

  )
}

export default JobView