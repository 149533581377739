import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../images/logo-big.png'

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import { toast } from 'react-toastify';
import axios from 'axios';

function ResetToken() {
  const backendURL = process.env.REACT_APP_URL_BACKEND
  const password = useRef("");
  const navigate = useNavigate()


  const [token, setToken] = useState("")
  const [status, setStatus] = useState(null)
  const [url, setURL] = useState(null)

  useEffect(() => {
      if (process.env.REACT_APP_URL_BACKEND) {
          setURL(process.env.REACT_APP_URL_BACKEND);
      }
  }, []);


   useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      setToken(urlParams.get('code'))
  }, [url, token]);


  const handleSend = async (event) => {
    event.preventDefault(); // Prevent default form submission


    const config = {
     headers: {
       'Content-Type': 'application/json',

     },  
   }


   try{
     const { data } = await axios.put(
       `${backendURL}/authentification/api/v1/set?code=${token}`,
       {password: password.current.value  },
       config
     )
     setStatus(data)

     console.log(data)

     toast.success(`Password successfully changed!`);

   }catch(e){
     toast.error(`An error occured, try again!`);
     console.log(e)
     setStatus(e.response)
   }


 
 }
  return (
    <main className="bg-white dark:bg-slate-900">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img style={{width:"200px"}} src={Logo} alt={"logo"} />
                </Link>
              </div>
            </div>

          { !status  && <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Reset your Password </h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">New Password <span className="text-rose-500">*</span></label>
                    <input ref={password} id="password" className="form-input w-full" type="password" />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button onClick={handleSend} className="btn bg-[#f11a7b] hover:bg-pink-500 text-white whitespace-nowrap">Change Password</button>
                </div>
              </form>
            </div>}

            {status && status.isAuthenticated &&
                                          <div className='flex flex-col items-center'> 
                                          <svg width="160" height="160" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                <mask id="ipSSuccess0">
                                                    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                                                        <path fill="#fff" stroke="#fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z" />
                                                        <path stroke="#000" d="m17 24l5 5l10-10" />
                                                    </g>
                                                </mask>
                                                <path fill="#84cc16" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
                                            </svg>
                                            <span>Password successfully changed!</span>
                                            <button onClick={() => navigate('/signin')} className="btn mt-4 bg-[#f11a7b] hover:bg-pink-500 text-white whitespace-nowrap" type={'primary'} size={"large"} >Sign in</button>

                                        </div>

                                        }

                                        {
                                          status && status.status === 404 &&

                                          <div className='flex flex-col items-center'> 
                                          <svg width="200" height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <path fill="#ef4444" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                                          </svg>
                                          <span>An error occured</span>
                                          <button onClick={() => navigate('/')} className="btn mt-4 bg-[#f11a7b] hover:bg-pink-500 text-white whitespace-nowrap" type={'primary'} size={"large"} >Return</button>
                                      </div>
                                        }

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

export default ResetToken;