import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/LandingPage/ui/header';
import Footer from '../components/LandingPage/ui/footer';
import HeroAbout from '../components/AboutUs/hero-about';
import TeamImages from '../components/AboutUs/team-images';
import Timeline from '../components/AboutUs/timeline';
import Team from '../components/AboutUs/team';
import PageIllustration from '../components/LandingPage/page-illustration';
import HeroFeatures from '../components/LandingPage/Features/hero-features';
import ManageCV from '../components/LandingPage/Features/manageCV';


function Features() {
  return (
    <main className="bg-white dark:bg-slate-900 overflow-x-hidden">
                  <PageIllustration/>

      <Header/>

        <HeroFeatures/>

        <ManageCV/>
        <Footer/>

    </main>
  );
}

export default Features;