import React from 'react'



const DetailsProject= ({ project }) => {

  const formatDate = (dateString) => {
    const parts = dateString.split('.');
    if (parts.length === 3) {
        const date = new Date(parts[2], parts[1] - 1, parts[0]);
        return date.toLocaleDateString('en-EN', { month: 'short', year: 'numeric' }).replace('.', '');
    }
    return dateString;
  };

  return (
    <div className='flex mb-3 flex-col' >

      <div className='flex flex-1 items-baseline justify-between'>
        <span className='font-bold uppercase'>{project.projectTitle}</span>
      </div>
      <div className='flex flex-1 items-baseline justify-between'>
        <span className='font-bold'>{project.projectSubtitle}</span>
      </div>       
       <span className='text-xs '>{formatDate(project.startDate)} – {project.endDate?formatDate(project.endDate):'Present'}</span>

      <div
          className='text-xs  mt-4 text-content'  style={{wordBreak:'break-word'}}
          dangerouslySetInnerHTML={{ __html: project.description }}
        />


      

      


    </div>
  )
}

export default DetailsProject