// authSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { createCompany, deleteCompany, getCompaniesByAdmins, getCompanyById, getMyCompany } from '../actions/companies'


const initialState = {
  loading: false,
  companyInfo: null,
  companies: [],
  error: null,
  errorMsg: null,
  success: false,
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {

  },
  extraReducers: {

    [getMyCompany.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getMyCompany.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.companyInfo = payload.data
    },
    [getMyCompany.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getCompanyById.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getCompanyById.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.companyInfo = payload
    },
    [getCompanyById.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [getCompaniesByAdmins.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },
    [getCompaniesByAdmins.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.companies = payload
    },
    [getCompaniesByAdmins.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
      state.companies = []
    },


    [createCompany.pending]: (state) => {
      state.loading = true
      state.errorMsg = null
    },

    [createCompany.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.companies = [payload, ...state.companies]
    },

    [createCompany.rejected]: (state, { payload }) => {
      state.loading = false
      state.errorMsg = payload
      state.error = true
    },

    [deleteCompany.pending]: (state) => {
      state.loading = true;
      state.errorMsg = null;
    },
    
    [deleteCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.companies = state.companies.filter(company => company._id !== payload._id);
    },
    
    [deleteCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMsg = payload;
      state.error = true;
    },


  },
})


export default companySlice.reducer