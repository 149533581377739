import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        fontFamily: 'Times-Roman'

    },
    name: {
        fontSize: 20,
        fontFamily: 'Times-Bold',
        marginBottom: 12,
        margin: '0px auto',
        color: 'black',

    },
    jobTitle: {
        fontSize: 18,
        margin: '0px auto',
        marginBottom: 10,

    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontWeight: 'bold',
        marginBottom: 20,
        fontSize: 14,
        gap: 20,
        lineHeight: -2,
    },

    headerText: {
        fontFamily: 'Times-Bold',
        marginBottom: 0,
        padding: 0

    },

    sectionHeader: {
        fontSize: 16,
        fontFamily: 'Times-Bold',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        marginTop: 10,
        marginBottom: 10,
    },

    sectionHeaderItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 14,
        marginBottom: 1
    },

    sectionTitleItem: {
        fontSize: 16,
        fontFamily: 'Times-Bold',
    },
    sectionItem: {
        marginBottom: 2,
        fontSize: 14

    },

    skillsContainer: {
        flexDirection: 'row', // set direction to row for two columns
        flexWrap: 'wrap', // allow items to wrap to next line
      },

      skillItem: {
        width:"50%",
      },
});

const stylesheet = {
    // clear margins for all <p> tags

    u:{
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
    },
    p: {
        margin: 0,
        fontSize: 14
    },
    strong:{
        fontFamily: 'Times-Bold'
    },

    em:{
        fontFamily: 'Times-Italic'
    },
    'strong em': {
        fontFamily: 'Times-BoldItalic'

      } ,
    ol: {
        width: '100%',
        color: 'red',
        flexDirection: 'column', // changed 'col' to 'column'
        backgroundColor: 'red'
    },

    // add pink background color to elements with class="special"
    ['.special']: {
        backgroundColor: 'pink',
    },
};
const Quixote = ({ resume }) => (
    <Document>
        <Page style={styles.page}>
            <Text style={styles.name}>{resume.fullname}</Text>
            <Text style={styles.jobTitle}>{resume.jobtitle}</Text>
            <View style={styles.header}>
                <Text>{resume.email}</Text>
                <Text>{resume.phone}</Text>
                <Text>{resume.localisation}</Text>
                <Text>{resume.nationality}</Text>
                <Text>{resume.gender}</Text>
                <Text>{resume.drivinglicense}</Text>

            </View>


            {
                (resume.statessections.education && resume.education.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>Education</Text>

                    {
                        resume.education.map((education) => (
                            <> {!education.ishidden &&
                                <View style={styles.sectionItem}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem} >{education.school}</Text>
                                        <Text>{education.city} {(education.city && education.country) && ',' } {education.country}</Text>

                                    </View>
                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={{ fontFamily: 'Times-Italic' }}>{education.degree}</Text>
                                        <Text>{`${education.startdate.year}`}{ (education.startdate.year && !education.enddate.noshow ) &&  '-'}{`${education.enddate.year}`}{education.enddate.current && 'ongoing'}</Text>
                                    </View>
                                    {
                                        education.description && <View>
                                            <Text style={styles.sectionItem}>
                                                <Html stylesheet={stylesheet}>
                                                    {education.description}

                                                </Html>
                                            </Text>

                                        </View>
                                    }

                                </View>}
                            </>
                        ))
                    }


                </>

            }

            {
                (resume.statessections.professionalexperience && resume.professionalexperience.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>EXPERIENCE</Text>

                    {
                        resume.professionalexperience.map((exp) => (
                            <>{!exp.ishidden &&
                                <View style={styles.sectionItem}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{exp.employer}</Text>
                                        <Text>{exp.city} {(exp.city && exp.country) && ',' } {exp.country}</Text>

                                    </View>
                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={{ fontFamily: 'Times-Italic' }}>{exp.jobtitle}</Text>
                                        <Text>{`${exp.startdate.year}`}{ (exp.startdate.year && !exp.enddate.noshow ) &&  '-'}{`${exp.enddate.year}`}{exp.enddate.current && 'ongoing'}</Text>
                                    </View>
                                    {exp.description && <View>
                                        <Text style={styles.sectionItem}>

                                            <Html stylesheet={stylesheet}>
                                                {exp.description}

                                            </Html>
                                        </Text>

                                    </View>}
                                </View>}
                            </>
                        ))
                    }


                </>

            }

{
                (resume.statessections.project && resume.project.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>PROJET</Text>

                    {
                        resume.project.map((project) => (
                            <>{!project.ishidden &&
                                <View style={styles.sectionItem}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{project.projecttitle}</Text>
                                        <Text>{project.city} {(project.city && project.country) && ',' } {project.country}</Text>

                                    </View>
                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={{ fontFamily: 'Times-Italic' }}>{project.subtitle}</Text>
                                        <Text>{`${project.startdate.year}`}{ (project.startdate.year && !project.enddate.noshow ) &&  '-'}{`${project.enddate.year}`}{project.enddate.current && 'ongoing'}</Text>
                                    </View>
                                    {project.description && <View>
                                        <Text style={styles.sectionItem}>

                                            <Html stylesheet={stylesheet}>
                                                {project.description}

                                            </Html>
                                        </Text>

                                    </View>}
                                </View>}
                            </>
                        ))
                    }


                </>

            }

            {
                (resume.statessections.skill && resume.skill.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>SKILL</Text>

                    <View style={styles.skillsContainer}>

                    {
                        resume.skill.map((skill) => (
                            <>{!skill.ishidden &&
                                <View style={styles.skillItem}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{skill.skill} {skill.skilllevel && <>({skill.skilllevel})</>}</Text>
                                    </View>
                                    <Text style={styles.sectionItem}>{skill.skillinfos}</Text>

                                </View>}
                            </>
                        ))
                    }

                    </View>


                </>

            }

{
                (resume.statessections.language && resume.language.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>LANGUAGE</Text>

                    <View style={styles.skillsContainer}>

                    {
                        resume.language.map((language) => (
                            <>{!language.ishidden &&
                                <View style={{...styles.skillItem}}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{language.language}  {language.languagelevel && <>({language.languagelevel})</>}</Text>
                                    </View>
                                    <Text style={styles.sectionItem}>{language.languageinfos}</Text>

                                </View>}
                            </>
                        ))
                    }

                    </View>


                </>

            }

{
                (resume.statessections.certificate && resume.certificate.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>CERTIFICATE</Text>

                    <View style={styles.skillsContainer}>

                    {
                        resume.certificate.map((certificate) => (
                            <>{!certificate.ishidden &&
                                <View style={{...styles.skillItem}}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{certificate.certificate}  {certificate.certificatelevel && <>({certificate.certificatelevel})</>}</Text>
                                    </View>
                                    <Text style={styles.sectionItem}>{certificate.certificateinfos}</Text>

                                </View>}
                            </>
                        ))
                    }

                    </View>


                </>

            }

{
                (resume.statessections.hobby && resume.hobby.length > 0) &&

                <>
                    <Text style={styles.sectionHeader}>HOBBY</Text>

                    <View style={styles.skillsContainer}>

                    {
                        resume.hobby.map((hobby) => (
                            <>{!hobby.ishidden &&
                                <View style={{...styles.skillItem}}>

                                    <View style={styles.sectionHeaderItem}>
                                        <Text style={styles.sectionTitleItem}>{hobby.hobby}</Text>
                                    </View>
                                    <Text style={styles.sectionItem}>{hobby.hobbyinfos}</Text>

                                </View>}
                            </>
                        ))
                    }

                    </View>


                </>

            }
        </Page>
    </Document>
);

export default Quixote;
