import React, { useEffect, useState } from 'react'
import {AiOutlinePhone } from 'react-icons/ai';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { updateResume } from '../../redux/actions/resumes';
import { removeNullProperties } from '../../utils/Utils';
import { toast } from 'react-toastify';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';


export default function DatasPersoNew({ resume }) {

  const dispatch = useDispatch()
  const [dataPersoIsEdit, setDataPersoIsEdit] = useState(false)
  const { auth } = useSelector(state => state)
  const [form] = Form.useForm();

  const [date, setDate] = useState(null)


  const [formData, setFormData] = useState({ ...resume });

  // Initialize form values with resume data
  useEffect(() => {
    const { dateBirth, ...restOfData } = formData; // Destructure to exclude 'dateBirth'
    form.setFieldsValue({ ...restOfData });
   // form.setFieldsValue({...formData });
  }, [formData, form]);



  const days = [];
  for (let i = 1; i <= 31; i++) {
    // Convert the number to a string and pad with leading zeros if necessary
    const day = i.toString().padStart(2, '0');
    days.push(<option key={i} value={day}>{day}</option>);
  }

  const genders = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHER", label: "Other" },

  ];


  const handleChangeFieldsDataSelected = (name, value) => {
    console.log(value)
    let field = ''
    if (name === 'isDateBirth') {
      field = 'birthDate'
    }
    if (name === 'nationality') {
      field = 'nationality'
      name = 'isNationality'
    }
    if (name === 'isGender') {
      field = 'gender'
    }

    console.log(field)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: '',
      presentInfo: {
        ...prevFormData.presentInfo,
        [name]: false,
      },
    }));





  };

  const handleCancel = async () => {
    setDataPersoIsEdit(false)
    setFormData(resume)
  }



  const handleSave = async () => {
    //setDataPersoIsEdit(false);
    try {
      let resumeData = formData;
      console.log(resumeData)
   

      if (!formData.presentInfo.isGender) {
        delete resumeData.gender;
      }

   
      console.log(resumeData)

      if(date){
        resumeData.dateBirth = date
      }
      if (!formData.presentInfo.isDateBirth) {
        delete resumeData.dateBirth;
      }

      let res = await dispatch(updateResume({ id: resume.id, resumeData: removeNullProperties(resumeData), token: auth.userToken }));
      if (res.type === 'resume/update/rejected') {
        console.log(res)
        toast.error(res.payload.response.data || "An error occurred while updating the resume.")
      } else {
        toast.success("Success!")

      }
      // Handle the success case, e.g. show a success message
    } catch (error) {
      console.error(error);
      // Handle the error case, e.g. show an error message
      alert('An error occurred while updating the resume.');
    }
  };

  const handleFieldClick = (field) => {

    setFormData((prevFormData) => ({
      ...prevFormData,
      presentInfo: {
        ...prevFormData.presentInfo,
        [field]: true,
      },
    }));


  };


  const handleChangeInput = e => {
    const { name, value } = e.target
    console.log(name)
    setFormData({ ...formData, [name]: value })

}

const handleChangeSelected = (value, option) => {
  console.log("Selected Value: ", value);
  console.log("Option selected: ", option); // option provides more details if needed
  setFormData({ ...formData, ['gender']: value })

};

const onChange = (date, dateString) => {
  if (date) {
    console.log(dateString);  // Outputs the formatted date string

    console.log(date.year()); // Accessing the year from a dayjs object, if directly using dayjs
    setDate(dateString)
  }
};



/* setFormData({ ...formData, ['dateBirth']: dateString })
 */
  return (
    <>


      {
        !dataPersoIsEdit && <div className='relative flex flex-col items-start px-5 py-5 md:px-10 md:py-6 bg-white dark:bg-slate-800  rounded-2xl border border-slate-200 dark:border-slate-700
           hover:cursor-pointer' style={{ wordBreak: 'break-word' }} onClick={() => setDataPersoIsEdit(true)}>


          <button className="absolute top-2 right-2 btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
            <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 shrink-0" viewBox="0 0 16 16">
              <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
            </svg>
          </button>
          <h3 className='text-xl font-bold'>{formData.fullname}</h3>
          <h4 className='text-lg '>{formData.jobTitle}</h4>

          <div className='flex items-center mt-4'>
            <HiOutlineMail className='text-2xl  mr-2' />
            <span className=''>{formData.email}</span>
          </div>

          <div className='flex items-center mt-4'>
            <AiOutlinePhone className='text-2xl mr-2' />
            <span className=''>{formData.phoneNumber}</span>
          </div>

          <div className='flex items-center mt-4'>
            <HiOutlineLocationMarker className='text-2xl  mr-2' />
            <span className=''>{formData.localisation}</span>
          </div>
        </div>
      }

      {
        dataPersoIsEdit && <div className='relative flex flex-col items-start px-5 py-5 md:px-10 md:py-6 bg-white dark:bg-slate-800 shadow-lg rounded-2xl border border-slate-200 dark:border-slate-700 hover:cursor-default'>

          <h3 className='text-xl font-semibold text-slate-800 dark:text-slate-100'>Edit Personal Details</h3>

          <Form
            form={form}
            className='w-full pt-6'
            layout="vertical"
            onFinish={handleSave}

          >

            <Form.Item
              label="Full Name"
              name="fullname"
              onChange={(e) => handleChangeInput(e)}

              rules={[{ required: true, message: 'Please input the full name!' }]}
            >
              <Input size='large'name="fullname" className='antd-input' />
            </Form.Item>


            <div className='grid grid-cols-2 gap-2 w-full'>
              <Form.Item
                label="Email"
                name="email"
                onChange={(e) => handleChangeInput(e)}
              >
                <Input size='large' name="email" className='antd-input' max={120} />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phoneNumber"
                onChange={(e) => handleChangeInput(e)}

              >
                <Input size='large' name="phoneNumber" className='antd-input' max={120} />
              </Form.Item>

            </div>

            <Form.Item
              label="Address"
              name="localisation"
              onChange={(e) => handleChangeInput(e)}

            >
              <Input size='large' name="localisation" className='antd-input' max={120} />
            </Form.Item>


            <h3 className='text-xl font-semibold text-slate-800 dark:text-slate-100 mb-6 '>Personal Information</h3>

            <div className='grid grid-cols-2 gap-2 w-full'>

            {formData.presentInfo.isDateBirth &&

              <div className='flex items-center gap-1 '>
                <Form.Item
                  label="Birthdate"
                  name="dateBirth"
                  className='w-full'
                  initialValue={formData.dateBirth?dayjs(formData.dateBirth, 'DD.MM.YYYY'):''}
                >
                   <DatePicker size='large'                   
 className='antd-input w-full' format={'YYYY-MM-DD'} onChange={onChange} needConfirm /> 

                </Form.Item>


                  <button onClick={() => { handleChangeFieldsDataSelected('isDateBirth', false) }} className="btn mt-1 h-9 dark:bg-slate-800 self-center border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
                    <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                      <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                    </svg>
                  </button>
              </div>
            }

          {
              formData.presentInfo.isGender &&

              <div className='flex items-center gap-1 w-full '>

                  <Form.Item
                  label="Gender"
                  name="gender"
                  className='w-full'

                >
                  <Select  size="large" name="gender"                   onChange={handleChangeSelected} 
 >
                    {genders.map((type, index) => (
                      <Select.Option  key={index} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <button onClick={() => { handleChangeFieldsDataSelected('isGender', false) }} className="btn h-9 mt-1 dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
                <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                  <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                </svg>
              </button>

                </div>
            }

            </div>

            {


              formData.presentInfo.isNationality &&
              <div className='flex items-center gap-1 w-full '>

              <Form.Item
                label="Nationality"
                name="nationality"
                className='w-full'
                onChange={(e) => handleChangeInput(e)}

              >
                <Input size='large' name="nationality" className='antd-input' max={120} />
              </Form.Item>

              <button onClick={() => { handleChangeFieldsDataSelected('isNationality', false) }} className="btn mt-1 h-9 dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600">
                <svg className="w-4 h-4 fill-current text-rose-500 shrink-0" viewBox="0 0 16 16">
                  <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                </svg>
              </button>


              </div>

            }

<div className='flex flex-wrap mt-4 mb-4 w-full gap-2'>
            {!formData.presentInfo.isDateBirth && <div className='d-flex justify-center items-center border-solid border-[1px] text-sm px-2 border-slate-200 dark:border-pink-500 rounded hover:bg-slate-100 hover:cursor-pointer' onClick={() => { handleFieldClick("isDateBirth"); }}> Date of Birth </div>}
            {!formData.presentInfo.isNationality && <div className='d-flex justify-center items-center border-solid border-[1px] text-sm px-2 border-slate-200 dark:border-pink-500 rounded hover:bg-slate-100 hover:cursor-pointer' onClick={() => { handleFieldClick("isNationality"); }}> Nationality </div>}
            {/*             {!formData.presentInfo.drivinglicense && <div className='d-flex justify-center items-center border-solid border-[1px] text-sm px-2 border-slate-200 dark:border-pink-500 rounded hover:bg-slate-100 hover:cursor-pointer'  onClick={() => { handleFieldClick("drivinglicense"); }}> Driving License </div>}
 */}            {!formData.presentInfo.isGender && <div className='d-flex justify-center items-center border-solid border-[1px] text-sm px-2 border-slate-200 dark:border-pink-500 rounded hover:bg-slate-100 hover:cursor-pointer' onClick={() => { handleFieldClick("isGender"); }}> Gender </div>}

          </div>


<div className="flex items-center justify-end gap-2">
            <Button
              onClick={handleCancel}
              type="default"
              className="flex items-center  !rounded-2xl gap-2"
              size="large"
            >
             Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center !rounded-2xl  bg-[#f11a7b] gap-2"
              size="large"
            >
             Save
            </Button>
          </div>

      



          </Form>

  





        </div>
      }

    </>
  )
}

