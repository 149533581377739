import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/LandingPage/ui/header';
import Footer from '../components/LandingPage/ui/footer';

import Team from '../components/AboutUs/team';
import PageIllustration from '../components/LandingPage/page-illustration';


function AboutUs() {
  return (
    <main className="bg-white dark:bg-slate-900 overflow-x-hidden">

        <Header/>

   
        <Team/>
        <Footer/>

    </main>
  );
}

export default AboutUs;