import React from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Input } from "antd";

const SearchAddress = ({ onAddressChange, address, setAddress }) => {
 

  return (

          <Autocomplete
          onLoad={(autocomplete) => {
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                setAddress(place.formatted_address);
                onAddressChange(place);              
            });
          }}
          >
            <Input
              size="large"
              value={address}
              placeholder="Enter a city"
              className='antd-input'
              onChange={(e) => setAddress(e.target.value)}
            />
          </Autocomplete>
        
  );
};



export default SearchAddress;
