import cv from '../../images/landing/cv.png'

export default function Goal() {
  return (
    <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-20 ">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-aos="fade-up">
          <h2 className="h2 text-black mb-4">BUILD YOUR CV WITH US</h2>
        {/*   <p className="text-xl text-gray-400">Digital credentials are like digital badges given by institutions, covering diplomas, skills, and transcripts.
Using the blockchain, a secure and shared system, these documents are kept safe and sound. This means people can show off their online certificates, which are trusted and valued, for their whole life!</p> */}
        </div>

        <div className=' flex-col md:flex-row flex'>
            <div className='hidden md:flex flex-col justify-around w-1/4'>
                <div className='flex flex-col justify-start border-t p-2 border-black' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Beautiful</h3>
                    <span className='text-black'>Elegantly designed for visual appeal</span>
                </div>

                <div className='flex flex-col justify-start border-t p-2 border-black ' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>One page</h3>
                    <span className='text-black'>Concise, with only necessary details on one A4</span>
                </div>

            </div>
            <div className='w-100 md:w-2/4' data-aos="fade-up" data-aos-delay="200">
              <img src={cv} alt="cv"/>
            </div>

            <div className=' hidden md:flex flex-col justify-around w-1/4'>
                <div className='flex flex-col justify-start border-t p-2 border-black' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Interactive</h3>
                    <span className='text-black'>With clickable sections for expanded details</span>
                </div>  
                <div className='flex flex-col justify-start border-t p-2 border-black'data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Verified</h3>
                    <span className='text-black'>Authentic and fraud-checked</span>
                </div>  
                <div className='flex flex-col justify-start border-t p-2 border-black'data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Standardized</h3>
                    <span className='text-black'>Uniformly formatted for unbiased comparison and easy filtering</span>
                </div>
            </div>

            <div className='flex md:hidden  mt-10 flex-col justify-around w-100'>
                <div className='flex flex-col justify-start border-t p-4 border-black' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Beautiful</h3>
                    <span className='text-black'>Elegantly designed for visual appeal</span>
                </div>

                <div className='flex flex-col justify-start border-t p-4 border-black ' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>One page</h3>
                    <span className='text-black'>Concise, with only necessary details on one A4</span>
                </div>

                <div className='flex flex-col justify-start border-t p-4 border-black' data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Interactive</h3>
                    <span className='text-black'>With clickable sections for expanded details</span>
                </div>  
                <div className='flex flex-col justify-start border-t p-4 border-black'data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Verified</h3>
                    <span className='text-black'>Authentic and fraud-checked</span>
                </div>  
                <div className='flex flex-col justify-start border-t p-4 border-black'data-aos="fade-up" data-aos-delay="200">
                    <h3 className='text-black   text-2xl font-bold'>Standardized</h3>
                    <span className='text-black'>Uniformly formatted for unbiased comparison and easy filtering</span>
                </div>

            </div>
 

        </div>

      </div>
    </div>
  </section>
  )
}
